import { Heading } from "@web/ui";

interface Props {
  className?: string;
  title?: string;
  children?: React.ReactNode;
  testId?: string;
}

export const SidebarBox = ({ className = "", title, children, testId = "sidebarBox" }: Props) => {
  return (
    <div
      className={`flex flex-col px-5 py-4 rounded-lg border bg-neutral_0 shadow ${className}`}
      data-testid={testId}
    >
      {title && (
        <div className="flex flex-row items-center justify-between pb-4">
          <Heading size="300">{title}</Heading>
        </div>
      )}
      <div className="pb-4">{children}</div>
    </div>
  );
};
export default SidebarBox;
