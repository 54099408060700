import { useCallback } from "react";

import { useFeatureFlagsManagementContext } from "../context/FeatureFlagsManagementContext";
import {
  Environment,
  ManagedFeatureFlags,
  ManagedUnleashFeatureFlags,
  SetUnleashFlagsStateActionPayload,
  UnleashContextFlagProperties,
} from "../models";

type UseFeatureFlagsManagement = {
  environment?: Environment;
  isFeatureFlagsManagementAllowed: boolean;
  unleashFlagsState: ManagedUnleashFeatureFlags;
  setUnleashFlagsState: (stateToSet: SetUnleashFlagsStateActionPayload) => void;
  flagsOverrideState: ManagedFeatureFlags;
  setFlagsOverrideState: (stateToSet: ManagedFeatureFlags) => void;
  unleashContextFlagProperties: UnleashContextFlagProperties;
};

export const useFeatureFlagsManagement = (): UseFeatureFlagsManagement => {
  const [featureFlagsManagementContext, dispatch] = useFeatureFlagsManagementContext();

  const environment = featureFlagsManagementContext.environment;
  const isFeatureFlagsManagementAllowed = featureFlagsManagementContext.isFlagsManagementAllowed;
  const unleashFlagsState = isFeatureFlagsManagementAllowed
    ? featureFlagsManagementContext.unleashFlagsState
    : {};
  const flagsOverrideState = isFeatureFlagsManagementAllowed
    ? featureFlagsManagementContext.flagsOverrideState
    : {};
  const unleashContextFlagProperties = isFeatureFlagsManagementAllowed
    ? featureFlagsManagementContext.unleashContextFlagProperties
    : {};

  const setUnleashFlagsState = useCallback(
    (stateToSet: SetUnleashFlagsStateActionPayload) => {
      if (!isFeatureFlagsManagementAllowed) {
        return;
      }

      dispatch({
        type: "setUnleashFlagsState",
        payload: stateToSet,
      });
    },
    [dispatch, isFeatureFlagsManagementAllowed]
  );

  const setFlagsOverrideState = useCallback(
    (stateToSet: ManagedFeatureFlags) => {
      if (!isFeatureFlagsManagementAllowed) {
        return;
      }

      dispatch({
        type: "setFlagsOverrideState",
        payload: stateToSet,
      });
    },
    [dispatch, isFeatureFlagsManagementAllowed]
  );

  return {
    environment,
    isFeatureFlagsManagementAllowed,
    unleashFlagsState,
    setUnleashFlagsState,
    flagsOverrideState,
    setFlagsOverrideState,
    unleashContextFlagProperties,
  };
};
