import { TextColors } from "../../../utils";

interface LoadingProps {
  className?: string;
  size?: string;
}

export const Loading: React.FC<LoadingProps> = ({ className = "", size = "5" }) => (
  <div
    data-testid="loader"
    className={`h-full w-full flex justify-center items-center ${className}`}
  >
    <svg
      className={`animate-spin h-${size} w-${size}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 48 48"
      focusable={false}
      aria-label="Loading in progress"
    >
      <path
        opacity="0.3"
        fill="currentColor"
        d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24ZM40.5164 24C40.5164 14.8782 33.1218 7.48361 24 7.48361C14.8782 7.48361 7.48361 14.8782 7.48361 24C7.48361 33.1218 14.8782 40.5164 24 40.5164C33.1218 40.5164 40.5164 33.1218 40.5164 24Z"
      ></path>
      <path
        fill="currentColor"
        d="M24 44.2582C24 46.3247 25.6854 48.0299 27.7269 47.709C32.7116 46.9254 37.3577 44.5834 40.9706 40.9706C44.5834 37.3577 46.9254 32.7116 47.709 27.7269C48.0299 25.6854 46.3247 24 44.2582 24V24C42.1917 24 40.5586 25.6963 40.0944 27.71C39.4042 30.7038 37.8862 33.4715 35.6789 35.6789C33.4715 37.8862 30.7038 39.4042 27.71 40.0944C25.6963 40.5586 24 42.1917 24 44.2582V44.2582Z"
      ></path>
    </svg>
  </div>
);

interface LoadingInlineProps {
  size?: string;
  display?: string;
  baseColor?: TextColors;
}

export const LoadingInline: React.FC<LoadingInlineProps> = ({
  baseColor = "current",
  size = "5",
  display = "inline",
}) => (
  <svg
    className={`animate-spin h-${size} w-${size} ${display} ${baseColor}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    focusable={false}
    aria-label="Loading in progress"
  >
    <path
      opacity="0.3"
      fill="currentColor"
      d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24ZM40.5164 24C40.5164 14.8782 33.1218 7.48361 24 7.48361C14.8782 7.48361 7.48361 14.8782 7.48361 24C7.48361 33.1218 14.8782 40.5164 24 40.5164C33.1218 40.5164 40.5164 33.1218 40.5164 24Z"
    ></path>
    <path
      fill="currentColor"
      d="M24 44.2582C24 46.3247 25.6854 48.0299 27.7269 47.709C32.7116 46.9254 37.3577 44.5834 40.9706 40.9706C44.5834 37.3577 46.9254 32.7116 47.709 27.7269C48.0299 25.6854 46.3247 24 44.2582 24V24C42.1917 24 40.5586 25.6963 40.0944 27.71C39.4042 30.7038 37.8862 33.4715 35.6789 35.6789C33.4715 37.8862 30.7038 39.4042 27.71 40.0944C25.6963 40.5586 24 42.1917 24 44.2582V44.2582Z"
    ></path>
  </svg>
);
