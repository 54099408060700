import { OrderVersionResponse, SupplierPortalAppService } from "src/typegens";

import { SupplierDeliverOrderParams } from "../models";

export const deliverOrder = async ({
  s2SOrderId,
  requestBody,
}: SupplierDeliverOrderParams): Promise<OrderVersionResponse> => {
  const result = await SupplierPortalAppService.deliverOrder({ s2SOrderId, requestBody });

  return result;
};
