import { AnnotationIcon } from "@heroicons/react/solid";

import { Label, Paragraph } from "@web/ui";
import { LEGACY_formatDateTime } from "@web/utils";

interface Comment {
  name: string;
  date: string;
  text?: string;
}

export const Comment: React.FC<Comment> = ({ name, date, text }) => {
  return (
    <div className="flex" data-testid="singleTransactionalComment">
      <div className="flex flex-col items-center">
        <AnnotationIcon className="text-textIcon-blackSecondary w-5 h-5 mr-2 mt-1" />
        <div className="w-1 h-full border-l-2 mt-1 pl-1" />
      </div>
      <div className="flex flex-col pb-3" data-testid="singleTransactionalComment_name">
        <Label size="200">{name}</Label>
        <Paragraph size="300" color="text-textIcon-blackSecondary" className="pt-2">
          {LEGACY_formatDateTime(new Date(date))}
        </Paragraph>
        <div className="p-2 mt-2 border rounded shadow flex">
          <div className="w-1 h-full border-l-2 mr-2" />
          <Label size="200">{text}</Label>
        </div>
      </div>
    </div>
  );
};
