export const Cloud = () => {
  return (
    <svg
      width={360}
      height={164}
      viewBox="0 0 360 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M227.773 149.967H77.0775C70.6754 149.967 65.4855 144.78 65.4855 138.382C65.4855 131.984 70.6754 126.797 77.0775 126.797H12.4935C6.0914 126.797 0.901489 121.61 0.901489 115.212C0.901489 108.814 6.0914 103.627 12.4935 103.627H78.7335C85.1356 103.627 90.3255 98.4404 90.3255 92.0422C90.3255 85.644 85.1356 80.4572 78.7335 80.4572H37.3335C30.9314 80.4572 25.7415 75.2704 25.7415 68.8722C25.7415 62.474 30.9314 57.2872 37.3335 57.2872H103.573C97.1714 57.2872 91.9815 52.1004 91.9815 45.7022C91.9815 39.304 97.1714 34.1172 103.573 34.1172H197.965C204.368 34.1172 209.557 39.304 209.557 45.7022C209.557 52.1004 204.368 57.2872 197.965 57.2872H303.949C310.352 57.2872 315.541 62.474 315.541 68.8722C315.541 75.2704 310.352 80.4572 303.949 80.4572H340.381C346.784 80.4572 351.973 85.644 351.973 92.0422C351.973 98.4404 346.784 103.627 340.381 103.627H308.917C302.515 103.627 297.325 108.814 297.325 115.212C297.325 121.61 302.515 126.797 308.917 126.797H318.853C325.256 126.797 330.445 131.984 330.445 138.382C330.445 144.78 325.256 149.967 318.853 149.967H232.741C231.889 149.967 231.058 149.875 230.257 149.701C229.457 149.875 228.626 149.967 227.773 149.967Z"
        fill="#F0F5FF"
      />
      <ellipse cx="348.005" cy="138.382" rx="11.592" ry="11.585" fill="#F0F5FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.048 160.38C105.927 160.38 89.6161 144.449 89.6161 124.797C89.6161 105.145 105.927 89.2146 126.048 89.2146C126.904 89.2146 127.752 89.2434 128.593 89.3001C128.009 86.0499 127.704 82.7029 127.704 79.2846C127.704 48.2076 152.912 23.0146 184.008 23.0146C208.833 23.0146 229.906 39.0714 237.395 61.3602C239.17 61.1747 240.972 61.0796 242.796 61.0796C270.691 61.0796 293.304 83.3087 293.304 110.73C293.304 136.78 272.894 158.273 246.936 160.344V160.38H153.385H126.048ZM145.913 160.38H134.38H145.913Z"
        fill="white"
      />
      <path
        d="M145.913 160.38H134.38M126.048 160.38C105.927 160.38 89.6161 144.449 89.6161 124.797C89.6161 105.145 105.927 89.2146 126.048 89.2146C126.904 89.2146 127.752 89.2434 128.593 89.3001C128.009 86.0499 127.704 82.7029 127.704 79.2846C127.704 48.2076 152.912 23.0146 184.008 23.0146C208.833 23.0146 229.906 39.0714 237.395 61.3602C239.17 61.1747 240.972 61.0796 242.796 61.0796C270.691 61.0796 293.304 83.3087 293.304 110.73C293.304 136.78 272.894 158.273 246.936 160.344V160.38H153.385H126.048Z"
        stroke="#2F6FED"
        strokeWidth={7}
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.678 37.0101C167.678 88.674 214.235 131.246 274.183 136.993C266.671 146.226 255.069 152.489 241.852 153.473V153.503H134.198C121.136 153.503 99.3159 148.003 99.3159 123.919C99.3159 99.8351 116.28 94.3352 134.198 94.3352C134.96 94.3352 135.716 94.3592 136.465 94.4063C135.944 91.704 135.853 88.9187 135.673 86.0792C134.456 66.8984 142.829 42.7249 167.707 34.8574C167.687 35.5746 167.678 36.2915 167.678 37.0101ZM184.53 117.842C179.655 117.842 175.703 121.745 175.703 126.559C175.703 131.373 179.655 135.276 184.53 135.276C189.405 135.276 193.357 131.373 193.357 126.559C193.357 121.745 189.405 117.842 184.53 117.842Z"
        fill="#F0F5FF"
      />
      <path
        d="M184.836 137.21C189.866 137.21 193.944 133.135 193.944 128.107C193.944 123.08 189.866 119.005 184.836 119.005C179.806 119.005 175.728 123.08 175.728 128.107C175.728 133.135 179.806 137.21 184.836 137.21Z"
        stroke="#2F6FED"
        strokeWidth={3}
      />
      <path
        d="M159.168 109.075L170.76 99.9835L159.168 91.3291"
        stroke="#2F6FED"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M210.504 109.075L198.912 99.9835L210.504 91.3291"
        stroke="#2F6FED"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M200.568 39.5649C211.347 42.4158 219.857 50.8402 222.825 61.5652"
        stroke="#1E4EAE"
        strokeWidth={3}
        strokeLinecap="round"
      />
      <path
        d="M235.344 11.43C238.088 11.43 240.312 9.20709 240.312 6.465C240.312 3.72291 238.088 1.5 235.344 1.5C232.6 1.5 230.376 3.72291 230.376 6.465C230.376 9.20709 232.6 11.43 235.344 11.43Z"
        stroke="#1E4EAE"
        strokeWidth={2}
      />
      <path
        d="M286.68 37.91C289.424 37.91 291.648 35.6871 291.648 32.945C291.648 30.2029 289.424 27.98 286.68 27.98C283.936 27.98 281.712 30.2029 281.712 32.945C281.712 35.6871 283.936 37.91 286.68 37.91Z"
        fill="#1E4EAE"
      />
      <path
        d="M248.19 24.2686L262.031 38.1014M262.242 24.2686L248.401 38.1014L262.242 24.2686Z"
        stroke="#1E4EAE"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.9408 53.7915L101.877 63.7215M101.877 53.7915L91.9408 63.7215L101.877 53.7915Z"
        stroke="#1E4EAE"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.456 42.8748C117.2 42.8748 119.424 40.6519 119.424 37.9098C119.424 35.1677 117.2 32.9448 114.456 32.9448C111.712 32.9448 109.488 35.1677 109.488 37.9098C109.488 40.6519 111.712 42.8748 114.456 42.8748Z"
        fill="#1E4EAE"
      />
    </svg>
  );
};
