import { SupplierPortalAppService } from "src/typegens";

import { ExportOrderToExcelParams } from "../models";

export const exportOrderToExcel = async ({
  s2SOrderId,
  version,
}: ExportOrderToExcelParams): Promise<Blob> => {
  const file: Blob = await SupplierPortalAppService.exportOrderByIdToExcel(
    {
      s2SOrderId,
      version,
    },
    {
      headers: {
        Accept: "*/*",
      },
    }
  );

  return file;
};
