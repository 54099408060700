import { ChangeEventHandler, forwardRef } from "react";

import { Label, Paragraph } from "../Typography";

interface TextareaProps {
  errorMessage?: string;
  label?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  value?: string;
  className?: string;
  parentClassName?: string;
  onBlur?: () => void;
  testId?: string;
}

export const Textarea = forwardRef<
  HTMLTextAreaElement,
  TextareaProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>
>(
  (
    {
      errorMessage,
      label,
      placeholder = "",
      onChange,
      value,
      className,
      parentClassName = "",
      onBlur,
      testId,
      ...rest
    },
    ref
  ) => {
    return (
      <div
        className={`${parentClassName}`}
        {...(testId && { "data-testid": `${testId}_container` })}
      >
        {!!label && (
          <Label size="200" color="text-textIcon-blackSecondary">
            {label}
          </Label>
        )}
        <textarea
          ref={ref}
          id={label}
          className={`h-10 shadow-sm w-full border border-solid focus:border-solid rounded-md py-2 px-3 font-normal focus:outline-none ${className} ${
            errorMessage ? "border-dangerDefault" : ""
          }`}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          {...(testId && { "data-testid": `${testId}_input` })}
          {...rest}
        />
        {!!errorMessage && (
          <Paragraph
            size="200"
            color="text-dangerDefault"
            className="h-4"
            {...(testId && { "data-testid": `${testId}_errorMessage` })}
          >
            {errorMessage}
          </Paragraph>
        )}
      </div>
    );
  }
);

Textarea.displayName = "Textarea";
