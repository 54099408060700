import { Cloud, S2SHeaderLogo } from "../../../icons";
import { Heading, Paragraph } from "../../atoms";

type Props = {
  errorCode?: number;
  title?: string;
  message?: string | React.ReactNode;
};

export const ServerErrorPage = ({
  errorCode = 500,
  title = "It's not you, it's us...",
  message,
}: Props) => {
  return (
    <div className="h-screen" data-testid="serverErrorPage">
      <div className="flex justify-start">
        <S2SHeaderLogo className="[&>path]:fill-[#022044]" />
      </div>
      <div className="h-full flex justify-center items-center">
        <Cloud />
        <div className="w-0.5 h-[285px] mx-7 bg-textIcon-blackSecondary" role="presentation" />
        <div className="flex flex-col">
          <Heading
            size="100"
            className="mb-4"
            color="text-primaryPressed"
            data-testid="serverErrorPage_title"
          >
            {title}
          </Heading>
          <Paragraph size="100" className="w-96">
            {message ? (
              message
            ) : (
              <span>
                We are working on fixing the problem. Be back soon. Please contact{" "}
                <a className="underline text-primaryHover" href="mailto:support@source2sea.com ">
                  support@source2sea.com
                </a>{" "}
                if the problem persists.
              </span>
            )}
          </Paragraph>
          <Paragraph size="100" weight="heavy">
            Error code: {errorCode}{" "}
            <span className="font-thin text-xs">{new Date().toISOString()}</span>
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
