type HeaderProps = {
  logo: React.ReactNode;
  right: React.ReactNode;
};

export const Header: React.FC<HeaderProps> = ({ logo, right }) => {
  return (
    <div className="w-full bg-neutral_1000 ">
      <div className="containerPadding px-8 flex flex-row justify-between items-center w-full h-[60px]">
        {logo}
        <div className="flex items-center">{right}</div>
      </div>
    </div>
  );
};
