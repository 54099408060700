import { OrderVersionResponse, SupplierPortalAppService } from "src/typegens";

import { SupplierDiscardOrderChangesParams } from "../models";

export const discardOrderChanges = async ({
  s2SOrderId,
}: SupplierDiscardOrderChangesParams): Promise<OrderVersionResponse> => {
  const result = await SupplierPortalAppService.discardChanges({ s2SOrderId });

  return result;
};
