import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAuth0WithRedirect } from "@web/common";

import { RoutesConfig } from "src/config/routes";
import { BackActionHeader, UserProfileSchema, UserProfileSchemaType } from "src/domain";

import { UserProfileForm } from "./components/UserProfileForm";

export const UserProfile: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth0WithRedirect();

  const form = useForm<UserProfileSchemaType>({
    defaultValues: {
      name: user?.name || "",
      email: user?.email || "",
    },
    resolver: zodResolver(UserProfileSchema),
  });

  return (
    <div className="bg-neutral_100 min-h-screen">
      <BackActionHeader
        backActionTitle="Back"
        backActionCallback={() => navigate(RoutesConfig.home)}
      />

      <div className={`flex w-full h-8 `}>
        <FormProvider {...form}>
          <UserProfileForm />
        </FormProvider>
      </div>
    </div>
  );
};
