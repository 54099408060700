import { ArrowNarrowRightIcon } from "@heroicons/react/solid";

import { Label, RegularButton } from "../../atoms";
import { containerPadding } from "../layouts/styles";

interface Props {
  className?: string;
  headerLabel: string;
  submitButtonLabel: string;
  saveDraftLabel?: string;
  isSubmitButtonDisabled?: boolean;
  isSubmitButtonLoading?: boolean;
  hasSubmitButtonIcon?: boolean;
  renderSaveDraftButton?: boolean;
  onSubmit: () => void;
  onSaveDraft?: () => void;
  cancelButtonLabel: string;
  isCancelButtonDisabled?: boolean;
  isCancelButtonLoading?: boolean;
  onCancel: () => void;
  hasOrderSummary: boolean;
  renderOrderSummary: () => void;
  testId?: string;
}

export const OrderConfiguratorHeader: React.FC<Props> = ({
  className = "",
  headerLabel,
  saveDraftLabel = "",
  renderSaveDraftButton = false,
  submitButtonLabel,
  isSubmitButtonDisabled = false,
  isSubmitButtonLoading = false,
  hasSubmitButtonIcon = false,
  onSubmit,
  onSaveDraft,
  cancelButtonLabel,
  isCancelButtonDisabled = false,
  isCancelButtonLoading = false,
  onCancel,
  hasOrderSummary = false,
  renderOrderSummary,
  testId = "orderConfiguratorHeader",
}) => {
  return (
    <div
      className={`bg-neutral_0 p-2 border-b-1 border-neutral_300 ${className}`}
      data-testid={testId}
    >
      <div className={containerPadding}>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center">
            <Label size="100">{headerLabel}</Label>
          </div>
          <div className="flex flex-row items-center gap-2">
            <RegularButton
              variant="secondary"
              size="large"
              label={cancelButtonLabel}
              disabled={isCancelButtonDisabled}
              loading={isCancelButtonLoading}
              onClick={onCancel}
            />
            {renderSaveDraftButton && onSaveDraft ? (
              <RegularButton
                variant="secondary"
                size="large"
                label={saveDraftLabel}
                onClick={onSaveDraft}
              />
            ) : null}
            <RegularButton
              variant="primary"
              size="large"
              label={submitButtonLabel}
              TrailingIcon={hasSubmitButtonIcon ? ArrowNarrowRightIcon : undefined}
              disabled={isSubmitButtonDisabled}
              loading={isSubmitButtonLoading}
              onClick={onSubmit}
              data-testid={`${testId}_submitButton`}
            />
          </div>
        </div>
        {hasOrderSummary && (
          <>
            <hr className="my-2" />
            {renderOrderSummary()}
          </>
        )}
      </div>
    </div>
  );
};
