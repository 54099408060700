import { forwardRef } from "react";

export type DividerDropdownItemProps = {
  "data-testid"?: string;
};

export const DividerDropdownItem = forwardRef<HTMLHRElement, DividerDropdownItemProps>(
  ({ "data-testid": testId = "" }, forwardedRef) => {
    return (
      <hr
        ref={forwardedRef}
        className="mt-[8px] mb-[8px]"
        {...(testId ? { "data-testid": testId } : {})}
      />
    );
  }
);
DividerDropdownItem.displayName = "DividerDropdownItem";
