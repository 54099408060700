import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { Loading } from "@web/ui";

import { RoutesParams } from "src/config/routes";
import { SupplierOrderService, useOrderByIdQuery } from "src/domain";
import { getOrderVersionFromParam } from "src/utils";

import { ConfirmOrderContainer } from "./components/ConfirmOrderContainer";

export const ConfirmOrderPage: React.FC = () => {
  const { [RoutesParams.ORDER_ID]: orderId, [RoutesParams.ORDER_VERSION_ID]: rawOrderVersionId } =
    useParams() as { orderId: string; orderVersionId?: string };

  const orderVersionId = useMemo(
    () => getOrderVersionFromParam(rawOrderVersionId, "OLDEST"),
    [rawOrderVersionId]
  );

  const originalOrderQuery = useOrderByIdQuery({
    orderId,
    orderVersion: orderVersionId,
  });
  const formDataQuery = useOrderByIdQuery({
    orderId,
    orderVersion: orderVersionId,
    select: SupplierOrderService.convertSupplierOrderToValidatedFormData,
    enabled: !!originalOrderQuery.data,
  });

  if (
    originalOrderQuery.isPending ||
    originalOrderQuery.isFetching ||
    !originalOrderQuery.data ||
    formDataQuery.isPending ||
    formDataQuery.isFetching ||
    !formDataQuery.data
  ) {
    return <Loading />;
  }

  return (
    <ConfirmOrderContainer
      editedOrder={formDataQuery.data}
      originalOrder={originalOrderQuery.data}
    />
  );
};
