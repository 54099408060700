import { QuestionMarkCircleIcon } from "@heroicons/react/solid";

import { Paragraph } from "./Typography";

interface Props {
  className?: string;
  copy?: string;
}

export const RfqBadge: React.FC<Props> = ({ className = "", copy: copyProp }) => {
  const copy = copyProp || "RfQ";

  return (
    <div
      className={`border rounded border-neutral_300 flex justify-center items-center w-auto px-2 py-0.5 ${className}`}
      data-testid="rfqBadge"
    >
      <QuestionMarkCircleIcon className="w-4 h-4 text-text-whiteDisabled" />
      <Paragraph size="300" className="sm:leading-5 ml-1" color="text-textIcon-blackSecondary">
        {copy}
      </Paragraph>
    </div>
  );
};
