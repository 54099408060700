import classnames from "classnames";
import { useCallback } from "react";

import { ButtonTypographyProps } from "../../Typography";
import { LinkButtonSize, LinkButtonVariantsWithStates, LinkCommonProps } from "./models";

export const useLinkCommons = <T>({
  size,
  variant,
  disabled = false,
  onClick,
  className = "",
  "data-testid": testId = "",
  ...rest
}: LinkCommonProps & { onClick?: (event: React.MouseEvent<T, MouseEvent>) => void }): {
  mainTagCommonProps: {
    className: string;
    onClick: (event: React.MouseEvent<T, MouseEvent>) => void;
    "data-testid"?: string;
  };
  typographySize: ButtonTypographyProps["size"];
  iconSizeClassNames: string;
  defaultedProps: Omit<LinkCommonProps, "className" | "onClick">;
} => {
  const sizeToButtonClassNames: Readonly<{ [key in LinkButtonSize]: string }> = {
    large: "gap-[8px]",
    small: "gap-[4px]",
  };

  const sizeToTypographySize: Readonly<{ [key in LinkButtonSize]: ButtonTypographyProps["size"] }> =
    {
      large: "100",
      small: "200",
    };

  const sizeToIconClassNames: Readonly<{ [key in LinkButtonSize]: string }> = {
    large: "w-[16px] h-[16px]",
    small: "w-[12px] h-[12px]",
  };

  const variantToButtonClassNames: Readonly<{
    [key in LinkButtonVariantsWithStates]: string;
  }> = {
    primary:
      "border-b-primaryDefault hover:border-b-primaryHover active:border-b-transparent focus-visible:border-b-transparent ring-primaryDefault active:ring-primaryPressed",
    "primary:disabled": "border-b-textIcon-blackDisabled",
    secondary:
      "border-b-textIcon-blackSecondary hover:border-b-neutral_800 active:border-b-transparent focus-visible:border-b-transparent ring-primaryDefault active:ring-primaryPressed",
    "secondary:disabled": "border-b-textIcon-blackDisabled",
    danger:
      "border-b-dangerDefault hover:border-b-dangerHover active:border-b-transparent focus-visible:border-b-transparent ring-primaryDefault active:ring-primaryPressed",
    "danger:disabled": "border-b-textIcon-blackDisabled",
    white:
      "border-b-textIcon-whitePrimary hover:border-b-textIcon-whiteSecondary active:border-b-transparent focus-visible:border-b-transparent ring-textIcon-whitePrimary active:ring-textIcon-whiteDisabled",
    "white:disabled": "border-b-textIcon-blackDisabled",
  };

  const variantToButtonTextColorClassNames: Readonly<{
    [key in LinkButtonVariantsWithStates]: ButtonTypographyProps["color"];
  }> = {
    primary: ["text-primaryDefault", "hover:text-primaryHover", "active:text-primaryPressed"],
    "primary:disabled": ["text-textIcon-blackDisabled"],
    secondary: [
      "text-textIcon-blackSecondary",
      "hover:text-neutral_800",
      "active:text-textIcon-blackPrimary",
    ],
    "secondary:disabled": ["text-textIcon-blackDisabled"],
    danger: ["text-dangerDefault", "hover:text-dangerHover", "active:text-dangerPressed"],
    "danger:disabled": ["text-textIcon-blackDisabled"],
    white: [
      "text-textIcon-whitePrimary",
      "hover:text-textIcon-whiteSecondary",
      "active:text-textIcon-whiteDisabled",
    ],
    "white:disabled": ["text-textIcon-whiteDisabled"],
  };

  const calculatedVariant: LinkButtonVariantsWithStates = disabled
    ? `${variant}:disabled`
    : variant;

  const buttonSizeClassNames = sizeToButtonClassNames[size];
  const typographySize = sizeToTypographySize[size];
  const iconSizeClassNames = sizeToIconClassNames[size];

  const buttonVariantClassNames = variantToButtonClassNames[calculatedVariant];
  const buttonVariantTextColorClassNames = variantToButtonTextColorClassNames[calculatedVariant];

  const handleOnClick = useCallback(
    (event: React.MouseEvent<T, MouseEvent>) => {
      if (disabled || !onClick) {
        return;
      }
      onClick(event);
    },
    [disabled, onClick]
  );

  const mainTagCommonProps = {
    className: classnames(
      "inline-flex justify-center items-center",
      "border-t-1 border-r-0 border-b-1 border-l-0 border-t-transparent",
      "focus:outline-none focus-visible:ring-1",
      "overflow-hidden whitespace-nowrap leading-none",
      "w-fit min-w-fit max-w-full py-[4px]",
      { "cursor-pointer": !disabled },
      { "pointer-events-none": disabled },
      buttonSizeClassNames,
      buttonVariantClassNames,
      buttonVariantTextColorClassNames,
      className
    ),
    onClick: handleOnClick,
    ...(testId ? { "data-testid": testId } : {}),
  };

  return {
    mainTagCommonProps,
    typographySize,
    iconSizeClassNames,
    defaultedProps: {
      size,
      variant,
      disabled,
      "data-testid": testId,
      ...rest,
    },
  };
};
