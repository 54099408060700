import { Dispatch, createContext, useContext } from "react";

import { OrderEditsSyncState, OrderEditsSyncStateAction } from "./models";
import { defaultOrderEditsSyncState } from "./reducer";

export const OrderEditsSyncContext = createContext<
  [state: OrderEditsSyncState, action: Dispatch<OrderEditsSyncStateAction>]
>([
  defaultOrderEditsSyncState,
  () => {
    return;
  },
]);

export const useOrderEditsSyncContext = () => useContext(OrderEditsSyncContext);
