import { Navigate, Route, Routes as RoutesSwitch, useNavigate } from "react-router-dom";

import { NotFoundErrorPage } from "@web/ui";

import { RoutesConfig } from "src/config/routes";
import { CompanyProfile } from "src/pages/CompanyProfile";
import { ConfirmOrderPage } from "src/pages/ConfirmOrder";
import { OrderAttachments } from "src/pages/OrderAttachments";
import { OrderConfirmed } from "src/pages/OrderConfirmed";
import { OrderDetails } from "src/pages/OrderDetails";
import { OrderEdit } from "src/pages/OrderEdit";
import { OrdersList } from "src/pages/OrdersList";
import { UserProfile } from "src/pages/UserProfile";

import { useUserPermissions } from "./domain";

export const Routes = () => {
  const { isSupervisor } = useUserPermissions();
  const navigate = useNavigate();

  return (
    <RoutesSwitch>
      <Route path={RoutesConfig.home} element={<OrdersList />} />
      <Route path={RoutesConfig.profile} element={<UserProfile />} />
      <Route path={RoutesConfig.companyProfile} element={<CompanyProfile />} />
      <Route path={RoutesConfig.orderDetails} element={<OrderDetails />} />
      <Route path={RoutesConfig.confirmOrderVersion} element={<ConfirmOrderPage />} />
      <Route path={RoutesConfig.confirmOrder} element={<ConfirmOrderPage />} />

      <Route path={RoutesConfig.orderConfirmed} element={<OrderConfirmed />} />
      <Route path={RoutesConfig.orderAttachments} element={<OrderAttachments />} />
      <Route
        path={RoutesConfig.orderEditVersion}
        element={isSupervisor ? <Navigate to={RoutesConfig.home} /> : <OrderEdit />}
      />
      <Route
        path="*"
        element={<NotFoundErrorPage onButtonClick={() => navigate(RoutesConfig.home)} />}
      />
    </RoutesSwitch>
  );
};
