import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Layout, OrdersTable } from "./components";
import { OrdersStatus, isOrdersStatus } from "./models";

export const OrdersList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status");
  const closed = searchParams.get("closed");

  useEffect(() => {
    if (!closed && !isOrdersStatus(status)) {
      setSearchParams({ status: "incoming" });
    }
  }, [status, setSearchParams, closed]);

  const updatedStatus: OrdersStatus | undefined = closed
    ? undefined
    : isOrdersStatus(status)
    ? status
    : "incoming";

  const updatedClosed: boolean = closed === "true";

  return (
    <Layout>
      <OrdersTable status={updatedStatus} closed={updatedClosed} />
    </Layout>
  );
};
