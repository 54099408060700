import { AttachmentInformationChangeResponse, SupplierPortalAppService } from "src/typegens";

import { LinkAttachmentsToOrderParams } from "../models";

export const linkAttachmentsToOrder = async ({
  s2SOrderId,
  attachments,
  orderVersionId,
}: LinkAttachmentsToOrderParams): Promise<AttachmentInformationChangeResponse> => {
  const result = await SupplierPortalAppService.linkAttachmentToOrder({
    s2SOrderId,
    requestBody: { attachments, version: orderVersionId },
  });

  return result;
};
