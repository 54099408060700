import classnames from "classnames";

export interface IBadge {
  text: string;
  size?: "s" | "m";
  color?: "base" | "primary" | "secondary" | "success" | "danger" | "warning";
  contrast?: "low" | "high";
  withBullet?: boolean;
  LeadingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  TrailingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  className?: string;
}

export const Badge = ({
  text,
  size = "m",
  color = "base",
  contrast = "high",
  withBullet = false,
  LeadingIcon,
  TrailingIcon,
  className,
}: IBadge) => {
  const getSizeClasses = () => {
    switch (size) {
      case "s":
        return "px-1 py-0.5 text-xs";
      case "m":
        return "py-1 px-2 text-sm";
    }
  };

  const getIconsClasses = () => {
    switch (size) {
      case "s":
        return "inline-block w-3 h-3";
      case "m":
        return "inline-block w-4 h-4";
    }
  };

  const getColorClasses = () => {
    if (contrast === "low") {
      switch (color) {
        case "base":
          return "text-textIcon-blackSecondary bg-neutral_200 border-1 border-neutral_300";
        case "primary":
          return "text-primaryDefault bg-primaryBackground border-1 border-neutral_300";
        case "secondary":
          return "text-secondaryDefault bg-secondaryBackground border-1 border-neutral_300";
        case "success":
          return "text-successDefault bg-successBackground border-1 border-neutral_300";
        case "danger":
          return "text-dangerDefault bg-dangerBackground border-1 border-neutral_300";
        case "warning":
          return "text-warningDefault bg-warningBackground border-1 border-neutral_300";
      }
    } else {
      switch (color) {
        case "base":
          return "text-textIcon-whitePrimary bg-neutral_700";
        case "primary":
          return "text-textIcon-whitePrimary bg-primaryDefault";
        case "secondary":
          return "text-textIcon-whitePrimary bg-secondaryDefault";
        case "success":
          return "text-textIcon-whitePrimary bg-successDefault";
        case "danger":
          return "text-textIcon-whitePrimary bg-dangerDefault";
        case "warning":
          return "text-textIcon-whitePrimary bg-warningDefault";
      }
    }
  };

  return (
    <span
      className={classnames(
        `inline-flex rounded-2xl leading-none font-semibold whitespace-nowrap items-center`,
        getSizeClasses(),
        getColorClasses(),
        className
      )}
    >
      {withBullet && <span className="scale-75 inline-block">●</span>}{" "}
      {LeadingIcon && (
        <div className="mr-1">
          <LeadingIcon className={classnames(getIconsClasses())} />
        </div>
      )}{" "}
      {text} {TrailingIcon && <TrailingIcon className={classnames(getIconsClasses(), "ml-2")} />}
    </span>
  );
};
