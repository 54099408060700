import { Boat, S2SHeaderLogo } from "../../../icons";
import { Heading, Paragraph, RegularButton } from "../../atoms";

type Props = {
  onButtonClick: () => void;
  title?: string;
  message?: string;
  errorMessage?: string;
  buttonText?: string;
  showButton?: boolean;
};

export const NotFoundErrorPage = ({
  onButtonClick,
  showButton = true,
  title = "These are uncharted waters...",
  message = "The link you followed may be broken, or the page may have been removed.",
  errorMessage = "Error code: 404",
  buttonText = "Go To Order List",
}: Props) => {
  return (
    <div className="h-screen" data-testid="notFoundErrorPage">
      <div className="flex justify-start">
        <S2SHeaderLogo className="[&>path]:fill-[#022044]" />
      </div>
      <div className="h-full flex justify-center items-center">
        <Boat />
        <div className="w-0.5 h-[285px] mx-7 bg-textIcon-blackSecondary" role="presentation" />
        <div className="flex flex-col">
          <Heading
            size="100"
            className="mb-4"
            color="text-primaryPressed"
            data-testid="notFoundErrorPage_title"
          >
            {title}
          </Heading>
          <Paragraph size="100" className="w-96">
            {message}
          </Paragraph>
          <Paragraph size="100" weight="heavy">
            {errorMessage} <span className="font-thin text-xs">{new Date().toISOString()}</span>
          </Paragraph>
          {showButton && (
            <RegularButton
              className="mt-[65px]"
              variant="primary"
              size="large"
              label={buttonText}
              width="content"
              onClick={onButtonClick}
              data-testid="notFoundErrorPage_button"
            />
          )}
        </div>
      </div>
    </div>
  );
};
