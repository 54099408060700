import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { Label, Modal } from "@web/ui";

import { RoutesConfig, RoutesParams } from "src/admin/config/routes";
import { RemoveSupplierModal } from "src/domain";
import { SupplierResponse } from "src/typegens";

import { TableRow } from "./TableRows";

const tdStyleTextBorderless = "p-2 px-4 text-left truncate";
const tdStyleNumberBorderless = "p-2 px-4 text-right pr-6 truncate";

type Props = {
  suppliers: SupplierResponse[];
};

type ModalData = { id: string; name: string };

const defaultModalData = { id: "", name: "" };

export const SuppliersTableWrapper: React.FC<Props> = ({ suppliers }) => {
  const [modalData, setModalData] = useState<ModalData>(defaultModalData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const openModal = (id: string, name: string) => {
    setModalData({
      id,
      name,
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalData(defaultModalData);
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <RemoveSupplierModal
          supplierId={modalData.id}
          supplierName={modalData.name}
          closeModal={closeModal}
        />
      </Modal>
      <table
        className="container-md w-full border-separate min-w-full break-words"
        style={{ borderSpacing: "0 0.25rem" }}
      >
        <colgroup>
          <col span={1} className="w-1/6" />
          <col span={1} className="w-1/4" />
          <col span={3} className="w-1/6" />
          <col span={1} className="w-1/9" />
        </colgroup>
        <thead>
          <tr>
            <th className={tdStyleTextBorderless}>
              <Label size="300" color="text-textIcon-blackSecondary">
                Vendor Name
              </Label>
            </th>
            <th className={tdStyleTextBorderless}>
              <Label size="300" color="text-textIcon-blackSecondary">
                Contact Person
              </Label>
            </th>
            <th className={tdStyleTextBorderless}>
              <Label size="300" color="text-textIcon-blackSecondary">
                Website, Phone
              </Label>
            </th>
            <th className={tdStyleTextBorderless}>
              <Label size="300" color="text-textIcon-blackSecondary">
                Address
              </Label>
            </th>
            <th className={tdStyleTextBorderless}>
              <Label size="300" color="text-textIcon-blackSecondary">
                Country
              </Label>
            </th>
            <th className={tdStyleNumberBorderless} />
          </tr>
        </thead>
        <tbody>
          {suppliers.map((supplier: SupplierResponse) => (
            <TableRow
              key={supplier.supplierId}
              supplier={supplier}
              onEdit={() =>
                navigate(
                  generatePath(RoutesConfig.editSupplier, {
                    [RoutesParams.SUPPLIER_ID]: supplier.supplierId,
                  })
                )
              }
              onDelete={openModal}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};
