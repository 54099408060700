import { DefaultError, useMutation } from "@tanstack/react-query";

import { OrderVersionResponse } from "src/typegens";

import { deliverOrder } from "../api";
import { SupplierDeliverOrderParams } from "../models";

export const useOrderDeliverMutation = (options = {}) => {
  return useMutation<OrderVersionResponse, DefaultError, SupplierDeliverOrderParams>({
    mutationKey: ["deliverOrder"],
    mutationFn: deliverOrder,
    ...options,
  });
};
