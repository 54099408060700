import _cloneDeep from "lodash/cloneDeep";

import {
  ChangesEventType,
  ChangesSyncQueue,
  IChangesEventListener,
  StatusEventListener,
  StatusEventListenerParams,
  StatusEventType,
} from "../models";

// Do not import outside `changesSync` domain. Import `ChangesSyncService` instead.
export class ChangesSyncListenersService {
  private static changesListeners: IChangesEventListener[] = [];

  public static registerChangesListener = (listener: IChangesEventListener): void => {
    // Intentional side effects
    ChangesSyncListenersService.changesListeners.push(listener);
  };

  public static unregisterChangesListener = (listener: IChangesEventListener): void => {
    const index = ChangesSyncListenersService.changesListeners.indexOf(listener);
    if (index > -1) {
      // Intentional side effects
      ChangesSyncListenersService.changesListeners.splice(index, 1);
    }
  };

  public static notifyChangesListeners = (
    eventType: ChangesEventType,
    changesToNotify: ChangesSyncQueue
  ): void => {
    if (changesToNotify.length === 0) {
      return;
    }

    // Intentional side effects
    ChangesSyncListenersService.changesListeners.forEach((listener) => {
      const doesListenerQualifyForNotification = listener.changeTypes.some((type) =>
        changesToNotify.some((change) => change.type === type)
      );

      if (!doesListenerQualifyForNotification) {
        return;
      }

      const filteredChangesToNotify = changesToNotify.filter((change) =>
        listener.changeTypes.includes(change.type)
      );

      if (filteredChangesToNotify.length === 0) {
        return;
      }

      listener.onChanges(_cloneDeep(filteredChangesToNotify), eventType);
    });
  };

  private static statusListeners: StatusEventListener[] = [];

  public static registerStatusListener = (listener: StatusEventListener): void => {
    // Intentional side effects
    ChangesSyncListenersService.statusListeners.push(listener);
  };

  public static unregisterStatusListener = (listener: StatusEventListener): void => {
    const index = ChangesSyncListenersService.statusListeners.indexOf(listener);
    if (index > -1) {
      // Intentional side effects
      ChangesSyncListenersService.statusListeners.splice(index, 1);
    }
  };

  public static notifyStatusListeners = (
    eventType: StatusEventType,
    eventPayload: StatusEventListenerParams["eventPayload"]
  ): void => {
    // Intentional side effects
    ChangesSyncListenersService.statusListeners.forEach((listener) => {
      listener({ eventType, eventPayload });
    });
  };
}
