import { forwardRef } from "react";

import { ComboChildren } from "./ComboChildren";
import { ComboCommonProps } from "./models";
import { useComboCommons } from "./useComboCommons";

/**
 * This type merges:
 * - the common props (like `iconPlacement`, `label`, etc.),
 * - the native props of the main tag (<button> tag in this case),
 * - and sets more rigid typechecking for the `onClick` prop, so it is required when the button
 *   type is just `button`. It is not required when the button type is `submit` or `reset`, or when
 *   an arbitrary `isControlled` prop is passed (this can be used for button wrappers setting
 *   onClick events via ref. Please see usage examples in the apps).
 */
export type ComboButtonProps = ComboCommonProps &
  Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    "children" | "onClick" | keyof ComboCommonProps
  > &
  (
    | {
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        type?: "button";
      }
    | {
        onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        type: "submit" | "reset";
      }
    | {
        onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        isControlled: true;
      }
  );

export const ComboButton = forwardRef<HTMLButtonElement, ComboButtonProps>(
  (props, forwardedRef) => {
    const { mainTagCommonProps } = useComboCommons<HTMLButtonElement>(props);
    // Removing illegal props so only valid ones are applied to the HTML tag
    const {
      type = "button",
      label,
      iconPlacement,
      Icon,
      loading,
      isControlled,
      ...buttonTagProps
    } = props;

    return (
      <button {...buttonTagProps} {...mainTagCommonProps} ref={forwardedRef} type={type}>
        <ComboChildren {...props} />
      </button>
    );
  }
);
ComboButton.displayName = "ComboButton";
