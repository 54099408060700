import { z } from "zod";

import { DraftSupplierOrderSchema } from "./DraftSupplierOrderSchema";
import { SupplierOrderItemSchema } from "./SupplierOrderItemSchema";

export const ValidatedSupplierOrderSchema = DraftSupplierOrderSchema.extend({
  items: z.array(SupplierOrderItemSchema).refine(
    (items) => {
      const isAnyItemOrdered = !!items.find((item) => item.quantity > 0);
      return isAnyItemOrdered;
    },
    {
      message: "Order has to consist of at least 1 item with quantity greater than 0",
    }
  ),
  totalGrossAmount: z.object({
    amount: z.number().nonnegative("Order's New Total has to be greater than or equal to 0"),
    currencyCode: z.string(),
  }),
}).omit({ editable: true });

export type ValidatedSupplierOrderForm = z.infer<typeof ValidatedSupplierOrderSchema>;
