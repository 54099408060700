import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useAuth0WithRedirect } from "@web/common";
import { Loading } from "@web/ui";

export const MainRoute: React.FC = () => {
  const { loginWithRedirectToOriginalRoute, logout } = useAuth0WithRedirect();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const error = searchParams.get("error");
    if (error === "access_denied") {
      logout();
    } else {
      loginWithRedirectToOriginalRoute();
    }
  }, [searchParams, logout, loginWithRedirectToOriginalRoute]);

  return <Loading />;
};
