import { z } from "zod";

import { SupplierOrderAmountAdditionalCostSchema } from "./SupplierOrderAmountAdditionalCostSchema";
import { SupplierOrderAmountDiscountSchema } from "./SupplierOrderAmountDiscountSchema";
import { SupplierOrderAttachmentSchema } from "./SupplierOrderAttachmentSchema";
import { SupplierOrderItemSchema } from "./SupplierOrderItemSchema";

export const DraftSupplierOrderSchema = z.object({
  orderId: z.string(),
  items: z.array(SupplierOrderItemSchema),
  totalGrossAmount: z.object({
    amount: z.number(),
    currencyCode: z.string(),
  }),
  attachments: z.array(SupplierOrderAttachmentSchema),
  amountAdditionalCosts: z.array(SupplierOrderAmountAdditionalCostSchema),
  amountDiscounts: z.array(SupplierOrderAmountDiscountSchema),
  version: z.number().positive(),
  editable: z.boolean(),
});

export type DraftSupplierOrderForm = z.infer<typeof DraftSupplierOrderSchema>;
