import { useCallback, useEffect } from "react";
import {
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  UseFormGetValues,
} from "react-hook-form";
import { v4 as uuIdV4 } from "uuid";

import { ApiError } from "@web/attachments";
import { isDefined } from "@web/utils";

import { uploadFile } from "../api";
import { MAX_FILE_SIZE_IN_BYTES } from "../config";
import { AttachmentForm, AttachmentsForm } from "../models";
import { AttachmentsService } from "../services";
import {
  FileUploadError,
  UploadCancelledError,
  useFileUpload,
  useFileUploadError,
  useFileUploadSuccess,
} from "./FileUpload";

export const useAttachmentUpload = (
  attachmentsForm: AttachmentForm[],
  removeFormAttachment: UseFieldArrayRemove,
  updateFormAttachment: UseFieldArrayUpdate<AttachmentsForm, "attachments">,
  appendFormAttachment: UseFieldArrayAppend<AttachmentsForm, "attachments">,
  getFormValues: UseFormGetValues<AttachmentsForm>
) => {
  const { showFileUploadError, getFileUploadErrorTypeFromPromiseError } =
    useFileUploadError(MAX_FILE_SIZE_IN_BYTES);
  const { showFileUploadSuccess } = useFileUploadSuccess();
  const {
    fileUploads,
    getFileUploadId,
    addFileUpload,
    removeFileUpload,
    cancelFileUpload,
    setFileUploadError,
    setFileUploadSuccess,
  } = useFileUpload();

  useEffect(() => {
    // Detect newly added files and begin upload
    const attachmentToUpload = attachmentsForm.find((attachment) => attachment.isQueued);

    if (!attachmentToUpload) {
      return;
    }

    const attachmentToUploadIndex = attachmentsForm.findIndex(
      (attachment) => attachment === attachmentToUpload
    );

    const fileToUpload = attachmentToUpload.file as File;

    const uploadFilePromise = uploadFile(fileToUpload);
    const cancelUpload = () => uploadFilePromise.cancel();

    uploadFilePromise
      .then((apiAttachmentData) => {
        setFileUploadSuccess(fileToUpload, apiAttachmentData);
      })
      .catch((error: ApiError | UploadCancelledError | unknown) => {
        const errorType: FileUploadError = getFileUploadErrorTypeFromPromiseError(error);
        setFileUploadError(fileToUpload, error, errorType);
      });

    addFileUpload(fileToUpload, cancelUpload);

    updateFormAttachment(attachmentToUploadIndex, {
      ...attachmentToUpload,
      isQueued: false,
      isLoading: true,
    });
  }, [
    attachmentsForm,
    addFileUpload,
    setFileUploadError,
    setFileUploadSuccess,
    updateFormAttachment,
    getFileUploadErrorTypeFromPromiseError,
  ]);

  useEffect(() => {
    // Update uploaded attachment data and remove from uploads
    const uploadedFile = fileUploads.find((fileUpload) => !!fileUpload.apiFileData);

    if (!uploadedFile?.apiFileData) {
      return;
    }

    const newAttachmentData = AttachmentsService.convertFromApiAttachmentToFormAttachment(
      uploadedFile.apiFileData
    );

    const { attachment: attachmentToUpdate, attachmentIndex: attachmentToUpdateIndex } =
      AttachmentsService.findAttachmentAndItsIndex(attachmentsForm, uploadedFile.uploadId);

    if (!attachmentToUpdate || !isDefined(attachmentToUpdateIndex)) {
      return;
    }

    // Show success message
    showFileUploadSuccess(uploadedFile.file);

    // Remove from uploads
    removeFileUpload(uploadedFile.file);

    // The attachmentsForm reference may not change if just the category on an item changed,
    // so we need to get the currently chosen category manually.
    const chosenCategory = getFormValues(`attachments.${attachmentToUpdateIndex}.category`);

    // Update in form collection
    updateFormAttachment(attachmentToUpdateIndex, {
      ...attachmentToUpdate,
      ...newAttachmentData,
      // Do not overwrite category with API data, because it may have been set by the user while
      // the file was uploading
      category: chosenCategory,
      isLoading: false,
    });
  }, [
    fileUploads,
    attachmentsForm,
    removeFileUpload,
    updateFormAttachment,
    showFileUploadSuccess,
    getFormValues,
  ]);

  useEffect(() => {
    // Create side effect: cancel the upload request, which will throw the promise and force the upload error path
    const cancelledFileUpload = fileUploads.find((fileUpload) => fileUpload.isCancelled);

    if (!cancelledFileUpload) {
      return;
    }

    cancelledFileUpload.cancelUpload();
  }, [fileUploads]);

  useEffect(() => {
    // Show upload error and remove faulty (or cancelled) upload from uploads
    const fileUploadWithError = fileUploads.find((fileUpload) => !!fileUpload.errorType);

    if (!fileUploadWithError?.errorType) {
      return;
    }

    const { attachmentIndex: attachmentToRemoveIndex } =
      AttachmentsService.findAttachmentAndItsIndex(attachmentsForm, fileUploadWithError.uploadId);

    if (!isDefined(attachmentToRemoveIndex)) {
      return;
    }

    // Show error message
    showFileUploadError(
      fileUploadWithError.file,
      fileUploadWithError.errorType,
      fileUploadWithError.error
    );

    // Remove from uploads
    removeFileUpload(fileUploadWithError.file);

    // Remove from form collection
    removeFormAttachment(attachmentToRemoveIndex);
  }, [fileUploads, attachmentsForm, removeFileUpload, removeFormAttachment, showFileUploadError]);

  const fileSelectSuccess = (file: File) => {
    // Not all fields' values are available from the start, so we coerce the type
    appendFormAttachment({
      // Set temporary unique file ID that will be replaced when upload is finished
      // This is used for `key` prop during rendering
      id: uuIdV4(),
      name: file.name,
      size: file.size,
      isQueued: true,
      file,
      // This field is used to relate uploads with attachments form array items
      uploadId: getFileUploadId(file.name, file.size),
    } as AttachmentForm);
  };

  const fileSelectError = (file: File, errorType: FileUploadError) => {
    showFileUploadError(file, errorType);
  };

  const cancelUpload = (attachment: AttachmentForm) => {
    if (attachment.uploadId) {
      cancelFileUpload(attachment.uploadId);
    }
  };

  const isAnyUploadPending = !!attachmentsForm.find(
    (editedAttachment) => editedAttachment.isQueued || editedAttachment.isLoading
  );

  const doesUploadExist = useCallback(
    (file: File): boolean => {
      const existingFilesUploadIds = attachmentsForm.map((attachment) =>
        getFileUploadId(attachment.name, attachment.size)
      );
      const uploadId = getFileUploadId(file.name, file.size);
      return existingFilesUploadIds.includes(uploadId);
    },
    [attachmentsForm, getFileUploadId]
  );

  return {
    fileSelectSuccess,
    fileSelectError,
    cancelUpload,
    doesUploadExist,
    isAnyUploadPending,
  };
};
