import { ReactNode } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";

interface Props {
  children: ReactNode;
  tooltipText: string;
  className?: string;
  hAlignTo?: "start" | "end";
  vAlignTo?: "top" | "bottom";
}

const mapAlignToPlacement = (hAlignTo?: "start" | "end", vAlignTo?: "top" | "bottom") => {
  if (hAlignTo && vAlignTo) {
    if (vAlignTo === "top") {
      return hAlignTo === "start" ? "top-start" : "top-end";
    }
    if (vAlignTo === "bottom") {
      return hAlignTo === "start" ? "bottom-start" : "bottom-end";
    }
  }
  return "auto";
};

export const Tooltip = ({ children, tooltipText, className, hAlignTo, vAlignTo }: Props) => {
  const placement = mapAlignToPlacement(hAlignTo, vAlignTo);
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement,
  });
  return (
    <div
      className={className ?? ""}
      data-tip
      data-for="registerTip"
      role="tooltip"
      ref={setTriggerRef}
    >
      {children}
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className:
              "tooltip-container bg-black text-neutral_0 text-sm rounded-md px-2 py-1 z-20",
          })}
        >
          {/* TODO #3948: style tooltip arrow
          <div {...getArrowProps({ className: "tooltip-arrow" })} /> */}
          {tooltipText}
        </div>
      )}
    </div>
  );
};
