export const S2SLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="220"
      height="220"
      fill="none"
      viewBox="0 0 220 220"
    >
      <path
        fill="#022044"
        d="M191.135 50.002v-3.25s-70.69-7.302-78.194-8.494c-2.186-.347-7.624-.573-9.828-.367a15.824 15.824 0 00-11.485 24.851c1.272 1.812 6.123 6.613 8.02 7.752 0 0 19.021 16.58 26.144 23.723 1.999 2 3.655 4.592 4.478 7.489a19.094 19.094 0 01-6.496 20.215 19.105 19.105 0 01-6.634 3.404l-10.455 2.989-53.643 15.365-24.177 38.335 31.8-8.93-5.296 9.103h11.818l7.855-13.024 11.034-3.15-9.693 16.174h12.694l12.32-20.429 3.92-.999 22.065-6.312a31.168 31.168 0 0018.687-14.835 31.173 31.173 0 002.715-23.705c-.174-.61-.37-1.211-.575-1.803v-.031a31.408 31.408 0 00-1.825-4.182l-11.71-26.782a38.335 38.335 0 0014.397 4.744 38.479 38.479 0 0026.284-6.406c7.697-5.247 13.23-13.935 15.571-22.95h-66.65l-3.712-7.624h70.571v-.871zm-85.129 9.884a6.248 6.248 0 01-6.358-9.976 6.243 6.243 0 017.006-1.964 6.248 6.248 0 014.113 6.002 6.253 6.253 0 01-4.761 5.938z"
      ></path>
      <path
        fill="#022044"
        d="M115.704 117.263l-10.792 3.285-11.412 3.499-34.79 10.267.205-.326c10.122-16.153 16.785-26.786 30.493-33.664 12.893-8.03 25.281-3.526 28.766-1.296 5.445 3.485 5.31 7.529 5.174 8.941-.137 1.413-.355 2.843-1.025 4.096-.67 1.253-1.108 1.837-2.21 2.738-1.102.902-3.043 2.047-4.409 2.46z"
      ></path>
    </svg>
  );
};
