import { ReplyIcon, TrashIcon, XCircleIcon } from "@heroicons/react/solid";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { DropdownItem, RegularDropdownItem } from "@web/ui";

import { AttachmentForm, AttachmentsForm } from "../../models";
import { AttachmentUI } from "../AttachmentUI";

const attachmentCategoryOptions = [
  { value: "MD", label: "MD - Material Declaration" },
  { value: "SDOC", label: "SDoC - Supplier Declaration of Compliance" },
  { value: "CERT", label: "Cert - Certificate" },
  { value: "SDS", label: "SDS - Safety Data Sheet" },
  { value: "DWG", label: "DWG - Drawing" },
  { value: "PHOTO", label: "Photo - Photograph" },
  { value: "SOW", label: "SoW - Scope of Work" },
  { value: "SVC_NOTES", label: "SVC - Service Notes" },
  { value: "OTHER", label: "Other - Unspecified" },
];

interface Props {
  attachment: AttachmentForm;
  attachmentIndex: number;
  cancelUpload?: (attachment: AttachmentForm, attachmentIndex: number) => void;
  restoreAttachment?: (attachment: AttachmentForm, attachmentIndex: number) => void;
  removeAttachment?: (attachment: AttachmentForm, attachmentIndex: number) => void;
  className?: string;
  testId?: string;
}

export const AttachmentController: React.FC<Props> = ({
  attachment,
  attachmentIndex,
  cancelUpload,
  restoreAttachment,
  removeAttachment,
  className = "",
  testId = "",
}) => {
  const { control } = useFormContext<AttachmentsForm>();

  const isRemoved = attachment.isRemoved;
  const isLoading = attachment.isLoading;

  const actions: DropdownItem[] = useMemo(
    () => [
      ...(isLoading && !!cancelUpload
        ? [
            {
              key: "cancelUpload",
              renderComponent: () => (
                <RegularDropdownItem
                  label="Cancel upload"
                  variant="danger"
                  LeadingIcon={XCircleIcon}
                  onClick={() => {
                    cancelUpload(attachment, attachmentIndex);
                  }}
                  data-testid="attachmentActions_cancelUpload"
                />
              ),
            },
          ]
        : [
            ...(isRemoved && !!restoreAttachment
              ? [
                  {
                    key: "restoreFile",
                    renderComponent: () => (
                      <RegularDropdownItem
                        label="Restore"
                        variant="basic"
                        LeadingIcon={ReplyIcon}
                        onClick={() => {
                          restoreAttachment(attachment, attachmentIndex);
                        }}
                        data-testid="attachmentActions_restoreFile"
                      />
                    ),
                  },
                ]
              : []),
            ...(!isRemoved && !!removeAttachment
              ? [
                  {
                    key: "removeFile",
                    renderComponent: () => (
                      <RegularDropdownItem
                        label="Remove"
                        variant="danger"
                        LeadingIcon={TrashIcon}
                        onClick={() => {
                          removeAttachment(attachment, attachmentIndex);
                        }}
                        data-testid="attachmentActions_removeFile"
                      />
                    ),
                  },
                ]
              : []),
          ]),
    ],
    [
      isLoading,
      isRemoved,
      cancelUpload,
      restoreAttachment,
      removeAttachment,
      attachment,
      attachmentIndex,
    ]
  );

  const getChosenCategoryOption = (chosenCategory?: string) =>
    attachmentCategoryOptions.find((categoryOption) => categoryOption.value === chosenCategory);

  return (
    <Controller
      name={`attachments.${attachmentIndex}.category`}
      control={control}
      render={({ field: categoryField }) => (
        <AttachmentUI
          className={className}
          fileName={attachment.name}
          uploadedAt={attachment.createdDate}
          uploader={attachment.createdBy}
          fileSizeInBytes={attachment.size}
          categories={attachmentCategoryOptions}
          chosenCategory={getChosenCategoryOption(categoryField.value)}
          onCategoryChange={(newChosenCategory) => {
            categoryField.onChange(newChosenCategory?.value);
          }}
          isRemoved={isRemoved}
          isLoading={isLoading}
          actions={actions}
          testId={testId}
        />
      )}
    />
  );
};
