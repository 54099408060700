import { useNavigate } from "react-router-dom";

import { RoutesConfig } from "src/admin/config/routes";
import { BackActionHeader } from "src/domain";

import { EditSupplierFormQuery } from "./components";

export const EditSupplier: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-between bg-neutral_100 min-h-full">
      <BackActionHeader
        backActionTitle="Back to All Suppliers"
        backActionCallback={() => navigate(RoutesConfig.home)}
      />
      <EditSupplierFormQuery />
    </div>
  );
};
