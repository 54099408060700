import { useIsMutating } from "@tanstack/react-query";

import { ModalProvider as CommonModalProvider } from "@web/common";

interface Props {
  children: React.ReactNode;
}

export const ModalProvider = ({ children }: Props) => {
  const isMutating = useIsMutating();
  const handleClose = (onClose: () => void) => () => {
    if (!isMutating) {
      onClose();
    }
  };
  return <CommonModalProvider handleClose={handleClose}>{children}</CommonModalProvider>;
};
