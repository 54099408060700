export const formatShortString = (
  str: string,
  maxlength: number,
  abbreviation = "(...)"
): string => {
  if (str.length <= maxlength) {
    return str;
  }

  const abbreviationLength = abbreviation.length;
  const newStrLength = maxlength - abbreviationLength;
  return str.slice(0, newStrLength) + abbreviation;
};
