import { CubeIcon } from "@heroicons/react/solid";
import { ReactElement } from "react";

import { Paragraph } from "../Typography";

type Props = {
  label?: string;
  deliveryMethod: string | ReactElement;
  className?: string;
};

export const DeliveryMethod = ({ deliveryMethod, label = "delivery method", className }: Props) => {
  return (
    <div className={`py-3 ${className}`}>
      <Paragraph size="300" color="text-textIcon-blackSecondary" className="pb-3 uppercase">
        {label}
      </Paragraph>
      <div className="flex flex-row">
        <div>
          <CubeIcon className="-ml-0.5 text-textIcon-blackSecondary" width="20px" />
        </div>
        <div className="flex flex-col ml-3 flex-1">
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            {deliveryMethod}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
