import { parseISO } from "date-fns";
import { format, toZonedTime } from "date-fns-tz";

import { isDefined } from "../helpers";

export type DateFormat = "justTime" | "justDate" | "fullDate" | "yearFirst";

const formatMap: Record<DateFormat, string> = {
  fullDate: "eeee, dd MMMM yyyy",
  justDate: "dd MMM yyyy",
  justTime: "HH:mm",
  yearFirst: "yyyy-MM-dd",
};

export const extractFromISODateString = (isoDate?: string, type?: DateFormat): string => {
  let result = "";

  if (!isDefined(isoDate) || !isDefined(type)) {
    return "";
  }

  try {
    result = format(toZonedTime(parseISO(isoDate), "Z"), formatMap[type] || "", {
      timeZone: "UTC",
    });
  } catch (e) {
    // Handle error, if needed
  }

  return result;
};
