import { SVGProps } from "react";

export const MailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 249 122" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.91 122H54.0283C49.4876 122 45.8066 116.538 45.8066 109.8C45.8066 103.062 49.4876 97.6 54.0283 97.6H8.2217C3.68098 97.6 0 92.1379 0 85.4C0 78.6621 3.68098 73.2 8.2217 73.2H55.2028C59.7436 73.2 63.4245 67.7379 63.4245 61C63.4245 54.2621 59.7436 48.8 55.2028 48.8H25.8396C21.2989 48.8 17.6179 43.3379 17.6179 36.6C17.6179 29.8621 21.2989 24.4 25.8396 24.4H72.8208C68.28 24.4 64.5991 18.9379 64.5991 12.2C64.5991 5.46213 68.28 0 72.8208 0L139.769 0C144.31 0 147.991 5.46213 147.991 12.2C147.991 18.9379 144.31 24.4 139.769 24.4H214.939C219.479 24.4 223.16 29.8621 223.16 36.6C223.16 43.3379 219.479 48.8 214.939 48.8H240.778C245.319 48.8 249 54.2621 249 61C249 67.7379 245.319 73.2 240.778 73.2H218.462C213.922 73.2 210.241 78.6621 210.241 85.4C210.241 92.1379 213.922 97.6 218.462 97.6H225.509C230.05 97.6 233.731 103.062 233.731 109.8C233.731 116.538 230.05 122 225.509 122H164.434C163.829 122 163.24 121.903 162.672 121.719C162.105 121.903 161.515 122 160.91 122Z"
      fill="#F0F5FF"
    />
    <rect x="102.789" y="42" width="72.7829" height="57.5704" fill="white" />
    <rect x="44.5625" y="59.1855" width="43.6697" height="6.01481" rx="3.00741" fill="white" />
    <rect x="37.7129" y="70.3555" width="50.5199" height="6.01481" rx="3.00741" fill="white" />
    <rect x="45.4189" y="82.3848" width="43.6697" height="5.15556" rx="2.57778" fill="white" />
    <path
      d="M107.07 91.4152V50.3747C107.07 47.9409 109.816 46.52 111.803 47.9255L136.628 65.4852C137.423 66.0475 137.896 66.9607 137.896 67.9345V91.4152C137.896 93.072 136.553 94.4152 134.896 94.4152H110.07C108.413 94.4152 107.07 93.072 107.07 91.4152Z"
      fill="#F0F5FF"
    />
    <path
      d="M101.933 42H176M101.933 42V92C101.933 96.4183 105.514 100 109.933 100H168C172.418 100 176 96.4183 176 92V91.837M101.933 42L138.324 66.9185L176 42M176 42V77.2296M176 81.9556V87.1111"
      stroke="#2F6FED"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="46.2754" y="58.3262" width="31.682" height="5.15556" rx="2.57778" fill="#2F6FED" />
    <rect x="39.4248" y="69.4961" width="50.5199" height="5.15556" rx="2.57778" fill="#2F6FED" />
    <rect x="47.1318" y="80.667" width="42.8135" height="5.15556" rx="2.57778" fill="#2F6FED" />
    <rect x="79.6699" y="58.3262" width="10.2752" height="5.15556" rx="2.57778" fill="#2F6FED" />
  </svg>
);
