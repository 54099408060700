import { forwardRef } from "react";

import { IconChildren } from "./IconChildren";
import { IconCommonProps } from "./models";
import { useIconCommons } from "./useIconCommons";

/**
 * This type merges:
 * - the common props (like `variant`, `label`, `size` etc.),
 * - the native props of the main tag (<button> tag in this case),
 * - and sets more rigid typechecking for the `onClick` prop, so it is required when the button
 *   type is just `button`. It is not required when the button type is `submit` or `reset`, or when
 *   an arbitrary `isControlled` prop is passed (this can be used for button wrappers setting
 *   onClick events via ref. Please see usage examples in the apps).
 */
export type IconButtonProps = IconCommonProps &
  Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    "children" | "onClick" | keyof IconCommonProps
  > &
  (
    | {
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        type?: "button";
      }
    | {
        onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        type: "submit" | "reset";
      }
    | {
        onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        isControlled: true;
      }
  );

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, forwardedRef) => {
  const { mainTagCommonProps } = useIconCommons<HTMLButtonElement>(props);
  // Removing illegal props so only valid ones are applied to the HTML tag
  const {
    type = "button",
    size,
    variant,
    shape,
    label,
    Icon,
    loading,
    isControlled,
    ...buttonTagProps
  } = props;

  return (
    <button {...buttonTagProps} {...mainTagCommonProps} ref={forwardedRef} type={type}>
      <IconChildren {...props} />
    </button>
  );
});
IconButton.displayName = "IconButton";
