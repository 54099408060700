export const BondedIcon = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 17.3332V15.6665H2.16667V12.9998C1.68056 12.8332 1.28125 12.538 0.96875 12.1144C0.65625 11.6908 0.5 11.2082 0.5 10.6665V3.99984H5.5V10.6665C5.5 11.2082 5.34375 11.6908 5.03125 12.1144C4.71875 12.538 4.31944 12.8332 3.83333 12.9998V15.6665H5.5V17.3332H0.5ZM2.16667 8.1665H3.83333V5.6665H2.16667V8.1665ZM3 11.4998C3.23611 11.4998 3.43403 11.42 3.59375 11.2603C3.75347 11.1005 3.83333 10.9026 3.83333 10.6665V9.83317H2.16667V10.6665C2.16667 10.9026 2.24653 11.1005 2.40625 11.2603C2.56597 11.42 2.76389 11.4998 3 11.4998ZM8.83333 17.3332C8.375 17.3332 7.98264 17.17 7.65625 16.8436C7.32986 16.5172 7.16667 16.1248 7.16667 15.6665V7.70817C7.16667 7.34706 7.27083 7.02414 7.47917 6.73942C7.6875 6.4547 7.95833 6.24984 8.29167 6.12484L9.08333 5.83317C9.27778 5.76373 9.42361 5.66303 9.52083 5.53109C9.61806 5.39914 9.66667 5.23595 9.66667 5.0415V1.49984C9.66667 1.26373 9.74653 1.06581 9.90625 0.906087C10.066 0.746365 10.2639 0.666504 10.5 0.666504H13C13.2361 0.666504 13.434 0.746365 13.5938 0.906087C13.7535 1.06581 13.8333 1.26373 13.8333 1.49984V5.0415C13.8333 5.23595 13.8819 5.39914 13.9792 5.53109C14.0764 5.66303 14.2222 5.76373 14.4167 5.83317L15.2083 6.12484C15.5417 6.24984 15.8125 6.4547 16.0208 6.73942C16.2292 7.02414 16.3333 7.34706 16.3333 7.70817V15.6665C16.3333 16.1248 16.1701 16.5172 15.8438 16.8436C15.5174 17.17 15.125 17.3332 14.6667 17.3332H8.83333ZM11.3333 3.1665H12.1667V2.33317H11.3333V3.1665ZM8.83333 8.99984H14.6667V7.70817L13.875 7.4165C13.3472 7.22206 12.9306 6.9165 12.625 6.49984C12.3194 6.08317 12.1667 5.61095 12.1667 5.08317V4.83317H11.3333V5.08317C11.3333 5.61095 11.1806 6.08317 10.875 6.49984C10.5694 6.9165 10.1528 7.22206 9.625 7.4165L8.83333 7.70817V8.99984ZM8.83333 15.6665H14.6667V13.9998H8.83333V15.6665ZM8.83333 12.3332H14.6667V10.6665H8.83333V12.3332Z"
        fill="#1D2433"
        fillOpacity="0.6"
      />
    </svg>
  );
};
