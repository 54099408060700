import { DefaultError, useMutation } from "@tanstack/react-query";

import { OrderVersionResponse } from "src/typegens";

import { confirmOrder } from "../api";
import { SupplierConfirmOrderParams } from "../models";

export const useOrderConfirmMutation = (options = {}) => {
  return useMutation<OrderVersionResponse, DefaultError, SupplierConfirmOrderParams>({
    mutationKey: ["confirmOrder"],
    mutationFn: confirmOrder,
    ...options,
  });
};
