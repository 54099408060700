import { OAuthTokenOptions } from "@auth0/auth0-spa-js";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";

import { useAuth0WithRedirect } from "@web/common";

const listOfPermissions = {
  readSupplierOrders: "read:supplierOrders",
  writeSupplierOrder: "write:supplierOrder",
  readSuppliers: "read:suppliers",
};

export const useUserPermissions = () => {
  const [isSupervisor, setIsSupervisor] = useState<undefined | boolean>();
  const { getAccessTokenSilently } = useAuth0WithRedirect();
  const { readSupplierOrders, writeSupplierOrder, readSuppliers } = listOfPermissions;

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      const decodedToken: OAuthTokenOptions = jwtDecode(token);

      const hasOnlyReadSupplierOrdersPermissions =
        decodedToken.scope.includes(readSupplierOrders) &&
        !decodedToken.scope.includes(writeSupplierOrder) &&
        !decodedToken.scope.includes(readSuppliers);

      setIsSupervisor(hasOnlyReadSupplierOrdersPermissions);
    });
  }, [getAccessTokenSilently, readSupplierOrders, writeSupplierOrder, readSuppliers]);

  return { isSupervisor };
};
