import { useEffect, useState } from "react";

import { Modal } from "@web/ui";

import { useSystemMessages } from "src/context/SystemMessages";

import { OrderEditDiscardChangesConfirmationModal } from "../../../common";
import { Attachment } from "../../models";
import { AttachmentsModal } from "../AttachmentsModal";

interface Props {
  attachments: Attachment[];
  submitAttachmentsChanges: (attachments: Attachment[]) => void;
  // Is modal submitting
  isSubmitting?: boolean;
  // Is modal submission externally disabled
  isSubmittingExternallyDisabled?: boolean;
  onSetIsDirty: (isDirty: boolean) => void;
  render?: (openAttachmentsModal: () => void) => React.ReactNode;
}

export const AttachmentsModalController: React.FC<Props> = ({
  attachments,
  submitAttachmentsChanges,
  isSubmitting = false,
  isSubmittingExternallyDisabled = false,
  onSetIsDirty,
  render,
}) => {
  const { setSystemMessage } = useSystemMessages();
  const [isAttachmentsModalOpen, setIsAttachmentsModalOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isDiscardChangesConfirmationModalOpen, setIsDiscardChangesConfirmationModalOpen] =
    useState(false);

  // When NavigationPrompt will start reusing global state (singleton-like) in the future sp we can use
  // it in this component, we don't have to notify the parent of this component about isDirty state.
  useEffect(() => onSetIsDirty(isDirty), [isDirty, onSetIsDirty]);

  const notifyAboutSubmitInProgress = () => {
    setSystemMessage({
      type: "warning",
      message:
        "Your data is being saved at the moment. Please wait until it finishes before closing the modal.",
    });
  };

  const openAttachmentsModal = () => setIsAttachmentsModalOpen(true);
  const closeAttachmentsModal = () => {
    setIsDirty(false);
    setIsAttachmentsModalOpen(false);
  };

  const openDiscardChangesConfirmationModal = () => setIsDiscardChangesConfirmationModalOpen(true);
  const closeDiscardChangesConfirmationModal = () => {
    if (isSubmitting) {
      notifyAboutSubmitInProgress();
      return;
    }

    setIsDiscardChangesConfirmationModalOpen(false);
  };

  const cancelAttachmentsModal = () => {
    if (isSubmitting) {
      notifyAboutSubmitInProgress();
      return;
    }

    if (isDirty) {
      openDiscardChangesConfirmationModal();
      return;
    }

    closeAttachmentsModal();
  };

  const confirmAttachmentsModalCancellation = () => {
    closeDiscardChangesConfirmationModal();
    closeAttachmentsModal();
  };

  return (
    <>
      <OrderEditDiscardChangesConfirmationModal
        isOpen={isDiscardChangesConfirmationModalOpen}
        closeModal={closeDiscardChangesConfirmationModal}
        onConfirm={confirmAttachmentsModalCancellation}
        onClose={closeDiscardChangesConfirmationModal}
        testId="attachmentsModalDiscardChangesConfirmationModal"
      />
      <Modal isOpen={isAttachmentsModalOpen} closeModal={cancelAttachmentsModal}>
        <AttachmentsModal
          attachments={attachments}
          submit={submitAttachmentsChanges}
          cancel={cancelAttachmentsModal}
          isSubmitting={isSubmitting}
          isSubmittingExternallyDisabled={isSubmittingExternallyDisabled}
          onSetIsDirty={setIsDirty}
        />
      </Modal>

      {!!render && render(openAttachmentsModal)}
    </>
  );
};
