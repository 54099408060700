import { SupplierPortalAppService } from "src/typegens";

import { IPriceModifierRemovalConfig } from "../models/PriceModifierRemoval";

export const priceModifierRemovalConfig: Readonly<IPriceModifierRemovalConfig> = {
  // Leave empty to disable consolidation or provide a list of *all* meta fields
  // that should differentiate the changes for consolidation
  consolidationComparators: [],
  apiResponseMapper: (change, apiResponse) => ({
    ...change,
    status: "success",
    meta: {
      ...change.meta,
      newOrderVersionId: apiResponse.newVersion,
    },
    newValue: undefined,
  }),
  apiRequestCall: async (change, signal) =>
    SupplierPortalAppService.removePriceModifier(
      {
        s2SOrderId: change.meta.orderId,
        id: change.oldValue.id,
        requestBody: {
          version: change.meta.oldOrderVersionId,
        },
      },
      { signal }
    ),
};
