import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { NavigationPrompt } from "@web/common";
import { Heading, RegularButton } from "@web/ui";

import { RoutesConfig } from "src/admin/config/routes";
import { useSystemMessages } from "src/context/SystemMessages";
import {
  SupplierForm,
  SupplierFormType,
  SupplierSchema,
  useUpdateSupplierMutation,
} from "src/domain";

import { AdminEditDiscardChangesConfirmationModal } from "../../../components";

type Props = {
  defaultForm: SupplierFormType;
  supplierId: string;
};

export const EditSupplierForm: React.FC<Props> = ({ defaultForm, supplierId }) => {
  const navigate = useNavigate();
  const { setSystemMessage } = useSystemMessages();

  const form = useForm<SupplierFormType>({
    defaultValues: defaultForm,
    resolver: zodResolver(SupplierSchema),
  });

  const createMutation = useUpdateSupplierMutation({
    onSuccess: (data: SupplierFormType) => {
      form.reset(data);
      setSystemMessage({
        type: "success",
        message: "Your changes have been saved.",
      });
    },
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an error when updating this Supplier.",
      });
    },
  });

  const onSubmit = () =>
    form
      .handleSubmit((data) => createMutation.mutateAsync({ requestBody: data, supplierId }))()
      .catch(console.error);

  const isPending = createMutation.isPending;
  const { isDirty, errors } = form.formState;
  const blockCTAs = isPending || Object.keys(errors).length !== 0 || !isDirty;

  return (
    <>
      <NavigationPrompt
        isConfirmationRequired={isDirty}
        renderNavigationPrompt={({ isActive, cancel, confirm }) => (
          <AdminEditDiscardChangesConfirmationModal
            isOpen={isActive}
            closeModal={cancel as () => void}
            onConfirm={confirm as () => void}
            onClose={cancel as () => void}
          />
        )}
      />
      <div>
        <div className="max-w-[1024px] mx-auto px-8 py-6 mb-10">
          <FormProvider {...form}>
            <Heading size="300" className="pb-5">
              Edit Supplier
            </Heading>
            <SupplierForm />
            <div className="flex float-right">
              <RegularButton
                className="ml-6"
                variant="secondary"
                size="large"
                label="Cancel"
                onClick={() => navigate(RoutesConfig.home)}
                disabled={isPending}
                data-testid="cancelSupplierUpdateButton"
              />
              <RegularButton
                className="ml-6"
                variant="primary"
                size="large"
                label="Save changes"
                onClick={onSubmit}
                loading={isPending}
                disabled={blockCTAs}
                data-testid="submitSupplierUpdateButton"
              />
            </div>
          </FormProvider>
        </div>
      </div>
    </>
  );
};
