import { OrderVersionResponse, SupplierPortalAppService } from "src/typegens";

import { SupplierConfirmOrderParams } from "../models";

export const confirmOrder = async ({
  s2SOrderId,
  requestBody,
}: SupplierConfirmOrderParams): Promise<OrderVersionResponse> => {
  const result = await SupplierPortalAppService.confirmOrder({ s2SOrderId, requestBody });

  return result;
};
