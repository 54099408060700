export const CopyIcon = () => {
  return (
    <svg
      width="249"
      height="122"
      viewBox="0 0 249 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.1981 122H197.358C201.699 122 205.217 116.851 205.217 110.5C205.217 104.149 201.699 99 197.358 99H241.142C245.482 99 249 93.8513 249 87.5C249 81.1487 245.482 76 241.142 76H196.236C191.896 76 188.377 70.8513 188.377 64.5C188.377 58.1487 191.896 53 196.236 53H224.302C228.642 53 232.16 47.8513 232.16 41.5C232.16 35.1487 228.642 30 224.302 30H179.396C183.736 30 187.255 24.8513 187.255 18.5C187.255 12.1487 183.736 7 179.396 7L115.406 7C111.066 7 107.547 12.1487 107.547 18.5C107.547 24.8513 111.066 30 115.406 30H43.5566C39.2165 30 35.6981 35.1487 35.6981 41.5C35.6981 47.8513 39.2165 53 43.5566 53H18.8585C14.5184 53 11 58.1487 11 64.5C11 70.8513 14.5184 76 18.8585 76H40.1887C44.5288 76 48.0472 81.1487 48.0472 87.5C48.0472 93.8513 44.5288 99 40.1887 99H33.4528C29.1127 99 25.5943 104.149 25.5943 110.5C25.5943 116.851 29.1127 122 33.4528 122H91.8302C92.4082 122 92.9716 121.909 93.5141 121.735C94.0566 121.909 94.6201 122 95.1981 122Z"
        fill="#F0F5FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200 26C203.866 26 207 29.134 207 33C207 36.866 203.866 40 200 40L160 40C163.866 40 167 43.134 167 47C167 50.866 163.866 54 160 54L182 54C185.866 54 189 57.134 189 61C189 64.866 185.866 68 182 68L171.826 68C166.952 68 163 71.134 163 75C163 77.5773 165 79.9107 169 82C172.866 82 176 85.134 176 89C176 92.866 172.866 96 169 96L86 96C82.134 96 79 92.866 79 89C79 85.134 82.134 82 86 82L47 82C43.134 82 40 78.866 40 75C40 71.134 43.134 68 47 68L87 68C90.866 68 94 64.866 94 61C94 57.134 90.866 54 87 54L62 54C58.134 54 55 50.866 55 47C55 43.134 58.134 40 62 40L102 40C98.134 40 95 36.866 95 33C95 29.134 98.134 26 102 26L200 26ZM200 54C203.866 54 207 57.134 207 61C207 64.866 203.866 68 200 68C196.134 68 193 64.866 193 61C193 57.134 196.134 54 200 54Z"
        fill="#F0F5FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.672 24.9999L155.974 92.8427L156.81 99.649C157.079 101.842 155.52 103.837 153.327 104.107L94.767 111.297C92.5743 111.566 90.5785 110.007 90.3093 107.814L81.2935 34.3867C81.1589 33.2904 81.9386 32.2925 83.0349 32.1579C83.0418 32.157 83.0488 32.1562 83.0557 32.1554L87.9141 31.6104M91.8426 31.1698L96.4296 30.6552L91.8426 31.1698Z"
        fill="white"
      />
      <path
        d="M148.654 24.7283C148.504 23.6339 147.495 22.8684 146.401 23.0185C145.306 23.1685 144.541 24.1773 144.691 25.2716L148.654 24.7283ZM155.974 92.8427L157.959 92.5989C157.958 92.5896 157.957 92.5803 157.956 92.571L155.974 92.8427ZM156.81 99.649L158.795 99.4052L156.81 99.649ZM153.327 104.107L153.571 106.092L153.327 104.107ZM94.767 111.297L95.0107 113.282L94.767 111.297ZM90.3093 107.814L92.2944 107.57L90.3093 107.814ZM83.0557 32.1554L83.2786 34.143L83.0557 32.1554ZM88.137 33.598C89.2347 33.4749 90.0247 32.4852 89.9016 31.3875C89.7785 30.2898 88.7888 29.4998 87.6911 29.6229L88.137 33.598ZM91.6196 29.1822C90.5219 29.3054 89.7319 30.295 89.855 31.3927C89.9782 32.4904 90.9678 33.2804 92.0655 33.1573L91.6196 29.1822ZM96.6526 32.6428C97.7503 32.5196 98.5403 31.53 98.4172 30.4323C98.294 29.3346 97.3044 28.5446 96.2067 28.6677L96.6526 32.6428ZM144.691 25.2716L153.993 93.1144L157.956 92.571L148.654 24.7283L144.691 25.2716ZM153.989 93.0864L154.825 99.8927L158.795 99.4052L157.959 92.5989L153.989 93.0864ZM154.825 99.8927C154.959 100.989 154.18 101.987 153.083 102.122L153.571 106.092C156.86 105.688 159.199 102.694 158.795 99.4052L154.825 99.8927ZM153.083 102.122L94.5232 109.312L95.0107 113.282L153.571 106.092L153.083 102.122ZM94.5232 109.312C93.4269 109.446 92.429 108.667 92.2944 107.57L88.3242 108.058C88.7281 111.347 91.7217 113.686 95.0107 113.282L94.5232 109.312ZM92.2944 107.57L83.2786 34.143L79.3085 34.6304L88.3242 108.058L92.2944 107.57ZM83.2786 34.143L82.7912 30.1728C80.5985 30.442 79.0392 32.4378 79.3085 34.6304L83.2786 34.143ZM83.2786 34.143L83.2786 34.143L82.8327 30.1679C82.8189 30.1695 82.805 30.1711 82.7912 30.1728L83.2786 34.143ZM83.2786 34.143L88.137 33.598L87.6911 29.6229L82.8327 30.1679L83.2786 34.143ZM92.0655 33.1573L96.6526 32.6428L96.2067 28.6677L91.6196 29.1822L92.0655 33.1573Z"
        fill="#2F6FED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.14 29.2692L152.559 90.7529L153.317 96.9212C153.561 98.9083 152.167 100.715 150.203 100.956L97.761 107.395C95.7974 107.636 94.0079 106.22 93.7639 104.233L85.6139 37.8568C85.4792 36.7605 86.2589 35.7626 87.3552 35.628L93.8428 34.8314"
        fill="#F0F5FF"
      />
      <path
        d="M101.672 15C101.672 13.8954 102.567 13 103.672 13L149.229 13C149.759 13 150.267 13.2105 150.642 13.5853L164.086 27.0203C164.461 27.3954 164.672 27.9043 164.672 28.4349L164.672 91C164.672 92.1046 163.776 93 162.672 93L103.672 93C102.567 93 101.672 92.1046 101.672 91L101.672 15Z"
        fill="white"
        stroke="#2F6FED"
        strokeWidth="4"
      />
      <path
        d="M149.672 13.4028L149.672 25C149.672 26.6569 151.015 28 152.672 28L160.605 28"
        stroke="#2F6FED"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111 79L137 79M111 28L137 28L111 28ZM111 40L154 40L111 40ZM111 53L154 53L111 53ZM111 66L154 66L111 66Z"
        stroke="#1E4EAE"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
