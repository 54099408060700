import jwtDecode from "jwt-decode";
import { PropsWithChildren, useEffect, useState } from "react";

import { OpenAPI as AttachmentsOpenAPI } from "@web/attachments";
import { useAuth0WithRedirect } from "@web/common";
import { Loading } from "@web/ui";

import { UnauthenticatedApp } from "src/components";
import { OpenAPI } from "src/typegens";

import { useScopeContext } from "./ScopeContext/ScopeContext";

type AuthState = "loading" | "authenticated" | "notAuthenticated";

interface JwtDecoded {
  exp: number;
  scope: string;
}

const getUserScope = (token: string) => {
  try {
    const decoded = jwtDecode(token);
    const scope = (decoded as JwtDecoded).scope.split(" ");
    return scope;
  } catch (err) {
    console.warn(err);
    return [];
  }
};

export const AuthCheckpoint: React.FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0WithRedirect();
  const [state, setState] = useState<AuthState>("loading");
  const [, dispatch] = useScopeContext();

  useEffect(() => {
    if (isAuthenticated) {
      const handleToken = async () => {
        const token = await getAccessTokenSilently();
        OpenAPI.TOKEN = getAccessTokenSilently;
        AttachmentsOpenAPI.TOKEN = getAccessTokenSilently;
        const scope = getUserScope(token);
        dispatch({ type: "setScopeAction", value: scope });
        setState("authenticated");
      };
      handleToken().catch(console.error);
    } else {
      if (isLoading) {
        setState("loading");
      } else {
        setState("notAuthenticated");
      }
    }
  }, [isAuthenticated, isLoading, getAccessTokenSilently, setState, dispatch]);

  if (state === "loading") {
    return <Loading />;
  }

  if (state === "notAuthenticated") {
    return <UnauthenticatedApp />;
  }

  return <>{children}</>;
};
