/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { AttachmentMetadataResponse } from "../models/AttachmentMetadataResponse";

export class OrderAttachmentService {
  /**
   * @returns AttachmentMetadataResponse OK
   * @throws ApiError
   */
  public static createAttachment(
    {
      formData,
    }: {
      formData?: {
        file: Blob;
      };
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AttachmentMetadataResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/order-attachment",
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AttachmentMetadataResponse OK
   * @throws ApiError
   */
  public static getAttachmentMetadata(
    {
      fileId,
    }: {
      fileId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AttachmentMetadataResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/order-attachment/{fileId}/metadata",
      path: {
        fileId: fileId,
      },
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static getAttachmentContentForInline(
    {
      fileId,
    }: {
      fileId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/order-attachment/{fileId}/inline",
      path: {
        fileId: fileId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static getAttachmentContent(
    {
      fileId,
    }: {
      fileId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/order-attachment/{fileId}",
      path: {
        fileId: fileId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
