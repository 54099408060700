export const CheckboxIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.75 15.2402C1.41667 15.2402 1.125 15.1152 0.875 14.8652C0.625 14.6152 0.5 14.3236 0.5 13.9902V1.49023C0.5 1.1569 0.625 0.865234 0.875 0.615234C1.125 0.365234 1.41667 0.240234 1.75 0.240234H14.25C14.5833 0.240234 14.875 0.365234 15.125 0.615234C15.375 0.865234 15.5 1.1569 15.5 1.49023V13.9902C15.5 14.3236 15.375 14.6152 15.125 14.8652C14.875 15.1152 14.5833 15.2402 14.25 15.2402H1.75ZM1.75 13.9902H14.25V1.49023H1.75V13.9902Z"
        fill="#1D2433"
        fillOpacity="0.6"
      />
    </svg>
  );
};
