import { z } from "zod";

import { CostPriceSchema } from "./CostPriceSchema";

export const ProductSkuSchema = z.object({
  id: z.string(),
  supplierIdentifier: z.string(),
  measurementUnit: z.string(),
  price: CostPriceSchema,
  salesEntityQuantity: z.number(),
  salesEntityPrice: CostPriceSchema,
  about: z.object({
    name: z.string(),
    generalInformation: z
      .object({
        impaCode: z.string().optional(),
      })
      .optional(),
  }),
});

export type ProductSkuForm = z.infer<typeof ProductSkuSchema>;
