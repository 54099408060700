import { useCallback, useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { Loading } from "@web/ui";
import { isDefined } from "@web/utils";

import { RoutesConfig, RoutesParams } from "src/config/routes";
import { useSystemMessages } from "src/context/SystemMessages";
import {
  SupplierOrderService,
  useDiscardOrderChangesMutation,
  useOrderByIdQuery,
  useOrderByIdQueryHelpers,
} from "src/domain";
import { OrderVersionResponse } from "src/typegens";

import { OrderEditForm } from "../OrderEditForm";

interface Props {
  orderId: string;
  orderVersionId: number | "OLDEST" | "LATEST";
}

export const OrderEditFormQuery: React.FC<Props> = ({ orderId, orderVersionId }) => {
  const { setSystemMessage } = useSystemMessages();

  const [sudoEditedOrderVersion, setSudoEditedOrderVersion] = useState<number | undefined>();

  const calculatedOrderVersionId = isDefined(sudoEditedOrderVersion)
    ? sudoEditedOrderVersion
    : orderVersionId;

  const discardOrderChangesMutation = useDiscardOrderChangesMutation({
    onSuccess: (discardChangesResponse: OrderVersionResponse) => {
      const newOrderVersionId = discardChangesResponse.newVersion;
      // Update order edit page path to match new order version
      navigate(
        generatePath(RoutesConfig.orderEditVersion, {
          [RoutesParams.ORDER_ID]: orderId,
          [RoutesParams.ORDER_VERSION_ID]: newOrderVersionId.toString(),
        }),
        { replace: true }
      );
      // Overwrite orderVersionId to match new order version for the logic placed in useEffect below
      setSudoEditedOrderVersion(newOrderVersionId);
      // No need to invalidate specific order version query, since we'll be asking for a different
      // specific version than before, and also the previous version data did not change.
      invalidateQueries();
    },
  });

  const originalOrderQuery = useOrderByIdQuery({
    orderId,
    orderVersion: "OLDEST",
    select: SupplierOrderService.convertSupplierOrderToDraftFormData,
  });
  const {
    isPending: isOriginalOrderPending,
    isFetching: isOriginalOrderFetching,
    data: originalOrderFormData,
  } = originalOrderQuery;
  const { invalidate: invalidateOldestOrderQuery } = useOrderByIdQueryHelpers({
    orderId,
    orderVersion: "OLDEST",
  });

  const editedOrderQuery = useOrderByIdQuery({
    orderId,
    orderVersion: calculatedOrderVersionId,
    select: SupplierOrderService.convertSupplierOrderToDraftFormData,
  });
  const {
    isPending: isEditedOrderPending,
    isFetching: isEditedOrderFetching,
    data: editedOrderFormData,
  } = editedOrderQuery;

  const latestOrderQuery = useOrderByIdQuery({
    orderId,
    orderVersion: "LATEST",
    select: SupplierOrderService.convertSupplierOrderToDraftFormData,
  });
  const {
    isPending: isLatestOrderPending,
    isFetching: isLatestOrderFetching,
    data: latestOrderFormData,
  } = latestOrderQuery;
  const { invalidate: invalidateLatestOrderQuery } = useOrderByIdQueryHelpers({
    orderId,
    orderVersion: "LATEST",
  });

  const invalidateQueries = useCallback(() => {
    invalidateOldestOrderQuery();
    invalidateLatestOrderQuery();
  }, [invalidateOldestOrderQuery, invalidateLatestOrderQuery]);

  const isAnyQueryPendingOrFetching =
    isOriginalOrderPending ||
    isOriginalOrderFetching ||
    isEditedOrderPending ||
    isEditedOrderFetching ||
    isLatestOrderPending ||
    isLatestOrderFetching;

  const isOrderEditable: boolean | undefined = isDefined(originalOrderFormData)
    ? originalOrderFormData.editable
    : undefined;

  const latestOrderVersion = latestOrderFormData?.version;
  const editedOrderVersion = editedOrderFormData?.version;

  const navigate = useNavigate();

  useEffect(() => {
    // Only verify after we have the data from the query
    if (isDefined(isOrderEditable) && !isOrderEditable) {
      setSystemMessage({
        type: "failure",
        message: "This order is not available for editing",
      });

      navigate(generatePath(RoutesConfig.orderDetails, { [RoutesParams.ORDER_ID]: orderId }));
    }
  }, [isOrderEditable, navigate, orderId, setSystemMessage]);

  useEffect(() => {
    if (
      discardOrderChangesMutation.isPending ||
      !isDefined(latestOrderVersion) ||
      !isDefined(editedOrderVersion) ||
      isAnyQueryPendingOrFetching ||
      // Only verify after we have the data from the query
      (isDefined(isOrderEditable) && !isOrderEditable)
    ) {
      return;
    }

    // This means that in the meantime something has changed in the order unexpectedly,
    // and we need to discard all edits, so we get to the tip of the changes tree.
    // This can also happen when user edits order, cancels the edit and then tries to edit it again,
    // using an older order version as a base. In this case we are discarding changes and making
    // the HEAD of the changes tree with the oldest data of the order.
    if (latestOrderVersion !== editedOrderVersion) {
      discardOrderChangesMutation.mutate({ s2SOrderId: orderId });
    }
  }, [
    latestOrderVersion,
    editedOrderVersion,
    discardOrderChangesMutation.isPending,
    discardOrderChangesMutation,
    orderId,
    isAnyQueryPendingOrFetching,
    isOrderEditable,
  ]);

  if (
    isAnyQueryPendingOrFetching ||
    // Waiting for changes discard and reloading order data - refer to the useEffect above
    (isDefined(latestOrderVersion) &&
      isDefined(editedOrderVersion) &&
      latestOrderVersion > editedOrderVersion) ||
    discardOrderChangesMutation.isPending ||
    // If order is not editable, keep the Pending state until the user is redirected away
    !isOrderEditable ||
    // Satisfy compiler making sure these are defined
    !originalOrderFormData ||
    !editedOrderFormData
  ) {
    return <Loading />;
  }

  return (
    <OrderEditForm
      originalOrderFormData={originalOrderFormData}
      editedOrderFormData={editedOrderFormData}
    />
  );
};
