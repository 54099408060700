import { DefaultError, useMutation } from "@tanstack/react-query";

import { AssigneeInformationChangeResponse } from "src/typegens";

import { assignUserToPO } from "../api";

export const useAssignUserToPOMutation = (options = {}) => {
  return useMutation<
    AssigneeInformationChangeResponse,
    DefaultError,
    { s2SOrderId: string; version: number; id: string }
  >({
    mutationKey: ["assignUserToPO"],
    mutationFn: assignUserToPO,
    ...options,
  });
};
