import { MailIcon } from "@heroicons/react/solid";

import { CircledImage, Label } from "@web/ui";

import VesselIcon from "src/icons/VesselIcon.svg";

interface VesselContactProps {
  imoNumber: string;
  vesselName?: string;
  onContactClick: () => void;
}

export const RequesterContact: React.FC<VesselContactProps> = ({
  imoNumber,
  vesselName,
  onContactClick,
}) => {
  return (
    <div className="flex justify-between pb-4">
      <div className="flex w-full">
        <CircledImage Icon={VesselIcon} size={6} hashKey={vesselName} className="my-auto" />
        <div className="flex flex-col pl-1 truncate">
          <Label size="200" className="truncate">
            {vesselName}
          </Label>
          <Label size="200" color="text-textIcon-blackSecondary">
            {imoNumber}
          </Label>
        </div>
        <div className="ml-auto w-5 text-text-whiteDisabled cursor-pointer flex-shrink flex">
          <MailIcon onClick={onContactClick} width="20px" data-testid="requesterContact_mailIcon" />
        </div>
      </div>
    </div>
  );
};
