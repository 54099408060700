import { useReducer } from "react";

import { SystemMessages, SystemMessagesAction } from "./models";

export const defaultSystemMessages: SystemMessages = [];

const SystemMessagesReducer = (
  systemMessages: SystemMessages,
  action: SystemMessagesAction
): SystemMessages => {
  switch (action.type) {
    case "setSystemMessage":
      return [...systemMessages, action.value];
    case "clearSystemMessage":
      return systemMessages.filter((msg) => msg.id !== action.value.id);
    default:
      return systemMessages;
  }
};

export const useSystemMessagesReducer = () =>
  useReducer(SystemMessagesReducer, defaultSystemMessages);
