import { Money } from "@web/models";

export const formatMoney = (money: Money, omitCurrency?: boolean): string => {
  const rawMoney = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: money.currencyCode,
    currencyDisplay: "code",
  }).format(money.amount);

  if (money.amount < 0) {
    return `-${rawMoney.substring(5)}${!omitCurrency ? " " + rawMoney.substring(1, 4) : ""}`;
  }

  return `${rawMoney.substring(4)}${!omitCurrency ? " " + rawMoney.substring(0, 3) : ""}`;
};
