import classnames from "classnames";
import { useCallback } from "react";

import { AvatarButtonVariantsWithStates, AvatarCommonProps } from "./models";

export const useAvatarCommons = <T>({
  disabled = false,
  onClick,
  className = "",
  "data-testid": testId = "",
  ...rest
}: AvatarCommonProps & { onClick?: (event: React.MouseEvent<T, MouseEvent>) => void }): {
  mainTagCommonProps: {
    className: string;
    onClick: (event: React.MouseEvent<T, MouseEvent>) => void;
    "data-testid"?: string;
  };
  defaultedProps: Omit<AvatarCommonProps, "className" | "onClick">;
} => {
  const variantToButtonClassNames: Readonly<{
    [key in AvatarButtonVariantsWithStates]: string;
  }> = {
    default: "ring-primaryDefault",
    "default:disabled": "ring-textIcon-blackDisabled",
  };

  const calculatedVariant: AvatarButtonVariantsWithStates = disabled
    ? "default:disabled"
    : "default";

  const buttonVariantClassNames = variantToButtonClassNames[calculatedVariant];

  const handleOnClick = useCallback(
    (event: React.MouseEvent<T, MouseEvent>) => {
      if (disabled || !onClick) {
        return;
      }
      onClick(event);
    },
    [disabled, onClick]
  );

  const mainTagCommonProps = {
    className: classnames(
      "group",
      "inline-flex items-center bg-transparent",
      "border-0 rounded-full",
      "w-fit min-w-fit max-w-full",
      "focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-white",
      "overflow-hidden whitespace-nowrap leading-none",
      { "cursor-pointer": !disabled },
      { "pointer-events-none": disabled },
      buttonVariantClassNames,
      className
    ),
    onClick: handleOnClick,
    ...(testId ? { "data-testid": testId } : {}),
  };

  return {
    mainTagCommonProps,
    defaultedProps: {
      disabled,
      "data-testid": testId,
      ...rest,
    },
  };
};
