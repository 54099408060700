interface Props {
  children: React.ReactNode;
  header: React.ReactNode;
  sidebar?: React.ReactNode;
  noPadding?: boolean;
  requisitionsWaitingForApproval?: number;
}

export const SidebarLayout: React.FC<Props> = ({ children, noPadding, sidebar, header }) => {
  return (
    <div data-testid="layout" className="min-h-full min-w-full w-fit flex flex-col">
      {header}
      <div className="containerPadding flex flex-grow">
        {sidebar}
        <div className={`flex-grow flex max-w-full ${noPadding ? "flex-col" : "p-6 pl-8"}`}>
          {noPadding ? (
            children
          ) : (
            <div className="mx-auto flex-grow max-w-full w-full flex flex-col">{children}</div>
          )}
        </div>
      </div>
    </div>
  );
};
