import { Switch as HeadlessSwitch } from "@headlessui/react";
import classnames from "classnames";
import { forwardRef } from "react";

type Props = {
  enabled: boolean;
  onToggle(state: boolean): void;
  dataTestId?: string;
  size?: "default" | "small";
};

export const Switch = forwardRef<
  HTMLButtonElement,
  Props & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ enabled, onToggle, dataTestId = "switch", size = "default" }, ref) => {
  const getContainerSizeClasses = () => {
    switch (size) {
      case "small":
        return "h-3.5 w-[30px]";
      case "default":
        return "h-5 w-[48px]";
    }
  };

  const getToggleTranslateClasses = () => {
    switch (size) {
      case "small":
        return "translate-x-[14px]";
      case "default":
        return "translate-x-5";
    }
  };

  const getToggleSizeClasses = () => {
    switch (size) {
      case "small":
        return "h-3 w-3";
      case "default":
        return "h-4.5 w-4.5";
    }
  };

  return (
    <HeadlessSwitch
      checked={enabled}
      data-testid={dataTestId}
      onChange={onToggle}
      className={classnames(
        `relative inline-flex ${getContainerSizeClasses()} flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primaryDefault focus:ring-offset-2`,
        enabled ? "bg-primaryDefault" : "bg-neutral_200"
      )}
      ref={ref}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classnames(
          enabled ? getToggleTranslateClasses() : "translate-x-0",
          `pointer-events-none inline-block ${getToggleSizeClasses()} transform rounded-full bg-neutral_0 shadow ring-0 transition duration-200 ease-in-out`
        )}
      />
    </HeadlessSwitch>
  );
});

Switch.displayName = "Switch";
