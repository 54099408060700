import { forwardRef } from "react";

import { AvatarChildren } from "./AvatarChildren";
import { AvatarCommonProps } from "./models";
import { useAvatarCommons } from "./useAvatarCommons";

/**
 * This type merges:
 * - the common props (like `className` etc.),
 * - the native props of the main tag (<button> tag in this case),
 * - and sets more rigid typechecking for the `onClick` prop, so it is required when the button
 *   type is just `button`. It is not required when the button type is `submit` or `reset`, or when
 *   an arbitrary `isControlled` prop is passed (this can be used for button wrappers setting
 *   onClick events via ref. Please see usage examples in the apps).
 */
export type AvatarButtonProps = AvatarCommonProps &
  Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    "children" | "onClick" | keyof AvatarCommonProps
  > &
  (
    | {
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        type?: "button";
      }
    | {
        onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        type: "submit" | "reset";
      }
    | {
        onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        isControlled: true;
      }
  );

export const AvatarButton = forwardRef<HTMLButtonElement, AvatarButtonProps>(
  (props, forwardedRef) => {
    const { mainTagCommonProps } = useAvatarCommons<HTMLButtonElement>(props);
    // Removing illegal props so only valid ones are applied to the HTML tag
    const { avatarProps, type = "button", isControlled, ...buttonTagProps } = props;

    return (
      <button {...buttonTagProps} {...mainTagCommonProps} ref={forwardedRef} type={type}>
        <AvatarChildren {...props} />
      </button>
    );
  }
);
AvatarButton.displayName = "AvatarButton";
