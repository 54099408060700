import { DefaultError, useMutation } from "@tanstack/react-query";

import { SupplierResponse } from "src/typegens";

import { deleteSupplier } from "../api";

export const useDeleteSupplierMutation = (options = {}) => {
  return useMutation<SupplierResponse, DefaultError, string>({
    mutationKey: ["deleteSupplier"],
    mutationFn: deleteSupplier,
    ...options,
  });
};
