import { ChangeConfig, ChangeTypesMap } from "../models";
import { orderItemAdditionConfig } from "./OrderItemAdditionConfig";
import { orderItemQuantityChangeConfig } from "./OrderItemQuantityChangeConfig";
import { orderItemReplacementConfig } from "./OrderItemReplacementConfig";
import { priceModifierRemovalConfig } from "./PriceModifierRemovalConfig";

export const changeConfig: Readonly<ChangeTypesMap<ChangeConfig>> = {
  orderItemQuantityChange: orderItemQuantityChangeConfig,
  priceModifierRemoval: priceModifierRemovalConfig,
  orderItemAddition: orderItemAdditionConfig,
  orderItemReplacement: orderItemReplacementConfig,
};
