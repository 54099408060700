import { DiscardChangesConfirmationModal } from "@web/common";
import { Modal } from "@web/ui";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  onClose: () => void;
  testId?: string;
}

export const OrderEditDiscardChangesConfirmationModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  onConfirm,
  onClose,
  testId,
}) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <DiscardChangesConfirmationModal
        title="Are you sure you want to discard changes?"
        confirmLabel="Discard changes"
        cancelLabel="Keep editing"
        onConfirm={onConfirm}
        onClose={onClose}
        testId={testId}
      />
    </Modal>
  );
};
