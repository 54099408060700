import { ContextModal } from "../../components/ContextModal";
import useModal from "../../hooks/useModal";
import { ModalContext } from "./ModalContext";

export interface OrderContextMenuActions {
  openModalCancelOrder?: () => void;
  openModalCloseOrder?: () => void;
  onMTMLDownload?: () => void;
  onExcelDownload?: () => void;
  onDeleteDraft?: () => void;
}

interface Props {
  children: React.ReactNode;
  handleClose?: (closeCb: () => void) => () => void;
}

const defaultClose = (onClose: () => void) => () => onClose();

export const ModalProvider = ({ children, handleClose = defaultClose }: Props) => {
  const { closeModal, ...modalProps } = useModal({
    isOpen: false,
    component: null,
  });

  return (
    <ModalContext.Provider value={{ closeModal: handleClose(closeModal), ...modalProps }}>
      <ContextModal />
      {children}
    </ModalContext.Provider>
  );
};
