import { Paragraph } from "@web/ui";

import { SupplierOrderService } from "src/domain";

interface Props {
  quantityInBasket: number;
  salesEntityQuantity: number;
  measurementUnit: string;
  label?: string;
  lineThrough?: boolean;
}

export const TinyTotalUnitOfMeasure: React.FC<Props> = ({
  quantityInBasket,
  salesEntityQuantity,
  measurementUnit,
  label = "Total",
  lineThrough,
}) => {
  const entityQuantity = SupplierOrderService.getTotalEntityQuantity(
    salesEntityQuantity,
    quantityInBasket
  );
  return (
    <div className="flex flex-row justify-center items-center flex-grow">
      <Paragraph size="300" color="text-textIcon-blackSecondary">
        {label}
      </Paragraph>
      <Paragraph
        size="300"
        color="text-textIcon-blackSecondary"
        className={`ml-3 ${lineThrough ? "line-through" : ""}`}
      >
        {entityQuantity} {measurementUnit}
      </Paragraph>
    </div>
  );
};
