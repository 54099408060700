import { ReactNode } from "react";

import { LoadingInline } from "../../atoms/Loading/index";
import { Dropdown, DropdownItem } from "../Dropdown";
import { EmptyState } from "../EmptyState";
import { TableActionsButton } from "../TableActionsButton";
import { TableCell } from "../TableCell/TableCell";
import { TableHeaderCell } from "../TableHeaderCell/TableHeaderCell";

interface ISecondLineObject {
  firstLine: string;
  secondLine: string;
}

export type TCellData = ISecondLineObject | string | number | ReactNode;

export interface ICell {
  type: "title" | "twoLines" | "statusBadge" | "numeric" | "icon";
  data: TCellData;
}

export interface IRow {
  isUnseen?: boolean;
  hasAttachment?: boolean;
  onClick?: () => void;
  dropdownOptions?: Array<DropdownItem>;
  data: Array<ICell | undefined>;
}

export interface ITable {
  headings?: Array<
    | {
        text: string;
        classNames?: string;
        dropdownOptions?: Array<DropdownItem>;
        testId?: string;
        textEnd?: boolean;
      }
    | undefined
  >;
  rows?: Array<IRow>;
  emptyState?: {
    title: string;
    subtitle: string;
  };
  isLoading?: boolean;
  testId?: string;
}

// TODO #5571: Along with the first implementation add parser for OrderRequisiton data to match the Table API
export const Table = ({
  headings = [],
  rows = [],
  emptyState,
  isLoading,
  testId,
  ...rest
}: ITable) => {
  const getCellFromType = (cell: ICell, index: number) => {
    if (cell.data === null) {
      return;
    }

    switch (cell.type) {
      case "title":
        return (
          <TableCell key={index} classNames="whitespace-nowrap">
            {cell.data}
          </TableCell>
        );
      case "twoLines":
        if (!cell.data || typeof cell.data !== "object" || !("firstLine" in cell.data)) {
          return;
        }

        return (
          <TableCell key={index}>
            <div className="flex flex-col">
              <span className="text-sm leading-none whitespace-nowrap">{cell.data.firstLine}</span>
              <span className="text-xxs leading-snug whitespace-nowrap text-textIcon-blackSecondary">
                {cell.data.secondLine}
              </span>
            </div>
          </TableCell>
        );
      case "statusBadge":
        if (typeof cell.data === "object" && "props" in cell.data) {
          return <TableCell key={index}>{cell.data}</TableCell>;
        }
        return;
      case "numeric":
        if (typeof cell.data === "number" || typeof cell.data === "string") {
          return (
            <TableCell key={index} classNames="text-right">
              {cell.data}
            </TableCell>
          );
        }

        return (
          <TableCell key={index} classNames="text-right">
            wrong data type
          </TableCell>
        );
      case "icon":
        if (typeof cell.data === "object" && "props" in cell.data) {
          return <TableCell key={index}>{cell.data}</TableCell>;
        }
        return;
    }
  };

  const isUnseen = (row: IRow) => {
    if (row.isUnseen) {
      return "unseen";
    }

    return "default";
  };

  const areAttachments = (row: IRow) => {
    if (row.hasAttachment) {
      return "attachments";
    }

    return "default";
  };

  const hasAnyDropdownOptions = !!rows.find((r) => r.dropdownOptions);

  return (
    <>
      <div className="w-full">
        <table className="w-full border-collapse overflow-x-scroll" data-testid={testId} {...rest}>
          <thead className="sticky top-0 z-10">
            <tr className="bg-neutral_200">
              <TableHeaderCell />
              {headings.map(
                (heading) =>
                  heading && (
                    <TableHeaderCell
                      text={heading.text}
                      classNames={`${heading.classNames || ""}`}
                      key={heading.text}
                      dropdownOptions={heading.dropdownOptions}
                      testId={heading.testId}
                      textEnd={heading.textEnd}
                    />
                  )
              )}
              <TableHeaderCell text={""} />
              {hasAnyDropdownOptions && <TableHeaderCell text={""} />}
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, i) => (
              <tr
                key={`tr-${i}`}
                className={`even:bg-neutral_100 odd:bg-white ${row.isUnseen ? "font-bold" : ""} ${
                  row.onClick ? "cursor-pointer hover:bg-neutral_200" : ""
                }`}
                onClick={row.onClick}
                data-testid={`${testId}-item`}
              >
                <TableCell mode={isUnseen(row)} sticky classNames="bg-inherit" />
                {row.data.map((cell: ICell | undefined, i) => cell && getCellFromType(cell, i))}
                <TableCell mode={areAttachments(row)} data-testid={testId + "-attachment"} />
                {hasAnyDropdownOptions && (
                  <TableCell mode="dropdown">
                    {row.dropdownOptions && (
                      <div className="flex items-center justify-center">
                        <Dropdown
                          hAlignTo="end"
                          vAlignTo="bottom"
                          items={row.dropdownOptions}
                          stopPropagation
                        >
                          <TableActionsButton label="Item actions" />
                        </Dropdown>
                      </div>
                    )}
                  </TableCell>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isLoading && (
        <div className="h-[80vh] w-full flex items-center justify-center">
          <LoadingInline />
        </div>
      )}
      {rows.length === 0 && !isLoading && (
        <div className="h-[80vh] w-full flex items-center justify-center">
          <EmptyState
            title={emptyState?.title || "We looked everywhere.."}
            subtitle={emptyState?.subtitle || "There aren’t any results."}
            image="no-results"
          />
        </div>
      )}
    </>
  );
};
