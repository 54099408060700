import { ArchiveIcon, InboxIcon } from "@heroicons/react/outline";
import { useFlag } from "@unleash/proxy-client-react";
import classnames from "classnames";

import { Label, SectionHeaderLink, SimpleBadgeLink } from "@web/ui";

import { RoutesConfig } from "src/config/routes";
import { useOrdersMetadata } from "src/domain";

export const Sidebar: React.FC = () => {
  const ordersQuery = useOrdersMetadata();

  const unseenIncomingOrdersCount = ordersQuery.isSuccess
    ? ordersQuery.data.unseenIncomingOrdersCount
    : 0;
  const incomingOrdersCount = ordersQuery.isSuccess ? ordersQuery.data.incomingOrdersCount : "";

  const isCloseAttributeEnabled = useFlag("close-attribute");

  return (
    <div
      className={classnames("py-6 pl-8 pr-5 bg-neutral_100 w-1/5 min-w-[270px]", {
        "flex flex-col gap-2": !isCloseAttributeEnabled,
      })}
    >
      <div className="flex items-center gap-2 rounded-lg py-2 px-3">
        <InboxIcon className="h-5 w-5 text-textIcon-blackPrimary" aria-hidden />
        <Label size="100" color="text-textIcon-blackPrimary">
          {isCloseAttributeEnabled ? "Active Orders" : "All Orders"}
        </Label>
      </div>
      <SimpleBadgeLink
        text={`Incoming ${incomingOrdersCount ? `(${incomingOrdersCount})` : ""}`}
        pathname={RoutesConfig.home}
        search="status=incoming"
        badge={unseenIncomingOrdersCount}
      />
      <SimpleBadgeLink text="Confirmed" pathname={RoutesConfig.home} search="status=confirmed" />
      {isCloseAttributeEnabled && (
        <SimpleBadgeLink text="Delivered" pathname={RoutesConfig.home} search="status=delivered" />
      )}
      <SimpleBadgeLink text="Rejected" pathname={RoutesConfig.home} search="status=rejected" />
      <SimpleBadgeLink text="Cancelled" pathname={RoutesConfig.home} search="status=cancelled" />
      <SimpleBadgeLink
        text="Cancellation requests"
        pathname={RoutesConfig.home}
        search="status=cancellationRequested"
      />
      {!isCloseAttributeEnabled && (
        <SimpleBadgeLink text="Closed" pathname={RoutesConfig.home} search="status=closed" />
      )}
      {isCloseAttributeEnabled && (
        <SectionHeaderLink
          className="mt-4"
          text="Closed Orders"
          Icon={ArchiveIcon}
          pathname={RoutesConfig.home}
          search="closed=true"
        />
      )}
    </div>
  );
};
