import { z } from "zod";

const CategoriesAsConst = [
  "MD",
  "SDOC",
  "CERT",
  "SDS",
  "DWG",
  "PHOTO",
  "SOW",
  "SVC_NOTES",
  "OTHER",
] as const;

export type Categories = typeof CategoriesAsConst[number];

export const AttachmentSchema = z.object({
  id: z.string(),
  name: z.string(),
  size: z.number().int().positive(),
  category: z
    .enum(CategoriesAsConst, {
      invalid_type_error: "Select category",
    })
    .optional(),
  createdBy: z.string(),
  createdDate: z.date(),
  isRemoved: z.boolean().optional(),
  isQueued: z.boolean().optional(),
  isLoading: z.boolean().optional(),
  error: z.boolean().optional(),
  file: z.any().optional(),
  uploadId: z.string().optional(),
});

export type AttachmentForm = z.infer<typeof AttachmentSchema>;
