import { useAuth0WithRedirect } from "@web/common";
import { Paragraph, RegularButton } from "@web/ui";

export const NoAccess: React.FC = () => {
  const { logout } = useAuth0WithRedirect();

  return (
    <div className="bg-neutral_0 overflow-hidden shadow rounded-lg h-full flex flex-col justify-center">
      <div data-testid="login-form" className="px-4 py-5 w-40 m-auto text-center">
        <Paragraph size="100" className="mb-5">
          No access
        </Paragraph>
        <Paragraph size="200" className="mb-2">
          This user has no access to supplier portal.
        </Paragraph>
        <Paragraph size="200" className="mb-8">
          Please log in to other account or contact support.
        </Paragraph>
        <RegularButton
          variant="primary"
          size="large"
          label="Log in"
          onClick={() => logout({ returnTo: window.location.origin })}
          data-testid="loginButton"
        />
      </div>
    </div>
  );
};
