import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { getAttachmentDownloadUrl } from "@web/attachments";
import { DigitalAssetsWrapper, Heading, Loading, containerPadding } from "@web/ui";
import { LEGACY_formatDate } from "@web/utils";

import { RoutesParams } from "src/config/routes";
import { useOrderByIdQuery, useOrderByIdQueryHelpers } from "src/domain";

import { Topbar } from "../OrderDetails/components";

export const OrderAttachments = () => {
  const { [RoutesParams.ORDER_ID]: orderId } = useParams() as { orderId: string };
  const orderQuery = useOrderByIdQuery({
    orderId,
    orderVersion: "OLDEST",
  });
  const { invalidate: invalidateOrderQuery } = useOrderByIdQueryHelpers({
    orderId,
    orderVersion: "OLDEST",
  });

  const invalidateOrderQueries = useCallback(() => {
    invalidateOrderQuery();
  }, [invalidateOrderQuery]);

  if (orderQuery.isPending || orderQuery.isFetching) {
    return <Loading />;
  }

  if (orderQuery.isError) {
    // Error is handled in `ErrorBoundary`
    return null;
  }

  const order = orderQuery.data;

  return (
    <>
      <div className="flex flex-col min-h-full bg-neutral_100">
        <Topbar
          order={order}
          orderVersion={order.version}
          invalidateOrderQueries={invalidateOrderQueries}
          className="flex-none"
          isAttachmentsPageMode
        />
        <div className={`${containerPadding} flex flex-col py-5`}>
          <Heading size="300">Attachments ({order.attachments.length})</Heading>

          <DigitalAssetsWrapper
            assets={order.attachments.map((asset) => ({
              ...asset,
              key: asset.attachmentId,
              createdDate: LEGACY_formatDate(asset.createdDate),
              nameUploadedBy: asset.createdBy,
              downloadLink: getAttachmentDownloadUrl(asset.attachmentId),
            }))}
            showMoreLabel="Show More Downloads"
            showLessLabel="Show Less Downloads"
            showLimit={order.attachments.length}
            className="md:grid-cols-3 lg:grid-cols-3 mt-4"
          />
        </div>
      </div>
    </>
  );
};
