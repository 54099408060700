import { useQuery } from "@tanstack/react-query";

import { SupplierResponse } from "src/typegens";

import { getSupplierSelfInformation } from "../api";

export const useSupplierInformationQuery = (options = {}) => {
  return useQuery<SupplierResponse>({
    queryKey: ["supplierInformation"],
    queryFn: () => getSupplierSelfInformation(),
    ...options,
  });
};
