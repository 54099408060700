import { Transition } from "history";
import { useCallback } from "react";

import { useBlocker, useConfirm } from "@web/ui";

type Props = {
  isConfirmationRequired: boolean;
  beforeConfirm?: () => Promise<void>;
  renderNavigationPrompt: ({
    isActive,
    confirm,
    cancel,
  }: {
    isActive: boolean;
    confirm: (value: unknown) => void;
    cancel: (value: unknown) => void;
  }) => React.ReactElement;
};

/**
 * @example
 * <NavigationPrompt isConfirmationRequired={isDirty} renderNavigationPrompt={({ isActive, confirm, cancel }) => <ModalComponent {...} />} />
 */

export const NavigationPrompt: React.FC<Props> = ({
  isConfirmationRequired,
  beforeConfirm,
  renderNavigationPrompt,
}) => {
  const { isActive, confirm, cancel, onConfirm, isConfirmed, resetConfirmation } =
    useConfirm(beforeConfirm);

  const blocker = useCallback(
    async (tx: Transition) => {
      if (await onConfirm()) {
        resetConfirmation();
        tx.retry();
      }
    },
    [resetConfirmation, onConfirm]
  );

  useBlocker(blocker, isConfirmationRequired && !isConfirmed);

  return renderNavigationPrompt({ isActive, confirm, cancel });
};
