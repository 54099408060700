import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useAuth0WithRedirect } from "@web/common";
import {
  AvatarButton,
  DividerDropdownItem,
  Dropdown,
  DropdownItem,
  Header,
  RegularDropdownItem,
  SidebarLayout,
} from "@web/ui";
import { formatInitials } from "@web/utils";

import { RoutesConfig } from "src/config/routes";
import { useUserPermissions } from "src/domain";
import Logo from "src/icons/S2SHeaderLogo.svg";

import { Sidebar } from "./components";

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const { logout, user } = useAuth0WithRedirect();
  const navigate = useNavigate();
  const { isSupervisor } = useUserPermissions();
  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);

  const dropdownItemsList: DropdownItem[] = useMemo(
    () => [
      {
        key: "viewProfile",
        renderComponent: () => (
          <RegularDropdownItem
            label="View profile"
            variant="basic"
            onClick={() => navigate(RoutesConfig.profile)}
            data-testid="viewProfileDropdownItem"
          />
        ),
      },
      {
        key: "viewCompanyProfile",
        renderComponent: () => (
          <RegularDropdownItem
            label="View company profile"
            variant="basic"
            onClick={() => navigate(RoutesConfig.companyProfile)}
            data-testid="viewCompanyProfileDropdownItem"
          />
        ),
      },
      {
        key: "dangerZoneDivider",
        renderComponent: () => <DividerDropdownItem />,
      },
      {
        key: "signOut",
        renderComponent: () => (
          <RegularDropdownItem
            label="Sign out"
            variant="danger"
            onClick={() => logout({ returnTo: window.location.origin })}
            data-testid="signOutDropdownItem"
          />
        ),
      },
    ],
    [logout, navigate]
  );

  useEffect(() => {
    if (isSupervisor) {
      setDropdownItems(dropdownItemsList.filter((item) => item.key !== "viewCompanyProfile"));
    } else {
      setDropdownItems(dropdownItemsList);
    }
  }, [isSupervisor, dropdownItemsList]);

  return (
    <SidebarLayout
      header={
        <Header
          logo={
            <Link to={RoutesConfig.home} className="flex">
              <img src={Logo} />
            </Link>
          }
          right={
            <Dropdown hAlignTo="end" vAlignTo="bottom" items={dropdownItems}>
              <AvatarButton
                avatarProps={{
                  text: formatInitials(user?.name || ""),
                }}
                data-testid="avatarSettingsDropdown"
                isControlled
              />
            </Dropdown>
          }
        />
      }
      sidebar={<Sidebar />}
      noPadding={false}
    >
      {children}
    </SidebarLayout>
  );
};
