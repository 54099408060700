import { OrderVersionResponse, SupplierPortalAppService } from "src/typegens";

import { SupplierConfirmOrderCancellationParams } from "../models";

export const confirmOrderCancellation = async ({
  s2SOrderId,
  requestBody,
}: SupplierConfirmOrderCancellationParams): Promise<OrderVersionResponse> => {
  const result = await SupplierPortalAppService.confirmOrderCancellation({
    s2SOrderId,
    requestBody,
  });

  return result;
};
