import { ChevronDownIcon, XCircleIcon } from "@heroicons/react/outline";
import { RefObject, useCallback } from "react";

import {
  CheckboxCheckedIcon,
  CheckboxIcon,
  Chip,
  Dropdown,
  DropdownItem,
  LinkButton,
  RegularDropdownItem,
} from "@web/ui";

import { ColumnName, Filter, FilterOptions, FilterValue } from "src/domain";

interface Props {
  filters: Filter[];
  setFilters: (filters: Filter[]) => void;
  recentlyAddedFilterRef?: RefObject<HTMLButtonElement>;
  filterOptions: FilterOptions;
}

export const OrdersTableFilters: React.FC<Props> = ({
  filters,
  setFilters,
  recentlyAddedFilterRef,
  filterOptions,
}) => {
  const columnNameFormatted = useCallback((columnName: ColumnName) => {
    switch (columnName) {
      case "CUSTOMER_NAME":
        return "Customer Name";
      case "VESSEL_NAME":
        return "Vessel";
      case "DELIVERY_PORT":
        return "Delivery Port";
      default:
        return "";
    }
  }, []);

  const renderLeadingIcon = useCallback(
    (option: FilterValue, columnName: ColumnName) => {
      const filter = filters.find((f) => f.columnName === columnName);
      if (!filter) {
        return undefined;
      }

      const selectedValues = filter.selectedValues || [];

      if (selectedValues.find((v) => v.value === option.value)) {
        return CheckboxCheckedIcon;
      }

      return CheckboxIcon;
    },
    [filters]
  );

  const selectOption = useCallback(
    (columnName: ColumnName, option: FilterValue) => {
      const index = filters.findIndex((f) => f.columnName === columnName);

      // If the filter does not exist, add it
      if (index === -1) {
        setFilters([...filters, { columnName, selectedValues: [option] }]);
        return;
      }

      const selectedValues = filters[index].selectedValues || [];

      // If option exists in selectedValues, remove it, otherwise add it
      const updatedSelectedValues = selectedValues.find(
        (prevOption) => prevOption.value === option.value
      )
        ? selectedValues.filter((v) => v.value !== option.value)
        : [...selectedValues, option];

      // Clone the filters array and replace the updated filter at the found index
      const updatedFilters = [...filters];
      updatedFilters[index] = { columnName, selectedValues: updatedSelectedValues };

      setFilters(updatedFilters);
    },
    [filters, setFilters]
  );

  const getDropdownItemsFromColumnType = useCallback(
    (columnName: ColumnName): DropdownItem[] => {
      switch (columnName) {
        case "CUSTOMER_NAME":
          return (
            filterOptions.buyers.map((item) => ({
              key: item.value,
              shouldKeepDropdownOpen: true,
              renderComponent: () => (
                <RegularDropdownItem
                  label={item.name}
                  variant="basic"
                  data-testid={`dropdownOption-CUSTOMER_NAME-filter-option-${item.name}`}
                  LeadingIcon={renderLeadingIcon(
                    {
                      value: item.value,
                      name: item.name,
                    },
                    columnName
                  )}
                  onClick={() => {
                    selectOption(columnName, {
                      value: item.value,
                      name: item.name,
                    });
                  }}
                />
              ),
            })) || []
          );
        case "VESSEL_NAME":
          return (
            filterOptions.vessels.map((item) => ({
              key: item.value,
              shouldKeepDropdownOpen: true,
              renderComponent: () => (
                <RegularDropdownItem
                  label={item.name}
                  variant="basic"
                  data-testid={`dropdownOption-VESSEL_NAME-filter-option-${item.name}`}
                  LeadingIcon={renderLeadingIcon(
                    {
                      value: item.value,
                      name: item.name,
                    },
                    columnName
                  )}
                  onClick={() => {
                    selectOption(columnName, {
                      value: item.value,
                      name: item.name,
                    });
                  }}
                />
              ),
            })) || []
          );

        case "DELIVERY_PORT":
          return (
            filterOptions.ports.map((item) => ({
              key: item.value,
              shouldKeepDropdownOpen: true,
              renderComponent: () => (
                <RegularDropdownItem
                  label={item.name}
                  variant="basic"
                  data-testid={`dropdownOption-DELIVERY_PORT-filter-option-${item.name}`}
                  LeadingIcon={renderLeadingIcon(
                    {
                      value: item.value,
                      name: item.name,
                    },
                    columnName
                  )}
                  onClick={() => {
                    selectOption(columnName, {
                      value: item.value,
                      name: item.name,
                    });
                  }}
                />
              ),
            })) || []
          );
        default:
          return [];
      }
    },
    [
      filterOptions.buyers,
      filterOptions.vessels,
      filterOptions.ports,
      renderLeadingIcon,
      selectOption,
    ]
  );

  const removeAllFilters = useCallback(() => {
    window.localStorage.removeItem("ordersTableFilters");
    setFilters([]);
  }, [setFilters]);

  if (!filters || filters.length === 0) {
    return null;
  }

  return (
    <div className="py-6 flex justify-between items-center">
      {filters.map((filter) => (
        <Dropdown
          hAlignTo="start"
          vAlignTo="bottom"
          key={filter.columnName}
          items={getDropdownItemsFromColumnType(filter.columnName)}
        >
          <Chip
            key={filter.columnName}
            label={`${columnNameFormatted(filter.columnName)}: ${
              filter.selectedValues?.map((v) => v.name).join(", ") || ""
            }`}
            onClick={() => {
              //
            }}
            size="xsmall"
            TrailingIcon={ChevronDownIcon}
            ref={recentlyAddedFilterRef}
            data-testid={`ordersTable-filterChip-${filter.columnName}`}
            className="my-1 mx-0.5"
          />
        </Dropdown>
      ))}

      <div className="ml-auto">
        <LinkButton
          onClick={removeAllFilters}
          label="Clear Filter"
          variant="primary"
          size="large"
          TrailingIcon={XCircleIcon}
        />
      </div>
    </div>
  );
};
