import { ShoppingCartIcon, SupportIcon } from "@heroicons/react/outline";
import { ComponentType, SVGProps } from "react";

import { IBadge } from "@web/ui";
import { WithRequiredProperty } from "@web/utils";

import { LocalStocktakeType, OrderType } from "../../models";
import { BondedIcon, ProvisionsIcon, StoresIcon } from "./icons";

export const getIconFromOrderOrStocktakeType = (
  orderType: OrderType | LocalStocktakeType
): ComponentType<SVGProps<SVGSVGElement>> => {
  switch (orderType) {
    case "BONDED":
      return BondedIcon;
    case "PROVISIONS":
      return ProvisionsIcon;
    case "STORES":
      return StoresIcon;
    case "WELFARE":
      return SupportIcon;
    default:
      return ShoppingCartIcon;
  }
};

export type BadgeSettings = WithRequiredProperty<IBadge, "color" | "contrast" | "LeadingIcon">;

export const getBaseBadgeSettingsForOrderAndStocktakeTypes = (
  orderOrStocktakeType: OrderType | LocalStocktakeType
): BadgeSettings => ({
  color: "base",
  contrast: "low",
  LeadingIcon: getIconFromOrderOrStocktakeType(orderOrStocktakeType),
  text: "",
});
