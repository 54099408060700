import { useQuery } from "@tanstack/react-query";

import { ConfigurationResponse, SupplierPortalAppService } from "src/typegens";

export const useFiltersConfigurationQuery = () => {
  return useQuery<ConfigurationResponse>({
    queryKey: ["configuration"],
    queryFn: () => SupplierPortalAppService.getConfiguration(),
  });
};
