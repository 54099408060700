import { ExclamationIcon } from "@heroicons/react/solid";

import { IntersectionMonitor } from "@web/common";
import { Heading, Loading, Paragraph } from "@web/ui";

import { useSuppliersQuery } from "src/domain";

import { SuppliersTableWrapper } from "./SuppliersTableWrapper";

export const SuppliersTable: React.FC = () => {
  const suppliersQuery = useSuppliersQuery();

  if (suppliersQuery.isError) {
    return (
      <div className="container-md mx-auto mt-8">
        <ExclamationIcon className="w-8 h-8 mx-auto" />
        <Heading size="300">Fetching error</Heading>
      </div>
    );
  }

  if (suppliersQuery.status !== "success") {
    return <Loading />;
  }

  const suppliersList = suppliersQuery.data.pages.flatMap((p) => p.suppliers.items);

  if (suppliersList.length < 1) {
    return (
      <div className="container-md mx-auto mt-8">
        <Paragraph size="100">No Suppliers yet</Paragraph>
      </div>
    );
  }

  return (
    <>
      <SuppliersTableWrapper suppliers={suppliersList} />
      {suppliersQuery.hasNextPage && (
        <IntersectionMonitor onEnter={suppliersQuery.fetchNextPage}>
          <div className="h-10 max-w-6xl">{suppliersQuery.isFetchingNextPage && <Loading />}</div>
        </IntersectionMonitor>
      )}
    </>
  );
};
