import { DefaultError, useMutation } from "@tanstack/react-query";

import { OrderVersionResponse } from "src/typegens";

import { useSystemMessages } from "../../../context/SystemMessages";
import { discardOrderChanges } from "../api";

export const useDiscardOrderChangesMutation = (options = {}) => {
  const { setSystemMessage } = useSystemMessages();

  return useMutation<OrderVersionResponse, DefaultError, { s2SOrderId: string }>({
    mutationKey: ["discardOrderChanges"],
    mutationFn: discardOrderChanges,
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an error while reverting the changes. Please try again.",
      });
      // TODO #2158: Check what is logged to sentry in this case and provide some manual logging if needed
    },
    ...options,
  });
};
