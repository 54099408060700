import { OrderEditsSyncContext } from "./OrderEditsSyncContext";
import { OrderEditsSyncLifecycle } from "./OrderEditsSyncLifecycle";
import { useOrderEditsSyncReducer } from "./reducer";

interface Props {
  children: React.ReactNode;
}

export const OrderEditsSyncProvider = ({ children }: Props) => {
  const [orderEditsSyncState, dispatch] = useOrderEditsSyncReducer();

  return (
    <OrderEditsSyncContext.Provider value={[orderEditsSyncState, dispatch]}>
      <OrderEditsSyncLifecycle>{children}</OrderEditsSyncLifecycle>
    </OrderEditsSyncContext.Provider>
  );
};
