import { TrashIcon } from "@heroicons/react/solid";

import { Money } from "@web/models";
import { IconButton, Paragraph } from "@web/ui";
import { formatMoney } from "@web/utils";

interface Props {
  className?: string;
  costName: string;
  amount: Money;
  onTrashClick: () => void;
  testId?: string;
}

export const PriceModifier: React.FC<Props> = ({
  costName,
  amount,
  onTrashClick,
  testId = "",
  ...rest
}) => {
  return (
    <div data-testid={testId} {...rest}>
      <div className="flex justify-between items-center mb-2">
        <div className="max-w-[60%]">
          <Paragraph size="200" className="font-medium truncate max-w-full">
            {costName}
          </Paragraph>
        </div>
        <div className="flex justify-end items-center gap-5">
          <Paragraph size="200" className="font-medium">
            {formatMoney(amount)}
          </Paragraph>
          <IconButton
            size="small"
            variant="danger"
            shape="circle"
            label="Delete"
            onClick={onTrashClick}
            Icon={TrashIcon}
            data-testid={testId ? `${testId}_trashButton` : ""}
          />
        </div>
      </div>
      <hr />
    </div>
  );
};
