/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { ApiListSupplierPortalProductSku } from "../models/ApiListSupplierPortalProductSku";
import type { AssigneeInformation } from "../models/AssigneeInformation";
import type { AssigneeInformationChangeResponse } from "../models/AssigneeInformationChangeResponse";
import type { AttachmentInformationChangeResponse } from "../models/AttachmentInformationChangeResponse";
import type { AttachmentsRequest } from "../models/AttachmentsRequest";
import type { ConfigurationResponse } from "../models/ConfigurationResponse";
import type { LineItemModifierRequest } from "../models/LineItemModifierRequest";
import type { OrderChange } from "../models/OrderChange";
import type { OrderChangeRequest } from "../models/OrderChangeRequest";
import type { OrderConfirmRequest } from "../models/OrderConfirmRequest";
import type { OrderVersionResponse } from "../models/OrderVersionResponse";
import type { OrdersMetadata } from "../models/OrdersMetadata";
import type { OrdersResponse } from "../models/OrdersResponse";
import type { PriceModifierInformationChangeResponse } from "../models/PriceModifierInformationChangeResponse";
import type { PriceModifierRequest } from "../models/PriceModifierRequest";
import type { SupplierPortalOrder } from "../models/SupplierPortalOrder";
import type { SupplierPortalOrderCancellationConfirmationRequest } from "../models/SupplierPortalOrderCancellationConfirmationRequest";
import type { SupplierPortalOrderRejectRequest } from "../models/SupplierPortalOrderRejectRequest";

export class SupplierPortalAppService {
  /**
   * @returns OrderVersionResponse OK
   * @throws ApiError
   */
  public static discardChanges(
    {
      s2SOrderId,
    }: {
      s2SOrderId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<OrderVersionResponse> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/supplier-portal/orders/{s2sOrderId}/discard-changes",
      path: {
        s2sOrderId: s2SOrderId,
      },
      errors: {
        404: `Not Found`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns OrderVersionResponse OK
   * @throws ApiError
   */
  public static rejectOrder(
    {
      s2SOrderId,
      requestBody,
    }: {
      s2SOrderId: string;
      requestBody: SupplierPortalOrderRejectRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<OrderVersionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/supplier-portal/orders/{s2sOrderId}/reject",
      path: {
        s2sOrderId: s2SOrderId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        404: `Not Found`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns PriceModifierInformationChangeResponse OK
   * @throws ApiError
   */
  public static addPriceModifier(
    {
      s2SOrderId,
      requestBody,
    }: {
      s2SOrderId: string;
      requestBody: PriceModifierRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<PriceModifierInformationChangeResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/supplier-portal/orders/{s2sOrderId}/price-modifier",
      path: {
        s2sOrderId: s2SOrderId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns OrderVersionResponse OK
   * @throws ApiError
   */
  public static updateLineItemQuantity(
    {
      s2SOrderId,
      itemId,
      requestBody,
    }: {
      s2SOrderId: string;
      itemId: string;
      requestBody: LineItemModifierRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<OrderVersionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/supplier-portal/orders/{s2sOrderId}/items/{itemId}/quantities",
      path: {
        s2sOrderId: s2SOrderId,
        itemId: itemId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
        404: `Not Found`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns OrderVersionResponse OK
   * @throws ApiError
   */
  public static addLineItem(
    {
      s2SOrderId,
      itemId,
      requestBody,
    }: {
      s2SOrderId: string;
      itemId: string;
      requestBody: LineItemModifierRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<OrderVersionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/supplier-portal/orders/{s2sOrderId}/items/{itemId}",
      path: {
        s2sOrderId: s2SOrderId,
        itemId: itemId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
        404: `Not Found`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns OrderVersionResponse OK
   * @throws ApiError
   */
  public static deliverOrder(
    {
      s2SOrderId,
      requestBody,
    }: {
      s2SOrderId: string;
      requestBody: OrderChangeRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<OrderVersionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/supplier-portal/orders/{s2sOrderId}/deliver",
      path: {
        s2sOrderId: s2SOrderId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
        404: `Not Found`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns OrderVersionResponse OK
   * @throws ApiError
   */
  public static confirmOrder(
    {
      s2SOrderId,
      requestBody,
    }: {
      s2SOrderId: string;
      requestBody: OrderConfirmRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<OrderVersionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/supplier-portal/orders/{s2sOrderId}/confirm",
      path: {
        s2sOrderId: s2SOrderId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        404: `Not Found`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns OrderVersionResponse OK
   * @throws ApiError
   */
  public static closeOrder(
    {
      s2SOrderId,
      requestBody,
    }: {
      s2SOrderId: string;
      requestBody: OrderChangeRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<OrderVersionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/supplier-portal/orders/{s2sOrderId}/close",
      path: {
        s2sOrderId: s2SOrderId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
        404: `Not Found`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns OrderVersionResponse OK
   * @throws ApiError
   */
  public static confirmOrderCancellation(
    {
      s2SOrderId,
      requestBody,
    }: {
      s2SOrderId: string;
      requestBody: SupplierPortalOrderCancellationConfirmationRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<OrderVersionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/supplier-portal/orders/{s2sOrderId}/cancel",
      path: {
        s2sOrderId: s2SOrderId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        404: `Not Found`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AttachmentInformationChangeResponse OK
   * @throws ApiError
   */
  public static linkAttachmentToOrder(
    {
      s2SOrderId,
      requestBody,
    }: {
      s2SOrderId: string;
      requestBody: AttachmentsRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AttachmentInformationChangeResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/supplier-portal/orders/{s2sOrderId}/attachments",
      path: {
        s2sOrderId: s2SOrderId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
        404: `Not Found`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AssigneeInformationChangeResponse OK
   * @throws ApiError
   */
  public static assign(
    {
      s2SOrderId,
      id,
      requestBody,
    }: {
      s2SOrderId: string;
      id: string;
      requestBody: OrderChangeRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AssigneeInformationChangeResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/supplier-portal/orders/{s2sOrderId}/assignees/{id}",
      path: {
        s2sOrderId: s2SOrderId,
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static acknowledgeOrder(
    {
      s2SOrderId,
    }: {
      s2SOrderId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/supplier-portal/orders/{s2sOrderId}/acknowledge",
      path: {
        s2sOrderId: s2SOrderId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns binary OK
   * @throws ApiError
   */
  public static exportOrderByIdToMtml(
    {
      s2SOrderId,
      version,
    }: {
      s2SOrderId: string;
      version: number;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/supplier-portal/orders/{s2sOrderId}/mtml",
      path: {
        s2sOrderId: s2SOrderId,
      },
      query: {
        version: version,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns ApiListSupplierPortalProductSku OK
   * @throws ApiError
   */
  public static search(
    {
      searchQuery,
      s2SOrderId,
      cursor = "0",
    }: {
      searchQuery: string;
      s2SOrderId: string;
      cursor?: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListSupplierPortalProductSku> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/supplier-portal/orders/{s2sOrderId}/items/search/{searchQuery}",
      path: {
        searchQuery: searchQuery,
        s2sOrderId: s2SOrderId,
      },
      query: {
        cursor: cursor,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns binary OK
   * @throws ApiError
   */
  public static exportOrderByIdToExcel(
    {
      s2SOrderId,
      version,
    }: {
      s2SOrderId: string;
      version: number;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/supplier-portal/orders/{s2sOrderId}/excel",
      path: {
        s2sOrderId: s2SOrderId,
      },
      query: {
        version: version,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns OrderChange OK
   * @throws ApiError
   */
  public static getChanges(
    {
      s2SOrderId,
    }: {
      s2SOrderId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Array<OrderChange>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/supplier-portal/orders/{s2sOrderId}/changes",
      path: {
        s2sOrderId: s2SOrderId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AssigneeInformation OK
   * @throws ApiError
   */
  public static getSupplierUsers(
    {
      s2SOrderId,
    }: {
      s2SOrderId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<Array<AssigneeInformation>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/supplier-portal/orders/{s2sOrderId}/assignees",
      path: {
        s2sOrderId: s2SOrderId,
      },
      errors: {
        400: `Request is not valid`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns OrderVersionResponse OK
   * @throws ApiError
   */
  public static unassign(
    {
      s2SOrderId,
      requestBody,
    }: {
      s2SOrderId: string;
      requestBody: OrderChangeRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<OrderVersionResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/supplier-portal/orders/{s2sOrderId}/assignees",
      path: {
        s2sOrderId: s2SOrderId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
        404: `Not Found`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns SupplierPortalOrder OK
   * @throws ApiError
   */
  public static getOrderById(
    {
      s2SOrderId,
      version,
      versionVariant,
    }: {
      s2SOrderId: string;
      version?: number;
      versionVariant?: "OLDEST" | "LATEST";
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<SupplierPortalOrder> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/supplier-portal/orders/{s2sOrderId}",
      path: {
        s2sOrderId: s2SOrderId,
      },
      query: {
        version: version,
        versionVariant: versionVariant,
      },
      errors: {
        404: `Not Found`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns OrdersMetadata OK
   * @throws ApiError
   */
  public static getOrdersMetadata(
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<OrdersMetadata> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/supplier-portal/orders/metadata",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns ConfigurationResponse OK
   * @throws ApiError
   */
  public static getConfiguration(
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ConfigurationResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/supplier-portal/orders/configuration",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns OrdersResponse OK
   * @throws ApiError
   */
  public static getOrders(
    {
      status,
      ports,
      vessels,
      buyers,
      cursor,
      sortBy = "CREATED_DATE",
      sortDir = "DESC",
      closed,
    }: {
      status?:
        | "CANCELLATION_REQUESTED"
        | "CANCELLED"
        | "CLOSED"
        | "INCOMING"
        | "CONFIRMED"
        | "REJECTED"
        | "DELIVERED";
      ports?: Array<string>;
      vessels?: Array<string>;
      buyers?: Array<string>;
      cursor?: string;
      sortBy?:
        | "CUSTOMER_NAME"
        | "VESSEL_NAME"
        | "DELIVERY_PORT"
        | "CREATED_DATE"
        | "DELIVERY_DATE"
        | "CUSTOMER_PO"
        | "LINE_ITEM_QTY"
        | "TOTAL_VALUE"
        | "CURRENCY";
      sortDir?: "ASC" | "DESC";
      closed?: boolean;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<OrdersResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/supplier-portal/orders",
      query: {
        status: status,
        ports: ports,
        vessels: vessels,
        buyers: buyers,
        cursor: cursor,
        sortBy: sortBy,
        sortDir: sortDir,
        closed: closed,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns OrderVersionResponse OK
   * @throws ApiError
   */
  public static removePriceModifier(
    {
      s2SOrderId,
      id,
      requestBody,
    }: {
      s2SOrderId: string;
      id: string;
      requestBody: OrderChangeRequest;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<OrderVersionResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/supplier-portal/orders/{s2sOrderId}/price-modifier/{id}",
      path: {
        s2sOrderId: s2SOrderId,
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Request is not valid`,
        404: `Not Found`,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
