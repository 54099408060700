import { SupplierPortalAppService } from "src/typegens";

export const acknowledgeOrderSeen = async ({
  s2SOrderId,
}: {
  s2SOrderId: string;
}): Promise<void> => {
  const result = await SupplierPortalAppService.acknowledgeOrder({
    s2SOrderId,
  });

  return result;
};
