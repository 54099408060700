import { Comment, SidebarBox } from "@web/common";

import { SupplierPortalComment } from "src/typegens";

interface Props {
  comments?: Array<SupplierPortalComment>;
}

export const OrderComments: React.FC<Props> = ({ comments = [] }) => {
  return (
    <SidebarBox className="mt-4" title="Order Comments" testId="orderDetails_orderComments">
      {comments.map((comment) => (
        <Comment
          name={comment.name}
          date={comment.date}
          text={comment.text}
          key={`${comment.text}-${comment.date}`}
        />
      ))}
    </SidebarBox>
  );
};
