import _isEmpty from "lodash/isEmpty";
import { useEffect, useRef } from "react";

import { FLAGS_OVERRIDE_LC_KEY } from "../config";
import { useAllUnleashTogglesQuery, useFeatureFlagsManagement } from "../hooks";
import { GetAllUnleashTogglesParams, ManagedFeatureFlags } from "../models";

const getPersistedFlagsOverrideState = (): ManagedFeatureFlags => {
  try {
    const flagsOverrideState = localStorage.getItem(FLAGS_OVERRIDE_LC_KEY);
    return flagsOverrideState ? JSON.parse(flagsOverrideState) : {};
  } catch (err) {
    console.warn(err);
    return {};
  }
};

const setPersistedFlagsOverrideState = (flagsOverrideState: ManagedFeatureFlags): void => {
  localStorage.setItem(FLAGS_OVERRIDE_LC_KEY, JSON.stringify(flagsOverrideState));
};

type Props = GetAllUnleashTogglesParams;

export const FeatureFlagsManagementStateLifecycle = ({
  unleashProxyClientKey,
  unleashProxyUrl,
  unleashEnvironment,
  unleashAppName,
}: Props) => {
  const { setFlagsOverrideState, flagsOverrideState, unleashFlagsState, setUnleashFlagsState } =
    useFeatureFlagsManagement();

  const { query: allUnleashFlagsQuery } = useAllUnleashTogglesQuery({
    unleashProxyClientKey,
    unleashProxyUrl,
    unleashEnvironment,
    unleashAppName,
  });
  const { isSuccess: isAllUnleashFlagsQuerySuccess, data: allUnleashFlagsQueryData } =
    allUnleashFlagsQuery;

  const firstUpdate = useRef<boolean>(true);
  const isFirstUpdate = firstUpdate.current;

  useEffect(() => {
    if (isFirstUpdate && isAllUnleashFlagsQuerySuccess) {
      setUnleashFlagsState({
        nativeUnleashFlags: allUnleashFlagsQueryData.nativeUnleashFlags,
        nativeOverriddenUnleashFlags: allUnleashFlagsQueryData.nativeOverriddenUnleashFlags,
      });
    }
  }, [
    allUnleashFlagsQueryData,
    isAllUnleashFlagsQuerySuccess,
    isFirstUpdate,
    setUnleashFlagsState,
  ]);

  useEffect(() => {
    // First read from LS only after we have loaded flags from Unleash, and they have been set into state
    if (isFirstUpdate && isAllUnleashFlagsQuerySuccess && !_isEmpty(unleashFlagsState)) {
      const persistedFlagsOverrideState = getPersistedFlagsOverrideState();
      setFlagsOverrideState(persistedFlagsOverrideState);
      firstUpdate.current = false;
    }
  }, [
    isAllUnleashFlagsQuerySuccess,
    flagsOverrideState,
    isFirstUpdate,
    setFlagsOverrideState,
    setUnleashFlagsState,
    unleashFlagsState,
  ]);

  useEffect(() => {
    // Update persisted flags override state whenever the flags override in state changes
    if (!isFirstUpdate) {
      setPersistedFlagsOverrideState(flagsOverrideState);
    }
  }, [flagsOverrideState, isFirstUpdate]);

  return null;
};
