import { KeyboardEventHandler, useEffect, useState } from "react";

import { useFeatureFlagsManagement } from "../../hooks";
import { ManagedFeatureFlags } from "../../models";
import {
  mapEnvironmentToUnleashPanelUrl,
  mapEnvironmentToUnleashProxyEnvironment,
} from "../../utils";
import { FeatureFlagsManager } from "./FeatureFlagsManager";

export const FeatureFlagsManagerController = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { environment, unleashFlagsState, flagsOverrideState, setFlagsOverrideState } =
    useFeatureFlagsManagement();

  useEffect(() => {
    const eventHandler: KeyboardEventHandler<HTMLBodyElement> = (e) => {
      if (e.ctrlKey && e.key === "i") {
        e.preventDefault();
        setIsOpen(true);
      }
    };
    document.addEventListener("keydown", eventHandler as unknown as EventListener);
    return () => document.removeEventListener("keydown", eventHandler as unknown as EventListener);
  }, []);

  const unleashEnvironment = environment
    ? mapEnvironmentToUnleashProxyEnvironment(environment)
    : undefined;
  const unleashPanelUrl = environment ? mapEnvironmentToUnleashPanelUrl(environment) : undefined;

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSubmit = (flagsOverrideFormValues: ManagedFeatureFlags) => {
    setFlagsOverrideState(flagsOverrideFormValues);

    // Let the state update and persist to LS
    setTimeout(() => window.location.reload(), 50);
  };

  return isOpen ? (
    <FeatureFlagsManager
      environment={environment}
      unleashEnvironment={unleashEnvironment}
      unleashPanelUrl={unleashPanelUrl}
      unleashFlagsState={unleashFlagsState}
      originalFlagsOverrideState={flagsOverrideState}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    />
  ) : null;
};
