import { Label } from "../../Typography";
import { SearchNavLink } from "../SearchNavLink";

interface Props {
  text: string;
  pathname: string;
  search?: string;
  badge?: number;
}

export const SimpleBadgeLink: React.FC<Props> = ({ pathname, search, badge, text }) => {
  return (
    <SearchNavLink
      to={{ pathname, search }}
      activeClassName="bg-neutral_300"
      className="flex gap-3 justify-between items-center rounded-lg py-2 px-4 hover:bg-neutral_300"
    >
      {({ isActive }: { isActive: boolean }) => (
        <>
          <Label
            size="200"
            color={`${isActive ? "text-textIcon-blackPrimary" : "text-textIcon-blackSecondary"}`}
          >
            {text}
          </Label>
          {!!badge && badge !== 0 && (
            <Label
              size="300"
              color="text-textIcon-whitePrimary"
              className="bg-dangerDefault rounded-xl px-4 py-1 -mr-1 shadow-md"
            >
              {badge}
            </Label>
          )}
        </>
      )}
    </SearchNavLink>
  );
};
