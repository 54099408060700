import { DocumentTextIcon } from "@heroicons/react/outline";
import { DownloadIcon } from "@heroicons/react/solid";
import classnames from "classnames";

import { formatFileSize, formatShortFileName, formatShortString } from "@web/utils";

import { IconButton, Paragraph } from "../atoms";
import { renderLongStringWithOptionalTooltip } from "../utils";

export interface DigitalAssetProps {
  className?: string;
  attachmentId: string;
  category?:
    | "MD"
    | "SDOC"
    | "CERT"
    | "SDS"
    | "DWG"
    | "PHOTO"
    | "SOW"
    | "SVC_NOTES"
    | "OTHER"
    | "TYPE_APPROVAL"
    | "DECLARATION_OF_CONFORMITY"
    | "DNV"
    | "MARINE_EQUIPMENT_DIRECTIVE"
    | "MATERIAL_SAFETY_DATA_SHEET"
    | "SAFETY_DATA_SHEETS"
    | "INVENTORY_OF_HAZARDOUS_MATERIALS"
    | "TEST_CERTIFICATE"
    | "CERTIFICATE_OF_COMPLIANCE"
    | "MANUALS"
    | "USER_INSTRUCTIONS"
    | "NOT_DEFINED"
    | string;
  size: number;
  name: string;
  nameUploadedBy?: string;
  createdDate?: string;
  downloadLink: string;
  removed?: boolean;
}

export const DigitalAsset = (props: DigitalAssetProps) => {
  const {
    className,
    attachmentId,
    category,
    size,
    name,
    nameUploadedBy,
    createdDate,
    downloadLink,
    removed,
  } = props;

  const renderSecondLine = () => {
    if (nameUploadedBy && createdDate) {
      return (
        <span className="block">
          <div>Added by: </div>
          {renderLongStringWithOptionalTooltip(nameUploadedBy, 20, formatShortString)} •{" "}
          {createdDate}
        </span>
      );
    }

    if (!nameUploadedBy && !createdDate) {
      return undefined;
    }

    return nameUploadedBy || createdDate;
  };

  return (
    <div
      className={classnames(
        `flex justify-between items-center px-2 py-2 rounded-lg border bg-neutral_0 shadow text-textIcon-blackSecondary max-w-[310px]`,
        removed && "border-dangerDefault",
        className
      )}
      data-testid="orderDetails_orderAttachment"
    >
      <>
        <DocumentTextIcon
          className={classnames("w-5 mr-2 h-auto", removed && "text-dangerDefault")}
        />
      </>
      <div className="flex basis-5/6 grow-2 flex-col">
        <Paragraph size="200" className="my-0 whitespace-nowrap" weight="heavy">
          {renderLongStringWithOptionalTooltip(name, 28, formatShortFileName)}
        </Paragraph>
        <Paragraph size="300" color="text-textIcon-blackSecondary" weight="light">
          {category && category !== "NOT_DEFINED" ? (
            <>
              {renderLongStringWithOptionalTooltip(category, 20, formatShortString)} •{" "}
              {formatFileSize(size)}
            </>
          ) : (
            formatFileSize(size)
          )}
        </Paragraph>
        {renderSecondLine() && (
          <div className="text-textIcon-blackSecondary text-xs">{renderSecondLine()}</div>
        )}
      </div>
      <div className="flex basis-1/6 justify-end">
        <a href={downloadLink} target="_blank" download={attachmentId} className="ml-2 rounded-md">
          <IconButton
            Icon={DownloadIcon}
            size="small"
            variant="secondary"
            shape="square"
            label="Download"
            onClick={() => {}}
            disabled={removed}
            className="border border-neutral_300 rounded-md text-textIcon-blackSecondary"
          />
        </a>
      </div>
    </div>
  );
};
