import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { OpenAPI as AttachmentsOpenAPI } from "@web/attachments";

import App from "src/App";
import reportWebVitals from "src/reportWebVitals";
import { OpenAPI } from "src/typegens";

import "./index.css";

const VITE_API_BASE_URL = import.meta.env.VITE_API_BASE_URL as string;

OpenAPI.BASE = VITE_API_BASE_URL;
OpenAPI.ENCODE_PATH = encodeURIComponent;
AttachmentsOpenAPI.BASE = VITE_API_BASE_URL;
AttachmentsOpenAPI.ENCODE_PATH = encodeURIComponent;

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = createRoot(rootElement);

  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
} else {
  throw new Error('there is no "root" element in the DOM');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint.
reportWebVitals();
