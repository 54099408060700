import _cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqual";

import { changeConfig } from "../configs";
import {
  Change,
  ChangeConfig,
  ChangeSyncStatus,
  ChangeTypesMap,
  ChangesSyncQueue,
  OrderItemQuantityChangeValue,
} from "../models";

// Do not import outside `changesSync` domain. Import `ChangesSyncService` instead.
export class ChangesSyncConsolidationService {
  private static getConsolidationComparators = (
    change: Change
  ): ChangeConfig["consolidationComparators"] => changeConfig[change.type].consolidationComparators;

  // This method exists only to make the compiler happy
  private static getConsolidatedPreviousChangeContent = (
    previousChange: Change,
    currentChange: Change
  ): ChangeTypesMap<Change> => {
    if (previousChange.type !== currentChange.type) {
      return {
        orderItemQuantityChange: previousChange,
        priceModifierRemoval: previousChange,
        orderItemAddition: previousChange,
        orderItemReplacement: previousChange,
      };
    }

    const changeType = previousChange.type;

    return {
      orderItemQuantityChange:
        changeType === "orderItemQuantityChange"
          ? {
              ...previousChange,
              newValue: currentChange.newValue as OrderItemQuantityChangeValue,
            }
          : previousChange,
      priceModifierRemoval: previousChange,
      orderItemAddition: previousChange,
      orderItemReplacement: previousChange,
    };
  };

  private static consolidateChanges = (queue: ChangesSyncQueue): ChangesSyncQueue => {
    const processedQueue = _cloneDeep(queue);

    let consolidatedChanges: ChangesSyncQueue = [...processedQueue];
    let wasAnyChangeConsolidatedOrRemoved: boolean;

    do {
      wasAnyChangeConsolidatedOrRemoved = false;

      const tempConsolidatedChanges: ChangesSyncQueue = [];
      consolidatedChanges.forEach((currentChange, index) => {
        const previousChange: Change | undefined = tempConsolidatedChanges[index - 1];
        let isCurrentChangeConsolidatableWithPreviousChange = false;

        if (previousChange) {
          const currentChangeConsolidationComparators =
            ChangesSyncConsolidationService.getConsolidationComparators(currentChange);
          const previousChangeConsolidationComparators =
            ChangesSyncConsolidationService.getConsolidationComparators(previousChange);

          // Check if currentChange's consolidation comparators values match previousChange's consolidation comparators values
          const areCurrentChangeComparatorsMatching =
            currentChangeConsolidationComparators.length > 0 &&
            !currentChangeConsolidationComparators.find((currentChangeComparator) => {
              const currentChangeComparatorValue = currentChange.meta[currentChangeComparator];
              const previousChangeComparatorValue = previousChange.meta[currentChangeComparator];
              return !_isEqual(currentChangeComparatorValue, previousChangeComparatorValue);
            });

          // Check if previousChange has comparators that do not exist in currentChange
          const arePreviousChangeComparatorsMatching =
            previousChangeConsolidationComparators.length > 0 &&
            !previousChangeConsolidationComparators.find(
              (previousChangeComparator) =>
                !currentChangeConsolidationComparators.includes(previousChangeComparator)
            );

          isCurrentChangeConsolidatableWithPreviousChange =
            previousChange &&
            previousChange.type === currentChange.type &&
            areCurrentChangeComparatorsMatching &&
            arePreviousChangeComparatorsMatching;
        }

        if (isCurrentChangeConsolidatableWithPreviousChange) {
          const newPreviousChangeContent =
            ChangesSyncConsolidationService.getConsolidatedPreviousChangeContent(
              previousChange,
              currentChange
            )[previousChange.type];

          tempConsolidatedChanges[index - 1] = { ...newPreviousChangeContent };

          wasAnyChangeConsolidatedOrRemoved = true;
        } else {
          tempConsolidatedChanges.push(currentChange);
        }
      });

      // Remove all changes that have identical oldValue and newValue
      // May need to extend with a custom passed comparator function if there's
      // a need to compare collections.
      const consolidatedChangesAfterCleanup = tempConsolidatedChanges.filter(
        (change) => !_isEqual(change.oldValue, change.newValue)
      );

      if (consolidatedChangesAfterCleanup.length !== tempConsolidatedChanges.length) {
        wasAnyChangeConsolidatedOrRemoved = true;
      }

      consolidatedChanges = [...consolidatedChangesAfterCleanup];
    } while (wasAnyChangeConsolidatedOrRemoved);

    return consolidatedChanges;
  };

  public static consolidateTailingChangesOfStatus = (
    // Assumption here is that all changes to consolidate are tailing the collection
    queue: ChangesSyncQueue,
    statusToConsolidate: ChangeSyncStatus
  ): {
    processedQueue: ChangesSyncQueue;
  } => {
    const processedQueue = _cloneDeep(queue);

    const indexOfOldestChangeOfStatus = queue.findIndex(
      (item) => item.status === statusToConsolidate
    );
    const changesToConsolidate = processedQueue.slice(indexOfOldestChangeOfStatus);
    const previousChanges = processedQueue.slice(0, indexOfOldestChangeOfStatus);
    const consolidatedChanges =
      ChangesSyncConsolidationService.consolidateChanges(changesToConsolidate);

    return {
      processedQueue: [...previousChanges, ...consolidatedChanges],
    };
  };
}
