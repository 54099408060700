import ExclamationCircleIcon from "@heroicons/react/solid/ExclamationCircleIcon";
import classnames from "classnames";
import { ChangeEvent, forwardRef } from "react";

import { Label, Paragraph } from "../Typography";

export interface InputProps {
  errorMessage?: string;
  label?: string;
  leftBadge?: string;
  onInputChange?: (value: string) => void;
  prefixIcon?: React.ReactElement;
  testId?: string;
  withBorder?: boolean;
}

export const Input = forwardRef<
  HTMLInputElement,
  InputProps & React.InputHTMLAttributes<HTMLInputElement>
>(
  (
    {
      label,
      placeholder,
      errorMessage,
      onInputChange,
      value,
      disabled,
      className,
      prefixIcon,
      leftBadge,
      id,
      type,
      testId,
      withBorder = false,
      ...rest
    },
    ref
  ) => {
    return (
      <div
        {...(testId && { "data-testid": `${testId}_container` })}
        className={classnames("flex flex-col", className)}
      >
        {!!label && (
          <Label
            className="mb-2"
            color="text-textIcon-blackSecondary"
            htmlFor={id || label}
            id={id || label}
            size="200"
          >
            {label}
          </Label>
        )}
        <div
          className={classnames(
            className,
            "relative flex w-full rounded-md items-center bg-neutral_0 h-6.5",
            { "border-solid": withBorder }
          )}
        >
          {prefixIcon && <div className="pointer-events-none h-4 w-4 mx-2">{prefixIcon}</div>}
          {!!leftBadge && (
            <div className="flex items-center pointer-events-none px-2 border-r h-full bg-neutral_100">
              <Paragraph size="200">{leftBadge}</Paragraph>
            </div>
          )}
          <input
            type={type || "text"}
            id={id || label}
            name={id || label}
            className={classnames(
              "h-full w-full rounded-md py-2 pl-3 focus:outline-none cursor-pointer text-sm leading-5 font-light",
              {
                "border focus:border-solid": withBorder,
                "border-dangerDefault": withBorder && errorMessage,
                "border-neutral_300": withBorder && !errorMessage,
                "pl-0": prefixIcon,
                "pl-3": !prefixIcon,
              }
            )}
            placeholder={placeholder}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onInputChange && onInputChange((e.target as HTMLInputElement).value);
            }}
            value={value}
            disabled={disabled}
            {...(testId && { "data-testid": `${testId}_input` })}
            ref={ref}
            {...rest}
          />
          {!!errorMessage && (
            <div className="absolute right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon className="h-5 w-5 text-dangerDefault" aria-hidden="true" />
            </div>
          )}
          <Paragraph
            className="absolute -bottom-5"
            size="200"
            color="text-dangerDefault"
            {...(testId && { "data-testid": `${testId}_errorMessage` })}
          >
            {errorMessage}
          </Paragraph>
        </div>
      </div>
    );
  }
);

Input.displayName = "Input";
