import { useApiError } from "src/domain/apiError";

export const useAddDiscountApiError = () => {
  const { showApiError } = useApiError({
    one: "There was an error while adding the discount:\n{errors}\nPlease try again.",
    many: "There were some errors while adding the discount:\n{errors}\nPlease try again.",
    fallback: "There was an error while adding the discount. Please try again.",
  });

  return {
    showAddDiscountApiError: showApiError,
  };
};
