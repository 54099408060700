import { SupplierManagementService, SupplierRequest } from "src/typegens";

export const updateSupplier = async ({
  requestBody,
  supplierId,
}: {
  requestBody: SupplierRequest;
  supplierId: string;
}) => {
  const result = await SupplierManagementService.updateSupplierInformation({
    supplierId,
    requestBody,
  });
  return result;
};
