export enum RoutesParams {
  SUPPLIER_ID = "supplierId",
}

export const RoutesConfig = {
  home: "/",
  addSupplier: "/add-supplier",
  supplierAdded: "/supplier-added",
  editSupplier: `/edit-supplier/:${RoutesParams.SUPPLIER_ID}`,
};
