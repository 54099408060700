import { AssigneeInformationChangeResponse, SupplierPortalAppService } from "src/typegens";

export const assignUserToPO = async ({
  s2SOrderId,
  version,
  id,
}: {
  s2SOrderId: string;
  version: number;
  id: string;
}): Promise<AssigneeInformationChangeResponse> => {
  const result = await SupplierPortalAppService.assign({
    s2SOrderId,
    id,
    requestBody: { version },
  });
  return result;
};
