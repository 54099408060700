import { useCallback, useMemo } from "react";

import { useSystemMessages } from "src/context/SystemMessages";

import { ApiError, MessageTemplates, ValidationError } from "../models";

const defaultPresentableErrorParser = (fieldError: ValidationError): string =>
  fieldError.defaultMessage;

const defaultFallbackErrorParser = (message: string): string => message;

export const useApiError = (messageTemplates?: MessageTemplates) => {
  const { setSystemMessage } = useSystemMessages();

  const defaultedMessageTemplates: Required<MessageTemplates> = useMemo(
    () => ({
      one:
        messageTemplates?.one ||
        "There was an error while saving your changes:\n{errors}\nPlease try again.",
      many:
        messageTemplates?.many ||
        "There were some errors while saving your changes:\n{errors}\nPlease try again.",
      error: messageTemplates?.error || "- {error}\n",
      fallback:
        messageTemplates?.fallback ||
        "There was an error while saving your changes. Please try again.",
    }),
    [messageTemplates]
  );

  const showApiError = useCallback(
    (
      error: ApiError,
      presentableErrorParser: (
        fieldError: ValidationError
      ) => string = defaultPresentableErrorParser,
      fallbackErrorParser: (message: string) => string = defaultFallbackErrorParser
    ): void => {
      if (error.status >= 400 && error.status < 500 && error.body.errors.length > 0) {
        const presentableErrors: string[] = error.body.errors
          .map((fieldError: ValidationError) => presentableErrorParser(fieldError))
          .map((presentableError: string) =>
            defaultedMessageTemplates.error.replace("{error}", presentableError)
          );

        const presentableErrorsFormatted: string = presentableErrors.join("");

        setSystemMessage({
          type: "failure",
          message:
            presentableErrors.length > 1
              ? defaultedMessageTemplates.many.replace("{errors}", presentableErrorsFormatted)
              : defaultedMessageTemplates.one.replace("{errors}", presentableErrorsFormatted),
        });
      } else {
        setSystemMessage({
          type: "failure",
          message: fallbackErrorParser(defaultedMessageTemplates.fallback),
        });
      }
    },
    [setSystemMessage, defaultedMessageTemplates]
  );

  return {
    showApiError,
  };
};
