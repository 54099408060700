import { useMemo } from "react";

import { TotalUnitOfMeasure } from "@web/common";
import { Money } from "@web/models";

import {
  OrderItem,
  OrderItemPriceTotals,
  SpacingVariant,
  SupplierOrderService,
  SupplierUiOrderItem,
} from "src/domain";

interface Props {
  orderItem: SupplierUiOrderItem;
  onItemClick?: () => void;
  onNameClick?: () => void;
  isRemoved?: boolean;
  showItemLineNumber?: boolean;
  keepLeftMargin?: boolean;
  spacing?: SpacingVariant;
  testId?: string;
}

export const OrderItemDetails: React.FC<Props> = ({
  orderItem,
  onItemClick,
  onNameClick,
  isRemoved,
  showItemLineNumber,
  keepLeftMargin,
  spacing,
  testId,
}) => {
  const { lineNumber, impaCode, supplierIdentifier, measurementUnit, name, quantity, totalAmount } =
    orderItem;

  /**
   * TotalUnitOfMeasure
   */
  const salesEntityQuantity = useMemo(
    () => SupplierOrderService.getSalesEntityQuantity(orderItem),
    [orderItem]
  );

  /**
   * Unit total
   */
  const unitPrice: Money = useMemo(
    () => SupplierOrderService.getSingleItemPrice(orderItem),
    [orderItem]
  );

  return (
    <OrderItem
      isRemoved={isRemoved}
      impaCode={impaCode}
      itemId={supplierIdentifier}
      itemLineNumber={lineNumber}
      showItemLineNumber={showItemLineNumber}
      keepLeftMargin={keepLeftMargin}
      itemName={name}
      onItemClick={onItemClick}
      onNameClick={onNameClick}
      spacing={spacing}
      testId={testId}
      renderAdditionalData={(columnClassName) => (
        <div className="flex-none grid grid-cols-2">
          <div className={`flex flex-col items-start justify-around pl-4 ${columnClassName}`}>
            <TotalUnitOfMeasure
              className="justify-center"
              measurementUnit={measurementUnit}
              quantityInBasket={quantity}
              salesEntityQuantity={salesEntityQuantity}
              lineThrough={isRemoved}
              variant="positive"
            />
          </div>
          <div className={`flex flex-col items-end justify-around ${columnClassName}`}>
            <OrderItemPriceTotals
              itemTotal={totalAmount}
              unitPrice={unitPrice}
              measurementUnit={measurementUnit}
              isRemoved={isRemoved}
            />
          </div>
        </div>
      )}
    />
  );
};
