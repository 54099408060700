import { useEffect } from "react";

import { OpenAPI as AttachmentsOpenAPI } from "@web/attachments";
import { UNLEASH_ATTRIBUTES_HEADER_NAME, useFeatureFlagsManagement } from "@web/common";

import { OpenAPI } from "../typegens";

export const ApiHeadersSetup = () => {
  const { unleashContextFlagProperties } = useFeatureFlagsManagement();

  useEffect(() => {
    const unleashOverrideAttributesHeader = {
      [UNLEASH_ATTRIBUTES_HEADER_NAME]: JSON.stringify(unleashContextFlagProperties),
    };
    OpenAPI.HEADERS = unleashOverrideAttributesHeader;
    AttachmentsOpenAPI.HEADERS = unleashOverrideAttributesHeader;
  }, [unleashContextFlagProperties]);

  return null;
};
