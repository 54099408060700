import { Money } from "@web/models";
import { Paragraph } from "@web/ui";

import { SupplierOrderService } from "src/domain";

interface Props {
  itemTotal: Money;
  lineThrough?: boolean;
}

export const TinyPriceTotal: React.FC<Props> = ({ itemTotal, lineThrough }) => {
  const itemTotalFormatted = SupplierOrderService.formatOrderItemTotal(itemTotal);

  return (
    <Paragraph
      size="300"
      color="text-textIcon-blackSecondary"
      className={`${lineThrough ? "line-through" : ""}`}
    >
      {itemTotalFormatted}
    </Paragraph>
  );
};
