import { SupplierPortalAppService } from "src/typegens";

import { IOrderItemReplacementConfig } from "../models/OrderItemReplacement";

export const orderItemReplacementConfig: Readonly<IOrderItemReplacementConfig> = {
  // Leave empty to disable consolidation or provide a list of *all* meta fields
  // that should differentiate the changes for consolidation
  consolidationComparators: [],
  apiResponseMapper: (change, apiResponse) => ({
    ...change,
    status: "success",
    meta: {
      ...change.meta,
      newOrderVersionId: apiResponse.newVersion,
    },
  }),
  apiRequestCall: async (change, signal) =>
    SupplierPortalAppService.addLineItem(
      {
        s2SOrderId: change.meta.orderId,
        // When replacing: ID of the item that is a replacement for original item
        // When restoring original: ID of the original
        itemId: change.newValue.id,
        requestBody: {
          // ID of the original item that is being replaced, or which is being restored
          replacementForItemId: change.meta.orderItemId,
          quantity: change.newValue.quantity,
          version: change.meta.oldOrderVersionId,
        },
      },
      { signal }
    ),
};
