import { useEffect, useState } from "react";

import { Modal } from "@web/ui";

import { useSystemMessages } from "src/context/SystemMessages";

import { OrderEditDiscardChangesConfirmationModal } from "../../../common";
import { SupplierOrderAmountAdditionalCostForm } from "../../models";
import { AdditionalCostModalForm } from "../AdditionalCostModalForm";

interface Props {
  currencyCode: string;
  currentTotalAmount: number;
  submitAdditionalCost: (additionalCost: SupplierOrderAmountAdditionalCostForm) => Promise<void>;
  // Is modal submitting
  isSubmitting?: boolean;
  // Is modal submission externally disabled
  isSubmittingExternallyDisabled?: boolean;
  onSetIsDirty: (isDirty: boolean) => void;
  render?: (openAdditionalCostModal: () => void) => React.ReactNode;
}

export const AdditionalCostModalController: React.FC<Props> = ({
  currencyCode,
  currentTotalAmount,
  submitAdditionalCost,
  isSubmitting = false,
  isSubmittingExternallyDisabled = false,
  onSetIsDirty,
  render,
}) => {
  const { setSystemMessage } = useSystemMessages();
  const [isAdditionalCostModalOpen, setIsAdditionalCostModalOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isDiscardChangesConfirmationModalOpen, setIsDiscardChangesConfirmationModalOpen] =
    useState(false);

  // When NavigationPrompt will start reusing global state (singleton-like) in the future sp we can use
  // it in this component, we don't have to notify the parent of this component about isDirty state.
  useEffect(() => onSetIsDirty(isDirty), [isDirty, onSetIsDirty]);

  const notifyAboutSubmitInProgress = () => {
    setSystemMessage({
      type: "warning",
      message:
        "Your data is being saved at the moment. Please wait until it finishes before closing the modal.",
    });
  };

  const openAdditionalCostModal = () => setIsAdditionalCostModalOpen(true);
  const closeAdditionalCostModal = () => {
    setIsDirty(false);
    setIsAdditionalCostModalOpen(false);
  };

  const openDiscardChangesConfirmationModal = () => setIsDiscardChangesConfirmationModalOpen(true);
  const closeDiscardChangesConfirmationModal = () => {
    if (isSubmitting) {
      notifyAboutSubmitInProgress();
      return;
    }

    setIsDiscardChangesConfirmationModalOpen(false);
  };

  const cancelAdditionalCostModal = () => {
    if (isSubmitting) {
      notifyAboutSubmitInProgress();
      return;
    }

    if (isDirty) {
      openDiscardChangesConfirmationModal();
      return;
    }

    closeAdditionalCostModal();
  };

  const confirmAttachmentsModalCancellation = () => {
    closeDiscardChangesConfirmationModal();
    closeAdditionalCostModal();
  };

  return (
    <>
      <OrderEditDiscardChangesConfirmationModal
        isOpen={isDiscardChangesConfirmationModalOpen}
        closeModal={closeDiscardChangesConfirmationModal}
        onConfirm={confirmAttachmentsModalCancellation}
        onClose={closeDiscardChangesConfirmationModal}
        testId="additionalCostModalDiscardChangesConfirmationModal"
      />
      <Modal isOpen={isAdditionalCostModalOpen} closeModal={cancelAdditionalCostModal}>
        <AdditionalCostModalForm
          currencyCode={currencyCode}
          currentTotalAmount={currentTotalAmount}
          submit={submitAdditionalCost}
          cancel={cancelAdditionalCostModal}
          isSubmitting={isSubmitting}
          isSubmittingExternallyDisabled={isSubmittingExternallyDisabled}
          onSetIsDirty={setIsDirty}
          onSubmitSuccess={closeAdditionalCostModal}
        />
      </Modal>

      {!!render && render(openAdditionalCostModal)}
    </>
  );
};
