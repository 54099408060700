import { Environment, UnleashEnvironment } from "../models";

export const mapEnvironmentToUnleashProxyEnvironment = (
  environment: Environment
): UnleashEnvironment => {
  // We need this function because our Unleash Proxies do not support all names of environments.
  // Only "development" and "production" are supported.
  // Also, we have two different Unleash Proxies, Proxy 1 for the development purpose, Proxy 2 for clients and stakeholders.
  // Proxy 1's production points at TEST environment, Proxy 1's development points at LOCAL / DEVELOPMENT environment.
  // Proxy 2's production points at PRODUCTION environment, Proxy 2's development points at STAGING environment.
  switch (environment) {
    case "production":
      return "production";
    case "staging":
      return "development";
    case "test":
      return "production";
    case "development":
      return "development";
    default:
      return environment;
  }
};
