import { ChangeEventHandler, useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { RoutesConfig, RoutesParams } from "src/config/routes";
import { useSystemMessages } from "src/context/SystemMessages";
import {
  SupplierOrder,
  SupplierOrderService,
  ValidatedSupplierOrderForm,
  useOrderConfirmMutation,
} from "src/domain";

import { ConfirmOrder } from "./ConfirmOrder";

const MAX_NOTE_COUNT = 200;

type Props = {
  originalOrder: SupplierOrder;
  editedOrder: ValidatedSupplierOrderForm;
};

export const ConfirmOrderContainer: React.FC<Props> = ({ editedOrder, originalOrder }) => {
  const [note, setNote] = useState<string>("");
  const [noteError, setNoteError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const { setSystemMessage } = useSystemMessages();

  useEffect(() => {
    if (note.length > MAX_NOTE_COUNT) {
      setNoteError(
        `You have reached the maximum number of characters. ${note.length} / ${MAX_NOTE_COUNT}`
      );
    }
    if (!!noteError && note.length <= MAX_NOTE_COUNT) {
      setNoteError(undefined);
    }
  }, [note, noteError]);

  const handleConfirmationSuccess = () => {
    navigate(
      generatePath(RoutesConfig.orderConfirmed, { [RoutesParams.ORDER_ID]: editedOrder.orderId })
    );
  };

  const handleConfirmationError = () => {
    setSystemMessage({
      type: "failure",
      message: `There was an error when confirming order ${editedOrder.orderId}. Please try again.`,
    });
    // TODO #2158: Check what is logged to sentry in this case and provide some manual logging if needed
    // TODO #2159: Decide on error handling apart of just displaying error message
  };

  const confirmMutation = useOrderConfirmMutation({
    onSuccess: () => {
      handleConfirmationSuccess();
    },
    onError: () => {
      handleConfirmationError();
    },
  });

  const onSubmit = () => {
    confirmMutation.mutate(
      SupplierOrderService.convertSupplierOrderFormToApiConfirmOrderRequest({
        supplierOrderForm: editedOrder,
        note,
      })
    );
  };

  const onCancel = () => {
    navigate(
      generatePath(RoutesConfig.orderDetails, { [RoutesParams.ORDER_ID]: editedOrder.orderId })
    );
  };

  const isLoading = confirmMutation.isPending;
  const isDisabled = confirmMutation.isPending || confirmMutation.isSuccess || !!noteError;

  const handleNoteChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => setNote(e.target.value);

  return (
    <ConfirmOrder
      onSubmit={onSubmit}
      onCancel={onCancel}
      isLoading={isLoading}
      isDisabled={isDisabled}
      handleNoteChange={handleNoteChange}
      note={note}
      noteError={noteError}
      editedOrder={editedOrder}
      originalOrder={originalOrder}
    />
  );
};
