import { OrderVersionResponse, SupplierPortalAppService } from "src/typegens";

import { SupplierCloseOrderParams } from "../models";

export const closeOrder = async ({
  s2SOrderId,
  requestBody,
}: SupplierCloseOrderParams): Promise<OrderVersionResponse> => {
  const result = await SupplierPortalAppService.closeOrder({ s2SOrderId, requestBody });

  return result;
};
