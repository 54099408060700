import classnames from "classnames";

type Props = {
  className?: string;
};

export const S2SHeaderLogo = ({ className }: Props) => {
  return (
    <svg
      className={classnames(className)}
      width={150}
      height={32}
      viewBox="0 0 150 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.232 20.4677C44.5286 20.4677 44.7713 20.4448 44.96 20.399C45.1577 20.3441 45.315 20.2754 45.4319 20.193C45.5487 20.1014 45.6296 19.9961 45.6745 19.8771C45.7195 19.758 45.7419 19.6253 45.7419 19.4788C45.7419 19.1675 45.5981 18.9111 45.3105 18.7096C45.0229 18.499 44.5286 18.2747 43.8275 18.0366C43.522 17.9268 43.2164 17.8032 42.9108 17.6658C42.6052 17.5193 42.3311 17.3408 42.0884 17.1302C41.8457 16.9104 41.648 16.6494 41.4952 16.3473C41.3424 16.036 41.266 15.6606 41.266 15.2211C41.266 14.7815 41.3469 14.3878 41.5087 14.0399C41.6705 13.6828 41.8997 13.3806 42.1963 13.1334C42.4929 12.8862 42.8524 12.6985 43.2748 12.5703C43.6972 12.4329 44.1736 12.3643 44.7039 12.3643C45.333 12.3643 45.8768 12.4329 46.3351 12.5703C46.7935 12.7076 47.171 12.8587 47.4676 13.0235L46.8609 14.7129C46.6003 14.5755 46.3082 14.4565 45.9846 14.3558C45.67 14.2459 45.2881 14.191 44.8387 14.191C44.3354 14.191 43.9713 14.2642 43.7467 14.4107C43.5309 14.5481 43.4231 14.7632 43.4231 15.0562C43.4231 15.2302 43.4635 15.3767 43.5444 15.4957C43.6253 15.6148 43.7377 15.7247 43.8815 15.8254C44.0343 15.9169 44.205 16.0039 44.3938 16.0863C44.5915 16.1596 44.8072 16.2374 45.0409 16.3198C45.5262 16.5029 45.9487 16.6861 46.3082 16.8692C46.6677 17.0432 46.9643 17.2492 47.198 17.4873C47.4406 17.7253 47.6204 18.0046 47.7372 18.3251C47.8541 18.6455 47.9125 19.0347 47.9125 19.4925C47.9125 20.3807 47.6069 21.072 46.9957 21.5664C46.3846 22.0517 45.4633 22.2944 44.232 22.2944C43.8186 22.2944 43.4456 22.2669 43.113 22.212C42.7805 22.1662 42.4839 22.1067 42.2232 22.0334C41.9716 21.9602 41.7514 21.8823 41.5626 21.7999C41.3829 21.7175 41.2301 21.6397 41.1042 21.5664L41.6974 19.8633C41.9761 20.019 42.3176 20.1609 42.722 20.2891C43.1355 20.4081 43.6388 20.4677 44.232 20.4677Z"
        fill="white"
      />
      <path
        d="M51.2968 17.3362C51.2968 17.8032 51.3507 18.2243 51.4586 18.5998C51.5754 18.9752 51.7372 19.3002 51.9439 19.5749C52.1596 19.8405 52.4203 20.0465 52.7259 20.193C53.0315 20.3395 53.382 20.4127 53.7774 20.4127C54.1639 20.4127 54.5099 20.3395 54.8155 20.193C55.1301 20.0465 55.3907 19.8405 55.5975 19.5749C55.8132 19.3002 55.9749 18.9752 56.0828 18.5998C56.1996 18.2243 56.2581 17.8032 56.2581 17.3362C56.2581 16.8692 56.1996 16.448 56.0828 16.0726C55.9749 15.688 55.8132 15.363 55.5975 15.0974C55.3907 14.8227 55.1301 14.6122 54.8155 14.4656C54.5099 14.3191 54.1639 14.2459 53.7774 14.2459C53.382 14.2459 53.0315 14.3237 52.7259 14.4794C52.4203 14.6259 52.1596 14.8365 51.9439 15.1112C51.7372 15.3767 51.5754 15.7018 51.4586 16.0863C51.3507 16.4617 51.2968 16.8784 51.2968 17.3362ZM58.4286 17.3362C58.4286 18.1511 58.3073 18.8699 58.0646 19.4925C57.8309 20.106 57.5074 20.6233 57.0939 21.0445C56.6805 21.4566 56.1862 21.7679 55.6109 21.9785C55.0447 22.1891 54.4335 22.2944 53.7774 22.2944C53.1393 22.2944 52.5371 22.1891 51.9709 21.9785C51.4047 21.7679 50.9103 21.4566 50.4879 21.0445C50.0655 20.6233 49.7329 20.106 49.4903 19.4925C49.2476 18.8699 49.1263 18.1511 49.1263 17.3362C49.1263 16.5213 49.2521 15.8071 49.5037 15.1936C49.7554 14.5709 50.0924 14.049 50.5149 13.6278C50.9463 13.2066 51.4406 12.8908 51.9979 12.6802C52.5641 12.4696 53.1573 12.3643 53.7774 12.3643C54.4156 12.3643 55.0178 12.4696 55.584 12.6802C56.1502 12.8908 56.6445 13.2066 57.067 13.6278C57.4894 14.049 57.8219 14.5709 58.0646 15.1936C58.3073 15.8071 58.4286 16.5213 58.4286 17.3362Z"
        fill="white"
      />
      <path
        d="M63.8353 22.2944C63.1792 22.2944 62.6129 22.2028 62.1366 22.0197C61.6602 21.8274 61.2648 21.5664 60.9502 21.2368C60.6446 20.898 60.4154 20.4997 60.2626 20.0419C60.1188 19.5749 60.0469 19.0622 60.0469 18.5036V12.584H62.1501V18.3251C62.1501 18.7096 62.1905 19.0393 62.2714 19.314C62.3613 19.5795 62.4781 19.7992 62.6219 19.9732C62.7747 20.138 62.9545 20.2571 63.1612 20.3303C63.3769 20.4036 63.6106 20.4402 63.8622 20.4402C64.3746 20.4402 64.788 20.28 65.1026 19.9595C65.4261 19.639 65.5879 19.0942 65.5879 18.3251V12.584H67.691V18.5036C67.691 19.0622 67.6147 19.5749 67.4619 20.0419C67.3091 20.5089 67.0754 20.9117 66.7608 21.2505C66.4462 21.5802 66.0463 21.8365 65.5609 22.0197C65.0756 22.2028 64.5004 22.2944 63.8353 22.2944Z"
        fill="white"
      />
      <path
        d="M72.4505 12.4741C73.8526 12.4741 74.9266 12.7305 75.6726 13.2433C76.4186 13.7469 76.7916 14.5343 76.7916 15.6056C76.7916 16.274 76.6388 16.8188 76.3332 17.24C76.0366 17.6521 75.6052 17.9771 75.039 18.2152C75.2277 18.4533 75.4254 18.728 75.6322 19.0393C75.8389 19.3414 76.0411 19.6619 76.2388 20.0007C76.4456 20.3303 76.6433 20.6783 76.832 21.0445C77.0208 21.4016 77.196 21.7541 77.3578 22.1021H74.9985C74.8278 21.7908 74.6525 21.4749 74.4727 21.1544C74.302 20.8339 74.1222 20.5226 73.9335 20.2204C73.7537 19.9183 73.574 19.6344 73.3942 19.3689C73.2145 19.0942 73.0347 18.847 72.8549 18.6272H71.8169V22.1021H69.7137V12.7214C70.1721 12.6298 70.6439 12.5657 71.1293 12.5291C71.6236 12.4924 72.064 12.4741 72.4505 12.4741ZM72.5718 14.3008C72.419 14.3008 72.2797 14.3054 72.1539 14.3146C72.0371 14.3237 71.9247 14.3329 71.8169 14.342V16.9241H72.41C73.201 16.9241 73.7672 16.8234 74.1087 16.622C74.4503 16.4205 74.621 16.0772 74.621 15.5919C74.621 15.1249 74.4458 14.7953 74.0953 14.603C73.7537 14.4016 73.2459 14.3008 72.5718 14.3008Z"
        fill="white"
      />
      <path
        d="M82.7291 22.2944C81.2102 22.2944 80.0508 21.864 79.2508 21.0033C78.4599 20.1426 78.0644 18.9202 78.0644 17.3362C78.0644 16.5487 78.1858 15.8483 78.4285 15.2348C78.6711 14.6122 79.0037 14.0902 79.4261 13.669C79.8485 13.2387 80.3518 12.9136 80.936 12.6939C81.5203 12.4741 82.1539 12.3643 82.837 12.3643C83.2324 12.3643 83.5919 12.3963 83.9155 12.4604C84.2391 12.5153 84.5222 12.584 84.7648 12.6664C85.0075 12.7397 85.2097 12.8175 85.3715 12.8999C85.5333 12.9823 85.6501 13.0464 85.722 13.0922L85.1154 14.8227C84.8278 14.6671 84.4907 14.5343 84.1042 14.4244C83.7268 14.3146 83.2953 14.2596 82.81 14.2596C82.4864 14.2596 82.1674 14.3146 81.8528 14.4244C81.5472 14.5343 81.2731 14.7129 81.0304 14.9601C80.7967 15.1982 80.608 15.5095 80.4642 15.894C80.3204 16.2786 80.2485 16.7456 80.2485 17.295C80.2485 17.7345 80.2934 18.1465 80.3833 18.5311C80.4822 18.9065 80.635 19.2316 80.8417 19.5062C81.0574 19.7809 81.336 20.0007 81.6775 20.1655C82.0191 20.3212 82.4325 20.399 82.9179 20.399C83.2234 20.399 83.4976 20.3807 83.7402 20.3441C83.9829 20.3074 84.1986 20.2662 84.3874 20.2204C84.5761 20.1655 84.7424 20.106 84.8862 20.0419C85.03 19.9778 85.1603 19.9183 85.2771 19.8633L85.8569 21.5802C85.5603 21.7633 85.1423 21.9281 84.6031 22.0746C84.0638 22.2211 83.4391 22.2944 82.7291 22.2944Z"
        fill="white"
      />
      <path
        d="M87.2109 22.1021V12.584H93.5203V14.3832H89.3141V16.2511H93.0485V18.0092H89.3141V20.3029H93.8304V22.1021H87.2109Z"
        fill="white"
      />
      <path
        d="M104.234 15.0837C104.234 15.4316 104.167 15.7659 104.032 16.0863C103.897 16.4068 103.722 16.7181 103.506 17.0203C103.29 17.3133 103.048 17.5971 102.778 17.8718C102.508 18.1465 102.243 18.4075 101.983 18.6547C101.848 18.7829 101.699 18.9294 101.538 19.0942C101.385 19.2499 101.237 19.4101 101.093 19.5749C100.949 19.7397 100.819 19.8954 100.702 20.0419C100.594 20.1792 100.526 20.2937 100.5 20.3853H104.517V22.1021H98.2885C98.2706 22.0014 98.2616 21.8732 98.2616 21.7175C98.2616 21.5619 98.2616 21.452 98.2616 21.3879C98.2616 20.9484 98.329 20.5455 98.4638 20.1792C98.6076 19.813 98.7918 19.4742 99.0165 19.1629C99.2412 18.8424 99.4929 18.5448 99.7715 18.2701C100.059 17.9954 100.342 17.7207 100.621 17.4461C100.837 17.2355 101.039 17.0386 101.228 16.8555C101.416 16.6632 101.583 16.4801 101.726 16.3061C101.87 16.123 101.983 15.9444 102.063 15.7704C102.144 15.5873 102.185 15.4042 102.185 15.2211C102.185 14.8182 102.072 14.5343 101.848 14.3695C101.623 14.2047 101.344 14.1223 101.012 14.1223C100.769 14.1223 100.54 14.1635 100.324 14.2459C100.118 14.3191 99.9243 14.4107 99.7446 14.5206C99.5738 14.6213 99.4255 14.7266 99.2997 14.8365C99.1738 14.9372 99.0795 15.0196 99.0165 15.0837L98.0189 13.6553C98.4144 13.2799 98.8727 12.9732 99.394 12.7351C99.9243 12.4879 100.491 12.3643 101.093 12.3643C101.641 12.3643 102.113 12.4284 102.508 12.5565C102.904 12.6847 103.227 12.8679 103.479 13.1059C103.74 13.3348 103.928 13.6187 104.045 13.9575C104.171 14.2871 104.234 14.6625 104.234 15.0837Z"
        fill="white"
      />
      <path
        d="M111.983 20.4677C112.279 20.4677 112.522 20.4448 112.711 20.399C112.908 20.3441 113.066 20.2754 113.183 20.193C113.299 20.1014 113.38 19.9961 113.425 19.8771C113.47 19.758 113.493 19.6253 113.493 19.4788C113.493 19.1675 113.349 18.9111 113.061 18.7096C112.774 18.499 112.279 18.2747 111.578 18.0366C111.273 17.9268 110.967 17.8032 110.661 17.6658C110.356 17.5193 110.082 17.3408 109.839 17.1302C109.596 16.9104 109.399 16.6494 109.246 16.3473C109.093 16.036 109.017 15.6606 109.017 15.2211C109.017 14.7815 109.098 14.3878 109.259 14.0399C109.421 13.6828 109.65 13.3806 109.947 13.1334C110.244 12.8862 110.603 12.6985 111.025 12.5703C111.448 12.4329 111.924 12.3643 112.454 12.3643C113.084 12.3643 113.627 12.4329 114.086 12.5703C114.544 12.7076 114.922 12.8587 115.218 13.0235L114.612 14.7129C114.351 14.5755 114.059 14.4565 113.735 14.3558C113.421 14.2459 113.039 14.191 112.589 14.191C112.086 14.191 111.722 14.2642 111.497 14.4107C111.282 14.5481 111.174 14.7632 111.174 15.0562C111.174 15.2302 111.214 15.3767 111.295 15.4957C111.376 15.6148 111.488 15.7247 111.632 15.8254C111.785 15.9169 111.956 16.0039 112.144 16.0863C112.342 16.1596 112.558 16.2374 112.792 16.3198C113.277 16.5029 113.699 16.6861 114.059 16.8692C114.418 17.0432 114.715 17.2492 114.949 17.4873C115.191 17.7253 115.371 18.0046 115.488 18.3251C115.605 18.6455 115.663 19.0347 115.663 19.4925C115.663 20.3807 115.358 21.072 114.746 21.5664C114.135 22.0517 113.214 22.2944 111.983 22.2944C111.569 22.2944 111.196 22.2669 110.864 22.212C110.531 22.1662 110.235 22.1067 109.974 22.0334C109.722 21.9602 109.502 21.8823 109.313 21.7999C109.134 21.7175 108.981 21.6397 108.855 21.5664L109.448 19.8633C109.727 20.019 110.068 20.1609 110.473 20.2891C110.886 20.4081 111.389 20.4677 111.983 20.4677Z"
        fill="white"
      />
      <path
        d="M117.281 22.1021V12.584H123.591V14.3832H119.384V16.2511H123.119V18.0092H119.384V20.3029H123.901V22.1021H117.281Z"
        fill="white"
      />
      <path
        d="M131.713 22.1021C131.614 21.7725 131.502 21.4337 131.376 21.0857C131.259 20.7378 131.142 20.3898 131.025 20.0419H127.385C127.268 20.3898 127.147 20.7378 127.021 21.0857C126.904 21.4337 126.796 21.7725 126.698 22.1021H124.514C124.864 21.0766 125.197 20.1289 125.511 19.259C125.826 18.3892 126.131 17.5697 126.428 16.8005C126.734 16.0314 127.03 15.3035 127.318 14.6167C127.614 13.9208 127.92 13.2433 128.234 12.584H130.243C130.549 13.2433 130.85 13.9208 131.146 14.6167C131.443 15.3035 131.74 16.0314 132.036 16.8005C132.342 17.5697 132.652 18.3892 132.967 19.259C133.281 20.1289 133.614 21.0766 133.964 22.1021H131.713ZM129.192 14.7403C129.147 14.8777 129.079 15.0654 128.989 15.3035C128.9 15.5415 128.796 15.8162 128.679 16.1275C128.563 16.4389 128.432 16.7822 128.288 17.1576C128.154 17.533 128.014 17.9268 127.87 18.3388H130.526C130.383 17.9268 130.243 17.533 130.108 17.1576C129.974 16.7822 129.843 16.4389 129.717 16.1275C129.601 15.8162 129.497 15.5415 129.407 15.3035C129.318 15.0654 129.246 14.8777 129.192 14.7403Z"
        fill="white"
      />
      <path
        d="M37.26 8.04607V7.62331C37.26 7.62331 28.0615 6.67304 27.085 6.51795C26.8006 6.47277 26.0929 6.44342 25.8062 6.47014C25.5194 6.49685 25.2415 6.58341 24.9903 6.72423C24.7391 6.86504 24.5202 7.05699 24.3477 7.28766C24.1753 7.51833 24.0532 7.7826 23.9892 8.0634C23.9253 8.3442 23.921 8.6353 23.9765 8.91788C24.0321 9.20046 24.1463 9.46825 24.3118 9.70396C24.4772 9.93966 25.1084 10.5644 25.3553 10.7127C25.3553 10.7127 27.8304 12.87 28.7572 13.7996C29.0174 14.0598 29.2328 14.3971 29.34 14.774C29.4297 15.088 29.4567 15.4166 29.4194 15.741C29.3821 16.0655 29.2812 16.3794 29.1226 16.6648C28.9639 16.9502 28.7506 17.2016 28.4947 17.4045C28.2389 17.6074 27.9455 17.758 27.6315 17.8475L26.271 18.2364L19.2908 20.2358L16.1448 25.2241L20.2828 24.062L19.5935 25.2467H21.1314L22.1534 23.5519L23.5893 23.1419L22.328 25.2467H23.9798L25.5829 22.5882L26.093 22.4583L28.9642 21.6369C29.4764 21.4907 29.9548 21.2449 30.3721 20.9137C30.7893 20.5825 31.1372 20.1723 31.3958 19.7065C31.6544 19.2408 31.8188 18.7287 31.8794 18.1994C31.94 17.6701 31.8957 17.1341 31.7491 16.622C31.7264 16.5426 31.7009 16.4644 31.6743 16.3873V16.3833C31.6085 16.1964 31.5291 16.0145 31.4368 15.8392L29.9131 12.3541C30.4902 12.6826 31.1272 12.8924 31.7865 12.9714C32.9899 13.1167 34.2051 12.8205 35.2067 12.1378C36.2082 11.4551 36.9281 10.3246 37.2328 9.15143H28.56L28.077 8.15944H37.26V8.04607ZM26.1826 9.33225C26.0177 9.37259 25.8442 9.36027 25.6866 9.297C25.5291 9.23374 25.3952 9.12272 25.304 8.97952C25.2127 8.83632 25.1686 8.66813 25.1778 8.49857C25.187 8.32902 25.2491 8.1666 25.3553 8.03413C25.4616 7.90165 25.6066 7.80577 25.7701 7.75995C25.9336 7.71413 26.1074 7.72066 26.267 7.77863C26.4266 7.8366 26.564 7.94309 26.66 8.08317C26.756 8.22324 26.8057 8.38986 26.8022 8.55963C26.7982 8.73863 26.7354 8.91133 26.6234 9.05101C26.5114 9.19068 26.3565 9.28953 26.1826 9.33225Z"
        fill="white"
      />
      <path
        d="M27.4439 16.7979L26.0396 17.2253L24.5546 17.6806L20.0277 19.0166L20.0542 18.9742C21.3714 16.8723 22.2384 15.4887 24.0221 14.5936C25.6998 13.5487 27.3118 14.1349 27.7652 14.4251C28.4738 14.8785 28.4562 15.4048 28.4385 15.5886C28.4208 15.7724 28.3924 15.9584 28.3052 16.1214C28.218 16.2845 28.161 16.3605 28.0176 16.4778C27.8742 16.5952 27.6216 16.7441 27.4439 16.7979Z"
        fill="white"
      />
    </svg>
  );
};
