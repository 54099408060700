import classnames from "classnames";
import { PropsWithChildren } from "react";

import { TextColors } from "../../../utils";

type HeadlineVariant =
  | "headline-100-heavy"
  | "headline-100-light"
  | "headline-200-heavy"
  | "headline-200-light"
  | "headline-300-heavy"
  | "headline-300-light"
  | "headline-400-heavy"
  | "headline-400-light";

type ParagraphVariant =
  | "paragraph-100-heavy"
  | "paragraph-100-light"
  | "paragraph-200-heavy"
  | "paragraph-200-light"
  | "paragraph-300-heavy"
  | "paragraph-300-light"
  | "paragraph-400-heavy"
  | "paragraph-400-light";

type LabelVariant = "label-100" | "label-200" | "label-300" | "label-400";
type ButtonVariant = "button-100" | "button-200";

type TypographyVariant = HeadlineVariant | ParagraphVariant | LabelVariant | ButtonVariant;

type TypographyElement = Extract<
  keyof JSX.IntrinsicElements,
  "p" | "span" | "label" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
>;

type TypographyColor =
  | TextColors
  | `hover:${TextColors}`
  | `active:${TextColors}`
  | `focus:${TextColors}`
  | `focus-visible:${TextColors}`
  | `group-hover:${TextColors}`
  | `group-active:${TextColors}`
  | `group-focus:${TextColors}`
  | `group-focus-visible:${TextColors}`;

type BaseTypographyProps = Omit<
  React.HTMLAttributes<HTMLParagraphElement | HTMLSpanElement | HTMLHeadingElement>,
  "color"
> & {
  className?: string;
  color?: TypographyColor | TypographyColor[] | "";
  component?: TypographyElement;
};

const variantMapping: Record<TypographyVariant, TypographyElement> = {
  "headline-100-heavy": "h1",
  "headline-100-light": "h1",
  "headline-200-heavy": "h2",
  "headline-200-light": "h2",
  "headline-300-heavy": "h3",
  "headline-300-light": "h3",
  "headline-400-heavy": "h4",
  "headline-400-light": "h4",
  "paragraph-100-heavy": "p",
  "paragraph-100-light": "p",
  "paragraph-200-heavy": "p",
  "paragraph-200-light": "p",
  "paragraph-300-heavy": "p",
  "paragraph-300-light": "p",
  "paragraph-400-heavy": "p",
  "paragraph-400-light": "p",
  "button-100": "span",
  "button-200": "span",
  "label-100": "span",
  "label-200": "span",
  "label-300": "span",
  "label-400": "span",
};

const stylingMapping: Record<TypographyVariant, string> = {
  "headline-100-heavy": "text-4xl font-semibold",
  "headline-100-light": "text-4xl font-normal",
  "headline-200-heavy": "text-2xl font-semibold",
  "headline-200-light": "text-2xl font-normal",
  "headline-300-heavy": "text-xl font-semibold",
  "headline-300-light": "text-xl font-normal",
  "headline-400-heavy": "text-base font-semibold",
  "headline-400-light": "text-base font-normal",
  "paragraph-100-heavy": "text-base font-semibold",
  "paragraph-100-light": "text-base font-normal",
  "paragraph-200-heavy": "text-sm font-semibold",
  "paragraph-200-light": "text-sm font-normal",
  "paragraph-300-heavy": "text-xs font-semibold",
  "paragraph-300-light": "text-xs font-normal",
  "paragraph-400-heavy": "text-xxs font-semibold",
  "paragraph-400-light": "text-xxs font-normal",
  "button-100": "text-button-100 capitalize",
  "button-200": "text-button-200 capitalize",
  "label-100": "text-label-100",
  "label-200": "text-label-200",
  "label-300": "text-label-300",
  "label-400": "text-label-400",
};

export type ParagraphProps = BaseTypographyProps & {
  size: "100" | "200" | "300" | "400";
  weight?: "light" | "heavy";
};

export const Paragraph: React.FC<PropsWithChildren<ParagraphProps>> = ({
  children,
  className,
  color = "text-textIcon-blackPrimary",
  component,
  size,
  weight = "light",
  ...rest
}) => {
  const variant: ParagraphVariant = `paragraph-${size}-${weight}`;
  const Component = component || variantMapping[variant];
  const classes = stylingMapping[variant];

  return (
    <Component className={classnames(color, classes, className)} {...rest}>
      {children}
    </Component>
  );
};

type HeadingProps = BaseTypographyProps & {
  size: "100" | "200" | "300" | "400";
  weight?: "light" | "heavy";
};

export const Heading: React.FC<PropsWithChildren<HeadingProps>> = ({
  children,
  className,
  color = "text-textIcon-blackPrimary",
  component,
  size,
  weight = "heavy",
  ...rest
}) => {
  const variant: HeadlineVariant = `headline-${size}-${weight}`;
  const Component = component || variantMapping[variant];
  const classes = stylingMapping[variant];

  return (
    <Component className={classnames(color, classes, className)} {...rest}>
      {children}
    </Component>
  );
};

export type LabelProps = BaseTypographyProps &
  React.LabelHTMLAttributes<HTMLLabelElement> & {
    size: "100" | "200" | "300" | "400";
  };

export const Label: React.FC<PropsWithChildren<LabelProps>> = ({
  children,
  className,
  color = "text-textIcon-blackPrimary",
  component,
  size,
  ...rest
}) => {
  const variant: LabelVariant = `label-${size}`;
  const Component = component || variantMapping[variant];
  const classes = stylingMapping[variant];

  return (
    <Component className={classnames(color, classes, className)} {...rest}>
      {children}
    </Component>
  );
};

export type ButtonTypographyProps = BaseTypographyProps & {
  size: "100" | "200";
};

export const ButtonTypography: React.FC<PropsWithChildren<ButtonTypographyProps>> = ({
  children,
  className,
  color,
  component,
  size,
  ...rest
}) => {
  const variant: ButtonVariant = `button-${size}`;
  const Component = component || variantMapping[variant];
  const classes = stylingMapping[variant];

  return (
    <Component className={classnames(color, classes, className)} {...rest}>
      {children}
    </Component>
  );
};

export const Strong = ({ children }: PropsWithChildren) => (
  <strong className="font-semibold">{children}</strong>
);
