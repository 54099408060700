import { Controller, useFormContext } from "react-hook-form";

import { useAuth0WithRedirect } from "@web/common";
import { Avatar, Heading, LEGACY_Input } from "@web/ui";
import { formatInitials } from "@web/utils";

import { UserProfileSchemaType } from "src/domain";

export const UserProfileForm: React.FC = () => {
  const { control } = useFormContext<UserProfileSchemaType>();
  const { user } = useAuth0WithRedirect();

  return (
    <div className="w-[1024px] flex flex-col px-8 py-6 m-auto">
      <Heading size="300" className="pb-2">
        User profile
      </Heading>
      <div className="flex max-w-[668px]">
        <Avatar text={formatInitials(user?.name || "")} data-testid="userProfile-avatar" />
      </div>
      <div className="flex max-w-[668px]">
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <LEGACY_Input
              className="pt-2 mr-5 w-full"
              label="User Name"
              disabled
              testId="userProfile-nameField"
              {...field}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <LEGACY_Input
              className="pt-2 w-full"
              label="User Email"
              disabled
              testId="userProfile-emailField"
              {...field}
            />
          )}
        />
      </div>
    </div>
  );
};
