import { XIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";

import { IconButton, Paragraph } from "../../atoms";

type HeaderProps = {
  title?: string | React.ReactNode;
  link?: string;
  onClose?: () => void;
};

export const CloseHeader: React.FC<HeaderProps> = ({ title, link, onClose }) => {
  const navigate = useNavigate();
  return (
    <div className={`flex shadow w-full h-7 bg-neutral_0`}>
      <div className="w-[1024px] px-8 mx-auto flex">
        <div className="flex justify-between relative w-full">
          {!!title && (
            <div className="absolute w-full h-full flex items-center justify-center">
              {typeof title === "string" ? (
                <Paragraph size="100" className="my-auto">
                  {title}
                </Paragraph>
              ) : (
                title
              )}
            </div>
          )}
          {!!(link || onClose) && (
            <IconButton
              className="my-auto z-10"
              size="large"
              variant="secondary"
              shape="circle"
              label="Return"
              Icon={XIcon}
              data-testid="closeHeaderLink"
              onClick={() => (link ? navigate(link) : onClose ? onClose() : undefined)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
