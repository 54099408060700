import { formatShortFileName } from "@web/utils";

import { useSystemMessages } from "src/context/SystemMessages";

import { FILE_NAME_MAX_LENGTH_IN_NOTIFICATIONS } from "../../config";

export const useFileUploadSuccess = () => {
  const { setSystemMessage } = useSystemMessages();

  const showFileUploadSuccess = (file: File) => {
    setSystemMessage({
      type: "success",
      message: `Your file "${formatShortFileName(
        file.name,
        FILE_NAME_MAX_LENGTH_IN_NOTIFICATIONS
      )}" was successfully uploaded.`,
    });
  };

  return {
    showFileUploadSuccess,
  };
};
