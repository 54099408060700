import { useEffect, useMemo, useRef, useState } from "react";

interface IntersectionObserverProps {
  onEnter(): void;
  children: React.ReactNode;
  className?: string;
}

export const IntersectionMonitor: React.FC<IntersectionObserverProps> = ({
  onEnter,
  children,
  className,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => entry && setIsVisible(entry.isIntersecting)),
    [setIsVisible]
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [observer]);

  useEffect(() => {
    if (isVisible) {
      onEnter();
    }
  }, [isVisible, onEnter]);

  return (
    <div ref={ref} className={`flex flex-grow justify-center items-center ${className}`}>
      {children}
    </div>
  );
};
