import { Money } from "@web/models";
import { isDefined } from "@web/utils";

import { GrandTotal, Label } from "../../atoms";

interface Props {
  originalGrandTotal: Money;
  newGrandTotal?: Money;
  itemsQuantity: number;
  itemsLabel?: string;
  attachmentsLabel?: string;
  attachmentsQuantity?: number;
  originalGrandTotalLabel?: string;
  modifiedOriginalGrandTotalLabel?: string;
  newGrandTotalLabel?: string;
  isGrandTotalVisible?: boolean;
  isNewGrandTotalVisible?: boolean;
  testId?: string;
}

// TODO #2441: Add E2E tests
export const OrderConfiguratorSummary: React.FC<Props> = ({
  newGrandTotal,
  itemsQuantity,
  originalGrandTotal,
  attachmentsQuantity,
  itemsLabel = "Items",
  attachmentsLabel = "Attachments",
  originalGrandTotalLabel = "Total",
  modifiedOriginalGrandTotalLabel = "Initial Total",
  newGrandTotalLabel = "New Total",
  isGrandTotalVisible = false,
  isNewGrandTotalVisible = false,
  testId = "orderConfiguratorSummary",
}) => {
  const attachmentsInformation = isDefined(attachmentsQuantity)
    ? ` | ${attachmentsLabel} (${attachmentsQuantity})`
    : "";

  return (
    <div className="flex flex-row items-baseline justify-between py-1" data-testid={testId}>
      <Label size="200" data-testid="configuratorSummary_quantity">
        {`${itemsLabel} (${itemsQuantity})${attachmentsInformation}`}
      </Label>
      {isGrandTotalVisible && (
        <div className="flex">
          {isNewGrandTotalVisible && newGrandTotal ? (
            <>
              <GrandTotal
                textColor="text-textIcon-blackSecondary"
                totalGrossAmount={originalGrandTotal}
                data-testid={`${testId}_originalGrandTotal`}
              >
                {modifiedOriginalGrandTotalLabel}
              </GrandTotal>
              <GrandTotal
                totalGrossAmount={newGrandTotal}
                className="border-l-1 border-neutral_300 ml-2"
                data-testid={`${testId}_newGrandTotal`}
              >
                {newGrandTotalLabel}
              </GrandTotal>
            </>
          ) : (
            <GrandTotal
              totalGrossAmount={originalGrandTotal}
              data-testid={`${testId}_originalGrandTotal`}
            >
              {originalGrandTotalLabel}
            </GrandTotal>
          )}
        </div>
      )}
    </div>
  );
};
