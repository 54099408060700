import { ALL_UNLEASH_E2E_HOSTS } from "../config";
import { Environment } from "../models";

export const mapEnvironmentToUnleashPanelUrl = (environment: Environment): string | undefined => {
  // We need this function because our Unleash Proxies do not support all names of environments.
  // Only "development" and "production" are supported.
  // Also, we have two different Unleash Proxies, Proxy 1 for the development purpose, Proxy 2 for clients and stakeholders.
  // Proxy 1's production points at TEST environment, Proxy 1's development points at LOCAL / DEVELOPMENT environment.
  // Proxy 2's production points at PRODUCTION environment, Proxy 2's development points at STAGING environment.
  switch (environment) {
    case "production":
    case "staging":
      return `https://${ALL_UNLEASH_E2E_HOSTS.stagingAndProd}`;
    case "test":
    case "development":
      return `https://${ALL_UNLEASH_E2E_HOSTS.localAndTest}`;
    /* no default */
  }
};
