import _cloneDeep from "lodash/cloneDeep";

import { isDefined } from "@web/utils";

import { changeConfig } from "../configs";
import { Change, ChangeApiResponse, ChangeConfig, ChangesSyncQueue, NewChange } from "../models";
import { ChangesSyncConsolidationService } from "./ChangesSyncConsolidationService";

// Do not import outside `changesSync` domain. Import `ChangesSyncService` instead.
export class ChangesSyncQueueService {
  private static changes: ChangesSyncQueue = [];

  private static processErrors = (
    queue: ChangesSyncQueue
  ): {
    processedQueue: ChangesSyncQueue;
    changesToRollback: ChangesSyncQueue;
  } => {
    let processedQueue = _cloneDeep(queue);
    let changesToRollback: ChangesSyncQueue = [];

    const indexOfOldestErroredChange = queue.findIndex((item) => item.status === "error");

    if (indexOfOldestErroredChange > -1) {
      // Rollback all changes that were made after this change
      changesToRollback = ChangesSyncConsolidationService.consolidateTailingChangesOfStatus(
        processedQueue.slice(indexOfOldestErroredChange),
        "error"
      ).processedQueue;

      // Only take the changes that were made before the oldest errored change for further processing
      processedQueue = processedQueue.slice(0, indexOfOldestErroredChange);
    }

    return {
      processedQueue,
      changesToRollback,
    };
  };

  private static processSuccesses = (
    queue: ChangesSyncQueue
  ): {
    processedQueue: ChangesSyncQueue;
    successfulChanges: ChangesSyncQueue;
  } => {
    const processedQueue = _cloneDeep(queue);
    return {
      processedQueue: processedQueue.filter((item) => item.status !== "success"),
      successfulChanges: processedQueue.filter(
        (item) => item.status === "success"
      ) as ChangesSyncQueue,
    };
  };

  private static prepareChangesForSync = (
    queue: ChangesSyncQueue
  ): {
    processedQueue: ChangesSyncQueue;
    pendingChange?: Change;
  } => {
    const processedQueue = _cloneDeep(queue);

    // Mark first queued change as pending, as we want to push changes 1-by-1
    const firstQueuedChange = processedQueue.find((item) => item.status === "queued");

    if (firstQueuedChange) {
      // Intentionally mutating the change this way
      firstQueuedChange.status = "pending";
    }

    return { processedQueue, pendingChange: firstQueuedChange };
  };

  private static updateQueuedChangesVersion = (
    queue: ChangesSyncQueue
  ): {
    processedQueue: ChangesSyncQueue;
  } => {
    let processedQueue = _cloneDeep(queue);

    const lastSuccessfulChange = processedQueue.findLast((change) => change.status === "success");

    if (lastSuccessfulChange) {
      // Update all queued changes with the last successful change's newOrderVersionId,
      // as this version id is the base for all future changes.
      processedQueue = processedQueue.map((change) => {
        if (change.status === "queued" && isDefined(lastSuccessfulChange.meta.newOrderVersionId)) {
          return {
            ...change,
            meta: {
              ...change.meta,
              oldOrderVersionId: lastSuccessfulChange.meta.newOrderVersionId,
            },
          };
        }

        return change;
      });
    }

    return { processedQueue };
  };

  private static getApiResponseMapper = (change: Change): ChangeConfig["apiResponseMapper"] =>
    changeConfig[change.type].apiResponseMapper;

  // `pending` changes sync was successful, so we need to resolve them as successful & merge them with response data
  public static resolvePendingChangesAsSuccessful = (requestResponse: ChangeApiResponse): void => {
    let processedQueue = _cloneDeep(ChangesSyncQueueService.changes);

    processedQueue = processedQueue.map((change) => {
      if (change.status !== "pending") {
        return change;
      }
      const apiResponseMapper = ChangesSyncQueueService.getApiResponseMapper(change);
      // TODO #7282: [CHORE] Fix "never" type. So far, nothing I tried worked.
      return apiResponseMapper(change as never, requestResponse as never);
    });

    // Update queued changes with the last successful change's `newOrderVersionId`
    const versionUpdateResult = ChangesSyncQueueService.updateQueuedChangesVersion(processedQueue);

    // Intentional side effects
    ChangesSyncQueueService.changes = versionUpdateResult.processedQueue;
  };

  // `pending` changes sync errored, so we need to resolve them as failed & add error info to them
  public static resolvePendingChangesAsFailed = (error: Error): void => {
    // Intentional side effects
    ChangesSyncQueueService.changes = ChangesSyncQueueService.changes.map((change) =>
      change.status === "pending" ? { ...change, status: "error", error } : change
    );
  };

  /**
   * Triggers:
   * - when new item is added to queue
   * - when request completes (either success or error)
   */
  public static processQueue = (): {
    pendingChange: Change | undefined;
    successfulChanges: ChangesSyncQueue;
    changesToRollback: ChangesSyncQueue;
    processedQueue: ChangesSyncQueue;
  } => {
    let processedQueue = _cloneDeep(ChangesSyncQueueService.changes);

    // 1. Check for errors
    const errorsProcessingResult = ChangesSyncQueueService.processErrors(processedQueue);
    processedQueue = errorsProcessingResult.processedQueue;

    const changesToRollback = errorsProcessingResult.changesToRollback;

    // 2. Check for successes - cleanup the queue
    const successesProcessingResult = ChangesSyncQueueService.processSuccesses(processedQueue);
    processedQueue = successesProcessingResult.processedQueue;

    const successfulChanges = successesProcessingResult.successfulChanges;

    // 3. Consolidate changes in queue
    const consolidationResult = ChangesSyncConsolidationService.consolidateTailingChangesOfStatus(
      processedQueue,
      "queued"
    );
    processedQueue = consolidationResult.processedQueue;

    // 4. Check for queued items and trigger request
    const syncPreparationResult = ChangesSyncQueueService.prepareChangesForSync(processedQueue);
    processedQueue = syncPreparationResult.processedQueue;

    const pendingChange = syncPreparationResult.pendingChange;

    // Intentional side effects
    ChangesSyncQueueService.changes = processedQueue;

    return {
      pendingChange,
      successfulChanges,
      changesToRollback,
      processedQueue,
    };
  };

  public static clearQueue = (): void => {
    // Intentional side effects
    ChangesSyncQueueService.changes = [];
  };

  public static addToQueue = (change: NewChange): void => {
    // Intentional side effects
    ChangesSyncQueueService.changes.push({
      ...change,
      status: "queued",
    });
  };
}
