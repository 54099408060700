import { useReducer } from "react";

import { isDefined } from "@web/utils";

import {
  FeatureFlagsManagementState,
  FeatureFlagsManagementStateAction,
  ManagedFeatureFlags,
  ManagedUnleashFeatureFlags,
  NativeUnleashFeatureFlag,
  UnleashContextFlagProperties,
} from "../models";
import { convertFlagToOverriddenUnleashContextProperty } from "../utils";

export const defaultFeatureFlagsManagementState: FeatureFlagsManagementState = {
  environment: undefined,
  isFlagsManagementAllowed: false,
  unleashFlagsState: {},
  flagsOverrideState: {},
  unleashContextFlagProperties: {},
};

const convertFromFlagOverrideStateToUnleashContextProperty = (
  flagName: string,
  isFlagEnabledLocally: boolean
): UnleashContextFlagProperties => {
  // Only include flags that have been opted in
  if (!isFlagEnabledLocally) {
    return {};
  }

  return convertFlagToOverriddenUnleashContextProperty(flagName);
};

const convertFromNativeUnleashFlagsToManagedUnleashFeatureFlags = (
  nativeUnleashFlags: NativeUnleashFeatureFlag[],
  nativeOverriddenUnleashFlags: NativeUnleashFeatureFlag[]
): ManagedUnleashFeatureFlags =>
  nativeUnleashFlags.reduce(
    (acc, { name, enabled }) => ({
      ...acc,
      // Filter out all backend-only flags
      ...(name.startsWith("be.")
        ? {}
        : {
            [name]: {
              // The flag should be disabled by default. But If overridden, it should become enabled.
              // If it does not enable when overridden, then it is either disabled for environment
              // or not properly configured with a strategy in Unleash.
              // Also, if the flag is enabled by default, it means that the flag is not properly configured
              // with a strategy in Unleash.
              isEnabled: enabled,
              isProperlyConfigured:
                !enabled &&
                nativeOverriddenUnleashFlags.some((flag) => flag.name === name && flag.enabled),
            },
          }),
    }),
    {}
  );

const FeatureFlagsManagementReducer = (
  featureFlagsManagerState: FeatureFlagsManagementState,
  action: FeatureFlagsManagementStateAction
): FeatureFlagsManagementState => {
  switch (action.type) {
    case "setEnvironment":
      return {
        ...featureFlagsManagerState,
        environment: action.payload,
        isFlagsManagementAllowed: action.payload && action.payload !== "production",
      };

    case "setUnleashFlagsState":
      return {
        ...featureFlagsManagerState,
        unleashFlagsState: convertFromNativeUnleashFlagsToManagedUnleashFeatureFlags(
          action.payload.nativeUnleashFlags,
          action.payload.nativeOverriddenUnleashFlags
        ),
      };

    case "setFlagsOverrideState": {
      const stateToSet = action.payload;
      // Only store flags that exist in Unleash, and that have been opted in
      const filteredFlagsOverrideState: ManagedFeatureFlags = Object.entries(
        featureFlagsManagerState.unleashFlagsState
      ).reduce(
        (acc, [flagName, { isProperlyConfigured: isProperlyConfiguredInUnleash }]) => ({
          ...acc,
          // Check if user wants to opt in. Do not include flags that are improperly configured in Unleash
          ...(isProperlyConfiguredInUnleash &&
          isDefined(stateToSet[flagName]) &&
          stateToSet[flagName]
            ? {
                [flagName]: true,
              }
            : {}),
        }),
        {}
      );

      const unleashContextFlagProperties: UnleashContextFlagProperties = Object.entries(
        filteredFlagsOverrideState
      ).reduce((acc, [flagName, isFlagEnabledLocally]) => {
        return {
          ...acc,
          ...convertFromFlagOverrideStateToUnleashContextProperty(flagName, isFlagEnabledLocally),
        };
      }, {});

      return {
        ...featureFlagsManagerState,
        flagsOverrideState: filteredFlagsOverrideState,
        unleashContextFlagProperties,
      };
    }

    default:
      return featureFlagsManagerState;
  }
};

export const useFeatureFlagsManagementReducer = () =>
  useReducer(FeatureFlagsManagementReducer, defaultFeatureFlagsManagementState);
