import { useEffect } from "react";

import { SuccessModal } from "@web/common";

const MODAL_VISIBILITY_TIME = 2000;

interface Props {
  message: string;
  closeModal: () => void;
}

export const ItemActionSuccessModal = ({ message, closeModal }: Props) => {
  useEffect(() => {
    setTimeout(() => {
      closeModal();
    }, MODAL_VISIBILITY_TIME);
    // We want to run this effect only once. This modal is more like a notification,
    // it should automatically disappear after given time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SuccessModal message={message} closeModal={closeModal} />;
};
