// TODO #499/7360: Add unit tests
export function getOrderVersionFromParam<T extends "OLDEST" | "LATEST">(
  orderVersionParam: string | undefined,
  defaultValue: T
): number | T {
  try {
    const convertedOrderVersion = Number(orderVersionParam);
    if (isNaN(convertedOrderVersion)) {
      return defaultValue;
    }
    return convertedOrderVersion;
  } catch {
    return defaultValue;
  }
}
