import { z } from "zod";

import { preprocessFormAmountValue } from "@web/utils";

export const SupplierOrderAmountAdditionalCostSchema = z.object({
  id: z.string(),
  name: z.string().trim().min(1, { message: "Required" }),
  amount: z.object({
    amount: z.preprocess(
      preprocessFormAmountValue,
      z.number({ required_error: "Number required" }).positive()
    ),
    currencyCode: z.string(),
  }),
});

export type SupplierOrderAmountAdditionalCostForm = z.infer<
  typeof SupplierOrderAmountAdditionalCostSchema
>;
