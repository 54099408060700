import { OrderVersionResponse, SupplierPortalAppService } from "src/typegens";

export const unassignUserFromPO = async ({
  s2SOrderId,
  version,
}: {
  s2SOrderId: string;
  version: number;
}): Promise<OrderVersionResponse> => {
  const result = await SupplierPortalAppService.unassign({
    s2SOrderId,
    requestBody: { version },
  });
  return result;
};
