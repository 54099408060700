import { useEffect } from "react";
import { useInRouterContext, useMatch } from "react-router-dom";

import { RoutesConfig } from "src/config/routes";

import { useOrderEditsSync } from "./useOrderEditsSync";

interface Props {
  children: React.ReactNode;
}

export const OrderEditsSyncLifecycle = ({ children }: Props) => {
  const isInRouterContext = useInRouterContext();
  const isOrderVersionEditPage = useMatch(RoutesConfig.orderEditVersion);
  const { reset } = useOrderEditsSync();

  useEffect(() => {
    if (!isOrderVersionEditPage) {
      // Clear changes sync queue if routing targeted anything else than order edit page
      reset();
    }
  }, [isOrderVersionEditPage, reset]);

  if (!isInRouterContext) {
    throw new Error("OrderEditsSyncLifecycle must be used in Router context");
  }

  return <>{children}</>;
};
