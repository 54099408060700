import { ShoppingCartIcon, UserIcon } from "@heroicons/react/solid";

import { Paragraph } from "@web/ui";
import { isDefined } from "@web/utils";

import { DutyFreeDeclarationType } from "../../../network/model";

type Props = {
  dutyFreeDeclaration?: DutyFreeDeclarationType;
};

const Entry = ({
  heading,
  icon,
  primaryValue,
  secondaryValue,
  ...rest
}: {
  heading: string;
  icon?: React.ReactNode;
  primaryValue?: string;
  secondaryValue?: string;
}) => {
  return (
    <div {...rest}>
      <Paragraph size="300" color="text-textIcon-blackSecondary" className="pb-2 uppercase">
        {heading}
      </Paragraph>
      <div className="flex gap-3">
        {icon}
        <Paragraph size="200">
          <span className="flex flex-col">
            <span className="block text-textIcon-blackPrimary text-sm font-normal">
              {primaryValue}
            </span>
            <span className="block text-textIcon-blackSecondary text-xs font-normal">
              {secondaryValue}
            </span>
          </span>
        </Paragraph>
      </div>
    </div>
  );
};

export const DutyFreeDeclarationInfo = ({ dutyFreeDeclaration }: Props) => {
  return (
    <>
      <Entry
        icon={<ShoppingCartIcon className="-ml-0.5 text-textIcon-blackSecondary" width="20px" />}
        heading="Duty free entitlement"
        primaryValue={
          !isDefined(dutyFreeDeclaration?.dutyFree)
            ? "N/A"
            : dutyFreeDeclaration?.dutyFree
            ? "Yes"
            : "No"
        }
        data-testid="dutyFreeDeclarationInfo_dutyFreeEntitlement"
      />
      {dutyFreeDeclaration?.name && (
        <Entry
          icon={<UserIcon className="-ml-0.5 text-textIcon-blackSecondary" width="20px" />}
          heading="Ordered by"
          primaryValue={dutyFreeDeclaration?.name}
          secondaryValue={dutyFreeDeclaration?.position}
          data-testid="dutyFreeDeclarationInfo_nameAndPosition"
        />
      )}
    </>
  );
};
