import { z } from "zod";

const requiredMessage = "Required";

export const SupplierSchema = z.object({
  information: z.object({
    vendor: z.object({
      vendorName: z.string().min(1, { message: requiredMessage }),
      registrationNumber: z.string().min(1, { message: requiredMessage }),
      vatNumber: z.string().min(1, { message: requiredMessage }),
    }),
    paymentData: z.object({
      paymentEmail: z.string().email(),
      paymentTerms: z.string().min(1, { message: requiredMessage }),
      paymentMethod: z.string().min(1, { message: requiredMessage }),
      currency: z.string().min(1, { message: requiredMessage }),
      iban: z.string().min(1, { message: requiredMessage }),
      swift: z.string().min(1, { message: requiredMessage }),
    }),
    userData: z.object({
      name: z.string().min(1, { message: requiredMessage }),
      email: z.string().email(),
    }),
    contactDetails: z.object({
      website: z
        .string({ required_error: "Required" })
        .regex(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, {
          message: "Invalid website",
        }),
      phoneNumber: z.string().regex(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/i, {
        message:
          "Please enter the correct format: country code (+00) followed by an 8 or 9 digit phone number. ",
      }),
      rfqEmail: z.string().email(),
    }),
    location: z.object({
      address: z.string().min(1, { message: requiredMessage }),
      zipCode: z.string().min(1, { message: requiredMessage }),
      city: z.string().min(1, { message: requiredMessage }),
      countryCode: z.string().min(1, { message: requiredMessage }),
    }),
    notes: z.string().optional(),
  }),
});

export type SupplierFormType = z.infer<typeof SupplierSchema>;
