import { OfficeBuildingIcon, UserCircleIcon } from "@heroicons/react/solid";
import { useState } from "react";

import { DutyFreeDeclarationInfo } from "@web/common";
import {
  Agent,
  DeliveryMethod,
  DeliveryPort,
  Heading,
  Invoicing,
  Modal,
  PONumber,
  StorageLabel,
} from "@web/ui";

import { SupplierUiOrder } from "src/domain";

import { ContactInfoModal, DesiredDeliveryTime, RequesterContact } from "./components";

interface Props {
  className?: string;
  order: SupplierUiOrder;
  testId?: string;
}

export const ActivityBox = ({ className = "", order, testId = "" }: Props) => {
  const [isRequesterContactModalOpen, setIsRequesterContactModalOpen] = useState(false);
  const [isAgentContactModalOpen, setIsAgentContactModalOpen] = useState(false);

  const openRequesterContactModal = () => setIsRequesterContactModalOpen(true);
  const closeRequesterContactModal = () => setIsRequesterContactModalOpen(false);

  const openAgentContactModal = () => setIsAgentContactModalOpen(true);
  const closeAgentContactModal = () => setIsAgentContactModalOpen(false);

  return (
    <>
      <Modal isOpen={isRequesterContactModalOpen} closeModal={closeRequesterContactModal}>
        <ContactInfoModal
          closeModal={closeRequesterContactModal}
          title="Captain"
          Icon={UserCircleIcon}
          name={order.vessel.contactInformation?.name || ""}
          label={order.vessel.name}
          email={order.vessel.contactInformation?.email || ""}
        />
      </Modal>
      <Modal isOpen={isAgentContactModalOpen} closeModal={closeAgentContactModal}>
        <ContactInfoModal
          closeModal={closeAgentContactModal}
          title="Agent Details"
          Icon={OfficeBuildingIcon}
          name={`${order.agentInformation.firstName} ${order.agentInformation.lastName}`}
          label={order.agentInformation.company}
          email={order.agentInformation.email}
          phone={order.agentInformation.phoneNumber}
        />
      </Modal>
      <div
        className={`flex flex-col px-5 py-4 rounded-lg border bg-neutral_0 shadow ${className}`}
        data-testid={testId}
      >
        <div className="pb-4">
          <Heading size="300">Order Information</Heading>
        </div>
        <RequesterContact
          imoNumber={order.vessel.imoNumber}
          vesselName={order.vessel.name}
          onContactClick={openRequesterContactModal}
        />
        <hr />
        <div className="flex flex-col gap-3 py-3">
          <DeliveryPort portCode={order.port.locationCode} portName={order.port.name} />
          <DutyFreeDeclarationInfo dutyFreeDeclaration={order.dutyFreeDeclaration} />
          <DesiredDeliveryTime deliveryDate={order.deliveryDate} />
          {!!order.customerOrderId && <PONumber poNumber={order.customerOrderId} />}
          <Agent
            variant="base"
            agentInformation={order.agentInformation}
            onContactClick={openAgentContactModal}
          />
          {!!order.invoiceAccount && <Invoicing invoiceAccount={order.invoiceAccount} />}
          {!!order.storageLabel && <StorageLabel label={order.storageLabel} />}
          {!!order.deliveryDetails && <DeliveryMethod deliveryMethod={order.deliveryDetails} />}
        </div>
      </div>
    </>
  );
};
