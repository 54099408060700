import { DownloadIcon, DuplicateIcon, XIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import copy from "copy-to-clipboard";
import { useState } from "react";

import { TermsOfUse } from "@web/models";

import { Heading, Paragraph } from "../../../atoms/Typography";
import { CopyIcon } from "./CopyIcon";
import { MailIcon } from "./MailIcon";

interface Props {
  onClose: () => void;
  termsOfUse?: TermsOfUse;
}

const SUPPORT_EMAIL = "support@source2sea.com";

export const CustomerSupportModal = ({ onClose, termsOfUse }: Props) => {
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const shouldDisplayTermsOfUse = !!termsOfUse;

  const handleCopyLink = () => {
    copy(SUPPORT_EMAIL);

    setIsConfirmationVisible(true);
    setTimeout(() => {
      setIsConfirmationVisible(false);
    }, 4000);
  };

  return (
    <div
      className="inline-block bg-neutral_0 rounded-lg px-5 py-6 text-left shadow-xl transform transition-all my-8 align-middle relative"
      data-testid="customerSupport_modal"
    >
      <div className="absolute right-0 top-[30px] px-5">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-textIcon-blackSecondary hover:text-textIcon-blackPrimary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={onClose}
        >
          <span className="sr-only">Close modal</span>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="flex items-start pb-4">
        <div className="mt-3">
          <Heading size="200">
            Customer Support{shouldDisplayTermsOfUse && " & Terms of Use"}
          </Heading>
          <div className="mt-2">
            <Paragraph size="100">
              Having issues? Reach out to customer support to get help
            </Paragraph>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center gap-5 md:gap-8 border border-neutral_300 rounded-lg py-4 md:py-2 px-5 lg:w-[760px]">
        <MailIcon className="w-[249px] h-[122px] flex-none" />
        <div className="flex-1 relative">
          <Heading
            size="300"
            color="text-textIcon-blackSecondary"
            className="text-center md:text-left"
          >
            Email
          </Heading>
          <div className="flex">
            <Heading size="200">
              <a
                className="underline text-textIcon-blackPrimary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
                href={`mailto:${SUPPORT_EMAIL}`}
                data-testid="customerSupport_email"
              >
                {SUPPORT_EMAIL}
              </a>
            </Heading>
            <button
              type="button"
              className="ml-3 bg-neutral_0 rounded-md text-textIcon-blackSecondary hover:text-textIcon-blackPrimary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
              onClick={handleCopyLink}
              data-testid="customerSupport_termsOfUseLink"
            >
              <span className="sr-only">Copy email to clipboard</span>
              <DuplicateIcon
                className="w-4.5 text-textIcon-blackSecondary hover:text-textIcon-blackPrimary"
                aria-hidden="true"
              />
            </button>
          </div>
          <Paragraph
            size="200"
            color="text-successDefault"
            className={classnames(
              "absolute -bottom-5.5 left-0 opacity-0 transition-opacity duration-200",
              {
                "opacity-100": isConfirmationVisible,
              }
            )}
          >
            Copied to clipboard
          </Paragraph>
        </div>
      </div>
      {shouldDisplayTermsOfUse && (
        <div className="flex flex-col mt-3 md:flex-row items-center gap-5 md:gap-8 border border-neutral_300 rounded-lg py-4 md:py-2 px-5 lg:w-[760px]">
          <CopyIcon />
          <div className="flex-1 relative">
            <Heading
              size="300"
              color="text-textIcon-blackSecondary"
              className="text-center md:text-left"
            >
              Terms of Use
            </Heading>
            <div className="flex">
              <a
                target="_blank"
                href={termsOfUse?.attachmentUrl}
                className="flex items-center underline text-textIcon-blackPrimary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
                data-testid="customerSupport_copyIcon"
              >
                <Heading size="200" component="span">
                  Download Terms of Use PDF
                </Heading>
                <DownloadIcon
                  className="ml-2 w-4.5 text-textIcon-blackSecondary hover:text-textIcon-blackPrimary"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
