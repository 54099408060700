import { SupplierFormType } from "../models";

export const emptySupplierState: SupplierFormType = {
  information: {
    vendor: {
      vendorName: "",
      registrationNumber: "",
      vatNumber: "",
    },
    notes: "",
    location: {
      city: "",
      address: "",
      zipCode: "",
      countryCode: "",
    },
    userData: {
      name: "",
      email: "",
    },
    paymentData: {
      iban: "",
      swift: "",
      currency: "",
      paymentEmail: "",
      paymentTerms: "",
      paymentMethod: "",
    },
    contactDetails: {
      website: "",
      phoneNumber: "",
      rfqEmail: "",
    },
  },
};
