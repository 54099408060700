import classnames from "classnames";

import { SupplierUiOrderItem } from "src/domain";

import { OrderItemDetailsController } from "../OrderItemDetailsController";

interface Props {
  orderItems: SupplierUiOrderItem[];
  className?: string;
}

export const OrderItems: React.FC<Props> = ({ orderItems, className = "" }) => {
  return (
    <div
      className={classnames(className, "min-w-full")}
      data-testid="orderDetails_orderItemsContainer"
    >
      {orderItems.map((orderItem) => (
        <OrderItemDetailsController
          key={orderItem.id || orderItem.supplierIdentifier}
          showLineNumber={true}
          orderItem={orderItem}
          testId={`orderDetails_lineItem-${orderItem.lineNumber}`}
        />
      ))}
    </div>
  );
};
