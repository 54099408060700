import { DefaultError, useMutation } from "@tanstack/react-query";

import { AttachmentInformationChangeResponse } from "src/typegens";

import { linkAttachmentsToOrder } from "../api";
import { LinkAttachmentsToOrderParams } from "../models";

export const useLinkAttachmentsToOrderMutation = (options = {}) => {
  return useMutation<
    AttachmentInformationChangeResponse,
    DefaultError,
    LinkAttachmentsToOrderParams
  >({
    mutationKey: ["linkAttachmentsToOrder"],
    mutationFn: linkAttachmentsToOrder,
    ...options,
  });
};
