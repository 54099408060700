import { ChevronLeftIcon, DotsVerticalIcon, ThumbDownIcon } from "@heroicons/react/outline";
import { ArchiveIcon, DownloadIcon, PencilIcon } from "@heroicons/react/solid";
import { useFlag } from "@unleash/proxy-client-react";
import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import {
  Dropdown,
  DropdownItem,
  IconButton,
  Label,
  Modal,
  Paragraph,
  RegularButton,
  RegularDropdownItem,
  TextColors,
  containerPadding,
} from "@web/ui";
import { LEGACY_formatDate, triggerFileDownload } from "@web/utils";

import {
  RoutesConfig,
  RoutesParams,
  getOrderDetailsPath,
  getOrdersListPath,
} from "src/config/routes";
import { useSystemMessages } from "src/context/SystemMessages";
import {
  CloseOrderModal,
  ConfirmOrderCancellationModal,
  DeliverOrderModal,
  RejectOrderModal,
  SupplierOrderService,
  SupplierUiOrder,
  useDiscardOrderChangesMutation,
  useExportOrderToExcelMutation,
  useExportOrderToMtmlMutation,
  useUserPermissions,
} from "src/domain";

interface Props {
  order: SupplierUiOrder;
  orderVersion: number;
  invalidateOrderQueries: () => void;
  isAttachmentsPageMode?: boolean;
  className?: string;
}

const orderStatusToReadableStatusMap: ReadonlyMap<SupplierUiOrder["status"], string> = new Map([
  ["CONFIRMED", "Confirmed"],
  ["DELIVERED", "Delivered"],
  ["INCOMING", "Incoming PO"],
  ["REJECTED", "Rejected"],
  ["CANCELLATION_REQUESTED", "Cancellation Requested"],
  ["CANCELLED", "Order Cancelled"],
  ["CLOSED", "Closed"],
]);

const formatOrderStatus = (status: SupplierUiOrder["status"]): string =>
  orderStatusToReadableStatusMap.get(status) || "";

const orderStatusToColorClassMap: ReadonlyMap<SupplierUiOrder["status"], TextColors> = new Map([
  ["CONFIRMED", "text-successDefault"],
  ["DELIVERED", "text-successDefault"],
  ["REJECTED", "text-dangerDefault"],
  ["CANCELLATION_REQUESTED", "text-dangerDefault"],
  ["CANCELLED", "text-dangerDefault"],
]);

const getColorClassNameForStatus = (status: SupplierUiOrder["status"]): TextColors =>
  orderStatusToColorClassMap.get(status) || "text-textIcon-blackSecondary";

export const Topbar: React.FC<Props> = ({
  order,
  orderVersion,
  invalidateOrderQueries,
  isAttachmentsPageMode = false,
  className = "",
}) => {
  const {
    orderId,
    createdAt,
    cancellationRequestReceiveDate,
    customerName,
    customerOrderId,
    status,
    closed,
  } = order;
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [showDeliverOrderModal, setShowDeliverOrderModal] = useState(false);
  const [showCloseOrderModal, setShowCloseOrderModal] = useState(false);
  const [showConfirmCancellationModal, setShowConfirmCancellationModal] = useState(false);
  const { setSystemMessage } = useSystemMessages();
  const navigate = useNavigate();
  const { isSupervisor } = useUserPermissions();
  const isEditable = SupplierOrderService.isOrderEditable(order);

  // TODO 3819: Add E2E tests for Confirming/Rejecting order cancellation requests by Supplier
  // when these user stories become available to the Supplier Portal users, and not only to the devs.
  // These tests should consist of flow tests (like Confirming/Rejecting the order) and Topbar
  // and of Topbar component use cases (like for Rejecting Order Modal).
  const isSupplierOrderCancellationEnabled = useFlag("supplier-order-cancellation");
  const isCloseAttributeEnabled = useFlag("close-attribute");

  const exportOrderToExcelMutation = useExportOrderToExcelMutation({
    onSuccess: (file: Blob) => {
      setSystemMessage({
        type: "success",
        message: "The PO will be downloaded soon.",
      });
      triggerFileDownload({
        file,
        fileNameWithExtension: `S2S_${orderId}.xlsx`,
      });
    },
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an issue while downloading the PO. Please try again.",
      });
      // TODO #2158: Check what is logged to sentry in this case and provide some manual logging if needed
    },
  });

  const exportOrderToMtmlMutation = useExportOrderToMtmlMutation({
    onSuccess: (file: Blob) => {
      setSystemMessage({
        type: "success",
        message: "The PO will be downloaded soon.",
      });
      triggerFileDownload({
        file,
        fileNameWithExtension: `S2S_${orderId}.xml`,
      });
    },
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an issue while downloading the PO. Please try again.",
      });
      // TODO #2158: Check what is logged to sentry in this case and provide some manual logging if needed
    },
  });

  const shouldShowCancellationRequestDate =
    !!cancellationRequestReceiveDate &&
    (SupplierOrderService.isOrderCancellationRequested(order) ||
      SupplierOrderService.isOrderCancelled(order));

  const onEditOrder = () => {
    navigate(
      generatePath(RoutesConfig.orderEditVersion, {
        [RoutesParams.ORDER_ID]: orderId,
        [RoutesParams.ORDER_VERSION_ID]: orderVersion.toString(),
      })
    );
  };

  const dropdownItems: DropdownItem[] = [
    // TODO #8551: If we decide to rely on the `editable` property as the only condition for deciding
    // if the order is editable (disregarding the order status), then we can remove this check:
    // `SupplierOrderService.isOrderIncoming(order)`
    // This may come earlier, when we introduce editing of the CONFIRMED orders.
    ...(!isSupervisor && isEditable && SupplierOrderService.isOrderIncoming(order)
      ? [
          {
            key: "editOrder",
            renderComponent: () => (
              <RegularDropdownItem
                label="Edit Order"
                variant="basic"
                LeadingIcon={PencilIcon}
                onClick={onEditOrder}
                data-testid="editOrderDropdownItem"
              />
            ),
          },
        ]
      : []),
    ...(isCloseAttributeEnabled &&
    !isSupervisor &&
    SupplierOrderService.isOrderDelivered(order) &&
    !SupplierOrderService.isOrderClosed(order)
      ? [
          {
            key: "closeOrder",
            renderComponent: () => (
              <RegularDropdownItem
                label="Close Order"
                variant="basic"
                LeadingIcon={ArchiveIcon}
                onClick={() => {
                  setShowCloseOrderModal(true);
                }}
                data-testid="closeOrderDropdownItem"
              />
            ),
          },
        ]
      : []),
    {
      key: "downloadPoAsExcel",
      renderComponent: () => (
        <RegularDropdownItem
          label="Download PO as XLSX"
          variant="basic"
          LeadingIcon={DownloadIcon}
          onClick={() => {
            if (exportOrderToExcelMutation.isPending) {
              return;
            }

            exportOrderToExcelMutation.mutate({ s2SOrderId: orderId, version: orderVersion });
          }}
          data-testid="downloadPoAsExcelDropdownItem"
        />
      ),
    },
    {
      key: "downloadPoAsXml",
      renderComponent: () => (
        <RegularDropdownItem
          label="Download PO as MTML"
          variant="basic"
          LeadingIcon={DownloadIcon}
          onClick={() => {
            if (exportOrderToMtmlMutation.isPending) {
              return;
            }

            exportOrderToMtmlMutation.mutate({ s2SOrderId: orderId, version: orderVersion });
          }}
          data-testid="downloadPoAsXmlDropdownItem"
        />
      ),
    },
  ];

  const discardOrderChangesAndConfirmMutation = useDiscardOrderChangesMutation({
    onSuccess: () => {
      navigate(generatePath(RoutesConfig.confirmOrder, { [RoutesParams.ORDER_ID]: orderId }));
    },
  });

  const onConfirmOrder = () => {
    if (discardOrderChangesAndConfirmMutation.isPending) {
      return;
    }
    discardOrderChangesAndConfirmMutation.mutate({ s2SOrderId: orderId });
  };

  const handleOrderDeliverySuccess = () => {
    invalidateOrderQueries();
  };

  const handleOrderClosureSuccess = () => {
    invalidateOrderQueries();
  };

  return (
    <div
      className={`bg-neutral_0 border-b-1 border-neutral_300 ${className}`}
      data-testid="orderDetailsHeader"
    >
      <div className={`${containerPadding} flex py-4 items-center justify-between`}>
        <div className="flex items-center">
          <IconButton
            size="large"
            variant="secondary"
            shape="circle"
            label="Go back"
            onClick={() =>
              navigate(
                isAttachmentsPageMode
                  ? getOrderDetailsPath(orderId)
                  : getOrdersListPath({ status, closed })
              )
            }
            Icon={ChevronLeftIcon}
            data-testid="orderDetails_goBackCircleButton"
          />
          <div className="flex-col">
            <div className="flex items-baseline pl-4.5">
              <Label size="100">{customerName}</Label>
              {!!customerOrderId && (
                <Label
                  size="200"
                  color="text-textIcon-blackPrimary"
                  className="ml-4"
                  data-testid="orderDetailsHeader_internalPo"
                >
                  Internal PO: {customerOrderId}
                </Label>
              )}
            </div>
            <div className="flex pt-2 pl-4.5">
              <Paragraph
                size="300"
                color={getColorClassNameForStatus(status)}
                data-testid="orderDetailsHeader_orderStatus"
              >
                {formatOrderStatus(status)}
              </Paragraph>
              <Paragraph size="300" color="text-textIcon-blackSecondary" className="pl-4">
                Order Date: <span className="font-semibold">{LEGACY_formatDate(createdAt)}</span>
              </Paragraph>
              {!shouldShowCancellationRequestDate && (
                <Paragraph
                  size="300"
                  color="text-textIcon-blackSecondary"
                  className="pl-4"
                  data-testid="orderDetailsHeader_orderId"
                >
                  Order ID: <span className="font-semibold">{orderId}</span>
                </Paragraph>
              )}
              {shouldShowCancellationRequestDate && (
                <Paragraph
                  size="300"
                  color="text-textIcon-blackSecondary"
                  className="pl-4"
                  data-testid="orderDetailsHeader_cancelledByBuyer"
                >
                  Cancelled by buyer:{" "}
                  <span className="font-semibold">
                    {LEGACY_formatDate(cancellationRequestReceiveDate)}
                  </span>
                </Paragraph>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-2">
          {SupplierOrderService.isOrderIncoming(order) && !isAttachmentsPageMode && !isSupervisor && (
            <>
              <RegularButton
                variant="secondary"
                size="large"
                label="Reject"
                LeadingIcon={ThumbDownIcon}
                onClick={() => {
                  setShowRejectionModal(true);
                }}
                data-testid="rejectOrderButton"
              />
              <RegularButton
                variant="primary"
                size="large"
                label="Continue"
                loading={discardOrderChangesAndConfirmMutation.isPending}
                onClick={onConfirmOrder}
                data-testid="confirmOrderButton"
              />
              <Modal isOpen={showRejectionModal} closeModal={() => setShowRejectionModal(false)}>
                <RejectOrderModal
                  orderId={orderId}
                  orderVersion={orderVersion}
                  closeModal={() => setShowRejectionModal(false)}
                />
              </Modal>
            </>
          )}
          {isSupplierOrderCancellationEnabled &&
            !isSupervisor &&
            SupplierOrderService.isOrderCancellationRequested(order) &&
            !isAttachmentsPageMode && (
              <>
                <RegularButton
                  variant="secondary"
                  size="large"
                  label="Reject order cancellation"
                  onClick={() =>
                    navigate(
                      generatePath(RoutesConfig.confirmOrder, { [RoutesParams.ORDER_ID]: orderId })
                    )
                  }
                  data-testid="rejectOrderCancellation"
                />
                <RegularButton
                  variant="secondary"
                  size="large"
                  label="Confirm order cancellation"
                  onClick={() => {
                    setShowConfirmCancellationModal(true);
                  }}
                  data-testid="confirmOrderCancellation"
                />
                <Modal
                  isOpen={showConfirmCancellationModal}
                  closeModal={() => setShowConfirmCancellationModal(false)}
                >
                  <ConfirmOrderCancellationModal
                    orderId={orderId}
                    orderVersion={orderVersion}
                    closeModal={() => setShowConfirmCancellationModal(false)}
                  />
                </Modal>
              </>
            )}
          {isCloseAttributeEnabled &&
            SupplierOrderService.isOrderConfirmed(order) &&
            !isAttachmentsPageMode &&
            !isSupervisor && (
              <>
                <RegularButton
                  variant="primary"
                  size="large"
                  label="Mark As Delivered"
                  onClick={() => {
                    setShowDeliverOrderModal(true);
                  }}
                  data-testid="deliverOrderButton"
                />
                <Modal
                  isOpen={showDeliverOrderModal}
                  closeModal={() => setShowDeliverOrderModal(false)}
                >
                  <DeliverOrderModal
                    orderId={orderId}
                    orderVersion={orderVersion}
                    closeModal={() => setShowDeliverOrderModal(false)}
                    onDeliverySuccess={handleOrderDeliverySuccess}
                  />
                </Modal>
              </>
            )}
          {!isAttachmentsPageMode && dropdownItems.length > 0 && (
            <>
              <Dropdown hAlignTo="end" vAlignTo="bottom" items={dropdownItems}>
                <IconButton
                  size="large"
                  variant="secondary"
                  shape="square"
                  label="More actions"
                  Icon={DotsVerticalIcon}
                  data-testid="moreButton"
                  isControlled
                />
              </Dropdown>
              <Modal isOpen={showCloseOrderModal} closeModal={() => setShowCloseOrderModal(false)}>
                <CloseOrderModal
                  orderId={orderId}
                  orderVersion={orderVersion}
                  closeModal={() => setShowCloseOrderModal(false)}
                  onClosureSuccess={handleOrderClosureSuccess}
                />
              </Modal>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
