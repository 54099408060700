import { SupplierPortalAppService } from "src/typegens";

import { ExportOrderToMtmlParams } from "../models";

export const exportOrderToMtml = async ({
  s2SOrderId,
  version,
}: ExportOrderToMtmlParams): Promise<Blob> => {
  const file: Blob = await SupplierPortalAppService.exportOrderByIdToMtml(
    {
      s2SOrderId,
      version,
    },
    {
      headers: {
        Accept: "*/*",
      },
    }
  );

  return file;
};
