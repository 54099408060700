import { forwardRef } from "react";

import { RegularChildren } from "./RegularChildren";
import { RegularCommonProps } from "./models";
import { useRegularCommons } from "./useRegularCommons";

/**
 * This type merges:
 * - the common props (like `variant`, `label`, `size` etc.),
 * - the native props of the main tag (<button> tag in this case),
 * - and sets more rigid typechecking for the `onClick` prop, so it is required when the button
 *   type is just `button`. It is not required when the button type is `submit` or `reset`, or when
 *   an arbitrary `isControlled` prop is passed (this can be used for button wrappers setting
 *   onClick events via ref. Please see usage examples in the apps).
 */
export type RegularButtonProps = RegularCommonProps &
  Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    "children" | "onClick" | keyof RegularCommonProps
  > &
  (
    | {
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        type?: "button";
      }
    | {
        onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        type: "submit" | "reset";
      }
    | {
        onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        isControlled: true;
      }
  );

export const RegularButton = forwardRef<HTMLButtonElement, RegularButtonProps>(
  (props, forwardedRef) => {
    const { mainTagCommonProps } = useRegularCommons<HTMLButtonElement>(props);
    // Removing illegal props so only valid ones are applied to the HTML tag
    const {
      type = "button",
      size,
      variant,
      label,
      width,
      loading,
      LeadingIcon,
      TrailingIcon,
      isControlled,
      ...buttonTagProps
    } = props;

    return (
      <button {...buttonTagProps} {...mainTagCommonProps} ref={forwardedRef} type={type}>
        <RegularChildren {...props} />
      </button>
    );
  }
);
RegularButton.displayName = "RegularButton";
