import { AdminApp } from "src/admin";
import { useAppScopes } from "src/hooks/useAppScopes";
import { Routes } from "src/routes";

export const ApplicationSwitch: React.FC = () => {
  const isNonAdminUser = useAppScopes("read:supplierOrders");
  const isAdminUser = useAppScopes("read:suppliers", "write:suppliers");

  if (isNonAdminUser) {
    return <Routes />;
  }

  if (isAdminUser) {
    return <AdminApp />;
  }

  return <div data-testid="not-matched">Application not matched for this user</div>;
};
