type Params = { file: Blob; fileNameWithExtension: string };

export const triggerFileDownload = ({ file, fileNameWithExtension }: Params) => {
  const fileObjectUrl = window.URL.createObjectURL(file);
  const element = document.createElement("a");
  element.href = fileObjectUrl;
  element.download = fileNameWithExtension;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  element.remove();
  window.URL.revokeObjectURL(fileObjectUrl);
};
