import { useAuth0 } from "@auth0/auth0-react";

export const useAuth0WithRedirect = () => {
  const auth0 = useAuth0();

  const loginWithRedirectToOriginalRoute = () =>
    auth0.loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });

  return {
    ...auth0,
    loginWithRedirectToOriginalRoute,
  };
};
