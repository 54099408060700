import { forwardRef } from "react";

import { IconChildren } from "./IconChildren";
import { IconCommonProps } from "./models";
import { useIconCommons } from "./useIconCommons";

/**
 * This type merges:
 * - the common props (like `variant`, `label`, `size` etc.),
 * - the native props of the main tag (<a> tag in this case),
 * - and sets more rigid typechecking for the `onClick` prop, so either `href` or `onClick` is required.
 *
 * Please note that the <a> tag, in order to present the outline upon focus, needs to have a `href` attribute.
 */
export type IconAnchorProps = IconCommonProps &
  Omit<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    "children" | "href" | "onClick" | keyof IconCommonProps
  > &
  (
    | {
        href: string;
        onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
      }
    | {
        href?: string;
        onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
      }
  );

export const IconAnchor = forwardRef<HTMLAnchorElement, IconAnchorProps>((props, forwardedRef) => {
  const { mainTagCommonProps, defaultedProps } = useIconCommons<HTMLAnchorElement>(props);
  // Removing illegal props so only valid ones are applied to the HTML tag
  const { size, variant, shape, label, Icon, disabled, loading, ...anchorTagProps } = props;

  return (
    <a
      {...anchorTagProps}
      {...mainTagCommonProps}
      ref={forwardedRef}
      tabIndex={defaultedProps.disabled ? -1 : undefined}
    >
      <IconChildren {...props} />
    </a>
  );
});
IconAnchor.displayName = "IconAnchor";
