import ExclamationCircleIcon from "@heroicons/react/solid/ExclamationCircleIcon";
import { ChangeEvent, forwardRef } from "react";

import { Label, Paragraph } from "../Typography";

export interface LEGACY_InputProps {
  label?: string;
  errorMessage?: string;
  prefixIcon?: React.ReactElement;
  leftBadge?: string;
  inputClasses?: string;
  onInputChange?: (value: string) => void;
  testId?: string;
}

export const LEGACY_Input = forwardRef<
  HTMLInputElement,
  LEGACY_InputProps & React.InputHTMLAttributes<HTMLInputElement>
>(
  (
    {
      label,
      placeholder,
      errorMessage,
      onInputChange,
      value,
      disabled,
      className,
      prefixIcon,
      leftBadge,
      inputClasses,
      id,
      type,
      testId,
      ...rest
    },
    ref
  ) => {
    const onChangeLocal = (e: ChangeEvent<HTMLInputElement>) => {
      onInputChange && onInputChange((e.target as HTMLInputElement).value);
    };

    const borderColor = errorMessage ? "border-dangerDefault" : "border-neutral_300";
    const leftPadding = prefixIcon ? "pl-0" : "pl-3";
    const rightPadding = errorMessage ? "pr-6" : "pr-3";

    return (
      <div
        {...(testId && { "data-testid": `${testId}_container` })}
        className={`flex flex-col ${className ? className : "pt-4"}`}
        ref={ref}
      >
        {!!label && (
          <Label
            className="mb-1"
            color="text-textIcon-blackSecondary"
            htmlFor={id || label}
            id={id || label}
            size="200"
          >
            {label}
          </Label>
        )}
        <div className="relative flex shadow-sm w-full border border-solid rounded-md items-center overflow-hidden bg-neutral_0 h-6.5">
          {prefixIcon && <div className="pointer-events-none h-4 w-4 mx-2">{prefixIcon}</div>}
          {!!leftBadge && (
            <div className="flex items-center pointer-events-none px-2 border-r h-full bg-neutral_100">
              <Paragraph size="200">{leftBadge}</Paragraph>
            </div>
          )}
          <input
            type={type || "text"}
            id={id || label}
            name={id || label}
            className={`text-sm leading-5 font-normal w-full focus:outline-none h-full ${leftPadding} ${rightPadding} ${borderColor} ${
              disabled ? "bg-neutral_200" : ""
            } ${errorMessage ? "invalid" : ""} ${inputClasses || ""}`}
            placeholder={placeholder}
            onChange={onChangeLocal}
            value={value}
            disabled={disabled}
            {...(testId && { "data-testid": `${testId}_input` })}
            {...rest}
          />
          {!!errorMessage && (
            <div className="absolute right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon className="h-5 w-5 text-dangerDefault" aria-hidden="true" />
            </div>
          )}
        </div>
        <Paragraph size="200" color="text-dangerDefault" className="h-4">
          {errorMessage}
        </Paragraph>
      </div>
    );
  }
);

LEGACY_Input.displayName = "LEGACY_Input";
