import { useMemo } from "react";

import { Money } from "@web/models";
import { Label, Paragraph } from "@web/ui";

import { SupplierOrderService } from "../../services";

interface Props {
  isRemoved?: boolean;
  itemTotal: Money;
  unitPrice: Money;
  measurementUnit: string;
}

export const OrderItemPriceTotals: React.FC<Props> = ({
  isRemoved = false,
  itemTotal,
  unitPrice,
  measurementUnit,
}) => {
  const itemTotalFormatted: string = useMemo(
    () => SupplierOrderService.formatOrderItemTotal(itemTotal),
    [itemTotal]
  );
  const unitPriceWithUnitFormatted: string = useMemo(
    () => SupplierOrderService.formatUnitPriceWithUnit(unitPrice, measurementUnit),
    [unitPrice, measurementUnit]
  );
  return (
    <div className="text-right" data-testid="orderItemPriceTotals">
      <Label size="100" className={isRemoved ? "line-through" : ""}>
        {itemTotalFormatted}
      </Label>
      <Paragraph size="300" color="text-textIcon-blackSecondary">
        {unitPriceWithUnitFormatted}
      </Paragraph>
    </div>
  );
};
