import { useUnleashContext } from "@unleash/proxy-client-react";
import { useEffect } from "react";

import { useAuth0WithRedirect, useFeatureFlagsManagement } from "@web/common";

export const UnleashContextSetup = () => {
  const { user } = useAuth0WithRedirect();
  const { unleashContextFlagProperties } = useFeatureFlagsManagement();
  const updateContext = useUnleashContext();

  useEffect(() => {
    updateContext({ userId: user?.email, properties: unleashContextFlagProperties });
  }, [user?.email, unleashContextFlagProperties, updateContext]);

  return null;
};
