import { ChangeEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Paragraph, RegularButton, Textarea } from "@web/ui";

import { RoutesConfig } from "src/config/routes";
import { useSystemMessages } from "src/context/SystemMessages";

import { useOrderConfirmCancellationMutation } from "../../hooks";

interface Props {
  orderId: string;
  orderVersion: number;
  closeModal: () => void;
}

export const ConfirmOrderCancellationModal: React.FC<Props> = ({
  orderId,
  orderVersion,
  closeModal,
}) => {
  const [comment, setComment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { setSystemMessage } = useSystemMessages();

  const handleCommentChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setComment(e.target.value);
  };

  const confirmOrderCancellationMutation = useOrderConfirmCancellationMutation({
    onSuccess: () => {
      setSystemMessage({
        type: "success",
        message: "Order cancellation confirmed.",
      });
      closeModal();
      navigate(RoutesConfig.home);
    },
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an error.",
      });
      closeModal();
      // TODO #2158: Check what is logged to sentry in this case and provide some manual logging if needed
      // TODO #2159: Decide on error handling apart of just displaying error message
    },
  });

  const handleSubmit = () => {
    if (!comment) {
      return setErrorMessage("This field is mandatory.");
    }

    confirmOrderCancellationMutation.mutate({
      s2SOrderId: orderId,
      requestBody: { comment, version: orderVersion },
    });
  };

  const isModalBlocked =
    confirmOrderCancellationMutation.isPending || confirmOrderCancellationMutation.isSuccess;

  return (
    <div
      className="inline-block align-bottom bg-neutral_0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full divide-y"
      data-testid="confirmOrderCancellationModal"
    >
      <div className="py-4.5 px-5">
        <Paragraph size="100">Confirm Order Cancellation</Paragraph>
        <div className="mt-2">
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            Please add a comment.
          </Paragraph>
        </div>
      </div>
      <div className="px-5 py-2">
        <Textarea
          errorMessage={errorMessage}
          onChange={handleCommentChange}
          value={comment}
          data-testid="confirmOrderCancellationModal_commentInput"
        />
      </div>
      <div className="flex flex-row-reverse gap-3 px-5 py-3 bg-neutral_100">
        <RegularButton
          variant="primary"
          size="large"
          label="Confirm Order Cancellation"
          onClick={handleSubmit}
          disabled={isModalBlocked}
          loading={confirmOrderCancellationMutation.isPending}
          data-testid="confirmOrderCancellationModal_confirmButton"
        />
        <RegularButton
          variant="secondary"
          size="large"
          label="Cancel"
          onClick={closeModal}
          disabled={isModalBlocked}
          data-testid="confirmOrderCancellationModal_cancelButton"
        />
      </div>
    </div>
  );
};
