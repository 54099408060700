import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { RoutesParams } from "src/config/routes";

import { getOrderVersionFromParam } from "../../utils";
import { OrderEditFormQuery } from "./components";

export const OrderEdit: React.FC = () => {
  const { [RoutesParams.ORDER_ID]: orderId, [RoutesParams.ORDER_VERSION_ID]: rawOrderVersionId } =
    useParams() as { orderId: string; orderVersionId?: string };

  // Never update orderVersionId during the component lifecycle so queries do not refetch
  const orderVersionId = useMemo(
    () => getOrderVersionFromParam(rawOrderVersionId, "OLDEST"),
    // We do not want to recalculate when order version in path changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <OrderEditFormQuery orderId={orderId} orderVersionId={orderVersionId} />;
};
