import { useQuery } from "@tanstack/react-query";

import { AssigneeInformation } from "src/typegens";

import { getSupplierUsers } from "../api";

export const useSupplierUsersQuery = (
  {
    orderId,
  }: {
    orderId: string;
  },
  options = {}
) => {
  return useQuery<AssigneeInformation[]>({
    queryKey: ["supplierUsers", orderId],
    queryFn: () => getSupplierUsers({ orderId }),
    ...options,
  });
};
