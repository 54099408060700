import { Paragraph } from "@web/ui";

interface Props {
  message: string;
  closeModal: () => void;
}

export const SuccessModal = ({ message, closeModal }: Props) => {
  return (
    <div
      className="inline-block align-bottom bg-neutral_0 rounded-lg py-6 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
      style={{ width: "350px" }}
    >
      <div className="absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="sr-only bg-neutral_0 rounded-md text-text-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={closeModal}
        >
          <span className="sr-only">Close modal</span>
        </button>
      </div>
      <div className="flex flex-col items-center">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 20C0 8.95431 8.95431 0 20 0V0C31.0457 0 40 8.95431 40 20V20C40 31.0457 31.0457 40 20 40V40C8.95431 40 0 31.0457 0 20V20Z"
            fill="#6EE7B7"
          />
          <path
            d="M14.167 20.8335L17.5003 24.1668L25.8337 15.8335"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <Paragraph size="100" className="mt-4 px-6 text-center">
          {message}
        </Paragraph>
      </div>
    </div>
  );
};
