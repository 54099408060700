import { Tab as HeadlessTab } from "@headlessui/react";
import classnames from "classnames";
import { Fragment, useCallback, useState } from "react";

import { Tab, TabProps } from "./Tab";

export type TabItem = Pick<TabProps, "label" | "LeadingIcon" | "data-testid"> & {
  id: string;
  children?: React.ReactNode;
};

export type TabsProps = React.HTMLAttributes<HTMLDivElement> & {
  items: TabItem[];
  size: "small" | "large";
  side?: "left" | "right";
  initialTabId?: string;
  onTabChange?: (selectedTabId?: string) => void;
} & (
    | {
        variant: "line" | "pill";
        orientation?: "horizontal" | "vertical";
      }
    | {
        variant: "folder";
        orientation?: "horizontal";
      }
  );

const getBaseStyles = (orientation: TabsProps["orientation"], variant: TabsProps["variant"]) => {
  const commonStyles = "flex";
  const orientationStyles =
    orientation === "vertical" && variant !== "folder" ? "flex-col w-fit" : "flex-row";
  return `${commonStyles} ${orientationStyles}`;
};

const getVariantSizeStyles = (
  variant: TabsProps["variant"],
  size: TabsProps["size"],
  orientation: TabsProps["orientation"]
) => {
  if (variant === "folder") {
    return "gap-1";
  }

  if (variant === "line") {
    return size === "small" ? "gap-4" : "gap-5";
  }

  if (variant === "pill") {
    if (orientation === "horizontal") {
      return size === "small" ? "gap-1" : "gap-4";
    }
    if (orientation === "vertical") {
      return size === "small" ? "gap-2" : "gap-4";
    }
  }

  return "";
};

const getVariantBorderStyles = (
  variant: TabsProps["variant"],
  side: TabsProps["side"],
  orientation: TabsProps["orientation"]
) => {
  let borderColor = "border-neutral_300";
  let variantClasses = "";

  if (variant === "folder") {
    variantClasses = "border-b-1";
  }

  if (variant === "line") {
    if (orientation === "horizontal") {
      variantClasses = "border-b-1";
    }
    if (orientation === "vertical") {
      variantClasses = side === "left" ? "border-r-1" : "border-l-1";
    }
  }

  if (variant === "pill") {
    borderColor = "border-transparent";
  }

  return classnames(borderColor, variantClasses);
};

const getTabBorderPosition = (
  orientation: TabsProps["orientation"],
  variant: TabsProps["variant"],
  side?: "left" | "right"
): TabProps["borderPosition"] => {
  if (orientation === "horizontal" || variant === "folder") {
    return "bottom";
  }

  return side === "right" ? "left" : "right";
};

const getContentPosition = (
  orientation: TabsProps["orientation"],
  variant: TabsProps["variant"],
  side?: "left" | "right"
) => {
  return orientation === "horizontal" || variant === "folder"
    ? ""
    : classnames("flex", { "flex-row-reverse": side === "right" });
};

export const Tabs = ({
  variant,
  size,
  orientation = "horizontal",
  items,
  side = "left",
  initialTabId,
  onTabChange = () => {
    /* noop */
  },
  // Destruct so it is not set on the `div` element along the `rest` props
  className = "",
  ...rest
}: TabsProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(() => {
    const initialTabIndex = items.findIndex((tab) => tab.id === initialTabId);
    return initialTabId && initialTabIndex !== -1 ? initialTabIndex : 0;
  });

  const baseStyles = getBaseStyles(orientation, variant);
  const variantSizeStyles = getVariantSizeStyles(variant, size, orientation);
  const variantBorderStyles = getVariantBorderStyles(variant, side, orientation);
  const tabBorderPosition = getTabBorderPosition(orientation, variant, side);
  const contentPosition = getContentPosition(orientation, variant, side);

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => {
      setSelectedTabIndex(selectedTabIndex);

      const selectedTabId = items[selectedTabIndex]?.id;
      onTabChange(selectedTabId);
    },
    [items, onTabChange]
  );

  const anyChildNotEmpty = items.some((tab) => !!tab.children);

  return (
    <div className={classnames(className, contentPosition)} {...rest}>
      <HeadlessTab.Group selectedIndex={selectedTabIndex} onChange={handleTabChange}>
        {/* Small padding and negative margin are used to prevent focus ring from clipping due to overflow settings */}
        <div className="p-0.5 -m-0.5 overflow-x-auto overflow-y-hidden">
          <HeadlessTab.List
            className={classnames(baseStyles, variantSizeStyles, variantBorderStyles)}
          >
            {items.map((tab) => (
              <Tab
                key={tab.id}
                label={tab.label}
                LeadingIcon={tab.LeadingIcon}
                variant={variant}
                size={size}
                borderPosition={tabBorderPosition}
                data-testid={tab["data-testid"]}
              />
            ))}
          </HeadlessTab.List>
        </div>
        {anyChildNotEmpty && (
          <HeadlessTab.Panels as={Fragment}>
            {items.map((tab, index) => (
              <HeadlessTab.Panel key={index}>{tab.children}</HeadlessTab.Panel>
            ))}
          </HeadlessTab.Panels>
        )}
      </HeadlessTab.Group>
    </div>
  );
};
