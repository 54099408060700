/**
 * @param amount A number or a string representing the number with commas (,) as thousands
 *               separators and dot (.) as a decimal separator. May contain a currency code
 *               as a suffix with a space before it. Example: `1,000.00 USD`.
 *               However, it also passes numbers nested in a string, e.g. `abcdef100`,
 *               so it should not be treated as a validator.
 */
export const preprocessFormAmountValue = (amount: unknown): number | undefined => {
  if (typeof amount === "number") {
    return amount;
  } else if (typeof amount !== "string") {
    return undefined;
  }

  // Require fixed two decimal digits, assume a dot is used as decimal separator
  const amountRegex = /^-?\d+(\.\d{2})?$/;
  // 1. Remove thousands separators - assume they are commas
  // 2. Remove currency code if present - assume suffix: space + 3 letters
  // 3. Trim
  const cleanAmount = amount.replace(/[,a-z\s]/gi, "").trim();
  const leadingZeroAmount = cleanAmount.startsWith(".") ? `0${cleanAmount}` : cleanAmount;

  if (!amountRegex.test(leadingZeroAmount)) {
    return undefined;
  }

  return parseFloat(leadingZeroAmount);
};
