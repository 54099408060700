import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import { SupplierForm, SupplierFormType, SupplierSchema, emptySupplierState } from "src/domain";
import { SupplierResponse } from "src/typegens";

export const CompanyProfileForm = ({
  supplierInformationData,
}: {
  supplierInformationData: SupplierResponse;
}) => {
  const defaultForm = !supplierInformationData.information
    ? emptySupplierState
    : (supplierInformationData as SupplierFormType);

  const form = useForm<SupplierFormType>({
    defaultValues: defaultForm,
    resolver: zodResolver(SupplierSchema),
  });

  return (
    <FormProvider {...form}>
      <SupplierForm formMode="companyProfile" readonly />
    </FormProvider>
  );
};
