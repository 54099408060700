import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { NavigationPrompt } from "@web/common";
import { Heading, RegularButton } from "@web/ui";

import { AdminEditDiscardChangesConfirmationModal } from "src/admin/components";
import { RoutesConfig } from "src/admin/config/routes";
import { useSystemMessages } from "src/context/SystemMessages";
import {
  SupplierForm,
  SupplierFormType,
  SupplierSchema,
  emptySupplierState,
  useCreateSupplierMutation,
} from "src/domain";

export const NewSupplierForm: React.FC = () => {
  const navigate = useNavigate();
  const { setSystemMessage } = useSystemMessages();
  const [wasSupplierAdded, setWasSupplierAdded] = useState<boolean>(false);

  const form = useForm<SupplierFormType>({
    defaultValues: emptySupplierState,
    resolver: zodResolver(SupplierSchema),
  });

  const createMutation = useCreateSupplierMutation({
    onSuccess: (data: SupplierFormType) => {
      setWasSupplierAdded(true);
      form.reset(data);
      /**
       * Needs to be deferred for until the component re-renders with updated formState
       * that so NavigationPrompt won't activate.
       */
      setTimeout(() => navigate(RoutesConfig.supplierAdded), 0);
    },
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an error when creating a new Supplier",
      });
    },
  });

  const isPending = createMutation.isPending;
  const { isDirty, errors } = form.formState;
  const areCTAsBlocked = isPending || Object.keys(errors)?.length !== 0 || wasSupplierAdded;

  const onSubmit = () =>
    form
      .handleSubmit((data) => {
        createMutation.mutate({
          supplierBody: data,
        });
      })()
      .catch(console.error);

  return (
    <>
      <NavigationPrompt
        isConfirmationRequired={isDirty}
        renderNavigationPrompt={({ isActive, cancel, confirm }) => (
          <AdminEditDiscardChangesConfirmationModal
            isOpen={isActive}
            closeModal={cancel as () => void}
            onConfirm={confirm as () => void}
            onClose={cancel as () => void}
          />
        )}
      />
      <div>
        <div className="max-w-[1024px] mx-auto px-8 py-6 mb-10">
          <FormProvider {...form}>
            <Heading size="300" className="pb-5">
              New Supplier
            </Heading>
            <SupplierForm />
            <div className="flex float-right">
              <RegularButton
                className="ml-6"
                variant="secondary"
                size="large"
                label="Cancel"
                onClick={() => navigate(RoutesConfig.home)}
                disabled={isPending}
                data-testid="cancelNewSupplierButton"
              />
              <RegularButton
                className="ml-6"
                variant="primary"
                size="large"
                label="Add supplier"
                onClick={onSubmit}
                loading={isPending}
                disabled={areCTAsBlocked}
                data-testid="submitNewSupplierButton"
              />
            </div>
          </FormProvider>
        </div>
      </div>
    </>
  );
};
