import { OrderVersionResponse, SupplierPortalAppService } from "src/typegens";

import { SupplierRejectOrderParams } from "../models";

export const rejectOrder = async ({
  s2SOrderId,
  requestBody,
}: SupplierRejectOrderParams): Promise<OrderVersionResponse> => {
  const result = await SupplierPortalAppService.rejectOrder({ s2SOrderId, requestBody });

  return result;
};
