import { Avatar } from "../../../molecules/Avatar";
import { AvatarCommonProps } from "./models";
import { useAvatarCommons } from "./useAvatarCommons";

export const AvatarChildren = (props: AvatarCommonProps) => {
  const { defaultedProps } = useAvatarCommons<unknown>(props);
  const { "data-testid": testId } = defaultedProps;

  return (
    <Avatar
      {...defaultedProps.avatarProps}
      {...(testId ? { "data-testid": `${testId}_avatar` } : {})}
    />
  );
};
