export const CheckboxCheckedIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.75 15.2402C1.41667 15.2402 1.125 15.1152 0.875 14.8652C0.625 14.6152 0.5 14.3236 0.5 13.9902V1.49023C0.5 1.1569 0.625 0.865234 0.875 0.615234C1.125 0.365234 1.41667 0.240234 1.75 0.240234H14.25C14.5833 0.240234 14.875 0.365234 15.125 0.615234C15.375 0.865234 15.5 1.1569 15.5 1.49023V13.9902C15.5 14.3236 15.375 14.6152 15.125 14.8652C14.875 15.1152 14.5833 15.2402 14.25 15.2402H1.75ZM1.75 13.9902H14.25V1.49023H1.75V13.9902ZM6.72917 10.8027C6.8125 10.8027 6.88889 10.7888 6.95833 10.7611C7.02778 10.7333 7.09722 10.6847 7.16667 10.6152L12.3125 5.4694C12.4236 5.35829 12.4826 5.21246 12.4896 5.0319C12.4965 4.85135 12.4375 4.69857 12.3125 4.57357C12.1875 4.44857 12.0382 4.38607 11.8646 4.38607C11.691 4.38607 11.5417 4.44857 11.4167 4.57357L6.72917 9.26107L4.6875 7.2194C4.57639 7.10829 4.43403 7.04926 4.26042 7.04232C4.08681 7.03537 3.9375 7.0944 3.8125 7.2194C3.6875 7.3444 3.625 7.49371 3.625 7.66732C3.625 7.84093 3.6875 7.99023 3.8125 8.11523L6.29167 10.6152C6.36111 10.6847 6.43056 10.7333 6.5 10.7611C6.56944 10.7888 6.64583 10.8027 6.72917 10.8027Z"
        fill="#1D2433"
        fillOpacity="0.6"
      />
    </svg>
  );
};
