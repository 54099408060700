import { AttachmentMetadataResponse } from "@web/attachments";
import { WithOptionalProperty } from "@web/utils";

import { AttachmentForm, Categories, Attachment } from "../models";

export const AttachmentsService = {
  /**
   * Converters
   */
  convertFromApiAttachmentToFormAttachment: (
    apiAttachment: AttachmentMetadataResponse
  ): WithOptionalProperty<AttachmentForm, "category"> => ({
    ...apiAttachment,
    createdDate: new Date(apiAttachment.createdDate),
  }),
  convertFromFormAttachmentToAttachment: (attachment: AttachmentForm): Attachment => ({
    id: attachment.id,
    name: attachment.name,
    size: attachment.size,
    // We guard against submission without a category, so we can be sure that this field always has a proper value
    category: attachment.category as Categories,
    createdBy: attachment.createdBy,
    createdDate: attachment.createdDate,
  }),

  /**
   * Formatters
   */
  formatAcceptedFileFormats: (fileFormats: string[]): string =>
    fileFormats.join(", ").replace(/\./g, "").toUpperCase(),

  /**
   * Utils
   */
  findAttachmentAndItsIndex: (
    attachments: AttachmentForm[],
    uploadId: string
  ): { attachment: AttachmentForm | undefined; attachmentIndex: number | undefined } => {
    const attachmentToFind = attachments.find((attachment) => attachment.uploadId === uploadId);

    if (!attachmentToFind) {
      return {
        attachment: undefined,
        attachmentIndex: undefined,
      };
    }

    const foundAttachmentIndex = attachments.findIndex(
      (attachment) => attachment === attachmentToFind
    );

    return {
      attachment: attachmentToFind,
      attachmentIndex: foundAttachmentIndex,
    };
  },
};
