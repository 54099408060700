import { ThumbDownIcon } from "@heroicons/react/outline";
import { ChangeEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Paragraph, RegularButton, Textarea } from "@web/ui";

import { RoutesConfig } from "src/config/routes";
import { useSystemMessages } from "src/context/SystemMessages";

import { useOrderRejectMutation } from "../../hooks";

interface Props {
  orderId: string;
  orderVersion: number;
  closeModal: () => void;
}

export const RejectOrderModal: React.FC<Props> = ({ orderId, orderVersion, closeModal }) => {
  const [rejectionNote, setRejectionNote] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { setSystemMessage } = useSystemMessages();

  const handleNoteChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setRejectionNote(e.target.value);
  };

  const rejectMutation = useOrderRejectMutation({
    onSuccess: () => {
      setSystemMessage({
        type: "success",
        message: "Order rejected.",
      });
      closeModal();
      navigate(RoutesConfig.home);
    },
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an error.",
      });
      closeModal();
      // TODO #2158: Check what is logged to sentry in this case and provide some manual logging if needed
      // TODO #2159: Decide on error handling apart of just displaying error message
    },
  });

  const handleSubmit = () => {
    if (!rejectionNote) {
      return setErrorMessage("This field is mandatory.");
    }

    rejectMutation.mutate({
      s2SOrderId: orderId,
      requestBody: { comment: rejectionNote, version: orderVersion },
    });
  };

  const isModalBlocked = rejectMutation.isPending || rejectMutation.isSuccess;

  return (
    <div
      className="inline-block align-bottom bg-neutral_0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full divide-y"
      data-testid="rejectOrderModal"
    >
      <div className="py-4.5 px-5">
        <Paragraph size="100">Reject Order</Paragraph>
        <div className="mt-2">
          <Paragraph size="200" color="text-textIcon-blackPrimary">
            Please add the reason why you are rejecting this order.
          </Paragraph>
        </div>
      </div>
      <div className="px-5 py-2">
        <Textarea
          errorMessage={errorMessage}
          onChange={handleNoteChange}
          value={rejectionNote}
          data-testid="rejectOrderModal_rejectionMessageInput"
        />
      </div>
      <div className="flex flex-row-reverse gap-3 px-5 py-3 bg-neutral_100">
        <RegularButton
          variant="primary"
          size="large"
          label="Reject"
          LeadingIcon={ThumbDownIcon}
          onClick={handleSubmit}
          disabled={isModalBlocked}
          loading={rejectMutation.isPending}
          data-testid="rejectOrderModal_confirmButton"
        />
        <RegularButton
          variant="secondary"
          size="large"
          label="Cancel"
          onClick={closeModal}
          disabled={isModalBlocked}
          data-testid="rejectOrderModal_cancelButton"
        />
      </div>
    </div>
  );
};
