import { DefaultError, useMutation } from "@tanstack/react-query";

import { SupplierRequest, SupplierResponse } from "src/typegens";

import { createSupplier } from "../api";

export const useCreateSupplierMutation = (options = {}) => {
  return useMutation<SupplierResponse, DefaultError, { supplierBody: SupplierRequest }>({
    mutationKey: ["createSupplier"],
    mutationFn: createSupplier,
    ...options,
  });
};
