import { useQuery } from "@tanstack/react-query";

import { SupplierResponse } from "src/typegens";

import { getSupplier } from "../api";

export const useSupplierQuery = (
  {
    supplierId,
  }: {
    supplierId: string;
  },
  options = {}
) => {
  return useQuery<SupplierResponse>({
    queryKey: ["supplier", supplierId],
    queryFn: () => getSupplier({ supplierId }),
    ...options,
  });
};
