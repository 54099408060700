import { Modal } from "@web/ui";

import { useModalContext } from "../contexts/ModalContext/ModalContext";

export const ContextModal = () => {
  const { component: ModalComponent, isOpen, closeModal } = useModalContext();

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      {ModalComponent}
    </Modal>
  );
};
