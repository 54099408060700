import classnames from "classnames";

import { Dropdown, DropdownItem } from "../Dropdown";
import { TableActionsButton } from "../TableActionsButton";

interface ITableHeaderCell {
  text?: string;
  dark?: boolean;
  textEnd?: boolean;
  dropdownOptions?: Array<DropdownItem>;
  classNames?: string;
  testId?: string;
}

export const TableHeaderCell = ({
  text,
  dark,
  dropdownOptions,
  classNames,
  textEnd,
  testId,
}: ITableHeaderCell) => {
  return (
    <th
      className={classnames(
        "font-semibold",
        dark ? "bg-neutral_300" : "bg-neutral_200",
        "whitespace-nowrap px-2 py-2 w-fit text-left text-sm h-6.5 leading-none",
        classNames
      )}
      data-testid={`tableHeaderCell-${testId}`}
    >
      <div className={classnames("w-full flex items-center gap-1", { "justify-end": textEnd })}>
        {text && <span>{text}</span>}
        {dropdownOptions && dropdownOptions.length > 0 && (
          <Dropdown hAlignTo="end" vAlignTo="bottom" items={dropdownOptions}>
            <TableActionsButton
              label="Sort by"
              data-testid={`tableHeaderCell-${testId}-dropdownButton`}
            />
          </Dropdown>
        )}
      </div>
    </th>
  );
};
