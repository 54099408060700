import { AttachmentMetadataResponse } from "@web/attachments";

import { FileUploadError } from "./models";
import { useFileUploadReducer } from "./reducer";
import { getFileUploadId } from "./utils";

export const useFileUpload = () => {
  const [state, dispatch] = useFileUploadReducer();

  const addFileUpload = (file: File, cancelUpload: () => void) => {
    dispatch({
      type: "addFileUpload",
      value: {
        file,
        cancelUpload,
      },
    });
  };

  const removeFileUpload = (file: File) => {
    dispatch({
      type: "removeFileUpload",
      value: file,
    });
  };

  const cancelFileUpload = (uploadId: string) => {
    dispatch({
      type: "cancelFileUpload",
      value: uploadId,
    });
  };

  const setFileUploadError = (file: File, error: unknown, errorType: FileUploadError) => {
    dispatch({
      type: "setFileUploadError",
      value: {
        file,
        error,
        errorType,
      },
    });
  };

  const setFileUploadSuccess = (file: File, apiFileData: AttachmentMetadataResponse) => {
    dispatch({
      type: "setFileUploadSuccess",
      value: {
        file,
        apiFileData,
      },
    });
  };

  return {
    fileUploads: state,
    addFileUpload,
    removeFileUpload,
    cancelFileUpload,
    setFileUploadError,
    setFileUploadSuccess,
    getFileUploadId,
  };
};
