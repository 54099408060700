import classnames from "classnames";
import { forwardRef } from "react";

import { Paragraph, ParagraphProps } from "../../atoms";

export type RegularDropdownItemProps = {
  label: string;
  subLabel?: {
    label: string;
    placement: RegularDropdownItemSubLabelPlacement;
  };
  variant: RegularDropdownItemVariant;
  disabled?: boolean;
  selected?: boolean;
  onClick: () => void;
  className?: string;
  LeadingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  TrailingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  "data-testid"?: string;
};

type RegularDropdownItemVariant = "basic" | "danger";
type RegularDropdownItemSubLabelPlacement = "same-line" | "next-line";

type RegularDropdownItemVariantsWithStates =
  | RegularDropdownItemVariant
  | `${RegularDropdownItemVariant}:disabled`
  | `${RegularDropdownItemVariant}:selected`;

const variantToButtonClassNames: Readonly<{
  [key in RegularDropdownItemVariantsWithStates]: string;
}> = {
  basic: "bg-transparent hover:bg-primaryBackground",
  "basic:disabled": "bg-transparent",
  "basic:selected": "bg-primaryBackground",
  danger: "bg-transparent hover:bg-primaryBackground",
  "danger:disabled": "bg-transparent",
  "danger:selected": "bg-primaryBackground",
};

const variantToLabelColorClassNames: Readonly<{
  [key in RegularDropdownItemVariantsWithStates]: ParagraphProps["color"];
}> = {
  basic: "text-textIcon-blackPrimary",
  "basic:disabled": "text-textIcon-blackDisabled",
  "basic:selected": "text-textIcon-blackPrimary",
  danger: "text-dangerDefault",
  "danger:disabled": "text-textIcon-blackDisabled",
  "danger:selected": "text-dangerDefault",
};

const variantToSubLabelColorClassNames: Readonly<{
  [key in RegularDropdownItemVariantsWithStates]: ParagraphProps["color"];
}> = {
  basic: "text-textIcon-blackSecondary",
  "basic:disabled": "text-textIcon-blackDisabled",
  "basic:selected": "text-textIcon-blackSecondary",
  danger: "text-textIcon-blackSecondary",
  "danger:disabled": "text-textIcon-blackDisabled",
  "danger:selected": "text-textIcon-blackSecondary",
};

const variantToIconColorClassNames: Readonly<{
  [key in RegularDropdownItemVariantsWithStates]: ParagraphProps["color"];
}> = {
  basic: "text-textIcon-blackSecondary",
  "basic:disabled": "text-textIcon-blackSecondary",
  "basic:selected": "text-textIcon-blackSecondary",
  danger: "text-dangerDefault",
  "danger:disabled": "text-dangerDefault",
  "danger:selected": "text-dangerDefault",
};

const subLabelPlacementToSubLabelTypographySize: Readonly<{
  [key in RegularDropdownItemSubLabelPlacement]: ParagraphProps["size"];
}> = {
  "same-line": "200",
  "next-line": "300",
};

const subLabelPlacementToTypographyContainerFlexClassNames: Readonly<{
  [key in RegularDropdownItemSubLabelPlacement]: string;
}> = {
  "same-line": "gap-[8px]",
  "next-line": "flex-wrap",
};

export const RegularDropdownItem = forwardRef<HTMLButtonElement, RegularDropdownItemProps>(
  (
    {
      label,
      subLabel,
      variant,
      disabled = false,
      selected = false,
      onClick,
      LeadingIcon,
      TrailingIcon,
      className = "",
      "data-testid": testId = "",
      ...rest
    },
    forwardedRef
  ) => {
    const calculatedVariant: RegularDropdownItemVariantsWithStates = disabled
      ? `${variant}:disabled`
      : selected
      ? `${variant}:selected`
      : variant;

    const iconSizeClassNames = "w-[20px] h-[20px]";

    const buttonVariantClassNames = variantToButtonClassNames[calculatedVariant];

    const labelColorClassNames = variantToLabelColorClassNames[calculatedVariant];
    const iconColorClassNames = variantToIconColorClassNames[calculatedVariant];

    const subLabelTypographySize = subLabel
      ? subLabelPlacementToSubLabelTypographySize[subLabel.placement]
      : undefined;
    const subLabelColorClassNames = variantToSubLabelColorClassNames[calculatedVariant];

    const typographyContainerFlexClassNames = subLabel
      ? subLabelPlacementToTypographyContainerFlexClassNames[subLabel?.placement]
      : "";

    return (
      <button
        ref={forwardedRef}
        className={classnames(
          "flex justify-between items-center",
          "w-full px-[16px] py-[10px] gap-[8px]",
          buttonVariantClassNames,
          className
        )}
        disabled={disabled}
        onClick={onClick}
        {...(testId ? { "data-testid": testId } : {})}
        {...rest}
      >
        {LeadingIcon && (
          <LeadingIcon className={classnames(iconColorClassNames, iconSizeClassNames)} />
        )}
        <span
          className={classnames(
            "flex justify-start items-center",
            "flex-1",
            typographyContainerFlexClassNames
          )}
        >
          <Paragraph
            size="200"
            weight="light"
            color={labelColorClassNames}
            component="span"
            className={classnames("text-left break-words", {
              "flex-1": !subLabel || subLabel?.placement === "next-line",
            })}
          >
            {label}
          </Paragraph>
          {subLabel && subLabelTypographySize && (
            <Paragraph
              size={subLabelTypographySize}
              weight="light"
              color={subLabelColorClassNames}
              component="span"
              className={classnames("flex-1 text-left break-words", {
                "basis-full": subLabel.placement === "next-line",
              })}
            >
              {subLabel.label}
            </Paragraph>
          )}
        </span>
        {TrailingIcon && (
          <TrailingIcon className={classnames(iconColorClassNames, iconSizeClassNames)} />
        )}
      </button>
    );
  }
);
RegularDropdownItem.displayName = "RegularDropdownItem";
