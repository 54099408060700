export type OrdersStatus =
  | "rejected"
  | "confirmed"
  | "delivered"
  | "incoming"
  | "cancellationRequested"
  | "cancelled"
  | "closed";

export function isOrdersStatus(status?: string | null): status is OrdersStatus {
  return [
    "rejected",
    "confirmed",
    "delivered",
    "incoming",
    "cancellationRequested",
    "cancelled",
    "closed",
  ].includes(status || "");
}
