import { ManagedFeatureFlags, ManagedUnleashFeatureFlags } from "../models";

export const convertManagedUnleashFeatureFlagsToManagedFeatureFlags = (
  managedUnleashFlags: ManagedUnleashFeatureFlags
): ManagedFeatureFlags =>
  Object.entries(managedUnleashFlags).reduce(
    (acc, [flagName, { isEnabled }]) => ({
      ...acc,
      [flagName]: isEnabled,
    }),
    {}
  );
