import { forwardRef } from "react";

import { LinkChildren } from "./LinkChildren";
import { LinkCommonProps } from "./models";
import { useLinkCommons } from "./useLinkCommons";

/**
 * This type merges:
 * - the common props (like `variant`, `label`, `size` etc.),
 * - the native props of the main tag (<a> tag in this case),
 * - and sets more rigid typechecking for the `onClick` prop, so either `href` or `onClick` is required.
 *
 * Please note that the <a> tag, in order to present the outline upon focus, needs to have a `href` attribute.
 */
export type LinkAnchorProps = LinkCommonProps &
  Omit<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    "children" | "href" | "onClick" | keyof LinkCommonProps
  > &
  (
    | {
        href: string;
        onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
      }
    | {
        href?: string;
        onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
      }
  );

export const LinkAnchor = forwardRef<HTMLAnchorElement, LinkAnchorProps>((props, forwardedRef) => {
  const { mainTagCommonProps, defaultedProps } = useLinkCommons<HTMLAnchorElement>(props);
  // Removing illegal props so only valid ones are applied to the HTML tag
  const { size, variant, label, disabled, LeadingIcon, TrailingIcon, ...anchorTagProps } = props;

  return (
    <a
      {...anchorTagProps}
      {...mainTagCommonProps}
      ref={forwardedRef}
      tabIndex={defaultedProps.disabled ? -1 : undefined}
    >
      <LinkChildren {...props} />
    </a>
  );
});
LinkAnchor.displayName = "LinkAnchor";
