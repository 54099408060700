import { PriceModifierInformationChangeResponse, SupplierPortalAppService } from "src/typegens";

import { AddPriceModifierParams } from "../models";

export const addPriceModifier = async ({
  s2SOrderId,
  priceModifier,
}: AddPriceModifierParams): Promise<PriceModifierInformationChangeResponse> => {
  const result = await SupplierPortalAppService.addPriceModifier({
    s2SOrderId,
    requestBody: priceModifier,
  });

  return result;
};
