export type SupplierFormMode = "default" | "companyProfile";
export type SupplierFormSection =
  | "information"
  | "location"
  | "contactDetails"
  | "paymentDetails"
  | "user"
  | "notes";

const supplierFormCopy = {
  default: {
    information: {
      title: "Vendor Information",
      description: "Please add the full vendor name and identification number (Registration/VAT).",
    },
    location: {
      title: "Location",
      description: `Please add the new supplier's physical address.`,
    },
    contactDetails: {
      title: "Contact details",
      description: "Please add all the required contact details.",
    },
    paymentDetails: {
      title: "Payment details",
      description:
        "Please fill out the payment details and check twice - we will use this data to proceed with payments, create invoices and so on.",
    },
    user: {
      title: "User",
      description:
        "Please add the name and email of the person who will be managing this supplier.",
    },
    notes: {
      title: "Notes",
      description: "Please add any other relevant information, if needed.",
    },
  },
  companyProfile: {
    information: {
      title: "Company Information",
      description: "The full company name and identification number (Registration/VAT).",
    },
    location: {
      title: "Location",
      description: `The company's physical address.`,
    },
    contactDetails: {
      title: "Contact details",
      description: "All contact details.",
    },
    paymentDetails: {
      title: "Payment details",
      description: "This data is to proceed with payments and create invoices.",
    },
    user: {
      title: "User",
      description:
        "Please add the name and email of the person who will be managing this supplier.",
    },
    notes: {
      title: "Notes",
      description: "Extra relevant information.",
    },
  },
};

export const supplierFormCopies = {
  getSectionTitle: (mode: SupplierFormMode, section: SupplierFormSection) =>
    supplierFormCopy[mode][section].title,
  getSectionDescription: (mode: SupplierFormMode, section: SupplierFormSection) =>
    supplierFormCopy[mode][section].description,
};
