import { z } from "zod";

const Categories = [
  "MD",
  "SDOC",
  "CERT",
  "SDS",
  "DWG",
  "PHOTO",
  "SOW",
  "SVC_NOTES",
  "OTHER",
] as const;

export const SupplierOrderAttachmentSchema = z.object({
  attachmentId: z.string(),
  category: z.enum(Categories).optional(),
  createdBy: z.string(),
  createdDate: z.date(),
  name: z.string(),
  size: z.number().int().positive(),
});
