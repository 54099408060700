import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { Fragment, memo } from "react";

import { CategoriesTree } from "@web/common/components/CategoriesTree";
import { findDescendantById } from "@web/common/utils";
import { Category } from "@web/models";
import { Heading, IconButton } from "@web/ui";

type Props = {
  className?: string;
  categories: Category[];
  onCategoryClick: (category: Category) => void;
  openedByDefaultId?: string;
  activeCategoryId?: string;
  isTopLevelFoldable?: boolean;
  showTopHorizontalRule?: boolean;
  showBottomHorizontalRule?: boolean;
};

export const TopCategoriesTree = memo(
  ({
    className = "",
    categories,
    openedByDefaultId,
    onCategoryClick,
    activeCategoryId,
    isTopLevelFoldable = false,
    showTopHorizontalRule = false,
    showBottomHorizontalRule = false,
  }: Props) => {
    return (
      <div
        className={classnames(
          { "border-t-1 border-neutral_300": showTopHorizontalRule },
          className
        )}
        data-testid="topCategoriesTree"
      >
        {categories.map((category, index) => (
          <Disclosure
            key={category.id}
            as="div"
            className={classnames({
              "border-b-1 border-neutral_300":
                index < categories.length - 1 ||
                (index === categories.length - 1 && showBottomHorizontalRule),
            })}
            defaultOpen={
              !isTopLevelFoldable ||
              (openedByDefaultId
                ? category.id === openedByDefaultId ||
                  !!findDescendantById(openedByDefaultId, category)
                : false)
            }
            data-testid="topCategoriesTree_topLevel"
          >
            {({ open }) => (
              <>
                <div
                  className="py-2 min-h-[56px] flex items-center group cursor-pointer"
                  onClick={() => onCategoryClick(category)}
                  data-testid="topCategoriesTree_topLevel_header"
                >
                  <Heading
                    size="200"
                    color={
                      activeCategoryId === category.id
                        ? "text-primaryDefault"
                        : ["text-textIcon-blackPrimary", "group-hover:text-primaryDefault"]
                    }
                  >
                    {category.name}
                  </Heading>
                  {isTopLevelFoldable && category.children.length > 0 && (
                    <div className="ml-auto">
                      <Disclosure.Button as={Fragment}>
                        <IconButton
                          size="large"
                          variant="secondary"
                          shape="circle"
                          label="Expand/Contract Category"
                          Icon={open ? ChevronDownIcon : ChevronRightIcon}
                          isControlled
                          data-testid="topCategoriesTree_topLevel_button"
                        />
                      </Disclosure.Button>
                    </div>
                  )}
                </div>
                {category.children.length > 0 && (
                  <Disclosure.Panel
                    as="div"
                    className={classnames({
                      "mb-3": open,
                    })}
                    data-testid="topCategoriesTree_topLevel_nestedContent"
                  >
                    <CategoriesTree
                      categories={category.children}
                      onCategoryClick={onCategoryClick}
                      openedByDefaultId={openedByDefaultId}
                      activeCategoryId={activeCategoryId}
                    />
                  </Disclosure.Panel>
                )}
              </>
            )}
          </Disclosure>
        ))}
      </div>
    );
  }
);
TopCategoriesTree.displayName = "TopCategoriesTree";
