import { isDefined } from "@web/utils";

import { changeConfig } from "../configs";
import {
  Change,
  ChangeApiResponse,
  ChangeConfig,
  IChangesEventListener,
  NewChange,
  StatusEventListener,
  SyncedChange,
} from "../models";
import { ChangesSyncListenersService } from "./ChangesSyncListenersService";
import { ChangesSyncQueueService } from "./ChangesSyncQueueService";

// Currently acting as a singleton
export class ChangesSyncService {
  // --------------------------------------------------
  // Request handling

  private static isRequestActive = false;
  private static requestAbortController: AbortController | undefined;

  private static getApiRequestCall = (change: Change): ChangeConfig["apiRequestCall"] =>
    changeConfig[change.type].apiRequestCall;

  private static handleRequestSuccess = (requestResponse: ChangeApiResponse): void => {
    // Intentional side effects
    ChangesSyncService.isRequestActive = false;
    ChangesSyncService.requestAbortController = undefined;
    ChangesSyncListenersService.notifyStatusListeners("isSyncInProgress", false);
    ChangesSyncQueueService.resolvePendingChangesAsSuccessful(requestResponse);
    ChangesSyncService.processQueue();
  };

  private static handleRequestError = (error: Error): void => {
    // Intentional side effects
    ChangesSyncService.isRequestActive = false;
    ChangesSyncService.requestAbortController = undefined;
    ChangesSyncListenersService.notifyStatusListeners("isSyncInProgress", false);
    ChangesSyncQueueService.resolvePendingChangesAsFailed(error);
    ChangesSyncService.processQueue();
  };

  private static makeRequest = async (pendingChange: Change): Promise<ChangeApiResponse> => {
    ChangesSyncService.requestAbortController = new AbortController();
    const signal = ChangesSyncService.requestAbortController.signal;
    const apiRequestCall = ChangesSyncService.getApiRequestCall(pendingChange);
    // TODO #7282: [CHORE] Fix "never" type. So far, nothing I tried worked.
    return apiRequestCall(pendingChange as never, signal);
  };

  // --------------------------------------------------
  // Queue processing

  /**
   * Triggers:
   * - when new item is added to queue
   * - when request completes (either success or error)
   * - when the queue is cleared
   */
  private static processQueue = (): void => {
    if (ChangesSyncService.isRequestActive) {
      return;
    }

    const { pendingChange, successfulChanges, changesToRollback, processedQueue } =
      ChangesSyncQueueService.processQueue();

    if (pendingChange) {
      // Intentional side effects
      ChangesSyncService.isRequestActive = true;
      ChangesSyncListenersService.notifyStatusListeners("isSyncInProgress", true);
      ChangesSyncService.makeRequest(pendingChange)
        .then((requestResponse) => ChangesSyncService.handleRequestSuccess(requestResponse))
        .catch((error) => ChangesSyncService.handleRequestError(error));
    }

    // Intentional side effects
    ChangesSyncListenersService.notifyChangesListeners("synced", successfulChanges);
    ChangesSyncListenersService.notifyChangesListeners("rollback", changesToRollback);
    ChangesSyncListenersService.notifyChangesListeners("currentQueue", processedQueue);
  };

  public static clearQueue = (): void => {
    ChangesSyncQueueService.clearQueue();

    if (ChangesSyncService.isRequestActive) {
      // This will trigger request error, which will trigger queue processing here: `handleRequestError`
      ChangesSyncService.requestAbortController?.abort();
      console.info("The queue has been aborted and cleared while sync was in progress");
    } else {
      // Otherwise let's process queue by ourselves
      ChangesSyncQueueService.processQueue();
    }
  };

  public static addToQueue = (change: NewChange): void => {
    ChangesSyncQueueService.addToQueue(change);
    ChangesSyncService.processQueue();
  };

  public static isSyncedChange = (change: Change): change is SyncedChange => {
    return change.status === "success" && isDefined(change.meta.newOrderVersionId);
  };

  // ------------------------------
  // Listeners & pub-sub related code

  public static registerChangesListener = (listener: IChangesEventListener): void =>
    ChangesSyncListenersService.registerChangesListener(listener);
  public static unregisterChangesListener = (listener: IChangesEventListener): void =>
    ChangesSyncListenersService.unregisterChangesListener(listener);
  public static registerStatusListener = (listener: StatusEventListener): void =>
    ChangesSyncListenersService.registerStatusListener(listener);
  public static unregisterStatusListener = (listener: StatusEventListener): void =>
    ChangesSyncListenersService.unregisterStatusListener(listener);
}
