import { useApiError } from "src/domain/apiError";

export const useAddAdditionalCostApiError = () => {
  const { showApiError } = useApiError({
    one: "There was an error while adding the cost:\n{errors}\nPlease try again.",
    many: "There were some errors while adding the cost:\n{errors}\nPlease try again.",
    fallback: "There was an error while adding the cost. Please try again.",
  });

  return {
    showAddAdditionalCostApiError: showApiError,
  };
};
