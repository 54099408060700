import classnames from "classnames";
import { useCallback } from "react";

import { ButtonTypographyProps } from "../../Typography";
import { ComboButtonVariantsWithStates, ComboCommonProps } from "./models";

export const useComboCommons = <T>({
  iconPlacement,
  disabled = false,
  loading = false,
  onClick,
  className = "",
  "data-testid": testId = "",
  ...rest
}: ComboCommonProps & { onClick?: (event: React.MouseEvent<T, MouseEvent>) => void }): {
  mainTagCommonProps: {
    className: string;
    onClick: (event: React.MouseEvent<T, MouseEvent>) => void;
    "data-testid"?: string;
  };
  buttonVariantIconContainerClassNames: string;
  iconContainerRoundnessClassNames: string;
  defaultedProps: Omit<ComboCommonProps, "className" | "onClick">;
} => {
  const variantToButtonClassNames: Readonly<{
    [key in ComboButtonVariantsWithStates]: string;
  }> = {
    default: "ring-primaryDefault",
    "default:disabled": "ring-textIcon-blackDisabled",
  };

  const variantToIconContainerClassNames: Readonly<{
    [key in ComboButtonVariantsWithStates]: string;
  }> = {
    default:
      "bg-neutral_0 group-hover:bg-neutral_200 group-active:bg-neutral_300 border-neutral_300",
    "default:disabled": "bg-neutral_200 border-neutral_300",
  };

  const variantToButtonTextColorClassNames: Readonly<{
    [key in ComboButtonVariantsWithStates]: ButtonTypographyProps["color"];
  }> = {
    default: "text-textIcon-blackPrimary",
    "default:disabled": "text-textIcon-blackDisabled",
  };

  const calculatedVariant: ComboButtonVariantsWithStates =
    disabled || loading ? "default:disabled" : "default";

  const buttonVariantClassNames = variantToButtonClassNames[calculatedVariant];
  const buttonVariantIconContainerClassNames = variantToIconContainerClassNames[calculatedVariant];
  const buttonVariantTextColorClassNames = variantToButtonTextColorClassNames[calculatedVariant];

  const iconContainerRoundnessClassNames = "rounded-[8px]";

  const handleOnClick = useCallback(
    (event: React.MouseEvent<T, MouseEvent>) => {
      if (disabled || loading || !onClick) {
        return;
      }
      onClick(event);
    },
    [disabled, loading, onClick]
  );

  const mainTagCommonProps = {
    className: classnames(
      "group",
      "inline-flex items-center bg-transparent",
      "border-0",
      "h-[40px] min-h-[40px] max-h-[40px]",
      "w-fit min-w-fit max-w-full",
      "gap-[8px]",
      "focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-white",
      "overflow-hidden whitespace-nowrap leading-none",
      { "cursor-pointer": !loading && !disabled },
      { "pointer-events-none": loading || disabled },
      buttonVariantClassNames,
      buttonVariantTextColorClassNames,
      iconContainerRoundnessClassNames,
      className
    ),
    onClick: handleOnClick,
    ...(testId ? { "data-testid": testId } : {}),
  };

  return {
    mainTagCommonProps,
    buttonVariantIconContainerClassNames,
    iconContainerRoundnessClassNames,
    defaultedProps: {
      iconPlacement,
      disabled,
      loading,
      "data-testid": testId,
      ...rest,
    },
  };
};
