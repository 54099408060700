import { ReactElement, forwardRef } from "react";

import { Label } from "../../atoms/Typography";

export type AvatarProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  text?: string;
  sizeClass?: string;
  icon?: ReactElement;
};

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ text, sizeClass = "6.5", className = "", icon, ...rest }, forwardedRef) => {
    return (
      <div
        ref={forwardedRef}
        className={`bg-neutral_200 border-0 rounded-full overflow-hidden flex items-center justify-center relative w-${sizeClass} h-${sizeClass} ${className}`}
        {...rest}
      >
        <Label
          size={sizeClass === "6.5" ? "100" : "300"}
          data-testid="avatar_text"
          color="text-textIcon-blackSecondary"
        >
          {icon || text}
        </Label>
      </div>
    );
  }
);
Avatar.displayName = "Avatar";
