import { ToastMessage } from "@web/ui";
import { formatFileExtension, formatFileSize, formatShortFileName } from "@web/utils";

import { useSystemMessages } from "src/context/SystemMessages";
import { isApiError } from "src/domain/apiError";

import { FILE_NAME_MAX_LENGTH_IN_NOTIFICATIONS } from "../../config";
import { useFileUploadApiError } from "../useFileUploadApiError";
import { FileUploadError } from "./models";
import { isUploadCancelledError } from "./utils";

const fileUploadErrorTypeToSystemMessageConfig: ReadonlyMap<
  FileUploadError,
  Omit<ToastMessage, "id">
> = new Map([
  [
    "FILE_SIZE_EXCEEDED",
    {
      type: "warning",
      message: `File "{fileName}" is too large. Please upload a file up to {maxFileSize}.`,
    },
  ],
  [
    "FILE_ALREADY_EXISTS",
    {
      type: "warning",
      message: 'File "{fileName}" already exists on the list.',
    },
  ],
  [
    "FILE_EXTENSION_UNSUPPORTED",
    {
      type: "warning",
      message: "{fileExtension} format is not supported.",
    },
  ],
]);

const formatErrorMessageFileExtension = (fileName: string): string => {
  const extension: string = formatShortFileName(
    formatFileExtension(fileName),
    FILE_NAME_MAX_LENGTH_IN_NOTIFICATIONS
  );
  return extension ? `"${extension}"` : "This";
};

export const getFileUploadErrorSystemMessageConfig = (
  fileUploadErrorType: FileUploadError,
  file: File,
  maxFileSizeInBytes: number
): Omit<ToastMessage, "id"> | undefined => {
  if (fileUploadErrorType === "UPLOAD_CANCELLED") {
    return;
  }

  const config = fileUploadErrorTypeToSystemMessageConfig.get(fileUploadErrorType) || {
    type: "failure",
    message: 'There was an error while uploading your file: "{fileName}". Please try again.',
  };

  return {
    ...config,
    message: config.message
      .replace(/{fileName}/g, formatShortFileName(file.name, FILE_NAME_MAX_LENGTH_IN_NOTIFICATIONS))
      .replace(/{maxFileSize}/g, formatFileSize(maxFileSizeInBytes))
      .replace(/{fileExtension}/g, formatErrorMessageFileExtension(file.name)),
  };
};

export const getFileUploadErrorTypeFromPromiseError = (error: unknown): FileUploadError => {
  if (isUploadCancelledError(error)) {
    return "UPLOAD_CANCELLED";
  }

  if (isApiError(error)) {
    return "API";
  }

  return "OTHER";
};

export const useFileUploadError = (maxFileSizeInBytes: number) => {
  const { setSystemMessage } = useSystemMessages();
  const { showFileUploadApiError } = useFileUploadApiError();

  const showFileUploadError = (file: File, errorType: FileUploadError, error?: unknown) => {
    if (errorType === "API" && isApiError(error)) {
      showFileUploadApiError(file, error);
      return;
    }

    const systemMessageConfig = getFileUploadErrorSystemMessageConfig(
      errorType,
      file,
      maxFileSizeInBytes
    );

    if (systemMessageConfig) {
      setSystemMessage(systemMessageConfig);
    }
  };

  return {
    showFileUploadError,
    getFileUploadErrorTypeFromPromiseError,
  };
};
