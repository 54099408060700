import { useCallback, useEffect, useMemo, useState } from "react";

import { Loading } from "@web/ui";
import { isDefined } from "@web/utils";

import {
  SupplierOrderChanges,
  SupplierOrderService,
  SupplierUiOrderItem,
  useDiscardOrderChangesMutation,
  useOrderByIdQuery,
  useOrderByIdQueryHelpers,
  useOrderChangesByIdQuery,
} from "src/domain";

import { AcknowledgeOrderSeen } from "../AcknowledgeOrderSeen";
import { OrderDetailsComponent } from "../OrderDetailsComponent";

interface Props {
  orderId: string;
}

export const OrderDetailsQuery: React.FC<Props> = ({ orderId }) => {
  const [isOrderAcknowledging, setIsOrderAcknowledging] = useState<boolean>(true);

  const oldestOrderQuery = useOrderByIdQuery({
    orderId,
    orderVersion: "OLDEST",
  });
  const {
    isPending: isOldestOrderPending,
    isFetching: isOldestOrderFetching,
    isSuccess: isOldestOrderSuccess,
    data: rawOldestOrderData,
  } = oldestOrderQuery;
  const { invalidate: invalidateOldestOrderQuery } = useOrderByIdQueryHelpers({
    orderId,
    orderVersion: "OLDEST",
  });

  const latestOrderQuery = useOrderByIdQuery({
    orderId,
    orderVersion: "LATEST",
  });
  const {
    isPending: isLatestOrderPending,
    isFetching: isLatestOrderFetching,
    isSuccess: isLatestOrderSuccess,
    data: rawLatestOrderData,
  } = latestOrderQuery;
  const { invalidate: invalidateLatestOrderQuery } = useOrderByIdQueryHelpers({
    orderId,
    orderVersion: "LATEST",
  });

  const { query: orderChangesQuery, invalidate: invalidateOrderChangesQuery } =
    useOrderChangesByIdQuery(
      { orderId, orderStatus: rawOldestOrderData?.status },
      { enabled: !!rawOldestOrderData?.status }
    );
  const {
    isPending: areOrderChangesPending,
    isFetching: areOrderChangesFetching,
    isSuccess: isOrderChangesSuccess,
    data: rawOrderChangesData,
  } = orderChangesQuery;

  const invalidateQueries = useCallback(() => {
    invalidateOldestOrderQuery();
    invalidateLatestOrderQuery();
    invalidateOrderChangesQuery();
  }, [invalidateOldestOrderQuery, invalidateLatestOrderQuery, invalidateOrderChangesQuery]);

  const isAnyQueryPendingOrFetching =
    isOldestOrderPending ||
    isOldestOrderFetching ||
    isLatestOrderPending ||
    isLatestOrderFetching ||
    areOrderChangesPending ||
    areOrderChangesFetching;

  const oldestOrderVersion = rawOldestOrderData?.version;
  const latestOrderVersion = rawLatestOrderData?.version;

  const discardOrderChangesMutation = useDiscardOrderChangesMutation({
    onSuccess: () => {
      invalidateQueries();
    },
  });

  const orderUiItems: SupplierUiOrderItem[] = useMemo(
    () =>
      rawOldestOrderData?.items
        ? rawOldestOrderData.items.map((item) =>
            SupplierOrderService.convertOrderItemDataToUiModel(item, item.lineNumber)
          )
        : [],
    [rawOldestOrderData?.items]
  );

  const orderChanges: SupplierOrderChanges = useMemo(
    () =>
      SupplierOrderService.convertToSupplierOrderChanges(orderUiItems, rawOrderChangesData || []),
    [orderUiItems, rawOrderChangesData]
  );

  const onAcknowledgeSuccess = useCallback(() => {
    invalidateQueries();
    setTimeout(() => setIsOrderAcknowledging(false), 0);
  }, [invalidateQueries]);

  const onAcknowledgeNotNeeded = useCallback(() => {
    setIsOrderAcknowledging(false);
  }, []);

  useEffect(() => {
    if (
      discardOrderChangesMutation.isPending ||
      !isDefined(oldestOrderVersion) ||
      !isDefined(latestOrderVersion) ||
      isAnyQueryPendingOrFetching
    ) {
      return;
    }

    // This means that in the meantime something has changed in the order, e.g. there was a new edit
    // in the order. If we want to make any changes like acknowledge an order, or assign an assignee,
    // we need to discard all previous changes.
    if (latestOrderVersion !== oldestOrderVersion) {
      discardOrderChangesMutation.mutate({ s2SOrderId: orderId });
      return;
    }
  }, [
    latestOrderVersion,
    oldestOrderVersion,
    discardOrderChangesMutation.isPending,
    discardOrderChangesMutation,
    orderId,
    isAnyQueryPendingOrFetching,
  ]);

  if (
    isAnyQueryPendingOrFetching ||
    // Waiting for changes discard or order acknowledge and reloading order data - refer to the useEffect above
    (isDefined(oldestOrderVersion) &&
      isDefined(latestOrderVersion) &&
      latestOrderVersion > oldestOrderVersion) ||
    discardOrderChangesMutation.isPending
  ) {
    return <Loading />;
  }

  if (isOldestOrderSuccess && isLatestOrderSuccess && isOrderChangesSuccess) {
    return (
      <>
        <AcknowledgeOrderSeen
          order={rawOldestOrderData}
          onAcknowledgeSuccess={onAcknowledgeSuccess}
          onAcknowledgeNotNeeded={onAcknowledgeNotNeeded}
        />
        {isOrderAcknowledging ? (
          <Loading />
        ) : (
          <OrderDetailsComponent
            order={rawOldestOrderData}
            orderChanges={orderChanges}
            orderUiItems={orderUiItems}
            invalidateOrderQueries={invalidateQueries}
          />
        )}
      </>
    );
  }

  return <div>undefined state</div>;
};
