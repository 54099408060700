import { forwardRef } from "react";

import { RegularChildren } from "./RegularChildren";
import { RegularCommonProps } from "./models";
import { useRegularCommons } from "./useRegularCommons";

/**
 * This type merges:
 * - the common props (like `variant`, `label`, `size` etc.),
 * - the native props of the main tag (<a> tag in this case),
 * - and sets more rigid typechecking for the `onClick` prop, so either `href` or `onClick` is required.
 *
 * Please note that the <a> tag, in order to present the outline upon focus, needs to have a `href` attribute.
 */
export type RegularAnchorProps = RegularCommonProps &
  Omit<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    "children" | "href" | "onClick" | keyof RegularCommonProps
  > &
  (
    | {
        href: string;
        onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
      }
    | {
        href?: string;
        onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
      }
  );

export const RegularAnchor = forwardRef<HTMLAnchorElement, RegularAnchorProps>(
  (props, forwardedRef) => {
    const { mainTagCommonProps, defaultedProps } = useRegularCommons<HTMLAnchorElement>(props);
    // Removing illegal props so only valid ones are applied to the HTML tag
    const {
      size,
      variant,
      label,
      width,
      disabled,
      loading,
      LeadingIcon,
      TrailingIcon,
      ...anchorTagProps
    } = props;

    return (
      <a
        {...anchorTagProps}
        {...mainTagCommonProps}
        ref={forwardedRef}
        tabIndex={defaultedProps.disabled ? -1 : undefined}
      >
        <RegularChildren {...props} />
      </a>
    );
  }
);
RegularAnchor.displayName = "RegularAnchor";
