import classnames from "classnames";
import { useCallback } from "react";

import { ButtonTypographyProps } from "../../Typography";
import {
  IconButtonShape,
  IconButtonSize,
  IconButtonVariantsWithStates,
  IconCommonProps,
} from "./models";

export const useIconCommons = <T>({
  size,
  variant,
  shape,
  disabled = false,
  loading = false,
  onClick,
  className = "",
  "data-testid": testId = "",
  ...rest
}: IconCommonProps & { onClick?: (event: React.MouseEvent<T, MouseEvent>) => void }): {
  mainTagCommonProps: {
    className: string;
    onClick: (event: React.MouseEvent<T, MouseEvent>) => void;
    "data-testid"?: string;
  };
  iconSizeClassNames: string;
  loaderSize: string;
  defaultedProps: Omit<IconCommonProps, "className" | "onClick">;
} => {
  const shapeToButtonClassNames: Readonly<{ [key in IconButtonShape]: string }> = {
    circle: "rounded-full",
    square: "rounded-[8px]",
  };

  const sizeToButtonClassNames: Readonly<{ [key in IconButtonSize]: string }> = {
    large: "h-[40px] w-[40px] min-h-[40px] min-w-[40px] max-h-[40px] max-w-[40px]",
    small: "h-[32px] w-[32px] min-h-[32px] min-w-[32px] max-h-[32px] max-w-[32px]",
  };

  const sizeToIconClassNames: Readonly<{ [key in IconButtonSize]: string }> = {
    large: "w-[16px] h-[16px]",
    small: "w-[16px] h-[16px]",
  };

  const sizeToLoaderSize: Readonly<{ [key in IconButtonSize]: string }> = {
    large: "4.5",
    small: "4",
  };

  const variantToButtonClassNames: Readonly<{
    [key in IconButtonVariantsWithStates]: string;
  }> = {
    primary:
      "bg-primaryDefault hover:bg-primaryHover active:bg-primaryPressed border-transparent ring-primaryDefault",
    "primary:disabled": "bg-neutral_200 border-neutral_300 ring-textIcon-blackDisabled",
    secondary:
      "bg-neutral_0 hover:bg-neutral_200 active:bg-neutral_300 border-neutral_300 ring-primaryDefault",
    "secondary:disabled": "bg-neutral_200 border-neutral_300 ring-textIcon-blackDisabled",
    danger:
      "bg-dangerDefault hover:bg-dangerHover active:bg-dangerPressed border-transparent ring-dangerDefault",
    "danger:disabled": "bg-neutral_200 border-neutral_300 ring-textIcon-blackDisabled",
  };

  const variantToButtonTextColorClassNames: Readonly<{
    [key in IconButtonVariantsWithStates]: ButtonTypographyProps["color"];
  }> = {
    primary: "text-textIcon-whitePrimary",
    "primary:disabled": "text-textIcon-blackDisabled",
    secondary: "text-textIcon-blackPrimary",
    "secondary:disabled": "text-textIcon-blackDisabled",
    danger: "text-textIcon-whitePrimary",
    "danger:disabled": "text-textIcon-blackDisabled",
  };

  const calculatedVariant: IconButtonVariantsWithStates =
    disabled || loading ? `${variant}:disabled` : variant;

  const buttonShapeClassNames = shapeToButtonClassNames[shape];

  const buttonSizeClassNames = sizeToButtonClassNames[size];
  const iconSizeClassNames = sizeToIconClassNames[size];
  const loaderSize = sizeToLoaderSize[size];

  const buttonVariantClassNames = variantToButtonClassNames[calculatedVariant];
  const buttonVariantTextColorClassNames = variantToButtonTextColorClassNames[calculatedVariant];

  const handleOnClick = useCallback(
    (event: React.MouseEvent<T, MouseEvent>) => {
      if (disabled || loading || !onClick) {
        return;
      }
      onClick(event);
    },
    [disabled, loading, onClick]
  );

  const mainTagCommonProps = {
    className: classnames(
      "inline-flex justify-center items-center",
      "border",
      "focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-white",
      "relative overflow-hidden whitespace-nowrap leading-none",
      { "cursor-pointer": !loading && !disabled },
      { "pointer-events-none": loading || disabled },
      buttonShapeClassNames,
      buttonSizeClassNames,
      buttonVariantClassNames,
      buttonVariantTextColorClassNames,
      className
    ),
    onClick: handleOnClick,
    ...(testId ? { "data-testid": testId } : {}),
  };

  return {
    mainTagCommonProps,
    iconSizeClassNames,
    loaderSize,
    defaultedProps: {
      size,
      variant,
      shape,
      disabled,
      loading,
      "data-testid": testId,
      ...rest,
    },
  };
};
