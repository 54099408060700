export const formatShortFileName = (
  fileName: string,
  maxlength: number,
  abbreviation = "(...)"
): string => {
  if (fileName.length <= maxlength) {
    return fileName;
  }

  const abbreviationLength = abbreviation.length;
  const nameParts = fileName.split(".");
  const extension = nameParts.length < 2 ? "" : `.${nameParts.pop()}`;
  const name = nameParts.join(".");

  // Simple safeguard against extension-only filenames & really long extensions
  if (fileName.startsWith(".") || extension.length > name.length) {
    const newNameLength = maxlength - abbreviationLength;
    return fileName.slice(0, newNameLength) + abbreviation;
  }

  const newNameLength = maxlength - abbreviationLength - extension.length;
  return name.slice(0, newNameLength) + abbreviation + extension;
};
