import { DefaultError, useMutation } from "@tanstack/react-query";

import { acknowledgeOrderSeen } from "../api";

export const useOrderAcknowledgeSeenMutation = (options = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  return useMutation<void, DefaultError, { s2SOrderId: string }>({
    mutationKey: ["acknowledgeOrderSeen"],
    mutationFn: acknowledgeOrderSeen,
    ...options,
  });
};
