import classnames from "classnames";

import { LoadingInline } from "../../Loading";
import { IconCommonProps } from "./models";
import { useIconCommons } from "./useIconCommons";

export const IconChildren = (props: IconCommonProps) => {
  const { iconSizeClassNames, loaderSize, defaultedProps } = useIconCommons<unknown>(props);
  const { Icon, label, loading, "data-testid": testId } = defaultedProps;

  return (
    <>
      <Icon
        className={classnames(iconSizeClassNames, { hidden: loading })}
        aria-label={label}
        focusable={false}
      />

      {loading && (
        <span className="block absolute" {...(testId ? { "data-testid": `${testId}_loader` } : {})}>
          <LoadingInline baseColor="text-textIcon-blackPrimary" size={loaderSize} display="block" />
        </span>
      )}
    </>
  );
};
