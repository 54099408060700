import { useReducer } from "react";

import { FileUpload, FileUploadActions, FileUploads } from "./models";
import { getFileUploadId } from "./utils";

export const defaultFileUploads: FileUploads = [];

const FileUploadReducer = (fileUploads: FileUploads, action: FileUploadActions): FileUploads => {
  let actionUpload: FileUpload | undefined;

  switch (action.type) {
    case "addFileUpload":
      return [
        ...fileUploads,
        {
          file: action.value.file,
          uploadId: getFileUploadId(action.value.file.name, action.value.file.size),
          cancelUpload: action.value.cancelUpload,
        },
      ];
    case "setFileUploadError":
      actionUpload = fileUploads.find((fileUpload) => fileUpload.file === action.value.file);

      if (!actionUpload) {
        return fileUploads;
      }

      return [
        ...fileUploads.filter((fileUpload) => fileUpload.file !== action.value.file),
        {
          ...actionUpload,
          error: action.value.error,
          errorType: action.value.errorType,
        },
      ];
    case "setFileUploadSuccess":
      actionUpload = fileUploads.find((fileUpload) => fileUpload.file === action.value.file);

      if (!actionUpload) {
        return fileUploads;
      }

      return [
        ...fileUploads.filter((fileUpload) => fileUpload.file !== action.value.file),
        {
          ...actionUpload,
          apiFileData: action.value.apiFileData,
        },
      ];
    case "cancelFileUpload":
      actionUpload = fileUploads.find((fileUpload) => fileUpload.uploadId === action.value);

      if (!actionUpload) {
        return fileUploads;
      }

      return [
        ...fileUploads.filter((fileUpload) => fileUpload.uploadId !== action.value),
        {
          ...actionUpload,
          isCancelled: true,
        },
      ];
    case "removeFileUpload":
      return fileUploads.filter((fileUpload) => fileUpload.file !== action.value);
    default:
      return fileUploads;
  }
};

export const useFileUploadReducer = () => useReducer(FileUploadReducer, defaultFileUploads);
