import { Controller, useFormContext } from "react-hook-form";

import { Heading, LEGACY_Input, Paragraph, Textarea } from "@web/ui";

import { SupplierFormType } from "src/domain";

import { SupplierFormMode, supplierFormCopies as copy } from "./schema";

type Props = { readonly?: boolean; formMode?: SupplierFormMode };

export const SupplierForm: React.FC<Props> = ({ readonly, formMode = "default" }) => {
  const { control, formState } = useFormContext<SupplierFormType>();

  return (
    <div>
      <div className="flex mb-8">
        <div className="w-3/4">
          <Controller
            name="information.vendor.vendorName"
            control={control}
            render={({ field }) => (
              <LEGACY_Input
                className="pt-0"
                label={formMode === "default" ? "Vendor Name" : "Company Name"}
                errorMessage={formState?.errors.information?.vendor?.vendorName?.message}
                testId="supplierFormField-vendorName"
                disabled={readonly}
                {...field}
              />
            )}
          />
          <Controller
            name="information.vendor.registrationNumber"
            control={control}
            render={({ field }) => (
              <LEGACY_Input
                className="pt-3"
                label="Registration Number"
                errorMessage={formState?.errors.information?.vendor?.registrationNumber?.message}
                testId="supplierFormField-registrationNumber"
                disabled={readonly}
                {...field}
              />
            )}
          />
          <Controller
            name="information.vendor.vatNumber"
            control={control}
            render={({ field }) => (
              <LEGACY_Input
                className="pt-3"
                label="VAT Number"
                errorMessage={formState?.errors.information?.vendor?.vatNumber?.message}
                testId="supplierFormField-vatNumber"
                disabled={readonly}
                {...field}
              />
            )}
          />
        </div>
        <div className="w-1/4 ml-5" data-testid="supplierFormLabel-information">
          <Heading size="300">{copy.getSectionTitle(formMode, "information")}</Heading>
          <Paragraph size="200" className="mt-3 text-stone-600">
            {copy.getSectionDescription(formMode, "information")}
          </Paragraph>
        </div>
      </div>
      <div className="flex mb-8">
        <div className="w-3/4">
          <Controller
            name="information.location.address"
            control={control}
            render={({ field }) => (
              <LEGACY_Input
                className="pt-0"
                label="Address"
                errorMessage={formState?.errors.information?.location?.address?.message}
                testId="supplierFormField-address"
                disabled={readonly}
                {...field}
              />
            )}
          />
          <div className="flex justify-between pt-3">
            <Controller
              name="information.location.zipCode"
              control={control}
              render={({ field }) => (
                <LEGACY_Input
                  className="pt-0 w-1/4"
                  label="Postal/ZIP Code"
                  errorMessage={formState?.errors.information?.location?.zipCode?.message}
                  testId="supplierFormField-zipCode"
                  disabled={readonly}
                  {...field}
                />
              )}
            />
            <Controller
              name="information.location.city"
              control={control}
              render={({ field }) => (
                <LEGACY_Input
                  className="pt-0 pl-5 flex-1"
                  label="City"
                  errorMessage={formState?.errors.information?.location?.city?.message}
                  testId="supplierFormField-city"
                  disabled={readonly}
                  {...field}
                />
              )}
            />
            <Controller
              name="information.location.countryCode"
              control={control}
              render={({ field }) => (
                <LEGACY_Input
                  className="pt-0 pl-5 w-1/4"
                  label="Country Code"
                  errorMessage={formState?.errors.information?.location?.countryCode?.message}
                  testId="supplierFormField-country"
                  disabled={readonly}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className="ml-5 w-1/4" data-testid="supplierFormLabel-location">
          <Heading size="300">{copy.getSectionTitle(formMode, "location")}</Heading>
          <Paragraph size="200" className="mt-3 text-stone-600">
            {copy.getSectionDescription(formMode, "location")}
          </Paragraph>
        </div>
      </div>
      <div className="flex mb-8">
        <div className="w-3/4">
          <div className="flex">
            <Controller
              name="information.contactDetails.website"
              control={control}
              render={({ field }) => (
                <LEGACY_Input
                  className="pt-0 flex-1"
                  label="Website"
                  errorMessage={formState?.errors.information?.contactDetails?.website?.message}
                  testId="supplierFormField-website"
                  disabled={readonly}
                  {...field}
                />
              )}
            />
            <Controller
              name="information.contactDetails.phoneNumber"
              control={control}
              render={({ field }) => (
                <LEGACY_Input
                  className="pt-0 pl-5 flex-1"
                  label="Phone Number"
                  type="tel"
                  errorMessage={formState?.errors.information?.contactDetails?.phoneNumber?.message}
                  testId="supplierFormField-phone"
                  disabled={readonly}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex justify-between mt-5">
            <Controller
              name="information.contactDetails.rfqEmail"
              control={control}
              render={({ field }) => (
                <LEGACY_Input
                  className="pt-0 flex-1"
                  type="email"
                  label="RFQ Email"
                  errorMessage={formState?.errors.information?.contactDetails?.rfqEmail?.message}
                  testId="supplierFormField-rfqEmail"
                  disabled={readonly}
                  {...field}
                />
              )}
            />
            <Controller
              name="information.paymentData.paymentEmail"
              control={control}
              render={({ field }) => (
                <LEGACY_Input
                  className="pt-0 pl-5 flex-1"
                  type="email"
                  label="Payment Email"
                  errorMessage={formState?.errors.information?.paymentData?.paymentEmail?.message}
                  testId="supplierFormField-paymentEmail"
                  disabled={readonly}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className="ml-5 w-1/4" data-testid="supplierFormLabel-contactDetails">
          <Heading size="300">{copy.getSectionTitle(formMode, "contactDetails")}</Heading>
          <Paragraph size="200" className="mt-3 text-stone-600">
            {copy.getSectionDescription(formMode, "contactDetails")}
          </Paragraph>
        </div>
      </div>
      <div className="flex mb-8">
        <div className="w-3/4">
          <div className="flex justify-between">
            <Controller
              name="information.paymentData.paymentTerms"
              control={control}
              render={({ field }) => (
                <LEGACY_Input
                  className="flex-1 pt-0"
                  label="Payment Terms"
                  errorMessage={formState?.errors.information?.paymentData?.paymentTerms?.message}
                  testId="supplierFormField-paymentTerms"
                  disabled={readonly}
                  {...field}
                />
              )}
            />
            <Controller
              name="information.paymentData.paymentMethod"
              control={control}
              render={({ field }) => (
                <LEGACY_Input
                  className="flex-1 pt-0 ml-5"
                  label="Payment Method"
                  errorMessage={formState?.errors.information?.paymentData?.paymentMethod?.message}
                  testId="supplierFormField-paymentMethod"
                  disabled={readonly}
                  {...field}
                />
              )}
            />
            <Controller
              name="information.paymentData.currency"
              control={control}
              render={({ field }) => (
                <LEGACY_Input
                  className="flex-1 pt-0 ml-5"
                  label="Currency"
                  errorMessage={formState?.errors.information?.paymentData?.currency?.message}
                  testId="supplierFormField-currency"
                  disabled={readonly}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex mt-3">
            <Controller
              name="information.paymentData.iban"
              control={control}
              render={({ field }) => (
                <LEGACY_Input
                  className="flex-1 pt-0"
                  label="IBAN"
                  errorMessage={formState?.errors.information?.paymentData?.iban?.message}
                  testId="supplierFormField-iban"
                  disabled={readonly}
                  {...field}
                />
              )}
            />
            <Controller
              name="information.paymentData.swift"
              control={control}
              render={({ field }) => (
                <LEGACY_Input
                  className="flex-1 pt-0 ml-5"
                  label="Swift"
                  errorMessage={formState?.errors.information?.paymentData?.swift?.message}
                  testId="supplierFormField-swift"
                  disabled={readonly}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className="ml-5 w-1/4" data-testid="supplierFormLabel-paymentDetails">
          <Heading size="300">{copy.getSectionTitle(formMode, "paymentDetails")}</Heading>
          <Paragraph size="200" className="mt-3 text-stone-600">
            {copy.getSectionDescription(formMode, "paymentDetails")}
          </Paragraph>
        </div>
      </div>
      {formMode === "default" && (
        <div className="flex mb-8">
          <div className="flex-1">
            <div className="flex justify-between">
              <Controller
                name="information.userData.name"
                control={control}
                render={({ field }) => (
                  <LEGACY_Input
                    className="pt-0 flex-1"
                    label="Contact Person"
                    errorMessage={formState?.errors.information?.userData?.name?.message}
                    testId="supplierFormField-userName"
                    disabled={readonly}
                    {...field}
                  />
                )}
              />
              <Controller
                name="information.userData.email"
                control={control}
                render={({ field }) => (
                  <LEGACY_Input
                    className="pt-0 ml-5 flex-1"
                    type="email"
                    label="Contact Email"
                    errorMessage={formState?.errors.information?.userData?.email?.message}
                    testId="supplierFormField-userEmail"
                    disabled={readonly}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="ml-5 w-1/4">
            <Heading size="300">{copy.getSectionTitle(formMode, "user")}</Heading>
            <Paragraph size="200" className="mt-3 text-stone-600">
              {copy.getSectionDescription(formMode, "user")}
            </Paragraph>
          </div>
        </div>
      )}
      <div className="flex mb-8">
        <div className="flex-1">
          <Controller
            name="information.notes"
            control={control}
            render={({ field }) => (
              <Textarea
                label="Notes"
                testId="supplierFormField-notes"
                disabled={readonly}
                {...field}
              />
            )}
          />
        </div>
        <div className="ml-5 w-1/4" data-testid="supplierFormLabel-notes">
          <Heading size="300">{copy.getSectionTitle(formMode, "notes")}</Heading>
          <Paragraph size="200" className="mt-3 text-stone-600">
            {copy.getSectionDescription(formMode, "notes")}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
