import { Dispatch, createContext, useContext } from "react";

import { FeatureFlagsManagementState, FeatureFlagsManagementStateAction } from "../models";
import { defaultFeatureFlagsManagementState } from "./reducer";

export const FeatureFlagsManagementContext = createContext<
  [state: FeatureFlagsManagementState, action: Dispatch<FeatureFlagsManagementStateAction>]
>([
  defaultFeatureFlagsManagementState,
  () => {
    return;
  },
]);

export const useFeatureFlagsManagementContext = () => useContext(FeatureFlagsManagementContext);
