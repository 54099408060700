import { Transition } from "@headlessui/react";
import { CheckCircleIcon, ExclamationIcon, XCircleIcon, XIcon } from "@heroicons/react/outline";
import { Fragment, useCallback, useEffect, useState } from "react";

import { Label } from "../../atoms";

export type ToastMessage = {
  type: "success" | "failure" | "warning";
  message: string;
  id: string;
};

interface ToastProps {
  toastMessage: ToastMessage;
  onDismissToast: (id: string) => void;
}

const AUTO_FADE_DURATION = 4000;

export const Toast: React.FC<ToastProps> = ({ toastMessage, onDismissToast }) => {
  const [show, setShow] = useState(true);
  const [isAutoDismissEnabled, setIsAutoDismissEnabled] = useState<boolean>(true);
  const [autoDismissTimeout, setAutoDismissTimeout] = useState<number | null>(null);

  const handleDismissToast = useCallback(() => {
    // set visibility to `false` right away, but postpone clearing system message,
    // this ensures smoother transition if there is another message in the queue
    setShow(false);
    window.setTimeout(() => {
      onDismissToast(toastMessage.id);
    }, 500);
  }, [onDismissToast, toastMessage.id]);

  useEffect(() => {
    setShow(true);
  }, [toastMessage.id]);

  useEffect(() => {
    if (isAutoDismissEnabled) {
      if (autoDismissTimeout) {
        window.clearTimeout(autoDismissTimeout);
      }

      const timeoutId = window.setTimeout(() => {
        handleDismissToast();
      }, AUTO_FADE_DURATION);

      setAutoDismissTimeout(timeoutId);

      return () => {
        window.clearTimeout(timeoutId);
      };
    } else {
      if (autoDismissTimeout) {
        window.clearTimeout(autoDismissTimeout);
        setAutoDismissTimeout(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDismissToast, toastMessage.id, isAutoDismissEnabled]);

  const cancelDismissCountdown = useCallback(() => {
    setIsAutoDismissEnabled(false);
  }, []);

  const startDismissCountdown = useCallback(() => {
    setIsAutoDismissEnabled(true);
  }, []);

  return (
    <Transition
      as={Fragment}
      show={show}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        data-testid="toast"
        className="rounded-md bg-neutral_700 fixed right-6 bottom-6 p-4.5 w-96 flex items-center z-50"
        onMouseEnter={() => cancelDismissCountdown()}
        onMouseLeave={() => startDismissCountdown()}
      >
        {toastMessage.type === "failure" && <XCircleIcon className="text-dangerDefault w-5 mr-3" />}
        {toastMessage.type === "success" && (
          <CheckCircleIcon className="text-successDefault w-5 mr-3" />
        )}
        {toastMessage.type === "warning" && (
          <ExclamationIcon className="text-warningDefault w-5 mr-3" />
        )}
        <Label size="200" color="text-neutral_0" className="leading-none my-0">
          {toastMessage?.message.split("\n").map((msgLine, index) => (
            <Fragment key={index}>
              {msgLine}
              <br />
            </Fragment>
          ))}
        </Label>
        <div className="p-1 ml-auto cursor-pointer" onClick={handleDismissToast}>
          <XIcon className="text-neutral_0 w-4 ml-auto" />
        </div>
      </div>
    </Transition>
  );
};
