import { useCallback, useEffect, useMemo } from "react";

import { usePrevious } from "@web/utils";

import {
  ChangesEventType,
  ChangesSyncQueue,
  ChangesSyncService,
  IChangesEventListener,
  NewChange,
  StatusEventListener,
} from "../../../changesSync";
import { useOrderEditsSyncContext } from "./OrderEditsSyncContext";
import { IUseOrderEditsSync } from "./models";

export const useOrderEditsSync = (): IUseOrderEditsSync => {
  const [orderEditsSyncState, dispatch] = useOrderEditsSyncContext();
  const { editsToRollback, syncedEdits, isSyncInProgress } = orderEditsSyncState;

  const prevEditsToRollback = usePrevious(editsToRollback);
  const didEditsToRollbackChange = editsToRollback !== prevEditsToRollback;
  const prevSyncedEdits = usePrevious(syncedEdits);
  const didSyncedEditsChange = syncedEdits !== prevSyncedEdits;

  const reset = useCallback(() => {
    ChangesSyncService.clearQueue();
    dispatch({
      type: "reset",
    });
  }, [dispatch]);

  const handleSyncSuccess = useCallback(
    (newSyncedEdits: ChangesSyncQueue) => {
      dispatch({
        type: "addSyncedEdits",
        payload: newSyncedEdits,
      });
    },
    [dispatch]
  );

  const handleRollback = useCallback(
    (editsToRollback: ChangesSyncQueue) => {
      dispatch({
        type: "setEditsToRollback",
        payload: editsToRollback,
      });
    },
    [dispatch]
  );

  const handleEdits = useCallback(
    (payload: ChangesSyncQueue, eventType: ChangesEventType): void => {
      if (eventType === "rollback") {
        handleRollback(payload);
      } else if (eventType === "synced") {
        handleSyncSuccess(payload);
      }
    },
    [handleRollback, handleSyncSuccess]
  );

  const changesListener: IChangesEventListener = useMemo(
    () => ({
      changeTypes: [
        "orderItemQuantityChange",
        "priceModifierRemoval",
        "orderItemAddition",
        "orderItemReplacement",
      ],
      onChanges: handleEdits,
    }),
    [handleEdits]
  );

  useEffect(() => {
    ChangesSyncService.registerChangesListener(changesListener);

    return () => {
      ChangesSyncService.unregisterChangesListener(changesListener);
    };
  }, [changesListener]);

  const statusListener: StatusEventListener = useCallback(
    ({ eventType, eventPayload }) => {
      if (eventType === "isSyncInProgress") {
        dispatch({
          type: "setIsSyncInProgress",
          payload: eventPayload,
        });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    ChangesSyncService.registerStatusListener(statusListener);

    return () => {
      ChangesSyncService.unregisterStatusListener(statusListener);
    };
  }, [statusListener]);

  const addEdit = useCallback((edit: NewChange) => {
    ChangesSyncService.addToQueue(edit);
  }, []);

  return {
    editsToRollback,
    didEditsToRollbackChange,
    syncedEdits,
    didSyncedEditsChange,
    isSyncInProgress,
    addEdit,
    reset,
  };
};
