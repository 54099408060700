type Options = {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

export const formatNumber = (
  value: number,
  { minimumFractionDigits, maximumFractionDigits }: Options = {}
): string => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
};
