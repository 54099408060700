import { DotsVerticalIcon } from "@heroicons/react/outline";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import { useAuth0WithRedirect } from "@web/common";
import {
  Dropdown,
  DropdownItem,
  Header,
  IconButton,
  RegularDropdownItem,
  SidebarLayout,
} from "@web/ui";

import { RoutesConfig } from "src/admin/config/routes";
import Logo from "src/icons/S2SHeaderLogo.svg";

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const { logout } = useAuth0WithRedirect();
  const dropdownItems: DropdownItem[] = [
    {
      key: "signOut",
      renderComponent: () => (
        <RegularDropdownItem label="Sign out" variant="basic" onClick={() => logout()} />
      ),
    },
  ];

  return (
    <SidebarLayout
      header={
        <Header
          logo={
            <Link to={RoutesConfig.home} className="flex">
              <img src={Logo} />
            </Link>
          }
          right={
            <Dropdown hAlignTo="end" vAlignTo="bottom" items={dropdownItems}>
              <IconButton
                size="large"
                variant="secondary"
                shape="square"
                label="Menu"
                Icon={DotsVerticalIcon}
                data-testid="settingsDropdown_toggle"
                isControlled
              />
            </Dropdown>
          }
        />
      }
      noPadding={false}
    >
      {children}
    </SidebarLayout>
  );
};
