const sizes = ["B", "KB", "MB", "GB", "TB"];
const emptySize = "0 B";
const iterationSize = 1024;

export const formatFileSize = (bytes: number) => {
  if (bytes <= 0) {
    return emptySize;
  }
  const sizeType = Math.floor(Math.log(bytes) / Math.log(iterationSize));
  const size = parseFloat((bytes / Math.pow(iterationSize, sizeType)).toFixed(2));
  return `${size} ${sizes[sizeType]}`;
};
