import { useEffect, useState } from "react";

import { isDefined } from "@web/utils";

import { SupplierOrder, useOrderAcknowledgeSeenMutation, useUserPermissions } from "src/domain";

interface Props {
  order: SupplierOrder;
  onAcknowledgeSuccess: () => void;
  onAcknowledgeNotNeeded: () => void;
}

export const AcknowledgeOrderSeen: React.FC<Props> = ({
  order,
  onAcknowledgeSuccess,
  onAcknowledgeNotNeeded,
}) => {
  const { isSupervisor } = useUserPermissions();

  const [wasAcknowledged, setWasAcknowledged] = useState<boolean>(false);

  // We need to wait for `isSupervisor` permission to be resolved, and only then check for it
  const canAcknowledgementBeResolved = isDefined(isSupervisor);
  const canAcknowledge = canAcknowledgementBeResolved ? !order.seen && !isSupervisor : false;

  const acknowledgeOrderSeenMutation = useOrderAcknowledgeSeenMutation({
    onSuccess: () => {
      onAcknowledgeSuccess();
      setWasAcknowledged(true);
    },
  });

  useEffect(() => {
    if (wasAcknowledged || acknowledgeOrderSeenMutation.isPending) {
      return;
    }

    if (canAcknowledge) {
      acknowledgeOrderSeenMutation.mutate({ s2SOrderId: order.orderId });
      return;
    }

    // Do not call if we don't know if we can acknowledge yet
    if (canAcknowledgementBeResolved) {
      onAcknowledgeNotNeeded();
    }
  }, [
    wasAcknowledged,
    acknowledgeOrderSeenMutation.isPending,
    acknowledgeOrderSeenMutation,
    onAcknowledgeNotNeeded,
    canAcknowledgementBeResolved,
    canAcknowledge,
    order.orderId,
  ]);

  return null;
};
