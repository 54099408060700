import { ArrowDownIcon, ArrowUpIcon, FilterIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useRef, useState } from "react";

import { IntersectionMonitor } from "@web/common";
import { DropdownItem, Loading, RegularDropdownItem } from "@web/ui";

import { queryStatusToApiOrderStatusMap } from "src/config/routes";
import {
  ColumnName,
  Filter,
  FilterOptions,
  FilterValue,
  SupplierOrdersSortBy,
  useFiltersConfigurationQuery,
  useOrdersQuery,
} from "src/domain";

import { OrdersStatus } from "../../models";
import { OrdersTableFilters } from "../OrdersTableFilters";
import { OrdersTableUI } from "../OrdersTableUI";

type Props = {
  status?: OrdersStatus;
  closed?: boolean;
};

const defaultFilterOptions = {
  vessels: [],
  buyers: [],
  ports: [],
};

export const OrdersTable = ({ status, closed }: Props) => {
  const recentlyAddedFilterRef = useRef<HTMLButtonElement>(null);

  const [sortDirValue, setSortDirValue] = useState<"ASC" | "DESC">("DESC");
  const [sortByValue, setSortByValue] = useState<SupplierOrdersSortBy | undefined>("CREATED_DATE");
  const [filters, setFilters] = useState<Filter[]>([]);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>(defaultFilterOptions);

  const orderStatus = status ? queryStatusToApiOrderStatusMap.get(status) : undefined;
  const ordersQuery = useOrdersQuery({
    status: orderStatus,
    sortDir: sortDirValue,
    sortBy: sortByValue,
    vessels: filters
      .find((f) => f.columnName === "VESSEL_NAME")
      ?.selectedValues?.map((val) => val.value),
    buyers: filters
      .find((f) => f.columnName === "CUSTOMER_NAME")
      ?.selectedValues?.map((val) => val.value),
    ports: filters
      .find((f) => f.columnName === "DELIVERY_PORT")
      ?.selectedValues?.map((val) => val.value),
    closed,
  });

  const filtersConfiguration = useFiltersConfigurationQuery();

  const orderRequisitionsList = ordersQuery.data?.pages.flatMap((p) => p.orders.items);

  const sortFilterValueName = (a: FilterValue, b: FilterValue) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;

  const getDropdownOptions = useCallback(
    ({
      sortBy,
      filterBy,
    }: {
      sortBy?: SupplierOrdersSortBy;
      filterBy?: ColumnName;
    }): Array<DropdownItem> => [
      {
        key: "sortAscending",
        renderComponent: () => (
          <RegularDropdownItem
            label="Sort ascending"
            variant="basic"
            LeadingIcon={ArrowUpIcon}
            onClick={() => {
              setSortDirValue("ASC");
              setSortByValue(sortBy);
            }}
          />
        ),
      },
      {
        key: "sortDescending",
        renderComponent: () => (
          <RegularDropdownItem
            label="Sort descending"
            variant="basic"
            LeadingIcon={ArrowDownIcon}
            onClick={() => {
              setSortDirValue("DESC");
              setSortByValue(sortBy);
            }}
          />
        ),
      },
      ...(filterBy
        ? [
            {
              key: "filter",
              renderComponent: () => (
                <RegularDropdownItem
                  label="Filter"
                  variant="basic"
                  LeadingIcon={FilterIcon}
                  onClick={() => {
                    if (!filters || !filterBy) {
                      return;
                    }

                    if (filters.find((f) => f.columnName === filterBy)) {
                      return filters;
                    }

                    setFilters([...filters, { columnName: filterBy }]);

                    // It has to go out the "event loop" to make sure the filter is added before the click event is triggered
                    setTimeout(() => {
                      recentlyAddedFilterRef.current?.click();
                    }, 10);
                  }}
                  data-testid={`dropdownOption-${filterBy}-filter`}
                />
              ),
            },
          ]
        : []),
    ],
    [filters]
  );

  const filterOptionsFormatted = (options: FilterValue[]) => {
    const newSet = options.filter(
      (value, index, self) => index === self.findIndex((t) => t.value === value.value)
    );
    return newSet;
  };

  useEffect(() => {
    if (!filtersConfiguration.data || filterOptions.vessels.length > 0) {
      return;
    }

    setFilterOptions({
      vessels: filterOptionsFormatted(
        filtersConfiguration.data.vessels
          .map((v) => ({
            name: v.name,
            value: v.imoNumber,
          }))
          .sort(sortFilterValueName)
      ),
      buyers: filterOptionsFormatted(
        filtersConfiguration.data.buyers
          .map((b) => ({
            name: b.name,
            value: b.name,
          }))
          .sort(sortFilterValueName)
      ),
      ports: filterOptionsFormatted(
        filtersConfiguration.data.ports
          .map((p) => ({
            name: p.name,
            value: p.locationCode,
          }))
          .sort(sortFilterValueName)
      ),
    });
  }, [filterOptions.vessels.length, filtersConfiguration]);

  useEffect(() => {
    // Only proceed if filters is an empty array
    if (filters.length === 0) {
      const storedFilters = window.localStorage.getItem("ordersTableFilters");

      // If storedFilters is not null or undefined, parse and set them
      if (storedFilters) {
        try {
          const parsedFilters = JSON.parse(storedFilters);
          setFilters(parsedFilters);
        } catch (error) {
          // Clear localStorage if the data is corrupted
          window.localStorage.removeItem("ordersTableFilters");
        }
      }

      // If filters is empty and there are no storedFilters, or if the filters
      // have not changed, there's nothing more to do
      return;
    }

    // If filters have changed, update localStorage
    const serializedFilters = JSON.stringify(filters);
    if (serializedFilters !== window.localStorage.getItem("ordersTableFilters")) {
      window.localStorage.setItem("ordersTableFilters", serializedFilters);
    }
  }, [filters]);

  return (
    <>
      <OrdersTableFilters
        filters={filters}
        setFilters={setFilters}
        recentlyAddedFilterRef={recentlyAddedFilterRef}
        filterOptions={filterOptions}
      />

      <OrdersTableUI
        items={orderRequisitionsList}
        getHeaderCellDropdownOptions={getDropdownOptions}
        isLoading={ordersQuery.status !== "success"}
      />

      {ordersQuery.hasNextPage && (
        <IntersectionMonitor onEnter={ordersQuery.fetchNextPage}>
          <div className="h-10 max-w-6xl">{ordersQuery.isFetchingNextPage && <Loading />}</div>
        </IntersectionMonitor>
      )}
    </>
  );
};
