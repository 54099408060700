import { DefaultError, useMutation } from "@tanstack/react-query";

import { PriceModifierInformationChangeResponse } from "src/typegens";

import { addPriceModifier } from "../api";
import { AddPriceModifierParams } from "../models";

export const useAddPriceModifierMutation = (options = {}) => {
  return useMutation<PriceModifierInformationChangeResponse, DefaultError, AddPriceModifierParams>({
    mutationKey: ["addPriceModifier"],
    mutationFn: addPriceModifier,
    ...options,
  });
};
