import { FlagProvider } from "@unleash/proxy-client-react";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { IConfig } from "unleash-proxy-client";

import { FeatureFlagsManagerController } from "../components";
import { Environment, UnleashEnvironment } from "../models";
import { mapEnvironmentToUnleashProxyEnvironment } from "../utils";
import { FeatureFlagsManagementContext } from "./FeatureFlagsManagementContext";
import { FeatureFlagsManagementStateLifecycle } from "./FeatureFlagsManagementStateLifecycle";
import { useFeatureFlagsManagementReducer } from "./reducer";

type Props = {
  environment: Environment;
  unleashProxyClientKey: string;
  unleashProxyUrl: string;
  unleashAppName: string;
  unleashRefreshInterval: number;
};

export const FeatureFlagsManagementProvider = ({
  children,
  environment,
  unleashProxyClientKey,
  unleashProxyUrl,
  unleashAppName,
  // Refresh interval in seconds
  unleashRefreshInterval,
}: PropsWithChildren<Props>) => {
  const [featureFlagsManagementState, dispatch] = useFeatureFlagsManagementReducer();

  useEffect(
    () =>
      dispatch({
        type: "setEnvironment",
        payload: environment,
      }),
    [dispatch, environment]
  );

  const unleashEnvironment = mapEnvironmentToUnleashProxyEnvironment(
    environment
  ) as UnleashEnvironment;

  const unleashConfig: IConfig = useMemo(
    () => ({
      url: unleashProxyUrl,
      clientKey: unleashProxyClientKey,
      refreshInterval: unleashRefreshInterval, // seconds
      appName: unleashAppName,
      environment: mapEnvironmentToUnleashProxyEnvironment(environment),
      // If we pass even an empty array to `bootstrap`, Unleash will instantly mark `flagsReady` as `true`,
      // without waiting for the actual fetch to happen.
    }),
    [environment, unleashAppName, unleashProxyClientKey, unleashProxyUrl, unleashRefreshInterval]
  );

  /**
   * Remember to set up Unleash context with `unleashContextFlagProperties` deeper in the application:
   * ```
   * const { unleashContextFlagProperties } = useFeatureFlagsManagement();
   * useEffect(() => {
   *   updateContext({ userId: ..., properties: unleashContextFlagProperties });
   * }, [..., unleashContextFlagProperties]);
   * ```
   *
   * Also remember to send flags overrides to the application APIs, as high as possible:
   * ```
   * const { unleashContextFlagProperties } = useFeatureFlagsManagement();
   * useEffect(() => {
   *   OpenAPI.HEADERS = {
   *     [UNLEASH_ATTRIBUTES_HEADER_NAME]: JSON.stringify(unleashContextFlagProperties),
   *   };
   * }, [unleashContextFlagProperties]);
   * ```
   */
  return (
    <FeatureFlagsManagementContext.Provider value={[featureFlagsManagementState, dispatch]}>
      <FlagProvider config={unleashConfig}>
        {featureFlagsManagementState.isFlagsManagementAllowed && (
          <>
            <FeatureFlagsManagementStateLifecycle
              unleashProxyClientKey={unleashProxyClientKey}
              unleashProxyUrl={unleashProxyUrl}
              unleashEnvironment={unleashEnvironment}
              unleashAppName={unleashAppName}
            />
            <FeatureFlagsManagerController />
          </>
        )}
        {children}
      </FlagProvider>
    </FeatureFlagsManagementContext.Provider>
  );
};
