import { useParams } from "react-router-dom";

import { RoutesParams } from "src/config/routes";

import { OrderDetailsQuery } from "./components/OrderDetailsQuery/OrderDetailsQuery";

export const OrderDetails: React.FC = () => {
  const { [RoutesParams.ORDER_ID]: orderId } = useParams() as { orderId: string };

  return <OrderDetailsQuery orderId={orderId} />;
};
