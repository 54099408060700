import { DefaultError, useMutation } from "@tanstack/react-query";

import { exportOrderToExcel } from "../api";
import { ExportOrderToExcelParams } from "../models";

export const useExportOrderToExcelMutation = (options = {}) => {
  return useMutation<Blob, DefaultError, ExportOrderToExcelParams>({
    mutationKey: ["exportOrderToExcel"],
    mutationFn: exportOrderToExcel,
    ...options,
  });
};
