import { Blocker, History, Transition } from "history";
import { useContext, useEffect, useState } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

type ExtendedNavigator = Navigator & Pick<History, "block">;

/**
 * This hook is a reimplementation of the useBlocker hook from `react-router-dom` library,
 * which was removed in v6 with intention to provide another way for route blocking in the future.
 * When this issue is resolved in the library, the new way of route blocking should be used
 * in the app and this hook should be removed.
 *
 * Due to upgrade to react v18 that need to be updated to handle multiple block subscriptions
 * and move unsubscription outside of useEffect hook to make sure it won't be delayed
 *
 * More information here:
 * https://github.com/remix-run/react-router/issues/8139
 * https://github.com/remix-run/react-router/releases/tag/v6.0.0-beta.7
 * https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
export const useBlocker = (blocker: Blocker, when = true) => {
  const { navigator } = useContext(NavigationContext);
  const [unblock, setUnblock] = useState<(() => void) | undefined>(undefined);

  useEffect(() => {
    if (!when || typeof unblock === "function") {
      return;
    }

    const unblockFn = (navigator as unknown as ExtendedNavigator).block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblockFn();
          if (tx.action === "POP") {
            navigator.push(tx.location);
          } else {
            tx.retry();
          }
        },
      };

      blocker(autoUnblockingTx);
    });

    setUnblock(() => unblockFn);
  }, [navigator, blocker, when, unblock]);

  if (!when && typeof unblock === "function") {
    unblock();
    setUnblock(undefined);
  }
};
