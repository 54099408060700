import { DefaultError, useMutation } from "@tanstack/react-query";

import { exportOrderToMtml } from "../api";
import { ExportOrderToMtmlParams } from "../models";

export const useExportOrderToMtmlMutation = (options = {}) => {
  return useMutation<Blob, DefaultError, ExportOrderToMtmlParams>({
    mutationKey: ["exportOrderToMtml"],
    mutationFn: exportOrderToMtml,
    ...options,
  });
};
