import { LoadingInline } from "../../Loading";
import { ButtonTypography } from "../../Typography";
import { RegularCommonProps } from "./models";
import { useRegularCommons } from "./useRegularCommons";

export const RegularChildren = (props: RegularCommonProps) => {
  const { iconSizeClassNames, typographySize, loaderSize, defaultedProps } =
    useRegularCommons<unknown>(props);
  const { LeadingIcon, TrailingIcon, label, loading, "data-testid": testId } = defaultedProps;

  return (
    <>
      {LeadingIcon && <LeadingIcon className={iconSizeClassNames} />}
      <ButtonTypography size={typographySize}>{label}</ButtonTypography>
      {TrailingIcon && <TrailingIcon className={iconSizeClassNames} />}

      {loading && (
        <span className="block absolute" {...(testId ? { "data-testid": `${testId}_loader` } : {})}>
          <LoadingInline baseColor="text-textIcon-blackPrimary" size={loaderSize} display="block" />
        </span>
      )}
    </>
  );
};
