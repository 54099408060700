import { DefaultError, useMutation } from "@tanstack/react-query";

import { OrderVersionResponse } from "src/typegens";

import { rejectOrder } from "../api";
import { SupplierRejectOrderParams } from "../models";

export const useOrderRejectMutation = (options = {}) => {
  return useMutation<OrderVersionResponse, DefaultError, SupplierRejectOrderParams>({
    mutationKey: ["rejectOrder"],
    mutationFn: rejectOrder,
    ...options,
  });
};
