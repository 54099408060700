import { DiscardChangesConfirmationModal } from "@web/common";
import { Modal } from "@web/ui";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  onClose: () => void;
}

export const AdminEditDiscardChangesConfirmationModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  onConfirm,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <DiscardChangesConfirmationModal onConfirm={onConfirm} onClose={onClose} />
    </Modal>
  );
};
