import { MailIcon, OfficeBuildingIcon, PhoneIcon } from "@heroicons/react/solid";

import { AgentInformation } from "@web/models";

import { Paragraph } from "../Typography";

interface AgentInformationProps {
  agentInformation?: AgentInformation;
  onContactClick?: () => void;
  variant: "base" | "expanded";
}

export const Agent: React.FC<AgentInformationProps> = ({
  agentInformation = {},
  onContactClick,
  variant = "base",
}) => {
  const isBaseVariant = variant === "base";
  const isExpandedVariant = variant === "expanded";

  if (Object.values(agentInformation ?? {}).filter((value) => value !== "").length === 0) {
    return null;
  }

  const Info = ({ children }: { children: React.ReactNode }) => {
    return <div className="flex w-full justify-between truncate gap-3">{children}</div>;
  };

  return (
    <div>
      <Paragraph size="300" color="text-textIcon-blackSecondary" className="pb-2 uppercase">
        Agent
      </Paragraph>
      <Info>
        <div className="flex gap-3">
          <OfficeBuildingIcon
            className="-ml-0.5 text-textIcon-blackSecondary inline-block"
            width="20px"
          />
          <div className="flex flex-col">
            <div className="text-textIcon-blackPrimary text-sm font-normal h-4">{`${agentInformation.firstName} ${agentInformation.lastName}`}</div>
            <div className="text-textIcon-blackSecondary text-xs font-normal">
              {agentInformation.company}
            </div>
          </div>
        </div>
        {isBaseVariant && (
          <div className="flex gap-3">
            <PhoneIcon
              width="20px"
              onClick={onContactClick}
              className="text-textIcon-blackSecondary cursor-pointer"
            />
            <MailIcon
              data-testid="agent_mailIcon"
              onClick={onContactClick}
              width="20px"
              className="text-textIcon-blackSecondary cursor-pointer"
            />
          </div>
        )}
        {isExpandedVariant && (
          <div className="flex flex-col text-neutral_700">
            <div className="flex items-center gap-3 cursor-pointer">
              <MailIcon
                className="w-4 h-4 text-textIcon-blackSecondary"
                onClick={onContactClick}
                data-testid="agent_mailIcon"
              />
              <Paragraph size="200">{agentInformation.email}</Paragraph>
            </div>
            <div className="flex items-center gap-3 cursor-pointer">
              <PhoneIcon className="w-4 h-4 inline" onClick={onContactClick} />
              <Paragraph className="inline text-base" size="200">
                {agentInformation.phoneNumber}
              </Paragraph>
            </div>
          </div>
        )}
      </Info>
    </div>
  );
};
