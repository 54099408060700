import { DotsVerticalIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { forwardRef, useCallback } from "react";

type Props = {
  label: string;
  className?: string;
  "data-testid"?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type TableActionsButtonProps = Props &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "children" | keyof Props>;

export const TableActionsButton = forwardRef<HTMLButtonElement, TableActionsButtonProps>(
  (props, forwardedRef) => {
    // Removing illegal and defaulted props so only valid ones are applied to the HTML tag
    const { className, onClick, label, "data-testid": testId = "", ...buttonTagProps } = props;

    const handleOnClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!onClick) {
          return;
        }
        onClick(event);
      },
      [onClick]
    );

    return (
      <button
        ref={forwardedRef}
        {...buttonTagProps}
        className={classnames(
          "inline-flex justify-center items-center",
          "relative overflow-hidden whitespace-nowrap leading-none",
          "border-0 rounded-[2px]",
          "h-[20px] w-[20px] min-h-[20px] min-w-[20px] max-h-[20px] max-w-[20px]",
          "bg-transparent text-textIcon-blackSecondary ring-primaryDefault",
          "hover:bg-neutral_300",
          "active:bg-neutral_300 active:text-textIcon-blackPrimary",
          "focus:outline-none focus-visible:ring-2",
          className
        )}
        onClick={handleOnClick}
        {...(testId ? { "data-testid": testId } : {})}
        // Overwrite `type` to "button"
        type="button"
      >
        <DotsVerticalIcon className="w-[18px] h-[18px]" aria-label={label} focusable={false} />
      </button>
    );
  }
);
TableActionsButton.displayName = "TableActionsButton";
