import { ApiError } from "@web/attachments";

import { useApiError } from "src/domain/apiError";
import { ValidationError } from "src/typegens";

const presentableErrorParser = (file: File) => {
  return (fieldError: ValidationError): string => `"${file.name}": ${fieldError.defaultMessage}`;
};

const fallbackErrorParser = (file: File) => {
  return (message: string): string => message.replace("{fileName}", file.name);
};

export const useFileUploadApiError = () => {
  const { showApiError } = useApiError({
    one: "There was an error while uploading your file:\n{errors}\nPlease try again.",
    many: "There were some errors while uploading your file:\n{errors}\nPlease try again.",
    fallback: 'There was an error while uploading your file: "{fileName}". Please try again.',
  });

  const showFileUploadApiError = (file: File, error: ApiError) =>
    showApiError(error, presentableErrorParser(file), fallbackErrorParser(file));

  return {
    showFileUploadApiError,
  };
};
