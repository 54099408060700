import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { getAllUnleashToggles } from "../api";
import { GetAllUnleashTogglesParams, GetAllUnleashTogglesResponse } from "../models";

const QUERY_KEY_BASE = "allUnleashToggles";

export const useAllUnleashTogglesQuery = (
  {
    unleashProxyClientKey,
    unleashProxyUrl,
    unleashEnvironment,
    unleashAppName,
  }: GetAllUnleashTogglesParams,
  options?: { enabled: boolean }
) => {
  const queryClient = useQueryClient();

  const query = useQuery<GetAllUnleashTogglesResponse>({
    queryKey: [
      QUERY_KEY_BASE,
      unleashProxyClientKey,
      unleashProxyUrl,
      unleashEnvironment,
      unleashAppName,
    ],
    queryFn: ({ signal }) =>
      getAllUnleashToggles(
        {
          unleashProxyClientKey,
          unleashProxyUrl,
          unleashEnvironment,
          unleashAppName,
        },
        { signal }
      ),
    ...(options ? options : {}),
  });

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY_BASE,
          unleashProxyClientKey,
          unleashProxyUrl,
          unleashEnvironment,
          unleashAppName,
        ],
      }),
    [queryClient, unleashProxyClientKey, unleashProxyUrl, unleashEnvironment, unleashAppName]
  );

  return { query, invalidate };
};
