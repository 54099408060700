import { useState } from "react";

export interface ModalOptions {
  isOpen: boolean;
  component: React.ReactElement | null;
}

export type CloseModalCallback = {
  (...args: [cb?: () => void] | []): void;
};

const useModal = (initialState: ModalOptions) => {
  const [isOpen, setIsOpen] = useState(initialState.isOpen);
  const [component, setComponent] = useState<React.ReactElement | null>(null);

  const handleCloseModal: CloseModalCallback = (cb?: () => void) => {
    setIsOpen(false);
    if (cb && typeof cb === "function") {
      cb();
    }
  };
  const handleOpenModal = (component: React.ReactElement) => {
    setIsOpen(true);
    setComponent(component);
  };

  return { component, isOpen, closeModal: handleCloseModal, openModal: handleOpenModal };
};

export default useModal;
