import { Dispatch, createContext, useContext } from "react";

import { SystemMessages, SystemMessagesAction } from "./models";
import { defaultSystemMessages } from "./reducer";

export const SystemMessagesContext = createContext<
  [state: SystemMessages, action: Dispatch<SystemMessagesAction>]
>([
  defaultSystemMessages,
  () => {
    return;
  },
]);

export const useSystemMessagesContext = () => useContext(SystemMessagesContext);
