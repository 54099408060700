import { DefaultError, useMutation } from "@tanstack/react-query";

import { OrderVersionResponse } from "src/typegens";

import { unassignUserFromPO } from "../api";

export const useUnassignUserFromPOMutation = (options = {}) => {
  return useMutation<OrderVersionResponse, DefaultError, { s2SOrderId: string; version: number }>({
    mutationKey: ["unassignUserFromPO"],
    mutationFn: unassignUserFromPO,
    ...options,
  });
};
