import classnames from "classnames";

import { LoadingInline } from "../../Loading";
import { ButtonTypography, ButtonTypographyProps } from "../../Typography";
import { ComboCommonProps } from "./models";
import { useComboCommons } from "./useComboCommons";

export const ComboChildren = (props: ComboCommonProps) => {
  const { buttonVariantIconContainerClassNames, iconContainerRoundnessClassNames, defaultedProps } =
    useComboCommons<unknown>(props);
  const { iconPlacement, Icon, label, loading, "data-testid": testId } = defaultedProps;
  const typographySize: ButtonTypographyProps["size"] = "100";

  return (
    <>
      {iconPlacement === "trailing" && (
        <ButtonTypography size={typographySize}>{label}</ButtonTypography>
      )}
      <span
        className={classnames(
          "inline-flex justify-center items-center",
          "border",
          "h-[40px] w-[40px] min-h-[40px] min-w-[40px] max-h-[40px] max-w-[40px]",
          "relative",
          iconContainerRoundnessClassNames,
          buttonVariantIconContainerClassNames
        )}
      >
        <Icon className={classnames("w-[16px] h-[16px]", { hidden: loading })} focusable={false} />
        {loading && (
          <span
            className="block absolute"
            {...(testId ? { "data-testid": `${testId}_loader` } : {})}
          >
            <LoadingInline baseColor="text-textIcon-blackPrimary" size="4.5" display="block" />
          </span>
        )}
      </span>
      {iconPlacement === "leading" && (
        <ButtonTypography size={typographySize}>{label}</ButtonTypography>
      )}
    </>
  );
};
