import { XIcon } from "@heroicons/react/solid";
import { useState } from "react";

import { Heading } from "@web/ui";

import { SupplierOrderItemForm } from "../../models";
import { Candidate } from "../Candidate";
import { SearchResults } from "../SearchResults";
import { SearchSkuInput } from "../SearchSkuInput";

interface Props {
  children?: React.ReactNode;
  orderId: string;
  onAddItem: (item: SupplierOrderItemForm) => void;
  closeModal: () => void;
  excludedItemIds: string[];
}

export const AddItemModal = ({ orderId, onAddItem, closeModal, excludedItemIds }: Props) => {
  const [searchPhrase, setSearchPhrase] = useState("");
  const [additionCandidate, setAdditionCandidate] = useState<SupplierOrderItemForm | null>(null);

  const onSearch = (phrase: string) => {
    setSearchPhrase(phrase);
  };

  const onAddSelect = (itemToAdd: SupplierOrderItemForm) => {
    setAdditionCandidate(itemToAdd);
  };

  const onAddReject = () => {
    setSearchPhrase("");
    setAdditionCandidate(null);
  };

  const onAddConfirm = () => {
    if (additionCandidate !== null) {
      onAddItem(additionCandidate);
    }
  };

  return (
    <div
      className="inline-block bg-neutral_0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all  align-middle max-w-2xl w-full p-5"
      data-testid="addItemModal"
    >
      <Heading size="300" className="mb-2">
        Add Item
      </Heading>
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-text-whiteDisabled hover:text-neutral_800"
          data-testid="closeAddItemModalButton"
          onClick={closeModal}
        >
          <span className="sr-only">Close modal</span>
          <XIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col">
        {additionCandidate ? (
          <Candidate
            candidate={additionCandidate}
            onReject={onAddReject}
            onConfirm={onAddConfirm}
            confirmButtonLabel="Add Item"
          />
        ) : (
          <div className="mt-2">
            <SearchSkuInput handleSearch={onSearch} />
            <SearchResults
              searchPhrase={searchPhrase}
              orderId={orderId}
              onItemClick={onAddSelect}
              itemActionType="add"
              excludedItemIds={excludedItemIds}
            />
          </div>
        )}
      </div>
    </div>
  );
};
