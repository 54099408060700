import { useNavigate } from "react-router-dom";

import { Loading } from "@web/ui";

import { RoutesConfig } from "src/config/routes";
import { BackActionHeader, useSupplierInformationQuery } from "src/domain";

import { CompanyProfileForm } from "./components/CompanyProfileForm";

export const CompanyProfile = () => {
  const navigate = useNavigate();
  const supplierInformationQuery = useSupplierInformationQuery();

  if (supplierInformationQuery.isPending || supplierInformationQuery.isFetching) {
    return <Loading />;
  }

  if (supplierInformationQuery.isError) {
    // Error is handled in `ErrorBoundary`
    return null;
  }

  return (
    <div className="bg-neutral_100 min-h-screen">
      <BackActionHeader
        backActionTitle="Back"
        backActionCallback={() => navigate(RoutesConfig.home)}
      />
      <div>
        <div className="max-w-[1024px] mx-auto px-8 py-6 mb-10">
          <CompanyProfileForm supplierInformationData={supplierInformationQuery.data} />
        </div>
      </div>
    </div>
  );
};
