import { PropsWithChildren } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth0WithRedirect } from "@web/common";

import { useAppScopes } from "src/hooks/useAppScopes";

import { ErrorBoundary } from "./ErrorBoundary";

export const ErrorBoundaryRenderer: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth0WithRedirect();
  const isAdminUser = useAppScopes("read:suppliers", "write:suppliers");

  return (
    <ErrorBoundary
      isAdminUser={isAdminUser}
      logout={logout}
      navigate={navigate}
      pathname={location.pathname}
    >
      {children}
    </ErrorBoundary>
  );
};
