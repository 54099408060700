import { DangerConfirmationModal } from "@web/ui";

interface Props {
  title?: string;
  message?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm: () => void;
  onClose: () => void;
  testId?: string;
}

export const DiscardChangesConfirmationModal: React.FC<Props> = ({
  title = "Are you sure you want to leave without saving?",
  message = "If you leave without saving, your changes will be lost.",
  confirmLabel = "Discard changes",
  cancelLabel = "Cancel",
  onConfirm,
  onClose,
  testId = "discardChangesConfirmationModal",
}) => {
  return (
    <DangerConfirmationModal
      onConfirm={onConfirm}
      onClose={onClose}
      title={title}
      subtitle={message}
      closeButtonLabel={cancelLabel}
      confirmButtonLabel={confirmLabel}
      testIdPrefix={testId}
    />
  );
};
