import { DefaultError, useQuery, useQueryClient } from "@tanstack/react-query";
import _isNumber from "lodash/isNumber";
import _isString from "lodash/isString";
import { useCallback, useMemo } from "react";

import { SupplierPortalAppService } from "src/typegens";

import { SupplierOrder } from "../models";
import { SupplierOrderService } from "../services";

type OrderVersion = number | "LATEST" | "OLDEST";

const QUERY_KEY_BASE = "getOrderById";

const getQueryKey = (orderId: string, orderVersion: OrderVersion) => [
  QUERY_KEY_BASE,
  orderId,
  orderVersion,
];

export const useOrderByIdQuery = <T = SupplierOrder>({
  orderId,
  orderVersion,
  select,
  enabled = true,
}: {
  orderId: string;
  orderVersion: OrderVersion;
  select?: (data: SupplierOrder) => T;
  enabled?: boolean;
}) => {
  const queryKey = useMemo(() => getQueryKey(orderId, orderVersion), [orderId, orderVersion]);
  return useQuery<SupplierOrder, DefaultError, T>({
    queryKey,
    queryFn: async () => {
      const response = await SupplierPortalAppService.getOrderById({
        s2SOrderId: orderId,
        version: _isNumber(orderVersion) ? orderVersion : undefined,
        versionVariant: _isString(orderVersion) ? orderVersion : undefined,
      });

      return SupplierOrderService.convertFromApiToSupplierOrder(response);
    },
    select,
    enabled,
  });
};

type UseOrderByIdQueryHelpers = {
  invalidate: () => void;
};

export const useOrderByIdQueryHelpers = ({
  orderId,
  orderVersion,
}: {
  orderId: string;
  orderVersion: OrderVersion;
}): UseOrderByIdQueryHelpers => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => getQueryKey(orderId, orderVersion), [orderId, orderVersion]);

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
    [queryClient, queryKey]
  );

  return {
    invalidate,
  };
};
