import { DeliveryDateDetails } from "@web/ui";
import { extractFromISODateString } from "@web/utils";

interface DesiredDeliveryTimeProps {
  deliveryDate: string;
}

export const DesiredDeliveryTime: React.FC<DesiredDeliveryTimeProps> = ({ deliveryDate }) => {
  return (
    <DeliveryDateDetails
      date={extractFromISODateString(deliveryDate, "justDate")}
      hour={extractFromISODateString(deliveryDate, "justTime")}
      label="Delivery Date"
    />
  );
};
