import { useCallback } from "react";

import { Toast } from "@web/ui";

import { SystemMessagesContext } from "./SystemMessagesContext";
import { useSystemMessagesReducer } from "./reducer";

interface Props {
  children: React.ReactNode;
}

export const SystemMessagesProvider = ({ children }: Props) => {
  const [systemMessages, dispatch] = useSystemMessagesReducer();
  const clearMessage = useCallback(
    (id: string) => dispatch({ type: "clearSystemMessage", value: { id } }),
    [dispatch]
  );
  const currentMessage = systemMessages[0];

  return (
    <SystemMessagesContext.Provider value={[systemMessages, dispatch]}>
      {children}
      {currentMessage && <Toast toastMessage={currentMessage} onDismissToast={clearMessage} />}
    </SystemMessagesContext.Provider>
  );
};
