import { useEffect, useRef } from "react";

/**
 * Cache any value between two render cycles of a React component
 *
 * @param value Any value that should be cached and returned as previous value in the next render cycle
 * @return The value that the passed argument had in the previous render cycle
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
