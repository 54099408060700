import { useReducer } from "react";

import { ScopeAction, ScopeState } from "./models";

export const defaultScopes: ScopeState = [];

const ScopeReducer = (scope: ScopeState, action: ScopeAction): ScopeState => {
  switch (action.type) {
    case "setScopeAction":
      return action.value;
    default:
      return scope;
  }
};

export const useScopeReducer = () => useReducer(ScopeReducer, defaultScopes);
