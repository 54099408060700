import { CalendarIcon, ClockIcon } from "@heroicons/react/solid";

import { Paragraph } from "../Typography";

interface DeliveryTimeProps {
  label: string;
  date: string;
  hour: string;
}

export const DeliveryDateDetails: React.FC<DeliveryTimeProps> = ({ date, hour, label }) => {
  return (
    <div>
      <Paragraph size="300" color="text-textIcon-blackSecondary" className="pb-2 uppercase">
        {label}
      </Paragraph>
      <div className="flex flex-row items-center gap-3">
        <div className="flex gap-3">
          <CalendarIcon className="-ml-0.5 text-textIcon-blackSecondary" width="20px" />
          <Paragraph size="200" color="text-neutral_700">
            {date}
          </Paragraph>
        </div>
        <div className="flex gap-3">
          <ClockIcon className="text-textIcon-blackSecondary" width="20px" />
          <Paragraph size="200" color="text-neutral_700">
            {hour}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
