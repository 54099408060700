import { PaperClipIcon } from "@heroicons/react/outline";

import { RedDot } from "../../../icons";

export interface ITd {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
  classNames?: string;
  mode?: "unseen" | "attachments" | "dropdown" | "default";
  sticky?: boolean;
}

export const TableCell = ({
  children,
  classNames,
  mode = "default",
  sticky = false,
  ...rest
}: ITd) => {
  switch (mode) {
    case "unseen":
      return (
        <td
          className={`py-1 px-2 h-6.5 max-w-[160px] text-dangerDefault w-4 text-right ${
            sticky ? "sticky left-0" : ""
          }`}
          {...rest}
        >
          <RedDot />
        </td>
      );
    case "attachments":
      return (
        <td
          className={`py-1 px-2 h-6.5 max-w-[160px] text-textIcon-blackSecondary w-6.5 ${
            sticky ? "sticky left-0" : ""
          }`}
          {...rest}
        >
          <PaperClipIcon color="text-textIcon-blackSecondary" height={18} />
        </td>
      );
    case "dropdown":
      return (
        <td
          className={`py-1 px-2 h-6.5 max-w-[160px] text-sm ${
            classNames || ""
          } text-textIcon-blackSecondary w-6.5 ${sticky ? "sticky left-0" : ""}`}
          {...rest}
        >
          {children}
        </td>
      );
    default:
      return (
        <td
          className={`py-1 overflow-x-hidden text-ellipsis px-2 h-6.5 max-w-[160px] text-sm ${
            classNames || ""
          } text-textIcon-blackPrimary ${sticky ? "sticky left-0" : ""}`}
          {...rest}
        >
          {children}
        </td>
      );
  }
};
