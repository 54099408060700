import { useCallback, useState } from "react";

const noop = () => {
  // no operation
};

const initialState = {
  isActive: false,
  isConfirmed: false,
  confirm: noop,
  cancel: noop,
};

interface State {
  isActive: boolean;
  isConfirmed: boolean;
  confirm: (value: unknown) => void;
  cancel: (value: unknown) => void;
}

interface Confirm extends State {
  onConfirm: () => Promise<boolean>;
  resetConfirmation: () => void;
}

export const useConfirm = (beforeConfirm?: () => Promise<void>): Confirm => {
  const [confirmation, setConfirmation] = useState<State>(initialState);

  const onConfirm = useCallback(() => {
    const promise = new Promise((resolve, reject) => {
      setConfirmation((prevState: State) => ({
        ...prevState,
        isActive: true,
        confirm: resolve,
        cancel: reject,
      }));
    });

    return promise.then(
      async () => {
        if (beforeConfirm) {
          try {
            await beforeConfirm();
          } catch (error) {
            setConfirmation((confirmationData) => ({ ...confirmationData, isActive: false }));
            return false;
          }
        }

        setConfirmation((confirmationData) => ({
          ...confirmationData,
          isActive: false,
          isConfirmed: true,
        }));
        return true;
      },
      () => {
        setConfirmation((confirmationData) => ({ ...confirmationData, isActive: false }));
        return false;
      }
    );
  }, [beforeConfirm]);

  const resetConfirmation = useCallback(() => {
    setConfirmation(initialState);
  }, []);

  return {
    ...confirmation,
    onConfirm,
    resetConfirmation,
  };
};
