import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { useFlag } from "@unleash/proxy-client-react";
import classnames from "classnames";
import { Fragment, forwardRef, memo, useImperativeHandle, useRef } from "react";

import { findDescendantById, findHighestCategoryBySelectedId } from "@web/common/utils";
import { Category } from "@web/models";
import { Paragraph } from "@web/ui";
import { formatNumber, isDefined } from "@web/utils";

import { CategoriesTreeElement, CloseDisclosureType } from "./CategoriesTreeElement";

export type CloseAllDisclosureType = {
  closeAllDisclosures: () => void;
};

type Props = {
  categories: Category[];
  onCategoryClick: (category: Category) => void;
  openedByDefaultId?: string;
  activeCategoryId?: string;
};

/** @example 
 * how to use closeAllDisclosures: store ref, pass ref to component and use ref method
 * const categoriesTreeRef = useRef<CloseAllDisclosureType>(null);
 * <CategoriesTree ref={categoriesTreeRef} />
 * categoriesTreeRef?.current?.closeAllDisclosures?.()
  */
export const CategoriesTree = memo(
  forwardRef<CloseAllDisclosureType, Props>(
    ({ categories, openedByDefaultId, onCategoryClick, activeCategoryId }, ref) => {
      const elementsRef = useRef<(CloseDisclosureType | null)[]>([]);

      useImperativeHandle(ref, () => ({
        closeAllDisclosures: () => {
          elementsRef.current.forEach((element) => element?.closeDisclosure?.());
        },
      }));

      const hasGatherExpNavigationFeature = useFlag("gather-exp-navigation");

      const selectedCategory = findHighestCategoryBySelectedId(categories, activeCategoryId ?? "");

      return (
        <>
          {categories.map((categoryLevel1, index) => {
            const isSelectedCategory =
              selectedCategory?.id === categoryLevel1.id ||
              !!findHighestCategoryBySelectedId(categoryLevel1.children, activeCategoryId ?? "");

            return (
              <Fragment key={categoryLevel1.id}>
                {hasGatherExpNavigationFeature ? (
                  <CategoriesTreeElement
                    ref={(closeDisclosure) => (elementsRef.current[index] = closeDisclosure)}
                    openedByDefaultId={openedByDefaultId}
                    onCategoryClick={onCategoryClick}
                    activeCategoryId={activeCategoryId}
                    categoryLevel1={categoryLevel1}
                    isSelectedCategory={isSelectedCategory}
                  />
                ) : (
                  <Disclosure
                    as="div"
                    defaultOpen={
                      openedByDefaultId
                        ? categoryLevel1.id === openedByDefaultId ||
                          !!findDescendantById(openedByDefaultId, categoryLevel1)
                        : false
                    }
                    data-testid="categoriesTree_level1"
                  >
                    {({ open }) => {
                      const DisclosureButtonIconComponent = open
                        ? ChevronDownIcon
                        : ChevronRightIcon;
                      return (
                        <>
                          <Disclosure.Button as={Fragment}>
                            <div
                              className="py-1 flex items-center group cursor-pointer"
                              onClick={() => onCategoryClick(categoryLevel1)}
                              data-testid="categoriesTree_level1_header"
                            >
                              <Paragraph
                                size="200"
                                weight={
                                  activeCategoryId === categoryLevel1.id ||
                                  (hasGatherExpNavigationFeature && isSelectedCategory)
                                    ? "heavy"
                                    : "light"
                                }
                                color={
                                  activeCategoryId === categoryLevel1.id
                                    ? "text-primaryDefault"
                                    : [
                                        "text-textIcon-blackPrimary",
                                        "group-hover:text-primaryDefault",
                                      ]
                                }
                              >
                                {categoryLevel1.name}&nbsp;
                                {isDefined(categoryLevel1.productsNumber) && (
                                  <span
                                    className="text-gray-500"
                                    data-testid="categoriesTree_level1_quantity"
                                  >
                                    ({formatNumber(categoryLevel1.productsNumber)})
                                  </span>
                                )}
                              </Paragraph>
                              {categoryLevel1.children.length > 0 && (
                                <div className="ml-auto">
                                  <button
                                    className={classnames(
                                      "flex justify-center items-center",
                                      "h-[20px] w-[20px] min-h-[20px] min-w-[20px] max-h-[20px] max-w-[20px]",
                                      "border-none rounded-full",
                                      "focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-white ring-primaryDefault",
                                      "relative overflow-hidden whitespace-nowrap leading-none"
                                    )}
                                    type="button"
                                    data-testid="categoriesTree_level1_button"
                                  >
                                    <DisclosureButtonIconComponent
                                      className="w-[12px] h-[12px] text-textIcon-blackSecondary"
                                      aria-label="Expand/Contract Category"
                                      focusable={false}
                                    />
                                  </button>
                                </div>
                              )}
                            </div>
                          </Disclosure.Button>
                          {categoryLevel1.children.length > 0 && (
                            <Disclosure.Panel
                              as="div"
                              className={classnames("pl-4", {
                                "mb-1": open,
                              })}
                              data-testid="categoriesTree_level1_nestedContent"
                            >
                              {categoryLevel1.children.map((categoryLevel2) => (
                                <Paragraph
                                  key={categoryLevel2.id}
                                  size={hasGatherExpNavigationFeature ? "200" : "300"}
                                  className="py-1 cursor-pointer"
                                  weight={
                                    activeCategoryId === categoryLevel2.id ||
                                    (hasGatherExpNavigationFeature && isSelectedCategory)
                                      ? "heavy"
                                      : "light"
                                  }
                                  color={
                                    activeCategoryId === categoryLevel2.id
                                      ? "text-primaryDefault"
                                      : ["text-textIcon-blackPrimary", "hover:text-primaryDefault"]
                                  }
                                  onClick={() => onCategoryClick(categoryLevel2)}
                                  data-testid="categoriesTree_level2_header"
                                >
                                  {categoryLevel2.name}&nbsp;
                                  {isDefined(categoryLevel2.productsNumber) && (
                                    <span
                                      className="text-gray-500"
                                      data-testid="categoriesTree_level2_quantity"
                                    >
                                      ({formatNumber(categoryLevel2.productsNumber)})
                                    </span>
                                  )}
                                </Paragraph>
                              ))}
                            </Disclosure.Panel>
                          )}
                        </>
                      );
                    }}
                  </Disclosure>
                )}
              </Fragment>
            );
          })}
        </>
      );
    }
  )
);
CategoriesTree.displayName = "CategoriesTree";
