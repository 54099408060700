import { XIcon } from "@heroicons/react/solid";
import { useState } from "react";

import { Heading, Label } from "@web/ui";

import { SupplierOrderItemForm } from "../../models";
import { SupplierOrderService } from "../../services";
import { Candidate } from "../Candidate";
import { OrderItemDetails } from "../OrderItemDetails";
import { SearchResults } from "../SearchResults";
import { SearchSkuInput } from "./../SearchSkuInput";

interface Props {
  children?: React.ReactNode;
  orderId: string;
  itemToReplace: SupplierOrderItemForm;
  excludedItemIds: string[];
  onReplaceItem: (replacement: SupplierOrderItemForm) => void;
  closeModal: () => void;
}

export const ReplaceItemModal = ({
  orderId,
  itemToReplace,
  onReplaceItem,
  closeModal,
  excludedItemIds,
}: Props) => {
  const [searchPhrase, setSearchPhrase] = useState("");
  const [replacementCandidate, setReplacementCandidate] = useState<SupplierOrderItemForm>();
  const onSearch = (phrase: string) => {
    setSearchPhrase(phrase);
  };

  const onReplacementSelect = (itemToReplace: SupplierOrderItemForm) => {
    setReplacementCandidate(itemToReplace);
  };

  const onReplaceReject = () => {
    setSearchPhrase("");
    setReplacementCandidate(undefined);
  };

  const onReplaceConfirm = () => {
    onReplaceItem(replacementCandidate as SupplierOrderItemForm);
    closeModal();
  };

  const lineNumber = itemToReplace.lineNumber;
  const currentItem = itemToReplace;
  const uiCurrentItem = SupplierOrderService.convertOrderItemDataToUiModel(
    currentItem,
    currentItem.lineNumber
  );

  return (
    <div
      className="inline-block bg-neutral_0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all  align-middle max-w-2xl w-full p-5"
      data-testid="replaceItemModal"
    >
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-text-whiteDisabled hover:text-neutral_800"
          onClick={closeModal}
        >
          <span className="sr-only">Close modal</span>
          <XIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col">
        <Heading size="300">Replace Line Item #{lineNumber}</Heading>
        <Label size="200" color="text-textIcon-blackSecondary" className="mt-3">
          Original Item
        </Label>
        <OrderItemDetails orderItem={uiCurrentItem} testId="orderItemReplace" />
        <Label size="200" color="text-textIcon-blackSecondary" className="mt-4">
          Replacement Item
        </Label>

        {replacementCandidate ? (
          <Candidate
            candidate={replacementCandidate}
            onReject={onReplaceReject}
            onConfirm={onReplaceConfirm}
            confirmButtonLabel="Replace Item"
          />
        ) : (
          <div className="mt-2">
            <SearchSkuInput handleSearch={onSearch} />
            <SearchResults
              searchPhrase={searchPhrase}
              orderId={orderId}
              onItemClick={onReplacementSelect}
              originalItem={currentItem}
              itemActionType="replace"
              excludedItemIds={excludedItemIds}
            />
          </div>
        )}
      </div>
    </div>
  );
};
