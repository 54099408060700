import { Route, Routes as RoutesSwitch, useNavigate } from "react-router-dom";

import { NotFoundErrorPage } from "@web/ui";

import { RoutesConfig } from "src/admin/config/routes";

import { AddSupplier, EditSupplier, ManageSuppliers, NewSupplierSuccess } from "./pages";

export const Routes = () => {
  const navigate = useNavigate();

  return (
    <RoutesSwitch>
      <Route path={RoutesConfig.home} element={<ManageSuppliers />} />
      <Route path={RoutesConfig.addSupplier} element={<AddSupplier />} />
      <Route path={RoutesConfig.supplierAdded} element={<NewSupplierSuccess />} />
      <Route path={RoutesConfig.editSupplier} element={<EditSupplier />} />
      <Route
        path="*"
        element={
          <NotFoundErrorPage
            buttonText="Go To All Suppliers"
            onButtonClick={() => navigate(RoutesConfig.home)}
          />
        }
      />
    </RoutesSwitch>
  );
};
