import { ButtonTypography } from "../../Typography";
import { LinkCommonProps } from "./models";
import { useLinkCommons } from "./useLinkCommons";

export const LinkChildren = (props: LinkCommonProps) => {
  const { iconSizeClassNames, typographySize, defaultedProps } = useLinkCommons<unknown>(props);
  const { LeadingIcon, TrailingIcon, label } = defaultedProps;

  return (
    <>
      {LeadingIcon && <LeadingIcon className={iconSizeClassNames} />}
      <ButtonTypography size={typographySize}>{label}</ButtonTypography>
      {TrailingIcon && <TrailingIcon className={iconSizeClassNames} />}
    </>
  );
};
