import { ScopeContext } from "./ScopeContext";
import { useScopeReducer } from "./reducer";

interface Props {
  children: React.ReactNode;
}

export const ScopeContextProvider = ({ children }: Props) => {
  const [scope, dispatch] = useScopeReducer();

  return <ScopeContext.Provider value={[scope, dispatch]}>{children}</ScopeContext.Provider>;
};
