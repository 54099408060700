import { XIcon } from "@heroicons/react/solid";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { Money } from "@web/models";
import { GrandTotal, Heading, RegularButton } from "@web/ui";
import { preprocessFormAmountValue } from "@web/utils";

import { useSystemMessages } from "src/context/SystemMessages";

import { PriceModifierForm } from "../../models";
import { SupplierOrderService } from "../../services";

interface Props {
  headerLabel: string;
  cancel: () => void;
  submit: (priceModifier: PriceModifierForm) => Promise<void>;
  isSubmitting?: boolean;
  isSubmittingExternallyDisabled?: boolean;
  onSetIsDirty?: (isDirty: boolean) => void;
  onSubmitSuccess?: () => void;
  currentTotalAmount: number;
  currencyCode: string;
}

export const PriceModifierModal: React.FC<PropsWithChildren<Props>> = ({
  children,
  headerLabel,
  submit,
  isSubmitting = false,
  isSubmittingExternallyDisabled = false,
  cancel,
  onSetIsDirty,
  onSubmitSuccess,
  currentTotalAmount,
  currencyCode,
}) => {
  const { setSystemMessage } = useSystemMessages();

  const priceModifierForm = useFormContext<PriceModifierForm>();

  const { handleSubmit, formState, reset: resetForm, watch } = priceModifierForm;
  const { isDirty, isValid } = formState;

  useEffect(() => onSetIsDirty && onSetIsDirty(isDirty), [onSetIsDirty, isDirty]);

  const priceModifierAmountInputValue = watch("amount.amount");

  const newTotal: Money = useMemo(
    () => ({
      currencyCode,
      amount: SupplierOrderService.getTotalAmountWithPriceModifier(
        currentTotalAmount,
        preprocessFormAmountValue(priceModifierAmountInputValue) || 0
      ),
    }),
    [currencyCode, currentTotalAmount, priceModifierAmountInputValue]
  );

  const submitFormData = () => {
    handleSubmit(
      async (priceModifierData) => {
        await submit(priceModifierData);
        resetForm(priceModifierData);

        if (onSubmitSuccess) {
          /**
           * Defer onSubmitSuccess call so the modal's state updates before it is called.
           * This e.g. ensures that useEffect calling onSetIsDirty launches before the modal
           * is externally closed.
           */
          setTimeout(() => onSubmitSuccess(), 10);
        }
      },
      () => {
        setSystemMessage({
          type: "failure",
          message: "Some of the fields contain invalid data. Please amend them and try again.",
        });
      }
    )().catch(console.error);
  };

  return (
    <div
      className="inline-block bg-neutral_0 rounded-lg text-left shadow-xl transform max-w-2xl w-full align-middle p-5"
      data-testid="priceModifierModal"
    >
      <div className="absolute top-0 right-0 pt-5 px-5">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-text-whiteDisabled hover:text-textIcon-blackSecondary"
          onClick={cancel}
          data-testid="priceModifierModal_closeButton"
        >
          <span className="sr-only">Close modal</span>
          <XIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <Heading size="300">{headerLabel}</Heading>

      <div className="py-2 mt-2" data-testid="priceModifierModal_content">
        {children}
      </div>

      <div className="flex justify-between mt-6">
        <GrandTotal totalGrossAmount={newTotal}>New Total</GrandTotal>
        <div className="flex gap-2">
          <RegularButton
            variant="secondary"
            size="large"
            label="Cancel"
            onClick={cancel}
            disabled={isSubmitting}
            data-testid="priceModifierModal_cancelButton"
          />
          <RegularButton
            variant="primary"
            size="large"
            label="Add and close"
            onClick={submitFormData}
            disabled={!isDirty || !isValid || isSubmitting || isSubmittingExternallyDisabled}
            loading={isSubmitting || isSubmittingExternallyDisabled}
            data-testid="priceModifierModal_saveButton"
          />
        </div>
      </div>
    </div>
  );
};
