import { XIcon } from "@heroicons/react/solid";

import { Label, Paragraph } from "@web/ui";

interface Props {
  lineNumber: number;
  name: string;
  impaCode: string;
  itemId: string;
  closeModal: () => void;
}

export const OrderItemDetailsModal: React.FC<Props> = ({
  lineNumber,
  name,
  impaCode,
  itemId,
  closeModal,
}) => {
  const hasAdditionalDetails = impaCode || itemId;

  return (
    <div
      className="inline-block bg-neutral_0 rounded-lg text-left overflow-hidden shadow-xl transform max-w-lg w-full divide-y"
      data-testid="orderItemDetailsModal"
    >
      <div className="absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-text-whiteDisabled hover:text-textIcon-blackSecondary"
          onClick={closeModal}
          data-testid="orderItemDetailsModal_closeButton"
        >
          <span className="sr-only">Close modal</span>
          <XIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <div className="py-4.5 px-5">
        <Paragraph size="200">Line: {lineNumber}</Paragraph>
        <Label size="100">{name}</Label>
      </div>
      {hasAdditionalDetails && (
        <div className="py-4.5 px-5 flex flex-col space-y-2">
          {!!impaCode && (
            <Paragraph size="200" color="text-textIcon-blackSecondary">
              IMPA: {impaCode}
            </Paragraph>
          )}
          {!!itemId && (
            <Paragraph size="200" color="text-textIcon-blackSecondary">
              Item ID: {itemId}
            </Paragraph>
          )}
        </div>
      )}
    </div>
  );
};
