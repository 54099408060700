import { ChevronRightIcon } from "@heroicons/react/solid";
import { useNavigate, useParams } from "react-router-dom";

import {
  CircledImage,
  DeliveryDateDetails,
  DeliveryPort,
  Heading,
  Label,
  Loading,
  Paragraph,
  RegularButton,
} from "@web/ui";
import { extractFromISODateString } from "@web/utils";

import { RoutesConfig, RoutesParams } from "src/config/routes";
import { SupplierOrderService, useOrderByIdQuery } from "src/domain";
import VesselIcon from "src/icons/VesselIcon.svg";
import { SupplierPortalComment } from "src/typegens";

export const OrderConfirmed: React.FC = () => {
  const { [RoutesParams.ORDER_ID]: orderId } = useParams() as { orderId: string };
  const navigate = useNavigate();
  const orderQuery = useOrderByIdQuery({ orderId, orderVersion: "LATEST" });

  if (orderQuery.isPending || orderQuery.isFetching) {
    return <Loading />;
  }

  if (orderQuery.isSuccess) {
    const order = orderQuery.data;

    const nrLineItems = order.items.length;

    const approvalComment = order.comments?.find(
      (comment: SupplierPortalComment) =>
        comment.origin === "SUPPLIER" && comment.type === "APPROVED"
    )?.text;

    const additionalCostsUi = SupplierOrderService.convertOrderPriceModifiersToUiModel(
      order.amountAdditionalCosts
    );
    const discountsUi = SupplierOrderService.convertOrderPriceModifiersToUiModel(
      order.amountDiscounts
    );

    return (
      <div className="max-w-[1024px] mx-auto px-8 py-6 mb-10 flex flex-col text-center w-full">
        <div className="flex-1 mt-7">
          <Label size="300" color="text-successDefault" className="uppercase">
            order confirmed
          </Label>
          <div className="flex pt-2 pb-4 justify-center">
            <Paragraph size="200" color="text-textIcon-blackSecondary">
              {order.customerName}
            </Paragraph>
            <Paragraph size="200" color="text-textIcon-blackSecondary" className="ml-2">
              {order.orderId}
            </Paragraph>
          </div>
          <Heading size="200">Thank you for confirming this order</Heading>
        </div>

        <div className="border rounded-lg drop-shadow-md bg-neutral_0 flex text-left px-5 py-3 w-[520px] mx-auto mt-4">
          <div className="flex flex-col w-1/2 pr-2 whitespace-nowrap">
            <Paragraph
              size="300"
              color="text-textIcon-blackSecondary"
              className="uppercase mb-1 mt-3"
            >
              Origin
            </Paragraph>
            <div className="flex">
              <CircledImage Icon={VesselIcon} size={6} hashKey={order.vessel.name} />
              <div className="flex flex-col pl-1 truncate">
                <Label size="200" color="text-textIcon-blackPrimary" className="truncate">
                  {order.vessel.name}
                </Label>
                <Label size="200" color="text-textIcon-blackSecondary" className="truncate">
                  {order.vessel.contactInformation?.name || "Captain"}
                </Label>
                <Label size="300" color="text-textIcon-blackSecondary" className="truncate">
                  {order.vessel.imoNumber}
                </Label>
              </div>
            </div>
          </div>
          <div className="w-1/2">
            <DeliveryDateDetails
              label="delivery date"
              date={extractFromISODateString(order.deliveryDate, "justDate")}
              hour={extractFromISODateString(order.deliveryDate, "justTime")}
            />
            <DeliveryPort portCode={order.port.locationCode} portName={order.port.name} />
          </div>
        </div>

        {approvalComment && (
          <div className="border rounded-lg drop-shadow-md bg-neutral_0 flex text-left px-5 py-3 w-[520px] mx-auto mt-4 flex-col">
            <Paragraph size="300" color="text-textIcon-blackSecondary" className="uppercase">
              Confirmation comment
            </Paragraph>
            <div className="px-4 py-1 mt-3 border-l-2 min-h-12">
              <Paragraph size="200">{approvalComment}</Paragraph>
            </div>
          </div>
        )}

        <div className="flex flex-col text-left my-4 mx-auto w-[430px]">
          <div className="mt-4 pb-4">
            <Paragraph size="300" color="text-textIcon-whiteDisabled">
              Catalog Items
            </Paragraph>

            <div className="flex justify-between items-center pt-1">
              <Label size="300">
                {nrLineItems} {`Item${nrLineItems > 1 ? "s" : ""}`}
              </Label>
              <Label size="300" color="text-textIcon-blackPrimary">
                {SupplierOrderService.formatOrderItemsTotalAmount(
                  order.items,
                  order.totalGrossAmount.currencyCode
                )}
              </Label>
            </div>

            {additionalCostsUi.map((additionalCost, index) => (
              <div key={index} className="flex justify-between items-center pt-4">
                <Label
                  size="300"
                  color="text-textIcon-blackSecondary"
                  className="block max-w-[60%]"
                >
                  <span className="block truncate max-w-full">{additionalCost.name}</span>
                </Label>
                <Label size="300" color="text-textIcon-blackPrimary">
                  {additionalCost.amount}
                </Label>
              </div>
            ))}

            {discountsUi.map((discount, index) => (
              <div key={index} className="flex justify-between items-center pt-4">
                <Label
                  size="300"
                  color="text-textIcon-blackSecondary"
                  className="block max-w-[60%]"
                >
                  <span className="block truncate max-w-full">{discount.name}</span>
                </Label>
                <Label size="300" color="text-textIcon-blackPrimary">
                  {discount.amount}
                </Label>
              </div>
            ))}
          </div>
          <div className="pb-4 border-t">
            <div className="flex justify-between items-center pt-4">
              <Label size="300" color="text-textIcon-blackSecondary">
                Shipping
              </Label>
              <Label size="300" color="text-textIcon-blackPrimary">
                Free
              </Label>
            </div>
            <div className="flex justify-between items-center pt-4">
              <Label size="300" color="text-textIcon-blackSecondary">
                Sales Tax VAT 0%
              </Label>
              <Label size="300" color="text-textIcon-blackPrimary">
                {SupplierOrderService.formatZeroAmountInOrderCurrency(order)}
              </Label>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center h-7 border-t">
            <Label size="200" color="text-textIcon-blackPrimary">
              Total
            </Label>
            <Heading size="300">{SupplierOrderService.formatTotalGrossAmount(order)}</Heading>
          </div>
          <div className="max-w-lg mx-auto mt-6">
            <RegularButton
              variant="primary"
              size="large"
              label="Back to Incoming Orders"
              TrailingIcon={ChevronRightIcon}
              onClick={() => navigate(`${RoutesConfig.home}`)}
            />
          </div>
        </div>
      </div>
    );
  }

  return <div>undefined state</div>;
};
