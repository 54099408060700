import { Dispatch, createContext, useContext } from "react";

import { ScopeAction, ScopeState } from "./models";
import { defaultScopes } from "./reducer";

export const ScopeContext = createContext<[state: ScopeState, action: Dispatch<ScopeAction>]>([
  defaultScopes,
  () => {
    return;
  },
]);

export const useScopeContext = () => useContext(ScopeContext);
