import {
  GetAllUnleashTogglesParams,
  GetAllUnleashTogglesResponse,
  NativeUnleashFeatureFlag,
  UnleashContextFlagProperties,
} from "../models";
import { convertFlagToOverriddenUnleashContextProperty } from "../utils";

const fetchAllFlags = async (
  {
    unleashProxyClientKey,
    unleashProxyUrl,
    unleashEnvironment,
    unleashAppName,
    properties = {},
  }: GetAllUnleashTogglesParams & { properties?: UnleashContextFlagProperties },
  { signal }: { signal?: AbortSignal }
): Promise<NativeUnleashFeatureFlag[]> => {
  const headers = new Headers();
  headers.append("authorization", unleashProxyClientKey);
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");

  const unleashContext = {
    appName: unleashAppName,
    environment: unleashEnvironment,
    properties,
  };

  const requestOptions = {
    method: "POST",
    headers: headers,
    cache: "no-cache" as const,
    redirect: "follow" as const,
    body: JSON.stringify({ context: unleashContext }),
    signal,
  };

  const response = await fetch(`${unleashProxyUrl}/all`, requestOptions);

  if (!response.ok) {
    throw new Error(`Failed to fetch all feature toggles from Unleash: ${response.statusText}`);
  }

  const contentType = response.headers.get("Content-Type");

  if (contentType?.startsWith("application/json")) {
    const responseData = (await response.json()) as { toggles: NativeUnleashFeatureFlag[] };
    return responseData.toggles;
  }

  throw new Error("Failed to fetch all feature toggles from Unleash: Invalid response type");
};

export const getAllUnleashToggles = async (
  {
    unleashProxyClientKey,
    unleashProxyUrl,
    unleashEnvironment,
    unleashAppName,
  }: GetAllUnleashTogglesParams,
  { signal }: { signal?: AbortSignal }
): Promise<GetAllUnleashTogglesResponse> => {
  const allFeatureFlags = await fetchAllFlags(
    {
      unleashProxyClientKey,
      unleashProxyUrl,
      unleashEnvironment,
      unleashAppName,
    },
    { signal }
  );

  // Fetch all flags as if they were enabled by being overridden
  const allFeatureFlagsOverridden = await fetchAllFlags(
    {
      unleashProxyClientKey,
      unleashProxyUrl,
      unleashEnvironment,
      unleashAppName,
      properties: allFeatureFlags.reduce(
        (acc, { name }) => ({
          ...acc,
          ...convertFlagToOverriddenUnleashContextProperty(name),
        }),
        {}
      ),
    },
    { signal }
  );

  return {
    nativeUnleashFlags: allFeatureFlags,
    nativeOverriddenUnleashFlags: allFeatureFlagsOverridden,
  };
};
