import { DATE_STYLE } from "../config";

export const LEGACY_formatDate = (
  date?: Date,
  options?: {
    dateStyle?: "full" | "long" | "medium" | "short" | undefined;
    timeZone?: string;
  }
) => {
  if (!date) {
    return "";
  }
  const defaultedOptions: Intl.DateTimeFormatOptions = { dateStyle: DATE_STYLE, ...options };
  return new Intl.DateTimeFormat("en-GB", defaultedOptions).format(date);
};
