import { Fragment } from "react";

import { Label } from "@web/ui";

import { SupplierOrderChanges } from "src/domain";

import { OrderItemChangesController } from "../OrderItemChangesController";
import { OrderItemDetailsController } from "../OrderItemDetailsController";

interface Props {
  orderChanges: SupplierOrderChanges;
}

export const OrderChanges: React.FC<Props> = ({ orderChanges }) => {
  const { added, removed, updated, replaced, unchanged } = orderChanges;
  const hasAddedItems = added.length > 0;
  const hasRemovedItems = removed.length > 0;
  const hasUpdatedItems = updated.length > 0;
  const hasReplacedItems = replaced.length > 0;
  const hasUnchangedItems = unchanged.length > 0;

  return (
    <div className="min-w-full" data-testid="orderDetails_orderChangesContainer">
      {hasAddedItems && (
        <Label size="300" className="text-text-whiteDisabled block ml-6 mt-4 uppercase">
          Added
        </Label>
      )}
      {added.map((change) => (
        <OrderItemDetailsController
          key={change.item.id || change.item.supplierIdentifier}
          showLineNumber={true}
          orderItem={change.item}
          testId="orderDetails_addedLineItem"
        />
      ))}

      {hasRemovedItems && (
        <Label size="300" className="text-text-whiteDisabled block ml-6 mt-4 uppercase">
          Removed
        </Label>
      )}
      {removed.map((change) => (
        <OrderItemDetailsController
          key={change.item.id || change.item.supplierIdentifier}
          showLineNumber={true}
          orderItem={change.item}
          isRemoved={true}
          testId="orderDetails_removedLineItem"
        />
      ))}

      {hasUpdatedItems && (
        <Label size="300" className="text-text-whiteDisabled block ml-6 mt-4 uppercase">
          Updated
        </Label>
      )}
      {updated.map((updatedItem) => (
        <OrderItemChangesController
          key={updatedItem.item.id || updatedItem.item.supplierIdentifier}
          orderChange={updatedItem}
          testId="orderDetails_updatedLineItem"
        />
      ))}

      {hasReplacedItems && (
        <Label size="300" className="text-text-whiteDisabled block ml-6 mt-4 uppercase">
          Replaced
        </Label>
      )}
      {replaced.map((change) => (
        <Fragment key={change.item.id || change.item.supplierIdentifier}>
          <OrderItemDetailsController
            showLineNumber={true}
            orderItem={change.item}
            testId="orderDetails_replacementLineItem"
          />
          {change.previousItem && (
            <OrderItemDetailsController
              orderItem={change.previousItem}
              isRemoved={true}
              spacing="tight"
              keepLeftMargin={true}
              testId="orderDetails_replacedLineItem"
            />
          )}
        </Fragment>
      ))}

      {hasUnchangedItems && (
        <Label size="300" className="text-text-whiteDisabled block ml-6 mt-4 uppercase">
          No change
        </Label>
      )}
      {unchanged.map((unchangedItem) => (
        <OrderItemDetailsController
          key={unchangedItem.id || unchangedItem.supplierIdentifier}
          showLineNumber={true}
          orderItem={unchangedItem}
          testId="orderDetails_unchangedLineItem"
        />
      ))}
    </div>
  );
};
