import { useMemo } from "react";

import { isDefined } from "@web/utils";

import { useApiError } from "src/domain/apiError";
import { ApiError, ValidationError } from "src/typegens";

import { SupplierOrderAttachment } from "../models";

const attachmentErrorPresentableErrorParser = (attachments: SupplierOrderAttachment[]) => {
  return (fieldError: ValidationError): string => {
    const attachmentIndex: number | undefined = getAttachmentIndexFromFieldPath(fieldError.field);

    return isDefined(attachmentIndex)
      ? `"${attachments[attachmentIndex].name}": ${fieldError.defaultMessage}`
      : fieldError.defaultMessage;
  };
};

const getAttachmentIndexFromFieldPath = (fieldPath: string): number | undefined => {
  const attachmentIndexString: string | undefined =
    fieldPath.match(/attachments\[([\d+])\].*/)?.[1];
  const attachmentIndex: number = isDefined(attachmentIndexString)
    ? parseInt(attachmentIndexString)
    : NaN;
  return isNaN(attachmentIndex) ? undefined : attachmentIndex;
};

export const useOrderAttachmentsApiError = (attachments: SupplierOrderAttachment[]) => {
  const presentableErrorParser: (fieldError: ValidationError) => string = useMemo(
    () => attachmentErrorPresentableErrorParser(attachments),
    [attachments]
  );

  const { showApiError } = useApiError();

  const showOrderAttachmentsApiError = (error: ApiError) =>
    showApiError(error, presentableErrorParser);

  return {
    showOrderAttachmentsApiError,
  };
};
