import classnames from "classnames";
import { useState } from "react";

import { TermsOfUse } from "@web/models";

import { Label } from "../../atoms/Typography";
import { CustomerSupportModal, Modal } from "../modals";

type Props = {
  termsOfUse?: TermsOfUse;
};

export const CustomerSupportWidget = ({ termsOfUse }: Props) => {
  const [isCustomerSupportModalOpen, setIsCustomerSupportModalOpen] = useState(false);
  const shouldDisplayTermsOfUse = !!termsOfUse;

  const handleOpenModal = () => {
    setIsCustomerSupportModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsCustomerSupportModalOpen(false);
  };

  return (
    <>
      <Modal isOpen={isCustomerSupportModalOpen} closeModal={handleCloseModal}>
        <CustomerSupportModal onClose={handleCloseModal} termsOfUse={termsOfUse} />
      </Modal>
      <div
        className={classnames(
          shouldDisplayTermsOfUse ? "w-[153px]" : "w-[140px]",
          "z-10 h-5.5 bg-successPressed cursor-pointer fixed bottom-0 -translate-x-2/4 left-[50%] flex items-center justify-center rounded-t-lg"
        )}
        data-testid="customerSupport_widget"
        onClick={handleOpenModal}
      >
        <Label size="300" color="text-textIcon-whitePrimary">
          {shouldDisplayTermsOfUse ? "Support | Terms of Use" : "Contact Support"}
        </Label>
      </div>
    </>
  );
};
