enum VesselColor {
  cyan = "bg-LEGACY-cyanVessel",
  yellow = "bg-LEGACY-yellowVessel",
  pink = "bg-LEGACY-pinkVessel",
  orange = "bg-LEGACY-orangeVessel",
  red = "bg-LEGACY-redVessel",
  blue = "bg-LEGACY-blueVessel",
  teal = "bg-LEGACY-tealVessel",
}

export const vesselPalette = [
  VesselColor.cyan,
  VesselColor.yellow,
  VesselColor.pink,
  VesselColor.orange,
  VesselColor.red,
  VesselColor.blue,
  VesselColor.teal,
];

enum PortColor {
  blueZodiac = "bg-LEGACY-blueZodiac",
  saphire = "bg-LEGACY-saphire",
  scampi = "bg-LEGACY-scampi",
  oceanGreen = "bg-LEGACY-oceanGreen",
  acapulco = "bg-LEGACY-acapulco",
}

export const portPalette = [
  PortColor.acapulco,
  PortColor.blueZodiac,
  PortColor.oceanGreen,
  PortColor.saphire,
  PortColor.scampi,
];
