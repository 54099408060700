import classnames from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getTotalEntityQuantity } from "@web/common/utils";
import { Label, LabelProps } from "@web/ui";

type Variant = "positive";

interface Props {
  quantityInBasket: number;
  salesEntityQuantity: number;
  measurementUnit: string;
  className?: string;
  lineThrough?: boolean;
  variant?: Variant;
  label?: string;
  LabelComponent?: React.FC<LabelProps>;
}

const getValueColor = (variant?: Variant) =>
  variant === "positive" ? "text-successDefault" : "text-neutral_700";
const getValueClassName = (variant?: Variant) =>
  variant === "positive"
    ? "bg-successBackground border-successDefault"
    : "bg-neutral_0 border-neutral_300";

export const TotalUnitOfMeasure: React.FC<Props> = ({
  className = "",
  lineThrough,
  quantityInBasket,
  salesEntityQuantity,
  measurementUnit,
  variant,
  label,
  LabelComponent = Label,
}) => {
  const { t } = useTranslation();

  const valueColor = useMemo(() => getValueColor(variant), [variant]);
  const valueClassName = useMemo(() => getValueClassName(variant), [variant]);

  const entityQuantity = useMemo(
    () => getTotalEntityQuantity(salesEntityQuantity, quantityInBasket),
    [quantityInBasket, salesEntityQuantity]
  );

  return (
    <div className={`flex flex-row items-center flex-grow ${className}`}>
      <LabelComponent size="200" color="text-neutral_700">
        {label || t("common.components.totalUnitOfMeasure.total")}
      </LabelComponent>
      <Label
        size="200"
        color={valueColor}
        className={classnames("border py-1 px-2 mx-1 rounded-md", valueClassName, {
          "line-through": lineThrough,
        })}
        data-testid="totalUnitOfMeasureValue"
      >
        {entityQuantity} {measurementUnit}
      </Label>
    </div>
  );
};
