import { DefaultError, useMutation } from "@tanstack/react-query";

import { OrderVersionResponse } from "src/typegens";

import { closeOrder } from "../api";
import { SupplierCloseOrderParams } from "../models";

export const useOrderCloseMutation = (options = {}) => {
  return useMutation<OrderVersionResponse, DefaultError, SupplierCloseOrderParams>({
    mutationKey: ["closeOrder"],
    mutationFn: closeOrder,
    ...options,
  });
};
