import { OptionType } from "@web/ui";

import { CatalogConfiguration, OrderType } from "../../models";
import {
  BadgeSettings,
  getBaseBadgeSettingsForOrderAndStocktakeTypes,
  getIconFromOrderOrStocktakeType,
} from "./orderAndStocktakeTypesShared";

const defaultCatalogConfiguration: CatalogConfiguration[] = [
  {
    type: "DEFAULT",
    name: "Default",
    description: "Default order type",
  },
  {
    type: "BONDED",
    name: "Bonded",
    description: "Bonded order type",
  },
  {
    type: "PROVISIONS",
    name: "Provisions",
    description: "Provisions order type",
  },
  {
    type: "STORES",
    name: "Stores",
    description: "Stores order type",
  },
  {
    type: "WELFARE",
    name: "Welfare",
    description: "Welfare order type",
  },
];

export const DEFAULT_ORDER_TYPE = "DEFAULT";

const getOrderTypeDefaultLabel = (orderType: OrderType): string =>
  defaultCatalogConfiguration.find((config) => config.type === orderType)?.name ?? "Unknown";

export const getDefaultedOrderTypeAccountingForConfigurationLogic = (
  orderTypes: CatalogConfiguration[] = [],
  chosenOrderType: OrderType | undefined
): OrderType | undefined => {
  const shouldUseADefaultOrderType = orderTypes.length <= 1;
  const isChosenOrderTypeValid =
    !!chosenOrderType && !!orderTypes.find((ot) => ot.type === chosenOrderType);

  // - Will return the only order type if there's only one in the configuration - it should be "DEFAULT", but we don't care in FE
  // - Will return undefined or chosen order type if there are multiple order types in the configuration,
  //   because in this scenario we want the user to choose one
  return shouldUseADefaultOrderType
    ? orderTypes[0]?.type
    : isChosenOrderTypeValid
    ? chosenOrderType
    : undefined;
};

export const shouldRenderOrderTypes = (orderTypes: CatalogConfiguration[] = []): boolean => {
  return orderTypes.length > 1;
};

export const shouldRenderOrderType = (
  orderTypes: CatalogConfiguration[] = [],
  orderType?: OrderType
): boolean => {
  return !!(orderTypes.length > 1 && orderType && orderType !== "DEFAULT");
};

export const getBadgeSettingsFromOrderType = ({
  orderType,
  orderTypes,
  text,
}: {
  orderType: OrderType;
  orderTypes?: CatalogConfiguration[];
  text?: string;
}): BadgeSettings => {
  const baseConfig = getBaseBadgeSettingsForOrderAndStocktakeTypes(orderType);
  return {
    ...baseConfig,
    text:
      text ??
      (orderTypes || []).find((config) => config.type === orderType)?.name ??
      getOrderTypeDefaultLabel(orderType),
  };
};

export const getSelectOptionFromOrderTypeConfiguration = (
  orderTypeConfig: CatalogConfiguration
): OptionType<OrderType> => ({
  value: orderTypeConfig.type,
  label: orderTypeConfig.name,
  LeadingIcon: getIconFromOrderOrStocktakeType(orderTypeConfig.type),
});
