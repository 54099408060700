import { forwardRef } from "react";
import { NavLink as BaseNavLink, NavLinkProps, useLocation } from "react-router-dom";

type Props = {
  activeClassName: string;
  children:
    | React.ReactNode
    | ((props: { isActive: boolean; isPending: boolean }) => React.ReactNode);
};

export const SearchNavLink = forwardRef<HTMLAnchorElement, Props & NavLinkProps>(
  ({ activeClassName, children, ...props }, ref) => {
    const location = useLocation();
    const isActive =
      location.search.slice(1) === props.to.search ||
      (props.to.search === undefined && location.search === "");

    return (
      <BaseNavLink
        ref={ref}
        {...props}
        className={() =>
          [props.className, isActive ? activeClassName : null].filter(Boolean).join(" ")
        }
      >
        {typeof children === "function" ? children({ isActive, isPending: false }) : children}
      </BaseNavLink>
    );
  }
);

SearchNavLink.displayName = "SearchNavLink";
