import { DefaultError, InfiniteData, QueryKey, useInfiniteQuery } from "@tanstack/react-query";

import { SupplierPortalAppService } from "src/typegens";

import { SupplierOrder, SupplierOrdersSortBy } from "../models";
import { InfiniteOrdersResponse } from "../models/InfiniteOrdersResponse";
import { SupplierOrderService } from "../services";

export const useOrdersQuery = (
  {
    status,
    sortBy,
    sortDir,
    ports,
    vessels,
    buyers,
    closed,
  }: {
    status?: SupplierOrder["status"];
    sortBy?: SupplierOrdersSortBy;
    sortDir?: "ASC" | "DESC";
    ports?: string[];
    vessels?: string[];
    buyers?: string[];
    closed?: boolean;
  },
  options = {}
) => {
  return useInfiniteQuery<
    InfiniteOrdersResponse,
    DefaultError,
    InfiniteData<InfiniteOrdersResponse>,
    QueryKey,
    string
  >({
    queryKey: ["getOrdersList", status, sortBy, sortDir, ports, vessels, buyers, closed],
    queryFn: async ({ pageParam }) => {
      const result = await SupplierPortalAppService.getOrders({
        status,
        cursor: pageParam,
        sortBy,
        sortDir,
        ports,
        vessels,
        buyers,
        closed,
      });

      return {
        orders: {
          items: result.orders.items.map((orderItem) =>
            SupplierOrderService.convertFromApiToSupplierOrder(orderItem)
          ),
          cursor: result.orders.cursor,
        },
      };
    },
    initialPageParam: "0",
    ...options,
    getNextPageParam: (lastPage) => {
      if (lastPage.orders.cursor !== "") {
        return lastPage.orders.cursor;
      }
    },
  });
};
