import { NumericFormat } from "react-number-format";
import { NumericFormatProps } from "react-number-format/types/types";

type Props<T, Y> = {
  className?: string;
  InputComponent: T;
} & NumericFormatProps &
  Y;

export function AmountField<T, Y>({ className = "", InputComponent, ...rest }: Props<T, Y>) {
  return (
    <NumericFormat
      customInput={InputComponent}
      decimalScale={2}
      fixedDecimalScale={true}
      thousandSeparator=","
      className={className}
      {...rest}
    />
  );
}
