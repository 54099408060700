export const ProvisionsIcon = () => {
  return (
    <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.83325 17.3332V9.70817C2.12492 9.51373 1.53117 9.12484 1.052 8.5415C0.572835 7.95817 0.333252 7.27762 0.333252 6.49984V0.666504H1.99992V6.49984H2.83325V0.666504H4.49992V6.49984H5.33325V0.666504H6.99992V6.49984C6.99992 7.27762 6.76034 7.95817 6.28117 8.5415C5.802 9.12484 5.20825 9.51373 4.49992 9.70817V17.3332H2.83325ZM11.1666 17.3332V10.6665H8.66659V4.83317C8.66659 3.68039 9.07284 2.69775 9.88534 1.88525C10.6978 1.07275 11.6805 0.666504 12.8333 0.666504V17.3332H11.1666Z"
        fill="#1D2433"
        fillOpacity="0.6"
      />
    </svg>
  );
};
