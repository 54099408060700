import { isError } from "@web/utils";

import { Cloud, S2SHeaderLogo } from "../../../icons";
import { Heading, Paragraph } from "../../atoms";

type Props = {
  errorDescription: string;
  error?: unknown;
};

export const FallbackErrorPage = ({ errorDescription, error = {} }: Props) => {
  const isErrorValid = isError(error);
  return (
    <div className="h-screen" data-testid="fallbackErrorPage">
      <div className="flex justify-start">
        <S2SHeaderLogo className="[&>path]:fill-[#022044]" />
      </div>
      <div className="h-full flex justify-center items-center">
        <Cloud />
        <div className="w-0.5 h-[285px] mx-7 bg-textIcon-blackSecondary" role="presentation" />
        <div className="flex flex-col">
          <Heading size="100" className="mb-4" color="text-primaryPressed">
            It&apos;s not you, it&apos;s us...
          </Heading>
          <Paragraph size="100" className="w-96">
            Try refreshing the page.
          </Paragraph>
          <Paragraph size="100" className="w-96">
            If this does not help, please contact{" "}
            <a className="underline text-primaryHover" href="mailto:support@source2sea.com ">
              support@source2sea.com
            </a>{" "}
            for assistance and provide the following information:
          </Paragraph>
          <Paragraph size="100" weight="heavy" className="w-96">
            Error: {errorDescription}{" "}
            <span className="font-thin text-xs">{new Date().toISOString()}</span>
          </Paragraph>
          {isErrorValid && (
            <Paragraph size="200" color="text-textIcon-blackSecondary" className="w-96 mt-3">
              {error.name}: {error.message}
            </Paragraph>
          )}
        </div>
      </div>
    </div>
  );
};
