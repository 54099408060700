import { ReactNode, useCallback, useEffect, useState } from "react";

import { Avatar, Select } from "@web/ui";
import { formatInitials } from "@web/utils";

import { useSystemMessages } from "src/context/SystemMessages";
import {
  SupplierUiOrder,
  useAssignUserToPOMutation,
  useSupplierUsersQuery,
  useUnassignUserFromPOMutation,
  useUserPermissions,
} from "src/domain";
import {
  AssigneeInformation,
  AssigneeInformationChangeResponse,
  OrderVersionResponse,
} from "src/typegens";

interface Props {
  order: SupplierUiOrder;
  disabled?: boolean;
  orderVersion: number;
  onAssigneeChange: (orderVersion: number, assigneeInformation?: AssigneeInformation) => void;
}

export interface AssigneeOption {
  label: string;
  value: string;
  prefix: ReactNode;
}
const defaultAssignee: AssigneeOption = {
  label: "Unassigned",
  value: "unassigned",
  prefix: <></>,
};

// TODO #5118: Add tests
export const AssignToPO: React.FC<Props> = ({
  order,
  disabled = false,
  orderVersion,
  onAssigneeChange,
}) => {
  const [assignee, setAssignee] = useState<AssigneeOption>(defaultAssignee);
  const [supplierUsers, setSupplierUsers] = useState<AssigneeOption[]>([]);
  const { setSystemMessage } = useSystemMessages();
  const { isSupervisor } = useUserPermissions();

  const assignToPOMutation = useAssignUserToPOMutation({
    onSuccess: (res: AssigneeInformationChangeResponse) => {
      setSystemMessage({
        type: "success",
        message: `${res.assignee.name} has been assigned to order ${order.orderId}`,
      });
      onAssigneeChange(res.newVersion, res.assignee);
    },
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: `There was an error assigning ${assignee.label} to order ${order.orderId}`,
      });
      setAssigneeFromOrder();
    },
  });

  const unassignUserFromPOMutation = useUnassignUserFromPOMutation({
    onSuccess: (res: OrderVersionResponse) => {
      setAssignee(defaultAssignee);
      setSystemMessage({
        type: "success",
        message: `User has been unassigned from order ${order.orderId}`,
      });
      onAssigneeChange(res.newVersion);
    },
    // TODO #2158: Check what is logged to sentry in this case and provide some manual logging if needed
    // TODO #2159: Decide on error handling apart of just displaying error message
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: `There was an error.`,
      });
    },
  });

  const supplierUsersQuery = useSupplierUsersQuery(
    { orderId: order.orderId },
    { enabled: !disabled }
  );

  const setAssigneeFromOrder = useCallback(() => {
    if (!order.assignee) {
      return;
    }

    setAssignee({
      label: order.assignee.name,
      value: order.assignee.id,
      prefix: <Avatar text={formatInitials(order.assignee.name)} sizeClass="5" className="mr-2" />,
    });
  }, [order, setAssignee]);

  const assignUserToPO = (newAssignee: AssigneeOption) => {
    if (isSupervisor) {
      return;
    }

    setAssignee(newAssignee);

    assignToPOMutation.mutate({
      s2SOrderId: order.orderId,
      version: orderVersion,
      id: newAssignee.value,
    });
  };

  const unassignUserFromPO = () => {
    if (isSupervisor) {
      return;
    }

    unassignUserFromPOMutation.mutate({
      s2SOrderId: order.orderId,
      version: orderVersion,
    });
  };

  useEffect(() => {
    if (!supplierUsersQuery.isSuccess) {
      return;
    }

    if (assignee.value === "unassigned") {
      setSupplierUsers(
        supplierUsersQuery.data.map((user) => ({
          label: user.name,
          value: user.id,
          prefix: <Avatar text={formatInitials(user.name)} sizeClass="5" className="mr-2" />,
        }))
      );
    } else {
      setSupplierUsers([
        {
          label: "Unassign user",
          value: "unassign",
          prefix: <></>,
        },
        ...supplierUsersQuery.data.map((user) => ({
          label: user.name,
          value: user.id,
          prefix: <Avatar text={formatInitials(user.name)} sizeClass="5" className="mr-2" />,
        })),
      ]);
    }
  }, [assignee.value, supplierUsersQuery.data, supplierUsersQuery.isSuccess]);

  useEffect(() => {
    setAssigneeFromOrder();
  }, [order, setAssigneeFromOrder]);

  return (
    <div className="mb-4">
      <Select
        options={supplierUsers}
        onChange={(selectedUser) => {
          if (selectedUser.value === "unassign") {
            unassignUserFromPO();
            return;
          }

          assignUserToPO(selectedUser as AssigneeOption);
        }}
        value={assignee}
        label="Assigned to"
        disabled={disabled || !!isSupervisor}
        testId="assignToPO_select"
      />
    </div>
  );
};
