import { useRef } from "react";

interface Props {
  render: (triggerFileSelection: () => void) => void;
  onFileSelection: (file: File) => void;
  accept?: string;
}

export const FileSelect: React.FC<Props> = ({ render, onFileSelection, accept }) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      onFileSelection(selectedFile);
    }

    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  const triggerFileSelection = () => inputFileRef.current?.click();

  return (
    <>
      <input
        ref={inputFileRef}
        type="file"
        name="file"
        {...(accept && { accept })}
        onInput={handleFileSelection}
        hidden={true}
        data-testid="fileSelectInput"
      />
      {render(triggerFileSelection)}
    </>
  );
};
