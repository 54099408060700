import { PropsWithChildren } from "react";

import { Money } from "@web/models";
import { formatMoney } from "@web/utils";

import { TextColors } from "../../../utils";
import { Label } from "../Typography";

interface Props {
  children: React.ReactNode;
  textColor?: TextColors;
  totalGrossAmount: Money;
  className?: string;
}

export const GrandTotal: React.FC<PropsWithChildren<Props>> = ({
  children,
  textColor = "text-primaryDefault",
  totalGrossAmount,
  className = "",
  ...rest
}) => {
  return (
    <div className={`flex items-center ${className}`} {...rest}>
      <Label size="200" color={textColor} className="mx-2">
        {children}
      </Label>
      <Label size="100" color={textColor}>
        {formatMoney(totalGrossAmount)}
      </Label>
    </div>
  );
};
