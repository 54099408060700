import { DefaultError, useMutation } from "@tanstack/react-query";

import { OrderVersionResponse } from "src/typegens";

import { confirmOrderCancellation } from "../api";
import { SupplierConfirmOrderCancellationParams } from "../models";

export const useOrderConfirmCancellationMutation = (options = {}) => {
  return useMutation<OrderVersionResponse, DefaultError, SupplierConfirmOrderCancellationParams>({
    mutationKey: ["confirmOrderCancellation"],
    mutationFn: confirmOrderCancellation,
    ...options,
  });
};
