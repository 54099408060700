import { useReducer } from "react";

import { OrderEditsSyncState, OrderEditsSyncStateAction } from "./models";

export const defaultOrderEditsSyncState: OrderEditsSyncState = {
  editsToRollback: [],
  syncedEdits: [],
  isSyncInProgress: false,
};

const OrderEditsSyncReducer = (
  orderEditsSyncState: OrderEditsSyncState,
  action: OrderEditsSyncStateAction
): OrderEditsSyncState => {
  switch (action.type) {
    case "setEditsToRollback":
      return {
        ...orderEditsSyncState,
        editsToRollback: [...action.payload],
      };
    case "addSyncedEdits":
      return {
        ...orderEditsSyncState,
        syncedEdits: [...orderEditsSyncState.syncedEdits, ...action.payload],
      };
    case "setIsSyncInProgress":
      return {
        ...orderEditsSyncState,
        isSyncInProgress: action.payload,
      };
    case "reset":
      return { ...defaultOrderEditsSyncState };
    default:
      return orderEditsSyncState;
  }
};

export const useOrderEditsSyncReducer = () =>
  useReducer(OrderEditsSyncReducer, defaultOrderEditsSyncState);
