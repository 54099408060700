import { z } from "zod";

import { ProductSkuSchema } from "./ProductSkuSchema";

export const SupplierOrderItemSchema = z.object({
  id: z.string(),
  replacementForItemId: z.string().optional(),
  entityQuantity: z.number().nonnegative(),
  quantity: z.number().int().nonnegative(),
  skuDetails: ProductSkuSchema,
  lineNumber: z.number(),
  totalAmount: z.object({
    amount: z.number().nonnegative(),
    currencyCode: z.string(),
  }),
  impaCode: z.string().optional(),
  measurementUnit: z.string(),
  supplierIdentifier: z.string(),
  name: z.string(),
});

export type SupplierOrderItemForm = z.infer<typeof SupplierOrderItemSchema>;
