import { DefaultError, InfiniteData, QueryKey, useInfiniteQuery } from "@tanstack/react-query";

import { SupplierPortalAppService, SupplierPortalProductSku } from "src/typegens";

type QueryData = { items: Array<SupplierPortalProductSku>; cursor?: string };

export const useSearchProducts = (
  {
    s2SOrderId,
    searchQuery,
  }: {
    s2SOrderId: string;
    searchQuery: string;
  },
  options = {}
) => {
  return useInfiniteQuery<QueryData, DefaultError, InfiniteData<QueryData>, QueryKey, string>({
    queryKey: ["searchProducts", s2SOrderId, searchQuery],
    queryFn: ({ pageParam }) =>
      SupplierPortalAppService.search({
        searchQuery,
        s2SOrderId,
        cursor: pageParam,
      }),
    initialPageParam: "0",
    getNextPageParam: (lastPage) => {
      if (lastPage.cursor !== "") {
        return lastPage.cursor;
      }
    },
    throwOnError: false,
    ...options,
  });
};
