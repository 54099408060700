import { Paragraph } from "../../atoms/Typography";
import E404 from "./img/404.svg";
import E500 from "./img/500.svg";
import AllDone from "./img/all-done.svg";
import EmptyCart from "./img/empty-cart.svg";
import EmptyInbox from "./img/empty-inbox.svg";
import NoConnection from "./img/no-connection.svg";
import NoDocument from "./img/no-document.svg";
import NoImages from "./img/no-images.svg";
import NoResults from "./img/no-results.svg";
import NoTasks from "./img/no-tasks.svg";

type Image =
  | "all-done"
  | "empty-cart"
  | "empty-inbox"
  | "404"
  | "500"
  | "no-connection"
  | "no-document"
  | "no-images"
  | "no-results"
  | "no-tasks";

interface IEmptyState {
  title?: string;
  subtitle?: string;
  image?: Image;
}

const getImage = (image: Image) => {
  switch (image) {
    case "all-done":
      return AllDone;
    case "empty-cart":
      return EmptyCart;
    case "empty-inbox":
      return EmptyInbox;
    case "404":
      return E404;
    case "500":
      return E500;
    case "no-connection":
      return NoConnection;
    case "no-document":
      return NoDocument;
    case "no-images":
      return NoImages;
    case "no-results":
      return NoResults;
    case "no-tasks":
      return NoTasks;
  }
};

export const EmptyState = ({ title = "", subtitle = "", image = "all-done" }: IEmptyState) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <img src={getImage(image)} alt="Error" className="w-14" />
      <Paragraph size="100" weight="heavy" className="mt-5" data-testid="emptyState_title">
        {title}
      </Paragraph>
      <Paragraph size="100" className="mt-1" data-testid="emptyState_subtitle">
        {subtitle}
      </Paragraph>
    </div>
  );
};
