import { InfiniteData } from "@tanstack/react-query";

import { SupplierResponse, SuppliersResponse } from "src/typegens";

export const updateSuppliersListQueryData =
  (result: SupplierResponse) => (oldData: InfiniteData<SuppliersResponse> | undefined) => {
    if (!oldData) {
      return {
        pages: [],
        pageParams: [],
      };
    }

    const pages = oldData.pages.map((p) =>
      p.suppliers.items.some((r) => r.supplierId === result.supplierId)
        ? {
            suppliers: {
              items: p.suppliers.items.filter((r) => r.supplierId !== result.supplierId),
              cursor: p.suppliers.cursor,
            },
          }
        : p
    );

    return {
      pageParams: oldData.pageParams,
      pages,
    };
  };
