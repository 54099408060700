import {
  getTotalEntityQuantity as _getTotalEntityQuantity,
  roundEntityQuantity as _roundEntityQuantity,
} from "@web/common";
import { Money, ParsingError } from "@web/models";
import { PriceModifierUi } from "@web/ui";
import { formatMoney } from "@web/utils";

import {
  Attachment,
  AttachmentInformation,
  ChangedItem,
  OrderChange,
  PriceModifierInformation,
  PriceModifierRequest,
  SupplierPortalOrder,
  SupplierPortalOrderLineItem,
  SupplierPortalProductSku,
} from "src/typegens";

import { Attachment as AttachmentsAttachment } from "../../attachments";
import {
  DraftSupplierOrderForm,
  DraftSupplierOrderSchema,
  ProductSkuForm,
  ProductSkuSchema,
  SupplierConfirmOrderParams,
  SupplierOrder,
  SupplierOrderAmountAdditionalCostForm,
  SupplierOrderAmountDiscountForm,
  SupplierOrderAttachment,
  SupplierOrderChanges,
  SupplierOrderItemForm,
  SupplierPriceModifier,
  SupplierUiOrder,
  SupplierUiOrderChange,
  SupplierUiOrderItem,
  ValidatedSupplierOrderForm,
} from "../models";

export const SupplierOrderService = {
  /**
   * Converters
   */
  convertSupplierOrderFormToApiConfirmOrderRequest: ({
    supplierOrderForm,
    note,
  }: {
    supplierOrderForm: ValidatedSupplierOrderForm;
    note: string;
  }): SupplierConfirmOrderParams => ({
    requestBody: {
      supplierOrderNotes: note,
      version: supplierOrderForm.version,
    },
    s2SOrderId: supplierOrderForm.orderId,
  }),
  convertFromFormPriceModifierToPriceModifierRequest: (
    priceModifier: SupplierOrderAmountAdditionalCostForm | SupplierOrderAmountDiscountForm,
    type: PriceModifierRequest["changeType"],
    version: number
  ): PriceModifierRequest => ({
    name: priceModifier.name,
    // In case of a discount inverse the sign as API expects to receive a positive number
    value: type === "DISCOUNT" ? -priceModifier.amount.amount : priceModifier.amount.amount,
    changeType: type,
    version,
  }),
  convertFromApiToSupplierOrder: (apiOrder: SupplierPortalOrder): SupplierOrder => ({
    ...apiOrder,
    createdAt: new Date(apiOrder.createdAt),
    updatedAt: new Date(apiOrder.updatedAt),
    cancellationRequestReceiveDate: apiOrder.cancellationRequestReceiveDate
      ? new Date(apiOrder.cancellationRequestReceiveDate)
      : undefined,
    attachments: apiOrder.attachments.map((attachment) =>
      SupplierOrderService.convertFromApiAttachmentToOrderAttachment(attachment)
    ),
    amountAdditionalCosts: apiOrder.priceModifiers
      .filter((priceModifier) => priceModifier.changeType === "ADDITIONAL")
      .map((priceModifier) =>
        SupplierOrderService.convertFromApiPriceModifierToOrderPriceModifier(priceModifier)
      ),
    amountDiscounts: apiOrder.priceModifiers
      .filter((priceModifier) => priceModifier.changeType === "DISCOUNT")
      .map((priceModifier) =>
        SupplierOrderService.convertFromApiPriceModifierToOrderPriceModifier(priceModifier)
      ),
  }),
  convertFromApiPriceModifierToOrderPriceModifier: (
    apiPriceModifier: PriceModifierInformation
  ): SupplierPriceModifier => ({
    id: apiPriceModifier.id,
    name: apiPriceModifier.name,
    amount: {
      ...apiPriceModifier.amount,
      // Inverse the sign for discounts, as discounts come from the API as positive numbers
      amount:
        apiPriceModifier.changeType === "DISCOUNT"
          ? -apiPriceModifier.amount.amount
          : apiPriceModifier.amount.amount,
    },
  }),
  convertFromApiAttachmentToOrderAttachment: (
    apiAttachment: AttachmentInformation
  ): SupplierOrderAttachment => ({
    ...apiAttachment,
    createdDate: new Date(apiAttachment.createdDate),
  }),
  convertFromOrderAttachmentToValidatedAttachment: (
    orderAttachment: SupplierOrderAttachment
  ): AttachmentsAttachment => ({
    id: orderAttachment.attachmentId,
    name: orderAttachment.name,
    size: orderAttachment.size,
    category: orderAttachment.category,
    createdBy: orderAttachment.createdBy,
    createdDate: orderAttachment.createdDate,
  }),
  convertFromValidatedAttachmentToApiLinkToOrderRequestAttachment: (
    attachment: AttachmentsAttachment
  ): Attachment => ({
    attachmentId: attachment.id,
    category: attachment.category,
  }),
  convertSupplierOrderToDraftFormData: (orderData: SupplierOrder): DraftSupplierOrderForm => {
    // We need to make sure that skuDetails is in the order's items. Otherwise, further changes
    // are required.
    const parseResult = DraftSupplierOrderSchema.safeParse(orderData);

    if (!parseResult.success) {
      throw new ParsingError("This order is missing attributes", parseResult.error);
    }

    return parseResult.data;
  },
  convertSupplierOrderToValidatedFormData: (
    orderData: SupplierOrder
  ): ValidatedSupplierOrderForm => {
    const parseResult = DraftSupplierOrderSchema.safeParse(orderData);

    if (!parseResult.success) {
      throw new ParsingError("This order is missing attributes", parseResult.error);
    }

    return parseResult.data;
  },
  convertSkuToReplacementOrderItem: (
    replacedItem: SupplierOrderItemForm,
    sku: SupplierPortalProductSku
  ): SupplierOrderItemForm => {
    const parseResult = ProductSkuSchema.safeParse(sku);

    if (!parseResult.success) {
      throw new ParsingError("This SKU is missing attributes", parseResult.error);
    }

    const skuDetails = parseResult.data;

    const quantityForNewSku = SupplierOrderService.matchQuantityForReplacementItem(
      replacedItem,
      skuDetails
    );

    const entityQuantity = SupplierOrderService.getTotalEntityQuantity(
      skuDetails.salesEntityQuantity,
      quantityForNewSku
    );

    const amount = SupplierOrderService.getOrderItemTotalAmount(
      {
        ...replacedItem,
        entityQuantity,
        skuDetails,
      },
      quantityForNewSku
    );

    // Manually set all the fields so if model changes the compiler will warn about unmapped new fields
    return {
      id: skuDetails.id,
      replacementForItemId: replacedItem.id,
      entityQuantity,
      quantity: quantityForNewSku,
      skuDetails,
      lineNumber: replacedItem.lineNumber,
      totalAmount: {
        ...replacedItem.totalAmount,
        amount,
      },
      impaCode: skuDetails?.about?.generalInformation?.impaCode,
      measurementUnit: skuDetails?.measurementUnit,
      supplierIdentifier: skuDetails?.supplierIdentifier,
      name: skuDetails?.about?.name,
    };
  },
  convertSkuToAddOrderItem: (sku: SupplierPortalProductSku): SupplierOrderItemForm => {
    const parseResult = ProductSkuSchema.safeParse(sku);

    if (!parseResult.success) {
      throw new ParsingError("This SKU is missing attributes", parseResult.error);
    }

    const skuDetails = parseResult.data;

    // When adding item we should start from 1. Then it may be translated to minimum order quantity if needed.
    const quantity = 1;

    const entityQuantity = SupplierOrderService.getTotalEntityQuantity(
      skuDetails.salesEntityQuantity,
      quantity
    );

    const addOrderItem: SupplierOrderItemForm = {
      id: skuDetails.id,
      replacementForItemId: undefined,
      entityQuantity,
      quantity,
      skuDetails,
      lineNumber: SupplierOrderService.getLineNumberForAddedItem(),
      impaCode: skuDetails?.about?.generalInformation?.impaCode,
      measurementUnit: skuDetails?.measurementUnit,
      supplierIdentifier: skuDetails?.supplierIdentifier,
      name: skuDetails?.about?.name,
      // Temporarily set empty value - will be calculated in the next step
      totalAmount: {
        currencyCode: "",
        amount: 0,
      },
    };

    const amount = SupplierOrderService.getOrderItemTotalAmount(addOrderItem, quantity);

    return {
      ...addOrderItem,
      totalAmount: {
        currencyCode: skuDetails.price.costPrice.currencyCode,
        amount,
      },
    };
  },
  convertOrderItemDataToUiModel: (
    item: SupplierPortalOrderLineItem | SupplierOrderItemForm | ChangedItem,
    lineNumber: number
  ): SupplierUiOrderItem => ({
    ...item,
    lineNumber,
  }),
  convertToSupplierOrderChanges: (
    orderItems: SupplierUiOrderItem[],
    orderChanges: OrderChange[]
  ): SupplierOrderChanges => {
    if (orderItems.length < 1 || orderChanges?.length < 1) {
      // Return empty values
      return {
        unchanged: [],
        added: [],
        removed: [],
        updated: [],
        replaced: [],
      };
    }

    return {
      unchanged: SupplierOrderService.getUnchangedItems(orderItems, orderChanges),
      added: SupplierOrderService.getChangedItems(orderChanges, "ADDED"),
      removed: SupplierOrderService.getChangedItems(orderChanges, "REMOVED"),
      updated: SupplierOrderService.getChangedItems(orderChanges, "UPDATED"),
      replaced: SupplierOrderService.getChangedItems(orderChanges, "REPLACED"),
    };
  },
  convertOrderPriceModifiersToUiModel: (
    priceModifier: SupplierPriceModifier[]
  ): PriceModifierUi[] =>
    priceModifier.map((additionalCost) => ({
      name: additionalCost.name,
      amount: formatMoney(additionalCost.amount),
    })),

  /**
   * Formatters
   */
  formatTotalGrossAmount: (order: Pick<SupplierOrder, "totalGrossAmount">): string =>
    formatMoney(order.totalGrossAmount),

  formatZeroAmountInOrderCurrency: (order: Pick<SupplierOrder, "totalGrossAmount">): string =>
    formatMoney({ amount: 0, currencyCode: order.totalGrossAmount.currencyCode }),

  formatOrderItemTotal: (itemTotal: Money): string => formatMoney(itemTotal),

  formatUnitPriceWithUnit: (unitPrice: Money, measurementUnit: string): string =>
    `${formatMoney(unitPrice)} / ${measurementUnit}`,

  formatOrderItemsTotalAmount: (
    orderItems: Array<SupplierOrderItemForm | SupplierPortalOrderLineItem>,
    currencyCode: string
  ): string =>
    formatMoney({
      amount: SupplierOrderService.getOrderItemsTotalAmount(orderItems),
      currencyCode,
    }),

  /**
   * Calculators
   */
  // Defined with fallback to manual calculation in case skuDetails does not exist
  getSingleItemPriceAmount: (orderItem: ChangedItem | SupplierOrderItemForm): number =>
    hasSkuDetails(orderItem)
      ? orderItem.skuDetails.price.costPrice.amount
      : orderItem.totalAmount.amount / orderItem.entityQuantity,
  getSingleItemPrice: (orderItem: ChangedItem | SupplierOrderItemForm): Money => ({
    amount: SupplierOrderService.getSingleItemPriceAmount(orderItem),
    currencyCode: orderItem.totalAmount.currencyCode,
  }),
  // Defined with fallback to manual calculation in case skuDetails does not exist
  getSalesEntityQuantity: (orderItem: ChangedItem | SupplierOrderItemForm): number => {
    if (hasSkuDetails(orderItem)) {
      return _roundEntityQuantity(orderItem.skuDetails.salesEntityQuantity);
    }
    // Prevent division by 0
    return orderItem.quantity > 0
      ? _roundEntityQuantity(orderItem.entityQuantity / orderItem.quantity)
      : 0;
  },
  // We don't limit the supplier on how low the quantity can be
  getMinimumOrderQuantityForSupplier: (): number => 1,
  // Defined with fallback to manual calculation in case skuDetails does not exist
  // Price of a single bulk package
  getTotalEntityQuantity: (salesEntityQuantity: number, itemQuantity: number): number =>
    _getTotalEntityQuantity(salesEntityQuantity, itemQuantity),
  /**
   * @return Returned value is not rounded and should be used only for calculations,
   *         e.g. calculating grand total. For displaying purposes additionally
   *         wrap in `formatMoney`.
   */
  getOrderItemTotalAmount: (
    orderItem: SupplierOrderItemForm,
    quantityForCalculation: number
  ): number => {
    const singleEntityPriceAmount = SupplierOrderService.getSingleItemPriceAmount(orderItem);
    const lineItemEntityQuantity = SupplierOrderService.getTotalEntityQuantity(
      SupplierOrderService.getSalesEntityQuantity(orderItem),
      quantityForCalculation
    );
    return singleEntityPriceAmount * lineItemEntityQuantity;
  },
  getLineNumberForAddedItem: () => 0,
  getOrderItemsTotalAmount: (
    orderItems: Array<SupplierOrderItemForm | SupplierPortalOrderLineItem>
  ): number => orderItems.reduce((total, orderItem) => total + orderItem.totalAmount.amount, 0),
  getOrderAmountPriceModifiersAmount: (
    amountPriceModifiers: Array<SupplierOrderAmountAdditionalCostForm | PriceModifierInformation>
  ): number =>
    amountPriceModifiers.reduce(
      (total, amountPriceModifier) => total + amountPriceModifier.amount.amount,
      0
    ),
  getTotalAmountWithPriceModifier: (
    currentTotalAmount: number,
    priceModifierAmount: number
  ): number => {
    const newTotalAmount = Math.round(currentTotalAmount * 100 + priceModifierAmount * 100) / 100;
    return isNaN(newTotalAmount) ? currentTotalAmount : newTotalAmount;
  },

  /**
   * State checkers
   */
  isOrderEditable: (order: SupplierOrder | SupplierUiOrder) => order.editable,
  isOrderUnseen: (order: SupplierOrder | SupplierUiOrder) => !order.seen,
  isOrderIncoming: (order: SupplierOrder | SupplierUiOrder) => order.status === "INCOMING",
  isOrderConfirmed: (order: SupplierOrder | SupplierUiOrder) => order.status === "CONFIRMED",
  isOrderDelivered: (order: SupplierOrder | SupplierUiOrder) => order.status === "DELIVERED",
  isOrderClosed: (order: SupplierOrder | SupplierUiOrder) => order.closed,
  isOrderCancellationRequested: (order: SupplierOrder | SupplierUiOrder) =>
    order.status === "CANCELLATION_REQUESTED",
  isOrderCancelled: (order: SupplierOrder | SupplierUiOrder) => order.status === "CANCELLED",
  hasOrderChanges: (orderChanges: SupplierOrderChanges) =>
    orderChanges.added.length > 0 ||
    orderChanges.removed.length > 0 ||
    orderChanges.updated.length > 0 ||
    orderChanges.replaced.length > 0,
  isAddedItem: (orderItem: SupplierOrderItemForm) => orderItem.lineNumber === 0,
  hasOrderAttachments: (order: SupplierOrder | SupplierUiOrder) => order.attachments.length > 0,
  hasOrderComments: (order: SupplierOrder | SupplierUiOrder) =>
    !!(order.comments && order.comments?.length > 0),
  areAmountsEqual: (amount1: number, amount2: number): boolean =>
    Math.round(amount1 * 100) === Math.round(amount2 * 100),
  isOrderItemReplacement: (orderItem: SupplierOrderItemForm): boolean =>
    !!orderItem.replacementForItemId && orderItem.replacementForItemId !== orderItem.id,

  /**
   * Other utils
   */
  getUnchangedItems: (
    orderItems: SupplierUiOrderItem[],
    orderChanges: OrderChange[]
  ): SupplierUiOrderItem[] =>
    orderItems.filter(
      (orderItem) =>
        !SupplierOrderService.getOrderChangeByIds(
          orderChanges,
          orderItem.id,
          orderItem.supplierIdentifier
        )
    ),
  getChangedItems: (
    orderChanges: OrderChange[],
    changeType: "UPDATED" | "REMOVED" | "ADDED" | "REPLACED"
  ): SupplierUiOrderChange[] =>
    orderChanges
      .filter((orderChange) => orderChange.changeType === changeType)
      .map((orderChange) => ({
        ...orderChange,
        item: SupplierOrderService.convertOrderItemDataToUiModel(
          orderChange.item,
          orderChange.lineNumber
        ),
        previousItem: orderChange.previousItem
          ? SupplierOrderService.convertOrderItemDataToUiModel(
              orderChange.previousItem,
              orderChange.lineNumber
            )
          : undefined,
      })),
  isDefaultOrderType: (order: SupplierOrder) => order.orderType === "DEFAULT",
  // We trust the backend to always provide either item id supplier id
  getOrderChangeByIds: (orderChanges: OrderChange[], itemId?: string, supplierId?: string) =>
    orderChanges.find(
      (orderChange) =>
        orderChange.item.id === itemId && orderChange.item.supplierIdentifier === supplierId
    ),
  matchQuantityForReplacementItem: (
    originalItem: SupplierOrderItemForm,
    replacementSku: ProductSkuForm
  ) => Math.floor(originalItem.entityQuantity / replacementSku.salesEntityQuantity) || 1,
};

const hasSkuDetails = (item: ChangedItem | SupplierOrderItemForm): item is SupplierOrderItemForm =>
  !!(item as SupplierOrderItemForm).skuDetails;
