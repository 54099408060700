import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import {
  Fragment,
  MouseEvent,
  MutableRefObject,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { findDescendantById } from "@web/common/utils";
import { Category } from "@web/models";
import { Paragraph } from "@web/ui";
import { formatNumber, isDefined } from "@web/utils";

type CloseFnType = (
  focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null> | undefined
) => void;

export type CloseDisclosureType = {
  closeDisclosure: () => void;
};

type Props = {
  onCategoryClick: (category: Category) => void;
  openedByDefaultId?: string;
  activeCategoryId?: string;
  isSelectedCategory: boolean;
  categoryLevel1: Category;
};

export const CategoriesTreeElement = memo(
  forwardRef<CloseDisclosureType, Props>(
    (
      { openedByDefaultId, onCategoryClick, activeCategoryId, isSelectedCategory, categoryLevel1 },
      ref
    ) => {
      const closeFnRef = useRef<CloseFnType | null>(null);

      useImperativeHandle(ref, () => ({
        closeDisclosure: () => {
          if (closeFnRef.current) {
            setIsOpen(false);
            closeFnRef.current?.();
          }
        },
      }));

      const defaultOpen = useMemo(
        () =>
          openedByDefaultId
            ? categoryLevel1.id === openedByDefaultId ||
              !!findDescendantById(openedByDefaultId, categoryLevel1)
            : false,
        [categoryLevel1, openedByDefaultId]
      );

      const [isOpen, setIsOpen] = useState(false);
      const [preventOnCategoryClick, setPreventOnCategoryClick] = useState(false);

      const btnRef = useRef<HTMLDivElement>(null);
      const openBtn = btnRef?.current?.firstChild as HTMLElement | null | undefined;

      useEffect(() => {
        if (isOpen && !!openBtn) {
          if (openBtn?.dataset.headlessuiState !== "open") {
            openBtn.click();
            setPreventOnCategoryClick(false);
          }
        }
      }, [isOpen, openBtn]);

      const handleClickCategoryLevel1 = () => {
        if (!preventOnCategoryClick) {
          onCategoryClick(categoryLevel1);
          if (isOpen && !!openBtn) {
            openBtn.click();
          }
          setIsOpen(true);
        }
      };

      const handleClickCategoryLevel1Chevron = useCallback(
        (
          e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
          close: (
            focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null> | undefined
          ) => void
        ) => {
          e.stopPropagation();
          if (isOpen) {
            setPreventOnCategoryClick(false);
            setIsOpen(false);
            close();
          } else {
            setPreventOnCategoryClick(true);
            setIsOpen(true);
          }
        },
        [isOpen]
      );

      return (
        <Disclosure as="div" defaultOpen={defaultOpen} data-testid="categoriesTree_level1">
          {({ open, close }) => {
            closeFnRef.current = close;

            const DisclosureButtonIconComponent = open ? ChevronDownIcon : ChevronRightIcon;

            return (
              <div ref={btnRef}>
                <Disclosure.Button as={Fragment}>
                  <div
                    className="py-1 flex items-center group cursor-pointer"
                    onClick={handleClickCategoryLevel1}
                    data-testid="categoriesTree_level1_header"
                  >
                    <Paragraph
                      size="200"
                      weight={
                        activeCategoryId === categoryLevel1.id || isSelectedCategory
                          ? "heavy"
                          : "light"
                      }
                      color={
                        activeCategoryId === categoryLevel1.id
                          ? "text-primaryDefault"
                          : ["text-textIcon-blackPrimary", "group-hover:text-primaryDefault"]
                      }
                    >
                      {categoryLevel1.name}&nbsp;
                      {isDefined(categoryLevel1.productsNumber) && (
                        <span
                          className="text-gray-500"
                          data-testid="categoriesTree_level1_quantity"
                        >
                          ({formatNumber(categoryLevel1.productsNumber)})
                        </span>
                      )}
                    </Paragraph>
                    {categoryLevel1.children.length > 0 && (
                      <div className="ml-auto">
                        <button
                          className={classnames(
                            "flex justify-center items-center",
                            "h-[20px] w-[20px] min-h-[20px] min-w-[20px] max-h-[20px] max-w-[20px]",
                            "border-none rounded-full",
                            "focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-white ring-primaryDefault",
                            "relative overflow-hidden whitespace-nowrap leading-none",
                            {
                              "text-textIcon-blackSecondary hover:text-textIcon-blackPrimary":
                                !open,
                              "text-textIcon-blackPrimary": open,
                            }
                          )}
                          type="button"
                          data-testid="categoriesTree_level1_button"
                          onClick={(e) => handleClickCategoryLevel1Chevron(e, close)}
                        >
                          <DisclosureButtonIconComponent
                            className="w-[12px] h-[12px]"
                            aria-label="Expand/Contract Category"
                            focusable={false}
                          />
                        </button>
                      </div>
                    )}
                  </div>
                </Disclosure.Button>
                {categoryLevel1.children.length > 0 && (
                  <Disclosure.Panel
                    as="div"
                    className={classnames("pl-4", {
                      "mb-1": open,
                    })}
                    data-testid="categoriesTree_level1_nestedContent"
                  >
                    {categoryLevel1.children.map((categoryLevel2) => (
                      <Paragraph
                        key={categoryLevel2.id}
                        size={"200"}
                        className="py-1 cursor-pointer"
                        weight={
                          activeCategoryId === categoryLevel2.id || isSelectedCategory
                            ? "heavy"
                            : "light"
                        }
                        color={
                          activeCategoryId === categoryLevel2.id
                            ? "text-primaryDefault"
                            : ["text-textIcon-blackPrimary", "hover:text-primaryDefault"]
                        }
                        onClick={() => onCategoryClick(categoryLevel2)}
                        data-testid="categoriesTree_level2_header"
                      >
                        {categoryLevel2.name}&nbsp;
                        {isDefined(categoryLevel2.productsNumber) && (
                          <span
                            className="text-gray-500"
                            data-testid="categoriesTree_level2_quantity"
                          >
                            ({formatNumber(categoryLevel2.productsNumber)})
                          </span>
                        )}
                      </Paragraph>
                    ))}
                  </Disclosure.Panel>
                )}
              </div>
            );
          }}
        </Disclosure>
      );
    }
  )
);

CategoriesTreeElement.displayName = "CategoriesTreeElement";
