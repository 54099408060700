import { forwardRef } from "react";

import { AvatarChildren } from "./AvatarChildren";
import { AvatarCommonProps } from "./models";
import { useAvatarCommons } from "./useAvatarCommons";

/**
 * This type merges:
 * - the common props (like `className` etc.),
 * - the native props of the main tag (<a> tag in this case),
 * - and sets more rigid typechecking for the `onClick` prop, so either `href` or `onClick` is required.
 *
 * Please note that the <a> tag, in order to present the outline upon focus, needs to have a `href` attribute.
 */
export type AvatarAnchorProps = AvatarCommonProps &
  Omit<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    "children" | "href" | "onClick" | keyof AvatarCommonProps
  > &
  (
    | {
        href: string;
        onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
      }
    | {
        href?: string;
        onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
      }
  );

export const AvatarAnchor = forwardRef<HTMLAnchorElement, AvatarAnchorProps>(
  (props, forwardedRef) => {
    const { mainTagCommonProps, defaultedProps } = useAvatarCommons<HTMLAnchorElement>(props);
    // Removing illegal props so only valid ones are applied to the HTML tag
    const { avatarProps, disabled, ...anchorTagProps } = props;

    return (
      <a
        {...anchorTagProps}
        {...mainTagCommonProps}
        ref={forwardedRef}
        tabIndex={defaultedProps.disabled ? -1 : undefined}
      >
        <AvatarChildren {...props} />
      </a>
    );
  }
);
AvatarAnchor.displayName = "AvatarAnchor";
