import { DATE_STYLE, TIME_STYLE } from "../config";

export const LEGACY_formatDateTime = (
  dateTime?: Date,
  options?: {
    dateStyle?: "full" | "long" | "medium" | "short" | undefined;
    timeStyle?: "full" | "long" | "medium" | "short" | undefined;
    timeZone?: string;
  }
) => {
  if (!dateTime) {
    return "";
  }
  const defaultedOptions: Intl.DateTimeFormatOptions = {
    dateStyle: DATE_STYLE,
    timeStyle: TIME_STYLE,
    ...options,
  };
  return new Intl.DateTimeFormat("en-GB", defaultedOptions).format(dateTime);
};
