import { createContext, useContext } from "react";

import { CloseModalCallback } from "../../hooks/useModal";

interface ModalContextType {
  closeModal: CloseModalCallback;
  component: React.ReactElement | null;
  isOpen: boolean;
  openModal: (component: React.ReactElement) => void;
}

export const ModalContext = createContext<ModalContextType | null>(null);

export const useModalContext = () => useContext(ModalContext) as ModalContextType;
