import { DefaultError, InfiniteData, QueryKey, useInfiniteQuery } from "@tanstack/react-query";

import { SuppliersResponse } from "src/typegens";

import { getSuppliers } from "../api";

export const useSuppliersQuery = (options = {}) =>
  useInfiniteQuery<
    SuppliersResponse,
    DefaultError,
    InfiniteData<SuppliersResponse>,
    QueryKey,
    string
  >({
    queryKey: ["suppliersList"],
    queryFn: ({ pageParam }) => getSuppliers(pageParam),
    initialPageParam: "0",
    ...options,
    getNextPageParam: (lastPage) => {
      if (lastPage.suppliers.cursor !== "") {
        return lastPage.suppliers.cursor;
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
